import * as React from 'react';
import { Button } from '@mui/material';

import TabLayout from '../../../components/tabs';

import './index.scss';
import DynamicFormToSignTab from './toSign.tab';
import DynamicFormHistoryTab from './history.tab';
import DynamicFormEmptyFormsTab from './emptyFormsTab';
import ProviderPortalHeader from '../components/header';
import ProviderDynamicFormSectionToCompleteTab from './toCompleteTab';
import DynamicFormsUploadTab from './uploadTab';
import DynamicCustomFormsUploadForm from './customFormsUpload';
import ModalPopUp from '../../../components/modal/modal.popup';
import { translateThis } from '../../../helpers/language.helper';



const ProviderDynamicFormSection = () => {
    const [showPopup, setShowPopup] = React.useState(false);

    const tabNames = ["To complete", "To sign", "History", "Download", "To uploads",];
    const tabContents = [
        <span key={'to_complete'}><ProviderDynamicFormSectionToCompleteTab /></span>,
        <span key={'to_agreements'}><DynamicFormToSignTab /></span>,
        <span key={'to_allForms'}><DynamicFormHistoryTab /></span>,
        <span key={'to_emptyDownloadForms'}><DynamicFormEmptyFormsTab /></span>,
        <span key={'to_emptyForms'}><DynamicFormsUploadTab /></span>,
    ];

    return (
        <><ProviderPortalHeader header='Forms' />
            <Button onClick={() => setShowPopup(true)} className='red-btn providerFormBtn' style={{ maxWidth: '275px', float: 'right', marginBottom: '20px' }}>+ {translateThis("Create custom form")}</Button>
            <div className='tab-sec-wrp pay-table dailyComms-sec-tab providerForm-section f-wrp'>
                <TabLayout
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div>
            {showPopup ? <ModalPopUp
                isShowBackButton={false}
                onClose={() => setShowPopup(false)}
                isOpen={showPopup}
            >
                <DynamicCustomFormsUploadForm
                    onSuccess={() => setShowPopup(false)}
                />
            </ModalPopUp> : null}
        </>
    )
}

export default ProviderDynamicFormSection;