import * as React from 'react';
import TabLayout from '../../../components/tabs';

import './index.scss';

import ProviderPortalHeader from '../components/header';
import AttendanceList from './attendanceList';
import AttendanceDailyEntry from './attendanceDailyEntry';

const AttendanceTab = () => {
    
    const tabNames = ["Daily","Attendance"];
    const tabContents = [
        <span key={'to_daily'}><AttendanceDailyEntry /></span>,        
        <span key={'to_attendance'}><AttendanceList /></span>,
    ];

    return (
        <>
            <div className='tab-sec-wrp pay-table Attendance-section f-wrp'>
                <TabLayout
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div>
        </>
    )
}

export default AttendanceTab;