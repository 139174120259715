import * as React from 'react';

import TabLayout from '../../../components/tabs';
import ProviderPoliciesCreateTab from './createTab';
import ProviderPoliciesUploadTab from './uploadTab';
import ProviderPortalHeader from '../components/header';

const ProviderPoliciesSection = () => {

    const tabNames = ["To create", "upload"];
    const tabContents = [
        <ProviderPoliciesCreateTab key={'to_create'} />,
        <ProviderPoliciesUploadTab key={'to_upload'} />,
    ];

    return (
        <><ProviderPortalHeader header='Policies' />
            <div className='tab-sec-wrp pay-table f-wrp'>
                <TabLayout
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div>
        </>
    )
}

export default ProviderPoliciesSection;