import React from 'react';
import { GridPagination, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { PropTypes } from 'prop-types';

/**
     * The pagination component
     * @returns JSX
     */
function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  }

  Pagination.propTypes = {
    className: PropTypes.string,
    onPageChange: PropTypes.func,
    page: PropTypes.number

  }


function ClientSidePagination(props) {
    // eslint-disable-next-line
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  export default ClientSidePagination;