import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { getAuthRoleName, isLoggedIn, logoutUserSilent } from '../helpers/auth.helper';
import { showSnackbar } from '../components/snackbar/index';


/**
 * Private routes only authenticated users can access
 * @param {JSX} children 
 * @returns 
 */
const PrivateRoute = ({ children, allowOnly = '', ...rest }) => {
    const isAuth = isLoggedIn();
    const currentlyAuthRoleName = allowOnly !== '' ? getAuthRoleName() : '';
    const location = useLocation();
    const navigate = useNavigate();

    /**
     * Legendary useEffect
     */
    useEffect(() => {
        let message = '';
        if (!isAuth) {
            message = "Please login to continue."
        }
        if (allowOnly !== currentlyAuthRoleName) {
            message = "You are not authorized to visit this page.";
        }
        if (message) {
            showSnackbar({ timeout: 1000 }, () => {
                logoutUserSilent();
                navigate("/auth/login", {
                    state: { from: location }
                });
            }).info(message)

        }
    }, [allowOnly, currentlyAuthRoleName, isAuth, location, navigate]);
    return (
        <div {...rest}>
            {(isAuth && (allowOnly === currentlyAuthRoleName)) ? (
                children
            ) : null}
        </div>

    );
}

PrivateRoute.propTypes = {
    children: propTypes.any,
    // role name for which user should be allowed to view the route
    allowOnly: propTypes.string
}
export default PrivateRoute;