import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';
import { DATE_FORMAT } from '../../constants';



const ProviderCommunicationFormView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp providerCommunicationView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.logo} alt='profile' /></span>
                    </div>
                </div>
                <div className='gap' />

                <h2 style={{ marginBottom: '0' }}>{translateThis("DAILY COMMUNICATION")}</h2>
                <h3>{translateThis("Provider communication")}</h3>
                <div className='gap' />
                <div className="flex-box padding_btm15">
                    <p>{translateThis("Child name")}: <b>{child?.firstName}&nbsp;{child?.lastName}</b></p>
                </div>
                <div className="flex-box padding_btm15">
                    <p>{translateThis("Communication date")}: <b>{dayjs(data.logDate).format(DATE_FORMAT)}</b></p>
                    <p>{translateThis("Submission date")}: <b>{dayjs(data.submissionDate).format(DATE_FORMAT)}</b></p>
                </div>
                <div className='gap' />

                <div className="basic-details padding_btm15">
                    <div className="auto-flex">
                        <span>{translateThis("food")}:</span>
                        <div>
                            <p>{translateThis("breakFast")}: <b>{answerGetter('provider_communication_form_question_breakfast', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("morningSnack")}: <b>{answerGetter('provider_communication_form_question_morningSnack', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Lunch")}: <b>{answerGetter('provider_communication_form_question_lunch', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("afternoonSnack")}: <b>{answerGetter('provider_communication_form_question_afternoonSnack', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("dinner")}: <b>{answerGetter('provider_communication_form_question_dinner', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="auto-flex">
                        <span>{translateThis("diaperChange")}:</span>
                        <div style={{ width: '100%' }}>
                            <div className='flex-box'>
                                <p>{translateThis("firstChange")}: <b>{answerGetter('provider_communication_form_change_condition', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Time")}: <b>{answerGetter('provider_communication_form_question_firstChangeTime', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Staff")}: <b>{answerGetter('provider_communication_form_staffName', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>{translateThis("secondChange")}: <b>{answerGetter('provider_communication_form_change_condition_2', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Time")}: <b>{answerGetter('provider_communication_form_question_secondChangeTime', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Staff")}: <b>{answerGetter('provider_communication_form_staffName_2', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>{translateThis("thirdChange")}: <b>{answerGetter('provider_communication_form_change_condition_3', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Time")}: <b>{answerGetter('provider_communication_form_question_thirdChangeTime', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Staff")}: <b>{answerGetter('provider_communication_form_staffName_3', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>{translateThis("fourthChange")}: <b>{answerGetter('provider_communication_form_change_condition_4', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Time")}: <b>{answerGetter('provider_communication_form_question_fourthChangeTime', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Staff")}: <b>{answerGetter('provider_communication_form_staffName_4', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>{translateThis("fifthChange")}: <b>{answerGetter('provider_communication_form_change_condition_5', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Time")}: <b>{answerGetter('provider_communication_form_question_fifthChangeTime', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Staff")}: <b>{answerGetter('provider_communication_form_staffName_5', formData)?.answer || '-'}</b></p>
                            </div>
                            <div className='flex-box'>
                                <p>{translateThis("sixChange")}: <b>{answerGetter('provider_communication_form_change_condition_6', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Time")}: <b>{answerGetter('provider_communication_form_question_sixChangeTime', formData)?.answer || '-'}</b></p>
                                <p>{translateThis("Staff")}: <b>{answerGetter('provider_communication_form_staffName_6', formData)?.answer || '-'}</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="auto-flex">
                        <span>{translateThis("Child needs")}:</span>
                        <p><b>{
                            answerGetter('provider_communication_form_question_childNeeds', formData)?.rawAnswer
                                ? answerGetter('provider_communication_form_question_childNeeds', formData)
                                    .rawAnswer.map((item) => item).join(', ')

                                : `-`
                        }</b> {answerGetter("c4k_parentProviderAgreement_sectionThree_form_Key_QN_8_2", formData)?.answer === "Other" ? <>{translateThis("Others")}: <b>{answerGetter('provider_communication_form_change_condition_7', formData)?.answer}</b></> : null} </p>
                    </div>
                </div>
                <div className="auto-flex padding_btm15">
                    <span>{translateThis("Comments")}:</span>
                    <p><b>{answerGetter('provider_communication_form_comments', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex padding_btm15">
                    <span>{translateThis("Incidents before childcare")}:</span>
                    <p><b>{answerGetter('provider_communication_form_incident', formData)?.answer || '-'}</b></p>
                </div>
                <div className="signature-blk">
                    <div className="details-section">
                        <div className="lft-section">
                            <div style={{ textAlign: 'center', float: 'left' }}>
                                <h3>{data.additionalInfo?.providerSignature || '-'}</h3>
                                <p>{translateThis("Provider signature")}</p>
                                <p>{translateThis("Submission date and time")}: <b>{data.additionalInfo?.providerSignedDate}</b></p>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div style={{ textAlign: 'center', float: 'right' }}>
                                <h3>{data.additionalInfo?.parentSignature || '-'}</h3>
                                <p>{translateThis("Parent Guardian signature")}</p>
                                <p>{translateThis("Submission date and time")}: <b>{data.additionalInfo?.parentSignedDate}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

ProviderCommunicationFormView.propTypes = {
    data: PropTypes.any
}
export default ProviderCommunicationFormView;