import Translate from 'translate';
import { getRecoil, setRecoil } from 'recoil-nexus';
import en from '../translations/en.json';

import { appConfigAtomState } from '../state/reducerAtoms/app.atom';
import { showSnackbar } from '../components/snackbar/index';



/**
 * translate the key based on user language selection
 * @param {string} key string
 * @returns string
 */

export const translateThis = (key = '') => {
    try {
        const appState = JSON.parse(window.sessionStorage.getItem('kcolors-atom-persist'));
        const languageState = appState?.appConfigAtomState || {};

        const userLanguage = languageState.selectedLang || 'en';
        let languageFile = {};

        switch (userLanguage) {
            case 'en':
                languageFile = en;
                break;
            case 'es':
                languageFile = languageState.translationJson;
                break;
            default:
                languageFile = en;
                break;
        }
        return languageFile[key] || key;
    } catch (e) {
        return key;

    }

}

/**
 * Switches the user language
 * @param {string} lang the language to switch
 * @returns Object
 */
export const switchLanguage = async (lang = 'en') => {
    showSnackbar().info("Changing language please wait...");
    Translate.engine = "google";
    const translatedObject = {};
    await Promise.all(Object.keys(en).map(async (key) => {
        if (en[key]) {
            const translatedValue = await Translate(en[key], lang);
            translatedObject[key] = translatedValue;
        }
    }));
    const appState = getRecoil(appConfigAtomState);
    setRecoil(appConfigAtomState, { ...appState, selectedLang: lang, translationJson: translatedObject });
    showSnackbar().success("Language changed successfully!");
    window.location.reload()
    return translatedObject;
}