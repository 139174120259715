import * as React from 'react';

import TabLayout from '../../../components/tabs';
import ProviderDailyLogToCompleteTab from './toCompleteTab';
import ProviderDailyLogToSignTab from './toSignTab';
import ProviderDailyLogHistoryTab from './historyTab';
import ProviderPortalHeader from '../components/header';
// import DailyCommsDownloadTab from './downloadTab';
import DailyCommsUploadTab from './uploadTab';

const ProviderDailyLogSection = () => {

    const tabNames = ["To complete", "To sign", "History", "To uploads"];
    const tabContents = [
        <ProviderDailyLogToCompleteTab key={'to_complete'} />,
        <ProviderDailyLogToSignTab key={'to_sign'} />,
        <ProviderDailyLogHistoryTab key={'to_history'} />,
        // <DailyCommsDownloadTab key={'to_downloads'}/>,
        <DailyCommsUploadTab key={'to_uploads'} />
    ];

    return (
        <>
        <ProviderPortalHeader header='Daily communication' />
            <div className='tab-sec-wrp pay-table dailyComms-sec-tab f-wrp'>
                <TabLayout
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div>
        </>
    )
}

export default ProviderDailyLogSection;