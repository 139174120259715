import React from 'react';
import { Link } from '@mui/material';
import AppIcons from '../../assets/images/icons';

import './footer.scss';



export default function AppFooter() {
    return (
        <div>
            <div className="project-main-footer f-wrp">
                <div className="container">
                    <div className='copy-foot f-wrp'>
                        <div className='float-icon'>
                            <Link onClick={() => window.open('https://wa.me/3472572417', '_blank')}><img src={AppIcons.whastapp} alt='whatsapp'/></Link>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="copy-txt f-wrp">
                                    <p>© 2023 Kcolorful | Terms </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className='dev-blk'>
                                    <p style={{ textAlign: 'center', fontWeight: '400', color: '#fff', marginTop: '5px' }}>Built by &nbsp;<i style={{ cursor: 'pointer', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ textDecoration: "none", fontStyle: 'normal', cursor: 'pointer', color: '#fff' }}>mihapp.co</span> &nbsp;<img style={{ display: 'inline-block', width: '25px', maxWidth: '25px', }} src={AppIcons.mihapp} alt="mihapp" /></i></p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className='social-list f-wrp'>
                                    <ul>
                                        <li><span><img src={AppIcons.fb} alt='social media'/></span></li>
                                        {/* <li><span><img src={AppIcons.insta} alt='social media'/></span></li> */}
                                        <li><Link onClick={() => window.open('https://www.instagram.com/kcolorfuldaycare/?hl=en', '_blank')}><span><img src={AppIcons.insta} alt='insta' /></span></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}