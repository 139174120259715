import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import dayjs from 'dayjs';

// @mui
import { Stack, Divider, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';

// Styles
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// components

import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';
import { getUniqueId } from '../../../../helpers/helper.functions';

import { DATE_TIME_EXTENDED_FORMAT, DATE_TIME_FORMAT } from '../../../../constants/index';

// ........................................................................



const EmergencyNumber = (props) => {
    const formName = 'emergency-number';
    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [initialFormData, setInitialFormData] = useState(null);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey('')
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData({temp:'temp'}) // Temp fix to show forms
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                } else {
                    // setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (childId && selectedChild) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName,
            formTitle: 'Emergency number',
            userId: parentId,
            childId,
            formData,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: '',
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_EXTENDED_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }
    };

    

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading  && initialFormData) ? <div>

                <>

                    <Stack key={formKey}  className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head f-wrp'>
                            <h2>{translateThis("Emergency Number")}</h2>
                            <table className='emergency-contact-table'>
                                <tbody>
                                    <tr>
                                        <td style={{minWidth: '200px'}}>{translateThis("FIRE 911")}</td>
                                        <td>{translateThis("Poison Control 1-800-222-1222")}</td>
                                    </tr>
                                    <tr>
                                        <td style={{minWidth: '200px'}}>{translateThis("POLICE 911")}</td>
                                        <td>{translateThis("OEC Child Care Licensing 1-800-282-6063 or 1-860-500-4450")}</td>
                                    </tr>
                                    <tr>
                                        <td style={{minWidth: '200px'}}>{translateThis("AMBULANCE 911")}</td>
                                        <td>{translateThis("Child Abuse Care Line 1-800-842-2288")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Emergency Caregiver")}</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`emergency_form_Key_name`}
                                    label={translateThis('emergency_form_question_name')}
                                    name='emergency_form_question_name'
                                    id='emergency_form_question_name'
                                    value={answerGetter('emergency_form_question_name', initialFormData)?.answer}
                                    translationKey='emergency_form_question_name'
                                    type='text'
                                    required
                                    placeholder='Name'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_name',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`emergency_form_Key_phone`}
                                    label={translateThis('emergency_form_question_phone')}
                                    name='emergency_form_question_phone'
                                    id='emergency_form_question_phone'
                                    value={answerGetter('emergency_form_question_phone', initialFormData)?.answer}
                                    translationKey='emergency_form_question_phone'
                                    type='number'
                                    required
                                    placeholder={translateThis('emergency_form_question_phone')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_phone',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis("Child")}</h3>
                                </div>
                            </Grid>

                            {selectedChild && <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`emergency_form_Key_childName`}
                                    label={translateThis('emergency_form_question_childName')}
                                    name='emergency_form_question_childName'
                                    id='emergency_form_question_childName'
                                    value={answerGetter('emergency_form_question_childName', initialFormData)?.answer || `${selectedChild?.firstName || ''} ${selectedChild?.lastName || ''}`}
                                    translationKey='emergency_form_question_childName'
                                    type='text'
                                    required
                                    placeholder={translateThis('emergency_form_question_childName')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_childName',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>}
                            <Grid item xs={12} sm={6} md={6} className='mob-display-none'>
                                <Divider style={{ display: 'none' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`emergency_form_Key_parentName_1`}
                                    label={translateThis('emergency_form_question_parentName_1')}
                                    name='emergency_form_question_parentName_1'
                                    id='emergency_form_question_parentName_1'
                                    value={answerGetter('emergency_form_question_parentName_1', initialFormData)?.answer}
                                    translationKey='emergency_form_question_parentName_1'
                                    type='text'
                                    required
                                    placeholder={translateThis('emergency_form_question_parentName_1')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_parentName_1',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} className='mob-display-none'>
                                <Divider style={{ display: 'none' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`emergency_form_Key_workNumber_1`}
                                    label={translateThis('emergency_form_question_workNumber_1')}
                                    name='emergency_form_question_workNumber_1'
                                    id='emergency_form_question_workNumber_1'
                                    value={answerGetter('emergency_form_question_workNumber_1', initialFormData)?.answer}
                                    translationKey='emergency_form_question_workNumber_1'
                                    type='number'
                                    required
                                    placeholder={translateThis('emergency_form_question_workNumber_1')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_workNumber_1',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`emergency_form_Key_homeNumber_1`}
                                    label={translateThis('emergency_form_question_homeNumber_1')}
                                    name='emergency_form_question_homeNumber_1'
                                    id='emergency_form_question_homeNumber_1'
                                    value={answerGetter('emergency_form_question_homeNumber_1', initialFormData)?.answer}
                                    translationKey='emergency_form_question_homeNumber_1'
                                    type='number'
                                    required
                                    placeholder={translateThis('emergency_form_question_homeNumber_1')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_homeNumber_1',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`emergency_form_Key_cellPhoneNumber_1`}
                                    label={translateThis('emergency_form_question_cellPhoneNumber_1')}
                                    name='emergency_form_question_cellPhoneNumber_1'
                                    id='emergency_form_question_cellPhoneNumber_1'
                                    value={answerGetter('emergency_form_question_cellPhoneNumber_1', initialFormData)?.answer}
                                    translationKey='emergency_form_question_cellPhoneNumber_1'
                                    type='number'
                                    required
                                    placeholder={translateThis('emergency_form_question_cellPhoneNumber_1')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_cellPhoneNumber_1',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            
                            
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`emergency_form_Key_parentName_2`}
                                    label={translateThis('emergency_form_question_parentName_2')}
                                    name='emergency_form_question_parentName_2'
                                    id='emergency_form_question_parentName_2'
                                    value={answerGetter('emergency_form_question_parentName_2', initialFormData)?.answer}
                                    translationKey='emergency_form_question_parentName_2'
                                    type='text'
                                    required
                                    placeholder={translateThis('emergency_form_question_parentName_2')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_parentName_2',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} className='mob-display-none'>
                                <Divider style={{ display: 'none' }} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`emergency_form_Key_workNumber_2`}
                                    label={translateThis('emergency_form_question_workNumber_2')}
                                    name='emergency_form_question_workNumber_2'
                                    id='emergency_form_question_workNumber_2'
                                    value={answerGetter('emergency_form_question_workNumber_2', initialFormData)?.answer}
                                    translationKey='emergency_form_question_workNumber_2'
                                    type='number'
                                    required
                                    placeholder={translateThis('emergency_form_question_workNumber_2')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_workNumber_2',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`emergency_form_Key_homeNumber_2`}
                                    label={translateThis('emergency_form_question_homeNumber_2')}
                                    name='emergency_form_question_homeNumber_2'
                                    id='emergency_form_question_homeNumber_2'
                                    value={answerGetter('emergency_form_question_homeNumber_2', initialFormData)?.answer}
                                    translationKey='emergency_form_question_homeNumber_2'
                                    type='number'
                                    required
                                    placeholder={translateThis('emergency_form_question_homeNumber_2')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_homeNumber_2',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`emergency_form_Key_cellPhoneNumber_2`}
                                    label={translateThis('emergency_form_question_cellPhoneNumber_2')}
                                    name='emergency_form_question_cellPhoneNumber_2'
                                    id='emergency_form_question_cellPhoneNumber_2'
                                    value={answerGetter('emergency_form_question_cellPhoneNumber_2', initialFormData)?.answer}
                                    translationKey='emergency_form_question_cellPhoneNumber_2'
                                    type='number'
                                    required
                                    placeholder={translateThis('emergency_form_question_cellPhoneNumber_2')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'emergency_form_question_cellPhoneNumber_2',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                </>

                <br />

                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='pln-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        {(parentId && childId && formName === "sample123") ? <PDFDownloadButton
                            className='pln-btn'
                            parentId={parentId}
                            childId={childId}
                            formName={formName}
                        /> : null}

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm()}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            {translateThis("submit")}
                        </LoadingButton>

                    </div>
                </div>
            </div> : null}
        </>
    );
}

EmergencyNumber.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func
}

export default EmergencyNumber;