import React from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import './index.scss'

import { getAuthId } from '../../../helpers/auth.helper';
import { getProfile, updateProfile } from '../../../services/api/auth';
import { showSnackbar } from '../../../components/snackbar/index';



const ProviderAccountDesign = () => {
    const [design, setDesign] = React.useState({});
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    React.useEffect(() => {
        getDesign()
    }, []);

    const getDesign =()=> {
        getProfile().then((data)=> {
            if (data?.design) {
                setDesign(data?.design)
            }
            
        })
    }

    const onDataChange = (_, value) => {
        setDesign(value)

    }

    const onSubmit = () => {
        if (getAuthId()) {
            const postData = {
                design
            }
            setIsSubmitting(true)
            updateProfile(postData)
                .then(() => showSnackbar().success('Profile updated successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => window.location.reload())
        }

    }


    return (
        <>
            <div className='panel-form-sec forms-main-wrapper account-design f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>

                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <br />
                                <span style={{ color: '#0F0A01', display: 'block' }}>Change Parent Portal colors</span>
                                <span style={{ color: '#98948A', fontWeight: '400', display: 'block', marginBottom: '15px' }}>Select color for background</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ theme: 'light-bg' } })} item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                            <div className={`clr-box light ${design?.theme === 'light-bg' ? 'selected' : ''}`}>
                                <span>Light</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ theme: 'dark-bg' } })} item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                            <div className={`clr-box dark ${design?.theme === 'dark-bg' ? 'selected' : ''}`}>
                                <span>Dark</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            <div className='info-head f-wrp'>
                                <br />
                                <span style={{ color: '#98948A', fontWeight: '400', display: 'block', marginBottom: '15px' }}>Select color for highlights and buttons, this will affect Provider Portal and Parent Portal appearance.</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'blue-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box btnClr_1 ${design?.buttonTheme === 'blue-clr' ? 'selected' : ''}`}>
                                <span>Blue</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'green-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box btnClr_2 ${design?.buttonTheme === 'green-clr' ? 'selected' : ''}`}>
                                <span>Green</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'orange-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box btnClr_3 ${design?.buttonTheme === 'orange-clr' ? 'selected' : ''}`}>
                                <span>Orange</span>
                            </div>
                        </Grid>
                        <Grid onClick={() => onDataChange('design', { ...design, ...{ buttonTheme: 'yellow-clr' } })} item xs={12} sm={6} md={3} style={{ paddingTop: '0' }}>
                            <div className={`clr-box btnClr_4 ${design?.buttonTheme === 'yellow-clr' ? 'selected' : ''}`}>
                                <span>Yellow</span>
                            </div>
                        </Grid>

                    </Grid>
                    <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                        <div className='ryt-btn-sec'>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                className='fill-btn'
                                onClick={onSubmit}
                                style={{ backgroundColor: '#FF3D00' }}
                            >
                                Save changes
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
ProviderAccountDesign.propTypes = {};
export default ProviderAccountDesign;
