import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import Grid from '@mui/material/Grid';

import { showSnackbar } from '../../../components/snackbar';
import SVGIcons from '../../../assets/images/icons/svgIcons';

// ----------------------------------------------------------------------
import { getAuthDetailsByKey, getAuthId } from '../../../helpers/auth.helper';
import { DATE_TIME_READABLE_FORMAT, TIME_12HR_FORMAT, TIME_12HR_EXTENDED_FORMAT } from '../../../constants/index';
import ApiCaller from '../../../services/api/general';
import ChildrenListDropdown from '../../../components/dropdowns/children.dropdown';
import TimeInput from '../../../components/inputs/timeInput';
import { translateThis } from '../../../helpers/language.helper';



const AttendanceDailyEntry = () => {
    const apiCaller = new ApiCaller('attendance')
    const authId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [formData, setFormData] = useState([]);
    const [currentTime, setCurrentTime] = useState(dayjs());
    const [clockIn, setClockIn] = useState('');
    const [clockOut, setClockOut] = useState('');


    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (authId) {
            getData()
        }
        setInterval(() => {
            setCurrentTime(dayjs())
        }, 1000);
        return () => {
            setFormData({})
        };
        // eslint-disable-next-line
    }, [authId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData('status')
            .then((data) => {
                console.log(data)
                setFormData(data)
                if (data?.checkIn) {
                    setClockIn(dayjs(data?.checkIn).format(TIME_12HR_FORMAT))
                }
                if (data?.checkOut) {
                    setClockOut(dayjs(data?.checkOut).format(TIME_12HR_FORMAT))
                }

            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        const isValid = true;
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {}
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    getData()
                    showSnackbar().success("Successfully clocked in!")
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    console.log(getAuthDetailsByKey('id'))

    /**
     * The signup form elements
     */


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className=' Attendance-form-wrp standard-form' spacing={1}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack direction="column" alignItems="flex-start" justifyContent="space-between" sx={{ my: 2 }}>
                                <div className='info-head f-wrp'>
                                    <br />
                                    <span style={{ display: 'flex' }}><SVGIcons.ClockIcon /> &nbsp; {dayjs().format(DATE_TIME_READABLE_FORMAT)}</span>
                                </div>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="form-chooser-wrp">
                                <div className="child-selection-div f-wrp">
                                    <ChildrenListDropdown />
                                </div>

                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <TimeInput
                                key={`clock-in-time`}
                                label={"Check in"}
                                name={"Check in"}
                                value={clockIn}
                            // defaultValue={dayjs(answerGetter(`clockIn`)?.answer, TIME_12HR_FORMAT)}
                            // onChange={(v) => {
                            //     setFormValue({
                            //         questionId: `clock-in-time`,
                            //         answer: dayjs(v).format(TIME_12HR_FORMAT),
                            //         rawAnswer: dayjs(v),
                            //         translationKey: `clock-in-time`,
                            //         type: 'text'
                            //     })
                            // }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TimeInput
                                key={`clock-out-time`}
                                value={clockOut}
                                label={"Check Out"}
                                name={"Check Out"}
                            // defaultValue={dayjs(answerGetter(`clockIn`)?.answer, TIME_12HR_FORMAT)}
                            // onChange={(v) => {
                            //     setFormValue({
                            //         questionId: `clock-in-time`,
                            //         answer: dayjs(v).format(TIME_12HR_FORMAT),
                            //         rawAnswer: dayjs(v),
                            //         translationKey: `clock-in-time`,
                            //         type: 'text'
                            //     })
                            // }}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                <h1 style={{ margin: '0' }}>{dayjs(currentTime).format(TIME_12HR_EXTENDED_FORMAT)}</h1>
                                <span style={{ fontWeight: '700' }}>{translateThis('Current time')}</span>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='button-sec'>
                                {!clockOut ? <LoadingButton
                                    loading={isSubmitting}
                                    loadingPosition='end'
                                    endIcon={clockIn ? <SVGIcons.ClockOut /> : <SVGIcons.ClockIn />} // To remove Mui warning
                                    fullWidth
                                    disabled={!isValidForm()}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className='login-btn'
                                    onClick={onSubmit}>
                                    {clockIn ? <>{translateThis('Check in')}</> : <>{translateThis('Check in')}</>}
                                </LoadingButton> : <span className='center-txt'>{translateThis('attendanceSuccessMessage')}</span>}
                                    <br/>
                                <LoadingButton
                                    loading={isSubmitting}
                                    loadingPosition='end'
                                    fullWidth
                                    disabled={!isValidForm()}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className='pln-btn'
                                    onClick={onSubmit}>
                                    {translateThis('Mark absent')}
                                </LoadingButton>
                            </div>

                            <br />
                            <br />
                            <br />
                            <br />
                        </Grid>
                    </Grid>
                </Stack>
            </div>}
        </>
    );
}

AttendanceDailyEntry.propTypes = {
}

export default AttendanceDailyEntry;