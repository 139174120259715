import * as React from 'react';
import { DeleteRounded } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { Grid, IconButton } from '@mui/material';
import AppIcons from '../../../assets/images/icons';

import './index.scss';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';


const DynamicFormEmptyFormsTab = () => {
    const apiCaller = new ApiCaller('documents')
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataArr, setDataArr] = React.useState([]);

    React.useEffect(() => {
        getDocuments();
        // eslint-disable-next-line
    }, [])

    /**
     * Get the uploaded documents from API
     */
    const getDocuments = () => {
        setIsLoading(true);
        apiCaller.getList({ query: '&type=form' }).then((data) => {
            setDataArr(data?.results || [])
        }).catch(() => null)
            .finally(() => setIsLoading(false))
    }

    /**
         * On submit the form
         */
    const deleteFileFromCloud = (id) => {
        setIsLoading(true);
        // Submitting data to backend for registration
        apiCaller.deleteData(id).then((data) => {
            if (data.success) {
                showSnackbar().success("File deleted successfully!");
                getDocuments();
            } else {
                showSnackbar().failure(data.message)

            }
        }).finally(() => setIsLoading(true));

    };


    const childCareFormsSet = [
        {
            title: '1.Child enrollment',
            url: 'https://kcolors-dev.s3.amazonaws.com/1.+Child+enrollment.pdf'
        },
        {
            title: '2.Emergency numbers',
            url: 'https://kcolors-dev.s3.amazonaws.com/5.+New-Emergency-Numbers-English.pdf'
        },
        {
            title: '3.Written permission',
            url: 'https://kcolors-dev.s3.amazonaws.com/6.+NEW-Written-Permission-Form.pdf'
        },

    ];
    const careForChildFormsSet = [
        {
            title: '6.C4K Application',
            url: 'https://kcolors-dev.s3.amazonaws.com/8.+English-C4K-Application.Checklist.final-002.docB_.pdf'
        },
        {
            title: '7.C4K Redetermination',
            url: 'https://kcolors-dev.s3.amazonaws.com/10.+REDET-ENGLISH-9.2021.pdf'
        },
        {
            title: '8.Parent-Provider agreement ',
            url: 'https://kcolors-dev.s3.amazonaws.com/9.+Parent-Provider-Agreement-PPA-English-2020.pdf'
        },
        {
            title: '9.Summer Parent-Provider agreement',
            url: 'https://kcolors-dev.s3.amazonaws.com/11.+Summer-PPA-English-1.pdf'
        },
    ]

    const situationalFormsSet = [
        {
            title: '10.Self employment',
            url: 'https://kcolors-dev.s3.amazonaws.com/Self-Employment-Form-English2023.pdf'
        },
        {
            title: '11.Disability verification',
            url: 'https://kcolors-dev.s3.amazonaws.com/Disability-Verification-Form.pdf'
        },
        {
            title: '12.Medical exemption',
            url: 'https://kcolors-dev.s3.amazonaws.com/medical_exemption_immunization_299204_421576_37726_v1.pdf'
        },
        {
            title: '13.Accident',
            url: 'https://kcolors-dev.s3.amazonaws.com/B.+Accident+form+front.pdf'
        },
        {
            title: '14.Childcare incident log',
            url: 'https://kcolors-dev.s3.amazonaws.com/7.+NEW-IncidentLog-Enrollment-Packet-1.pdf'
        },
    ]



    return (
        <>
            <div className='panel-form-sec forms-main-wrapper f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>
                <div className='info-txt'>
                    <span>Download empty forms to print and hand them to the parents if necessary:</span>
                </div>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', paddingBottom: '15px', display: 'block' }}>Custom forms:</span>
                            </div>
                            {isLoading ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> : <div className='pdf-download f-wrp'>
                                {dataArr.map((element) => (
                                    <>
                                        <div key={element.id}>
                                            <span><IconButton children={<DeleteRounded />} onClick={() => deleteFileFromCloud(element.id)} />
                                                <Link className='each-pdf-download' target={'_blank'} to={element.file?.tempURL} >
                                                    <img src={AppIcons.pdfIcon} alt={element.name} />
                                                    <p>{element.name}</p>
                                                </Link>
                                            </span>
                                        </div >
                                    </>
                                ))}
                            </div>
                            }

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', paddingBottom: '15px', display: 'block', fontWeight: 'bold' }}>Childcare forms:</span>
                            </div>
                            <div className='pdf-download f-wrp'>
                                {childCareFormsSet.map((element, key) => (
                                    <Link className='each-pdf-download' target='_blank' to={element.url} key={key}>
                                        <span><img src={AppIcons.pdfIcon} alt={element.title} /></span>
                                        <p>{element.title}</p>
                                    </Link>
                                ))}
                            </div>

                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', paddingBottom: '15px', display: 'block' }}>Care 4 Kids forms:</span>
                            </div>
                            <div className='pdf-download f-wrp'>
                                {careForChildFormsSet.map((element, key) => (
                                    <Link className='each-pdf-download' target='_blank' to={element.url} key={key}>
                                        <span><img src={AppIcons.pdfIcon} alt={element.title} /></span>
                                        <p>{element.title}</p>
                                    </Link>
                                ))}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='info-head f-wrp'>
                                <span style={{ color: '#0F0A01', paddingBottom: '15px', display: 'block' }}>Situational forms:</span>
                            </div>
                            <div className='pdf-download f-wrp'>
                                {situationalFormsSet.map((element, key) => (
                                    <Link className='each-pdf-download' target='_blank' to={element.url} key={key}>
                                        <span><img src={AppIcons.pdfIcon} alt={element.title} /></span>
                                        <p>{element.title}</p>
                                    </Link>
                                ))}
                            </div>
                        </Grid>

                    </Grid>
                </div>
            </div>
        </>
    );
}

export default DynamicFormEmptyFormsTab;