import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import dayjs from 'dayjs';

// @mui
import { Stack, Divider, TextareaAutosize } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';

// services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChildFormBy } from '../../../../services/api/child';

// helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT } from '../../../../constants/index';
// ----------------------------------------------------------------------


const ParentsInfoForm = (props) => {
    const formName = 'parents-info';
    const { childId } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState([]);
    const [initialFormData, setInitialFormData] = useState({});

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (childId && parentId) {
            getData()
        }
        return () => {
            setInitialFormData({})
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData(data)
                if (data?.formData) {
                    setFormData(data?.formData)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (formData.name && formData.email && formData.message) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName,
            userId: parentId,
            childId,
            formData
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    // navigate('/', { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    /**
     * The signup form elements
     */
    const formElements = [
        {
            label: 'Patients Name',
            name: 'patientsName',
            questionId: 'patientsName',
            value: answerGetter('patientsName', initialFormData?.formData)?.answer,
            translationKey: 'patientsName',
            type: 'text',
            required: true,
            placeholder: 'Please enter Patients Name',
            className: 'custom-textfield',
            getValue: (name, value) => {
                setFormValue({
                    questionId: name,
                    answer: value,
                    translationKey: 'patientsName',
                    type: 'text'
                })
            }
        },
        {
            label: 'Print Name of Parent or supervising Adult',
            name: 'superVisingName',
            value: answerGetter('superVisingName', initialFormData?.formData)?.answer,
            translationKey: 'superVisingName',
            type: 'text',
            required: true,
            placeholder: 'Please enter your Parent/supervising Adult name',
            className: 'custom-textfield',
            getValue: (name, value) => {
                setFormValue({
                    questionId: name,
                    answer: value,
                    translationKey: 'superVisingName',
                    type: 'text'
                })
            }
        }
    ];

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='client-forms-verification standard-form' spacing={3}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h2>Parents information</h2>
                            <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                <h2>Parent/supervising adult authorization to release information</h2>
                                <p>I hereby authorize the release of the requested medical/psychiatric information to the State of
                                    Connecticut Office of Early Childhood Care 4 Kids program for:</p>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack className='login-form standard-form' spacing={3}>
                    {formElements.map((element, key) => (
                        <TextInput
                            key={`register-input-${key}`}
                            {...element}
                            useDefaultValidation
                            className='custom-textfield'
                        />
                    ))}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                value={answerGetter('sampleDate', initialFormData?.formData)?.answer ?
                                    dayjs(answerGetter('sampleDate', initialFormData?.formData)?.answer) :
                                    undefined
                                }
                                onChange={(v) => {
                                    setFormValue({
                                        questionId: 'sampleDate',
                                        answer: dayjs(v).format(DATE_FORMAT),
                                        translationKey: 'sampleDate',
                                        type: 'text'
                                    })
                                }
                                }
                                label="Date" />
                        </DemoContainer>
                    </LocalizationProvider>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                        <Divider />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                        <Stack>
                            <h2>To be completed by Child Physician or certified Health care Professionals</h2>
                            <p>The parent or supervising adult of the child named above has requested increased child care payments from
                                Care 4 Kids on the basis that the child requires extra supervision and care due to their medical/psychiatric
                                disability or impairment. Complete the following information to verify the special needs of this child. Care 4
                                Kids will need this form completed to determine the level of child care payments.
                            </p>
                        </Stack>
                    </Stack>

                    <FormControl>
                        <FormLabel id="sampleRadioOne">{translateThis('sampleRadioOne')}</FormLabel>
                        <RadioGroup row
                            aria-labelledby="sampleRadioOne"
                            defaultValue={answerGetter('sampleRadioOne', initialFormData?.formData)?.answer}
                            name="sampleRadioOne"
                            onChange={(e) => {
                                setFormValue({
                                    questionId: 'sampleRadioOne',
                                    answer: e.target.value,
                                    translationKey: 'sampleRadioOne',
                                    type: 'radio'
                                })
                            }}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel id="sampleRadioTwo">{translateThis('sampleRadioTwo')}</FormLabel>
                        <RadioGroup row
                            aria-labelledby="sampleRadioTwo"
                            defaultValue={answerGetter('sampleRadioTwo', initialFormData?.formData)?.answer}
                            name="sampleRadioTwo"
                            onChange={(e) => {
                                setFormValue({
                                    questionId: 'sampleRadioTwo',
                                    answer: e.target.value,
                                    translationKey: 'sampleRadioTwo',
                                    type: 'radio'
                                })
                            }}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                        <p>{translateThis("sampleTextArea")}</p>
                    </Stack>
                    <TextareaAutosize
                        defaultValue={answerGetter('sampleTextArea', initialFormData?.formData)?.answer}
                        onChange={(e) => setFormValue({
                            questionId: 'sampleTextArea',
                            answer: e.target.value,
                            translationKey: 'sampleTextArea',
                            type: 'textArea'
                        })}
                        maxRows={5}
                        minRows={5}
                        aria-label="Please describe any special accommodations the child requires in the child care setting and attach any
                    pertinent information."
                        placeholder="Please describe any special accommodations the child requires in the child care setting and attach any
                    pertinent information."
                        className="no-padding-btm"
                    />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                        <Divider />
                    </Stack>

                </Stack>
                <br />
                <LoadingButton
                    loading={isSubmitting}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!isValidForm()}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='login-btn'
                    onClick={onSubmit}>
                    Save
                </LoadingButton>
                <br />
                {(parentId && childId && formName === "sample123") ? <PDFDownloadButton
                    parentId={parentId}
                    childId={childId}
                    formName={formName}
                /> : null}
                <br />
            </div>}
        </>
    );
}

ParentsInfoForm.propTypes = {
    childId: propTypes.string
}

export default ParentsInfoForm;