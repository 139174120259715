import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { showSnackbar } from '../../../components/snackbar';
import TimeInput from '../../../components/inputs/timeInput';
import { translateThis } from '../../../helpers/language.helper';
import { getAuthDetailsByKey, getAuthId } from '../../../helpers/auth.helper';
import { DATE_TIME_READABLE_FORMAT, TIME_12HR_FORMAT, TIME_12HR_EXTENDED_FORMAT } from '../../../constants/index';
import ApiCaller from '../../../services/api/general';
import ChildrenListDropdown from '../../../components/dropdowns/children.dropdown';

const AttendanceEdit = () => {
    const apiCaller = new ApiCaller('attendance')
    const authId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [formData, setFormData] = useState([]);
    const [currentTime, setCurrentTime] = useState(dayjs());
    const [clockIn, setClockIn] = useState('');
    const [clockOut, setClockOut] = useState('');


    /**
the legendary use effect function
This will run very first on render
 */
    useEffect(() => {
        if (authId) {
            getData()
        }
        setInterval(() => {
            setCurrentTime(dayjs())
        }, 1000);
        return () => {
            setFormData({})
        };
        // eslint-disable-next-line
    }, [authId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData('status')
            .then((data) => {
                console.log(data)
                setFormData(data)
                if (data?.checkIn) {
                    setClockIn(dayjs(data?.checkIn).format(TIME_12HR_FORMAT))
                }
                if (data?.checkOut) {
                    setClockOut(dayjs(data?.checkOut).format(TIME_12HR_FORMAT))
                }

            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }



    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    // const isValidForm = () => {
    //     const isValid = true;
    //     return isValid;
    // }

    /**
     * On submit the form
     */
    // const onSubmit = () => {
    //     const postData = {}
    //     if (isValidForm()) {
    //         // Submitting data to backend for registration
    //         setIsSubmitting(true);
    //         apiCaller.postData(postData).then((data) => {
    //             if (data.success) {
    //                 getData()
    //                 showSnackbar().success("Successfully clocked in!")
    //             } else {
    //                 showSnackbar().failure(data.message)

    //             }
    //         }).finally(() => setIsSubmitting(false));

    //     }

    // };








    return (
        <>
            <div className='Attendance-section-edit parent-portal f-wrp'>
                <div className='prof-sec'>
                    <div className='prof-basic'>
                        <h4>Child name</h4>
                        <p><b>{translateThis('Date')}:</b> 20th August 2023</p>
                    </div>
                </div>
                <div className='Attendance-details'>
                    <h3>{translateThis('Attendance')}</h3>
                    <ul>
                        <li><p><b>{translateThis('Check in')}:</b> <TimeInput
                            key={`clock-in-time`}
                            label={"Check in"}
                            name={"Check in"}
                            value={clockIn}
                        // defaultValue={dayjs(answerGetter(`clockIn`)?.answer, TIME_12HR_FORMAT)}
                        // onChange={(v) => {
                        //     setFormValue({
                        //         questionId: `clock-in-time`,
                        //         answer: dayjs(v).format(TIME_12HR_FORMAT),
                        //         rawAnswer: dayjs(v),
                        //         translationKey: `clock-in-time`,
                        //         type: 'text'
                        //     })
                        // }}
                        /></p></li>
                        <li><p><b>{translateThis('By')}:</b> {translateThis('Guardian')}</p></li>
                        <li><p><b>{translateThis('Check out')}:</b> <TimeInput
                            key={`clock-out-time`}
                            value={clockOut}
                            label={"Check Out"}
                            name={"Check Out"}
                        // defaultValue={dayjs(answerGetter(`clockIn`)?.answer, TIME_12HR_FORMAT)}
                        // onChange={(v) => {
                        //     setFormValue({
                        //         questionId: `clock-in-time`,
                        //         answer: dayjs(v).format(TIME_12HR_FORMAT),
                        //         rawAnswer: dayjs(v),
                        //         translationKey: `clock-in-time`,
                        //         type: 'text'
                        //     })
                        // }}
                        /></p></li>
                        

                        <li><p><b>{translateThis('By')}:</b> {translateThis('Mother')}</p></li>
                    </ul>
                    <p className='note'>{translateThis('AttendanceNote')}</p>
                    <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                        {/* <div className='lft-btn-sec'>
                            <Button className='cancel-btn'>{translateThis('cancel')}</Button>
                        </div> */}
                        <div className='ryt-btn-sec pln-btn-style'>
                            <Button className='pln-btn'>{translateThis('Mark absent')}</Button>
                            <Button className='fill-btn'>{translateThis('Accept')}</Button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AttendanceEdit;