import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';
// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';


const ChildEnrollmentView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp childEnrollmentView f-wrp">

                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.logo} alt='profile' /></span>
                    </div>
                </div>
                <div className='gap' />

                <h2>{translateThis("CHILD ENROLLMENT FORM")}</h2>
                <div className='flex-box'>
                    <p>{translateThis("Application date")}: <b>{answerGetter('child_enrollment_form_DateOFApplication', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("Enrollment date")}: <b>{answerGetter('child_enrollment_form_date_of_enrollment', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("Last day of enrollment")}: <b>{answerGetter('child_enrollment_form_last_day_of_enrollment', formData)?.answer || '-'}</b></p>
                </div>
                <div className='gap' />
                <p>{translateThis("childEnrollmentH1")}: <b>{translateThis("childEnrollmentH1DETAILS")}</b></p>
                <div className='gap' />

                <div className="details-section fontWeight-normal">
                    <div className="lft-section">
                        <p>{translateThis("Child's Name")}: <b>{answerGetter('child_enrollment_form_question_name', formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                        <p>{translateThis("Child's address")}: <b>{answerGetter('child_enrollment_form_question_address', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className="ryt-section">
                        <p>{translateThis("Child's Date of birth")}: <b>{answerGetter('child_enrollment_form_Key_birthday', formData)?.answer || dayjs(child?.birthday).format(DATE_FORMAT) || '-'}</b></p>
                        <div className="flex-box">
                            <p>{translateThis("City")}: <b>{answerGetter('child_enrollment_form_question_city', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Zipcode")}: <b>{answerGetter('child_enrollment_form_question_zipcode', formData)?.answer || '-'}</b></p>
                        </div>

                    </div>
                </div>
                <div className='gap' />
                {/* <h4>Responsible adult {answerGetter('child_enrollment_form_no_of_adults', formData)?.answer ? '#1' : ''}</h4> */}
                <div className="details-section fontWeight-normal">
                    <div className="lft-section">
                        <p>{translateThis("Parent/Guardian Name")}: <b>{answerGetter('child_enrollment_form_question_name_2', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Address")}: <b>{answerGetter('child_enrollment_form_question_address_2', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("E-mail")}: <b>{answerGetter('child_enrollment_form_question_email', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Telephone")}: <b>{answerGetter('child_enrollment_form_question_cellphone', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Employer name")}: <b>{answerGetter('child_enrollment_form_question_EmployerName', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Employer address")}: <b>{answerGetter('child_enrollment_form_question_workAddress', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className="ryt-section">
                        {/* <p>Role: <b>{answerGetter('child_enrollment_form_Key_role', formData)?.answer || '-'}</b></p> */}
                        <div className="flex-box">
                            <p>{translateThis("City")}: <b>{answerGetter('child_enrollment_form_question_city_2', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Zipcode")}: <b>{answerGetter('child_enrollment_form_question_zipcode_2', formData)?.answer || '-'}</b></p>
                        </div>
                        <p>{translateThis("Cellphone")}: <b>{answerGetter('child_enrollment_form_question_telephone', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Emergency contact")}: <b>{answerGetter('child_enrollment_form_question_emergencyContact', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Work number")}: <b>{answerGetter('child_enrollment_form_question_workTelephone', formData)?.answer || '-'}</b></p>
                        <div className="flex-box">
                            <p>{translateThis("City")}: <b>{answerGetter('child_enrollment_form_question_city_3', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("Zipcode")}: <b>{answerGetter('child_enrollment_form_question_zipcode_3', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                </div>
                {answerGetter('child_enrollment_form_no_of_adults', formData)?.answer ? <>
                    {answerGetter('child_enrollment_form_no_of_adults', formData)?.rawAnswer.map((no) => (
                        <>
                        <div className='gap' />
                                {/* <h4>Responsible adult {answerGetter('child_enrollment_form_no_of_adults', formData)?.answer ? `#${i+2}` : ''}</h4>                                 */}
                            <div key={`details-${no}`} className="details-section fontWeight-normal">
                                <div className="lft-section">
                                    <p>{translateThis("Parent/Guardian Name")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_name`, formData)?.answer || '-'}</b></p>
                                    <p>{translateThis("Address")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_address`, formData)?.answer || '-'}</b></p>
                                    <p>{translateThis("E-mail")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_email`, formData)?.answer || '-'}</b></p>
                                    <p>{translateThis("Telephone")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_telephone`, formData)?.answer || '-'}</b></p>
                                    <p>{translateThis("Employer name")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_employerName`, formData)?.answer || '-'}</b></p>
                                    <p>{translateThis("Employer address")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_workAddress`, formData)?.answer || '-'}</b></p>
                                </div>
                                <div className="ryt-section">
                                    <div className="flex-box">
                                        <p>{translateThis("City")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_city`, formData)?.answer || '-'}</b></p>
                                        <p>{translateThis("Zipcode")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_zipcode`, formData)?.answer || '-'}</b></p>
                                    </div>
                                    <p>{translateThis("Cellphone")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_cellphone`, formData)?.answer || '-'}</b></p>
                                    <p>{translateThis("Emergency contact")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_emergencyContact`, formData)?.answer || '-'}</b></p>
                                    <p>{translateThis("Work number")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_workTelephone`, formData)?.answer || '-'}</b></p>
                                    <div className="flex-box">
                                        <p>{translateThis("City")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_workCity`, formData)?.answer || '-'}</b></p>
                                        <p>{translateThis("Zipcode")}: <b>{answerGetter(`child_enrollment_form_question_adult_${no}_workZipCode`, formData)?.answer || '-'}</b></p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </> : null}
                <h4>{translateThis("Weekly schedule")}</h4>
                <div className="details-section">
                    <div className="lft-section">
                        <table>
                            <tbody>
                                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                                    .map((day) => <tr key={day}>
                                        <td>{day}</td>
                                        <td><span>{translateThis("From")}</span>
                                            {answerGetter(`weekly_schedule_${day}_from`, formData)?.answer || '-'}
                                        </td>
                                        <td><span>{translateThis("To")}</span>
                                            {answerGetter(`weekly_schedule_${day}_to`, formData)?.answer || '-'}</td>
                                    </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="signature-blk">
                    <div style={{ maxWidth: '9cm' }}>
                        <h3>{
                            data.additionalInfo && data.additionalInfo.parentSignature
                                ? data.additionalInfo.parentSignature
                                : ''
                        }</h3>
                        <p>{translateThis("Parent / guardian signature")}</p>
                        <p>{translateThis("Submission time")}: <b>{
                            data.additionalInfo && data.additionalInfo.parentSignedDate
                                ? data.additionalInfo.parentSignedDate
                                : ''
                        }</b></p>
                    </div>
                </div>
            </div>
        </>
    )
}

ChildEnrollmentView.propTypes = {
    data: PropTypes.any
}
export default ChildEnrollmentView;