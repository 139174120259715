import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ApiCaller from '../../services/api/general';
import { getDynamicFormPreview } from "../../helpers/dynamicForm.helper";

const PdfPreviewPage = () => {
    const apiCaller = new ApiCaller('pdf-generate/pdf-preview');
    const { formId = '' } = useParams();
    const [preview, setPreview] = useState(<></>);


    /**
     * Legendary use effect
     */
    useEffect(() => {
        if (formId) {
            getFormData()
        }
        return () => {
            setPreview(<></>)
        };
        // eslint-disable-next-line
    }, [formId]);


    /**
     * Gets the form data
     */
    const getFormData = () => {
        apiCaller.getData(formId).then((data) => {
            if (data && data.formName) {
                const previewSelected = getDynamicFormPreview(data.formName, { data });
                setPreview(previewSelected);
            } else if (data && data.logKey) {
                const previewSelected = getDynamicFormPreview(data.logKey, { data });
                setPreview(previewSelected);
            } else {
                setPreview(<h3>Form not found</h3>)
            }
        }).finally(() => {
            setTimeout(() => {
                replaceContent();
            }, 1000);

        })

    }

    const replaceContent = () => {
        const classListArr = document.getElementsByClassName('check-box');
        // eslint-disable-next-line
        for (let i = 0; i < classListArr.length; i++) {
            const eachClass = classListArr[i];
            const elements = eachClass.getElementsByTagName('li');
            // eslint-disable-next-line
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                if (element.classList.contains('checked')) {
                    const img = document.createElement("img");
                    img.className = "img-size"
                    // img.style="width:25px, height: 25px"
                    img.src = "https://kcolors-dev.s3.amazonaws.com/checked.png";
                    element.prepend(img)
                } else {
                    const img = document.createElement("img");
                    img.className = "img-size-unchecked"
                    // img.style="width:25px, height: 25px"
                    img.src = "https://kcolors-dev.s3.amazonaws.com/uncheck.png";
                    element.prepend(img)
                }
            }
        }
    }


    return (
        <div className="pdf-preview-render">
            <img style={{ display: 'none' }} src="https://kcolors-dev.s3.amazonaws.com/checked.png" alt="check" />
            <img style={{ display: 'none' }} src="https://kcolors-dev.s3.amazonaws.com/uncheck.png" alt="uncheck" />
            {preview}
        </div>
    )
}


export default PdfPreviewPage;