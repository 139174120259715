import * as React from 'react';
import Box from '@mui/material/Box';
import { useRecoilState } from 'recoil';

import ParentList from './parentList';
import ParentView from './parentView';
import { getUniqueId } from '../../../helpers/helper.functions';

import './index.scss';

import { providerConfigState } from '../../../state/reducerAtoms/provider.atom';
import ChildView from './childView';


export default function ParentSection() {
    const [configState, setConfigState] = useRecoilState(providerConfigState);

    /**
     * Gets the content to display based on the current selection
     */
    const getSelectedContent = () => {
        const selectedItem = configState.selectedPageView || 'parent-list';
        let contentSelected = <></>
        switch (selectedItem) {
            case 'parent-list':
                contentSelected = <ParentList
                    key={getUniqueId('parent-list')}
                    onViewClick={(id) => {
                        modifyConfigState({ selectedParentId: id, selectedPageView: 'parent-view' });
                    }}
                />;
                break;
            case 'parent-view':
                contentSelected = configState.selectedParentId ? <ParentView
                    key={`${getUniqueId(configState.selectedParentId)}-parent-key-${configState.selectedParentId}`}
                    id={configState.selectedParentId}
                    onClose={() => {
                        modifyConfigState({ selectedParentId: "", selectedPageView: "parent-list" });
                    }}
                    onSelectChild={(childId) => {
                        modifyConfigState({ selectedPageView: "child-view", selectedChildId: childId });
                    }}
                /> : <span>Loading...</span>;

                break;
            case 'child-view':
                contentSelected = configState.selectedChildId ? <ChildView
                    key={`${getUniqueId(configState.selectedChildId)}-parent-key-${configState.selectedChildId}`}
                    id={configState.selectedChildId}
                    onClose={() => {
                        modifyConfigState({ selectedParentId: configState.selectedParentId, selectedPageView: "parent-view" });
                    }}
                /> : <span>Loading...</span>;

                break;
            case 'payment':
                contentSelected = <span>Switch payment</span>;
                break;
            case 'forms':
                contentSelected = <span>Switch forms</span>;
                break;
            case 'policies':
                contentSelected = <span>Switch policies</span>;
                break;
            case 'account':
                contentSelected = <span>Switch account</span>;
                break;
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        return contentSelected;
    }

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...modifiedObj }));

    return (
        <>
            <Box component="div" className='parents-main-wrp f-wrp' sx={{ flexGrow: 1, p: 3, }}>
                {getSelectedContent()}
            </Box>
        </>
    );
}