import * as React from 'react';
import TabLayout from '../../../components/tabs';
import DailyLogToCompleteTab from './toComplete.tab';
import DailyLogToSignTab from './toSign.tab';
import DailyLogUploadsTab from './uploads.tab';
import DailyLogHistoryTab from './history.tab';

const DailyLogs = () => {
    const tabNames = ["To Complete", "To Sign", "History", "To uploads"];
    return (
        <div className='dailylog-wrp-tab'>
            <TabLayout
                tabNames={tabNames}
                tabContents={[
                    <DailyLogToCompleteTab key={'to_complete'} />,
                    <DailyLogToSignTab key={'to_sign'} />,
                    <DailyLogHistoryTab key={'history'} />,
                    <DailyLogUploadsTab key={'uploads'} />,
                ]}
            />
        </div>
    )
}

export default DailyLogs;