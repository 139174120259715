import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { IconButton } from '@mui/material';

import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppIcons from '../../../assets/images/icons';

import './children.scss';
import { childModel } from '../../../models/child.model';
import { DATE_FORMAT } from '../../../constants';
import { getAgeFromBirthday } from '../../../helpers/helper.functions';
import { translateThis } from '../../../helpers/language.helper';



const ChildProfileCard = (props) => {
    const { child = childModel,
        onEdit = () => null,
        onDelete = () => null,
    } = props;

    return (
        <>
            <div className='profile-card f-wrp'>
                <div className='btn-sec f-wrp'>
                    <ul>
                        <li>
                            <IconButton
                                onClick={() => { onDelete() }}>
                                {SVGIcons.DeleteIcon()}
                            </IconButton>
                        </li>
                        <li>
                            <IconButton
                                onClick={() => { onEdit() }}>
                                {SVGIcons.EditIcon()}
                            </IconButton>

                        </li>
                    </ul>
                </div>
                <div className='profile-sec f-wrp'>
                    <div className='profile-img-sec'>
                        <span><img src={child?.profilePic?.tempURL || AppIcons.Child_1} alt={child?.firstName} /></span>
                        <h5>{`${child?.firstName} ${child?.lastName}`}</h5>
                        <p>{getAgeFromBirthday(child?.birthday)}</p>
                    </div>
                    <div className='basic-details'>
                        <div className='each-detail-sec'>
                            <div className='lft-sec'>
                                <p>{translateThis("Birthday")}:</p>
                            </div>
                            <div className='ryt-sec'>
                                <p>{dayjs(child?.birthday).format(DATE_FORMAT)}</p>
                            </div>
                        </div>
                        <div className='each-detail-sec'>
                            <div className='lft-sec'>
                                <p>{translateThis("Relationship")}: </p>
                            </div>
                            <div className='ryt-sec'>
                                <p>{child.relationship}</p>
                            </div>
                        </div>
                        <div className='each-detail-sec'>
                            <div className='lft-sec'>
                                <p>{translateThis("Guardians")}: </p>
                            </div>
                            <div className='ryt-sec'>
                                {(child?.guardian && child.guardian.length > 0) && <p>{child.guardian.join(', ')}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

ChildProfileCard.propTypes = {
    child: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
}

export default ChildProfileCard;