import * as React from 'react';


import ParentPaymentForm from './forms/payment';
import TabLayout from '../../../components/tabs';
import PaymentHistory from './historyTab';


const PaymentSection = () => <TabLayout
tabNames={['Pay', 'History']}
tabContents={[
<ParentPaymentForm key={'parent-payment-form'} />,
<PaymentHistory key={'parent-history-table'} />
]}
/>

PaymentSection.propTypes = {}
export default PaymentSection;