import { useEffect, useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';


// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { getChildFormBy } from '../../../../services/api/child';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';

// Services
import { submitForm } from '../../../../services/api/dynamicForms';

// ----------------------------------------------------------------------


const SampleFormTwo = (props) => {
    const formName = 'sample_form_two';
    const { childId } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState([]);
    const [initialFormData, setInitialFormData] = useState({});

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (childId && parentId) {
            getData()
        }
        return () => {
            setInitialFormData({})
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData(data)
                if (data?.formData) {
                    setFormData(data?.formData)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (formData.name && formData.email && formData.message) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName,
            userId: parentId,
            childId,
            formData
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    // navigate('/', { replace: true });
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    /**
     * The signup form elements
     */
    const formElements = [
        {
            label: 'Sample input one',
            name: 'sampleOne',
            questionId: 'sampleOne',
            value: answerGetter('sampleOne', initialFormData?.formData)?.answer,
            translationKey: 'sampleFormQuestionOne',
            type: 'text',
            required: true,
            placeholder: 'Please enter',
            className: 'custom-textfield',
            getValue: (name, value) => {
                setFormValue({
                    questionId: name,
                    answer: value,
                    translationKey: 'sampleFormQuestionOne',
                    type: 'text'
                })
            }
        },
        {
            label: 'Sample input two',
            name: 'sampleTwo',
            questionId: 'sampleTwo',
            value: answerGetter('sampleTwo', initialFormData?.formData)?.answer,
            translationKey: 'sampleFormQuestionTwo',
            type: 'text',
            required: true,
            placeholder: 'Please enter',
            className: 'custom-textfield',
            getValue: (name, value) => {
                setFormValue({
                    questionId: name,
                    answer: value,
                    translationKey: 'sampleFormQuestionTwo',
                    type: 'text'
                })
            }
        },
    ];

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='client-forms-verification standard-form' spacing={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                <h2>Sample form two</h2>
                                <p>I hereby authorize the release of the requested medical/psychiatric information to the State of
                                    Connecticut Office of Early Childhood Care 4 Kids program for:</p>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack className='login-form standard-form' spacing={3}>
                    {formElements.map((element, key) => (
                        <TextInput
                            key={`register-input-${key}`}
                            {...element}
                            useDefaultValidation
                            className='custom-textfield'
                        />
                    ))}


                    <TextInput
                        label='Question34'
                        name='Question34'
                        id='Question34'
                        value={answerGetter('Question34', initialFormData?.formData)?.answer}
                        translationKey='Question34'
                        type='text'
                        required
                        placeholder='Please enter'
                        className='custom-textfield'
                        getValue={(name, value) => {
                            setFormValue({
                                questionId: name,
                                answer: value,
                                translationKey: 'Question34',
                                type: 'text'
                            })
                        }}
                    />



                    <FormControl>
                        <FormLabel id="sampleRadioOne">{translateThis('sampleRadioOne')}</FormLabel>
                        <RadioGroup row
                            aria-labelledby="sampleRadioOne"
                            defaultValue={answerGetter('sampleRadioOne', initialFormData?.formData)?.answer}
                            name="sampleRadioOne"
                            onChange={(e) => {
                                setFormValue({
                                    questionId: 'sampleRadioOne',
                                    answer: e.target.value,
                                    translationKey: 'sampleRadioOne',
                                    type: 'radio'
                                })
                            }}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>

                </Stack>
                <br />
                <LoadingButton
                    loading={isSubmitting}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!isValidForm()}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='login-btn'
                    onClick={onSubmit}>
                    Save
                </LoadingButton>
                <br />
                {(parentId && childId && formName) ? <PDFDownloadButton
                    parentId={parentId}
                    childId={childId}
                    formName={formName}
                /> : null}
            </div>}
        </>
    );
}

SampleFormTwo.propTypes = {
    childId: propTypes.string
}

export default SampleFormTwo;