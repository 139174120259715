import * as React from 'react';
import Box from '@mui/material/Box';
import { useRecoilState } from 'recoil';

import { getUniqueId } from '../../../helpers/helper.functions';

import './index.scss';

import { providerConfigState } from '../../../state/reducerAtoms/provider.atom';
import ChildView from './view';
import ChildrenList from './list';


export default function ChildrenSection() {
    const [configState, setConfigState] = useRecoilState(providerConfigState);

    /**
     * Gets the content to display based on the current selection
     */
    const getSelectedContent = () => {
        const selectedItem = configState?.childrenSection?.selectedPageView || 'children-list';
        let contentSelected = <></>
        switch (selectedItem) {            
            case 'children-list':
                contentSelected = <ChildrenList
                    key={`${getUniqueId()}-children-key`}
                    onClose={() => {
                        modifyConfigState({ selectedChildId: "", selectedPageView: "children-list" });
                    }}
                    onSelectChild={(childId) => {
                        modifyConfigState({ selectedPageView: "child-view", selectedChildId: childId });
                    }}
                />;

                break;
            case 'child-view':
                contentSelected = configState?.childrenSection?.selectedChildId ? <ChildView
                    key={`${getUniqueId(configState?.childrenSection?.selectedChildId)}-parent-key-${configState?.childrenSection?.selectedChildId}`}
                    id={configState?.childrenSection?.selectedChildId}
                    onClose={() => {
                        modifyConfigState({ selectedChildId: "", selectedPageView: "children-list" });
                    }}
                /> : <span>Loading...</span>;

                break;            
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        return contentSelected;
    }

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{childrenSection:{...prv.childrenSection, ...modifiedObj}} }));

    return (
        <>
            <Box component="div" className='parents-main-wrp f-wrp' sx={{ flexGrow: 1, p: 3, }}>
                {getSelectedContent()}
            </Box>
        </>
    );
}