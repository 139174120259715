export const ProviderCommunicationFormModel = {
    incidentsAtHome: '',
    food: 'bottle',
    diaperChange: 'dry',
    mood:'normal',
    wakeUpTime:'',
    natureOfSleep: 'Slept soundly',
    comments: '',
    signature:''
}

/**
 * Parent communication form default values
 */
export const ProviderCommunicationFormDefaultValues = [
    {
        "questionId": "provider_communication_form_question_breakfast",
        "answer": "Ate well",
        "translationKey": "provider_communication_form_question_breakfast",
        "type": "text"
    },
    {
        "questionId": "provider_communication_form_question_morningSnack",
        "answer": "Ate well",
        "translationKey": "provider_communication_form_question_morningSnack",
        "type": "radio"
    },
    {
        "questionId": "provider_communication_form_question_lunch",
        "answer": "Ate well",
        "translationKey": "provider_communication_form_question_lunch",
        "type": "radio"
    },
    {
        "questionId": "provider_communication_form_question_afternoonSnack",
        "answer": "Ate well",
        "translationKey": "provider_communication_form_question_afternoonSnack",
        "type": "radio"
    },
    {
        "questionId": "provider_communication_form_question_dinner",
        "answer": "Ate well",
        "translationKey": "provider_communication_form_question_dinner",
        "type": "radio"
    },
    {
        "questionId": "provider_communication_form_comments",
        "answer": "",
        "translationKey": "provider_communication_form_comments",
        "type": "text"
    },
    {
        "questionId": "provider_communication_form_incident",
        "answer": "",
        "translationKey": "provider_communication_form_incident",
        "type": "text"
    },
    {
        "questionId": "signature",
        "answer": "",
        "translationKey": "signature",
        "type": "text"
    }
]