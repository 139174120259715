import React from 'react';
import propTypes from 'prop-types';
import { Stack, Grid } from '@mui/material';
// Custom components
import BasicDropdown from '../../../components/dropdowns/basic.dropdown';
import ChildrenListDropdown from '../../../components/dropdowns/children.dropdown';
import { translateThis } from '../../../helpers/language.helper';

import './index.scss';
import ParentProviderAgreement from './forms/ParentProviderAgreement';
import SummerPPAgreement from './forms/summerPPAgreement';

const ProviderDynamicFormSectionToCompleteTab = (props) => {
    const { id = '' } = props;
    const [selectedFormName, setSelectedFormName] = React.useState('');
    const [selectedChildId, setSelectedChildId] = React.useState('');
    const [selectedForm, setSelectedForm] = React.useState(<></>);

    /**
     * 
     * @param {string} value 
     */
    const setSelectedChild = (value) => {
        setSelectedChildId(value);
        setSelectedFormName('');
        setSelectedForm(<></>);
    }
    /**
     * Set the selected form
     * @param {string} formName selected form
     */
    const handleFormSelection = (formName) => {
        setSelectedFormName(formName);
        let formToRender = <></>;
        switch (formName) {
            case "parent_provider_agreement":
                formToRender = <ParentProviderAgreement
                    key={selectedChildId}
                    onChangeFormState={onChangeFormState}
                    childId={selectedChildId} />;
                break;
            case "summer_pp_agreement":
                formToRender = <SummerPPAgreement
                    key={selectedChildId}
                    onChangeFormState={onChangeFormState}
                    childId={selectedChildId} />;
                break;
            default:
                formToRender = <></>;
                break;
        }
        setSelectedForm(formToRender);
    };

    /**
     * Invokes when a form is submitted/cancelled or failed to submit
     */
    const onChangeFormState = () => {
        setSelectedFormName('');
        setSelectedForm(<></>);

    }

    return (
        <>
            <div className='panel-form-sec forms-main-wrapper f-wrp'>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className="form-chooser-wrp">
                                <div className="child-selection-div f-wrp">
                                    {!id && <ChildrenListDropdown onChange={setSelectedChild} />}
                                </div>
                                <div className="form-selection-div f-wrp">
                                    <BasicDropdown
                                        key={selectedFormName}
                                        label="Choose Form"
                                        id='providerFormSelect'
                                        isDisabled={!selectedChildId}
                                        options={[
                                            {
                                                'label': <b>{translateThis("Care 4 Kids forms")} </b>,
                                            },
                                            {
                                                'label': <p>{translateThis("8. Parent-Provider agreement")}<br /><i>{translateThis("8. Parent-Provider agreementText")} </i></p>,
                                                "id": 'parent_provider_agreement',
                                                "value": 'parent_provider_agreement',
                                            },
                                            {
                                                'label': <p>{translateThis("9. Summer parent-provider agreement")}<br /><i>{translateThis("9. Summer parent-provider agreementText")} </i></p>,
                                                "id": 'summer_pp_agreement',
                                                "value": 'summer_pp_agreement',
                                            },

                                        ]}
                                        onChange={(value) => handleFormSelection(value)}
                                        value={selectedFormName}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Stack className='panel-form' spacing={3}>
                                <div className="form-render-wrp formsToCompleteWrp">
                                    {selectedForm}
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

ProviderDynamicFormSectionToCompleteTab.propTypes = {
    id: propTypes.string
}
export default ProviderDynamicFormSectionToCompleteTab;