import { useEffect, useState } from 'react';

// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';

// Styles
import SVGIcons from '../../../assets/images/icons/svgIcons';

// components

import { showSnackbar } from '../../../components/snackbar';
import RichTextEditor from '../../../components/rich-text-editor';
// Helpers
import { getAuthId } from '../../../helpers/auth.helper';
import { translateThis } from '../../../helpers/language.helper';

// ........................................................................

import './index.scss';
import ApiCaller from '../../../services/api/general';


const ProviderPoliciesCreateTab = () => {
    const apiCaller = new ApiCaller('policies')
    const providerId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [policy, setPolicy] = useState('');

    

    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (providerId) {
            getData()
        }
        // eslint-disable-next-line
    }, [providerId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => {
                if(data?.results && data.results[0]) {
                    setPolicy(data.results[0].policy)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (policy) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            policy,
            providerId,
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success("Policy created successfully!")
                } else {
                    showSnackbar().failure('Something went wrong!')
                }
            }).finally(() => setIsSubmitting(false));

        }

    };



    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading &&
                <div className='policy-wrp f-wrp'>
                    <Stack className='standard-form form-full-width panel-form' spacing={3}>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <span style={{ color: '#0F0A01', fontWeight: '400' }}>{translateThis("The policies you type here will be seen at the Parent Portal")}</span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <RichTextEditor defaultValue={policy} onChange={(value) => setPolicy(JSON.stringify(value))} />
                            </Grid>
                        </Grid>
                    </Stack>

                    <br />

                    <div className='button-sec f-wrp' style={{ paddingBottom: '24px', justifyContent: 'flex-end' }}>
                        
                        <div className='ryt-btn-sec'>
                            
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                disabled={!isValidForm()}
                                size="large"
                                type="submit"
                                variant="contained"
                                className='fill-btn'
                                onClick={onSubmit}>
                                {translateThis("submit")}
                            </LoadingButton>

                        </div>
                    </div>
                </div>}
        </>
    );
}

ProviderPoliciesCreateTab.propTypes = {}

export default ProviderPoliciesCreateTab;











