import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// css
import './index.scss';

// components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import { usdFormatter } from '../../../helpers/currency.helper';
import { DATE_TIME_FORMAT, DATE_ONLY_FORMAT, MONTH_TEXT_FORMAT } from '../../../constants/index';
import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';


const PaymentHistory = () => {
    const apiCaller = new ApiCaller('payment')
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList({ query: 'sortBy=createdAt:desc' })
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 3,
            minWidth: 150,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            minWidth: 100,
            valueFormatter: ({ value }) => usdFormatter(value)
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1,
            minWidth: 180,
            valueFormatter: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT)

        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.8,
            minWidth: 90,
            renderCell: (params) => {
                const { status } = params.row;
                return <span className={`payment-status status-${status}`}>{status}  </span>
            }
        }

    ]

    return (
        <>
            <div className='panel-table-sec PC-Table f-wrp'>
                <AppTable
                    key={'parent-payment-table'}
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />
                <div className='mob-table-wrp childView f-wrp'>
                    {dataArr.length > 0 ? <ul>
                        {dataArr.map((element) => (
                            <li key={element.id}>
                                <div className='each-sec-box'>
                                    <h4>{dayjs(element?.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                    <h4>{dayjs(element?.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                </div>
                                <div className='each-sec-box'>
                                    <h4>{element?.childId.firstName}&nbsp;{element?.childId.lastName}</h4>
                                    <p className='flex-wrp'>{element.status === "completed" ?
                                        <span className='status complete'>{translateThis('Completed')}</span> :
                                        <span className='status pending'>{translateThis('Pending')}</span>}</p>

                                </div>
                                <div className='each-sec-box'>
                                    <p style={{ fontWeight: '700', minWidth: '45px' }}>{`$${element.amount}`}</p>
                                </div>
                            </li >
                        ))}
                    </ul> : <div className='empty-placeholder f-wrp'>
                        <div className='placeholder-con'>
                            <img src={AppIcons.paymentPlaceholder} alt='empty' />
                            <h4>{translateThis('No payment available')}</h4>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}
PaymentHistory.propTypes = {}
export default PaymentHistory;