import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import dayjs from 'dayjs';
// Mui
import { IconButton } from '@mui/material';


import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import { getAgeFromBirthday } from '../../../helpers/helper.functions';

import { DATE_FORMAT } from '../../../constants/index';
import { updateChildById } from '../../../services/api/child';
import TabLayout from '../../../components/tabs/index';
import ProviderPortalHeader from '../components/header';
import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';


const ParentView = (props) => {
    const {
        id = '',
        onClose = () => null,
        onSelectChild = () => null,
    } = props;
    const apiCaller = new ApiCaller('users/parents')
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        if (id) {
            getData()
        }
        return () => {
            setData(null)
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(id)
            .then((data) => setData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    // eslint-disable-next-line
    const toggleActiveStatus = (id, status) => {
        status = status === "active" ? "inactive" : "active";
        const statusMsg = status === "active" ? "accepted" : "blocked";
        if (id) {
            updateChildById(id, { status })
                .then(() => showSnackbar().success(`The selected child has been ${statusMsg} successfully.`))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getData();
                })
        }

    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            updateChildById(selectedId, { isDeleted: true })
                .then(() => showSnackbar().success('Child deleted successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getData();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'fullName',
            headerName: 'Child',
            flex: 1,
            minWidth: 170,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'age',
            headerName: 'Age',
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => getAgeFromBirthday(params.row.birthday),
        },
        {
            field: 'birthday',
            headerName: 'Birthday',
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => dayjs(params.row.birthday).format(DATE_FORMAT),
        },
        {
            field: 'guardian',
            headerName: 'Guardians',
            flex: 2,
            minWidth: 200,
            valueGetter: (params) => params.row?.guardian.join(", "),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                    (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
            }
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { id } = params.row;
                // eslint-disable-next-line
                const { status } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'Forms',
                        onClick: () => onSelectChild(id)
                    }
                    // ,
                    // {
                    //     label: status === "active" ? "Block" : "Accept",
                    //     onClick: () => {
                    //         toggleActiveStatus(id, status)
                    //     }
                    // }
                    ,
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]


    return (
        <>
            <div className='child-list-wrp f-wrp' style={{ marginTop: '10px' }}>
                <div className='pc-head'>
                    <IconButton
                        onClick={onClose}
                        aria-label="Go Back">
                        <SVGIcons.BackIcon />
                        {translateThis('Back')}
                    </IconButton>
                    {data?.firstName && <ProviderPortalHeader header={(data?.firstName) ? `${data?.firstName} ${data?.lastName}` : ''} />}
                    {data?.email && <span>{data?.email}</span>}
                </div>
                <div className='mob-head'>
                    <ProviderPortalHeader header='Parents' /> 
                    <div className='head-flex'>
                        <IconButton
                            onClick={onClose}
                            aria-label="Back">
                            <SVGIcons.LongArrowIcon />
                        </IconButton>
                        {data?.firstName && <h4>{data?.firstName} {data?.lastName}</h4>}
                    </div>
                </div>

            </div>
            <div className="f-wrp provider-table ">
                <TabLayout
                    tabNames={['Children', 'Payments']}
                    tabContents={[
                        <div key={'children-table'} className='panel-table-sec PC-Table f-wrp'>
                            <AppTable
                                columns={tableColumns}
                                isLoading={isLoading}
                                data={data?.children || []}
                            />
                            <div className='mob-table-wrp childView f-wrp'>
                                {data?.children ? <ul>
                                    {data?.children?.map(childViewData =>
                                        <li key={childViewData.id}>
                                            <div className='each-sec-box'>
                                                <span>{(childViewData.status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                                                    (childViewData.status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</span>
                                            </div>
                                            <div className='each-sec-box'>
                                                <h4>{childViewData.firstName}{childViewData.lastName} </h4>
                                                <p>{dayjs(childViewData.birthday).format(DATE_FORMAT)}</p>
                                                <p>{childViewData.guardian.join(", ")}</p>
                                            </div>
                                            <div className='each-sec-box'>
                                                <ul className='btn-wrp'>
                                                    <li><button className='pln-btn formIcon' type='button' onClick={() => { onSelectChild(childViewData.id) }}><SVGIcons.FormsIcon /></button></li>
                                                    <li><button className='pln-btn' type='button' onClick={() => { setSelectedId(id); setOpenDeleteDialog(true); }}><SVGIcons.DeleteIcon /></button></li>
                                                </ul>
                                            </div>
                                        </li>
                                    )}
                                </ul> : <div className='empty-placeholder f-wrp'>
                                    <div className='placeholder-con'>
                                        <img src={AppIcons.parentPlaceholder} alt='empty' />
                                        <h4>{translateThis('No statistics')}</h4>
                                        <p>{translateThis('There are no information loaded in the system')}</p>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>,
                        <span key={'children-table'}>Payments</span>
                    ]}

                />

                {openDeleteDialog && <ConfirmDialog
                    isOpen={openDeleteDialog}
                    onClose={() => {
                        setOpenDeleteDialog(false);
                        setSelectedId('');
                    }}
                    title={`Delete child`}
                    className={'delete-popup'}
                    description={`Are you sure you want to delete this child?\nThis action is permanent and cannot be undone.`}
                    okayButtonText={'Delete child'}
                    onConfirm={deleteData}
                />}
            </div>
        </>
    )
}
ParentView.propTypes = {
    id: propTypes.string,
    onClose: propTypes.func,
    onSelectChild: propTypes.func
}
export default ParentView;