import React from 'react';
import { Navigate } from 'react-router-dom';

import { getAuthRoleName } from '../../helpers/auth.helper';
import { EMPLOYEE_ROLE, PARENT_ROLE, PROVIDER_ROLE } from '../../constants/index';
import ParentPortal from '../../features/parentPortal';
import ProviderPortal from '../../features/providerPortal';



const MyPortal = () => {
    const loggedInAuthType = getAuthRoleName();
    console.log('hi')

    return (
        <>
            {loggedInAuthType === PARENT_ROLE ? <ParentPortal /> :
                loggedInAuthType === PROVIDER_ROLE ? <ProviderPortal /> :
                loggedInAuthType === EMPLOYEE_ROLE ? <ProviderPortal /> :
                    <Navigate to={'/auth/login'} />}
        </>
    )

}


export default MyPortal;