import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import { DATE_FORMAT, TIME_12HR_FORMAT } from '../../../constants';
import { getAuthId } from '../../../helpers/auth.helper';
import ModalPopUp from '../../../components/modal/modal.popup';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AttendanceEdit from './attendanceEdit';



const AttendanceList = () => {

    const apiCaller = new ApiCaller('attendance');
    const authId = getAuthId()
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [viewOpen, setViewOpen] = React.useState(false);

    const onViewOpen = () => {
        setViewOpen(true);
    };
    const onViewClose = () => {
        setViewOpen(false);
    };
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        const filter = {
            query: `employeeId=${authId}`
        }
        apiCaller.getList(filter)
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Name',
            minWidth: 150,
            flex: 1.5,
            valueGetter: ({ row }) =>
                `${row?.employeeId?.firstName || ''} ${row?.employeeId?.lastName || ''}`,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 0.7,
            minWidth: 200,
            valueGetter: ({ row }) =>
                `${dayjs(row.createdAt).format(DATE_FORMAT)}`,
        },
        {
            field: 'checkIn',
            headerName: 'Check in',
            flex: 0.7,
            minWidth: 200,
            valueGetter: ({ row }) =>
                `${dayjs(row.checkIn).format(TIME_12HR_FORMAT)}`,
        },
        {
            field: 'By',
            headerName: 'By',
            flex: 1,
            minWidth: 200,
            // valueGetter: ({row}) =>
            //     `${dayjs(row.checkIn).format(TIME_12HR_FORMAT)}`,
        },
        {
            field: 'checkOut',
            headerName: 'Check out',
            flex: 0.7,
            minWidth: 200,
            valueGetter: ({ row }) =>
                row.checkOut ? `${dayjs(row.checkOut).format(TIME_12HR_FORMAT)}` : '-',
        },
        {
            field: 'By',
            headerName: 'By',
            flex: 1,
            minWidth: 200,
            // valueGetter: ({row}) =>
            //     `${dayjs(row.checkIn).format(TIME_12HR_FORMAT)}`,
        },
        {
            field: 'id',
            headerName: 'Action',
            flex: 0.5,
            width: 90,
            sortable: false,
            renderCell: () => <><Button onClick={onViewOpen}>{SVGIcons.Pencil()}</Button> </>
        },

    ]


    return (
        <div className='employees-main-list f-wrp'>
            <div className='employees-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table  f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                    </div>
                    {viewOpen ? <ModalPopUp
                        className='preview-popup'
                        isOpen={viewOpen}
                        onClose={onViewClose}
                        aria-labelledby="table-view"
                        aria-describedby="table-view"
                    >
                        <AttendanceEdit />
                        {/* <Button onClick={onViewClose} className='fill-btn'>Close</Button> */}
                    </ModalPopUp> : null}

                </div>
            </div>
        </div>
    )
}


AttendanceList.propTypes = {
}
export default AttendanceList;