import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import { DATE_FORMAT, TIME_12HR_FORMAT } from '../../../constants';
import { getAuthId } from '../../../helpers/auth.helper';


const AttendanceList = () => {
    
    const apiCaller = new ApiCaller('attendance');
    const authId = getAuthId()
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        const filter = {
            query:`employeeId=${authId}`
        }
        apiCaller.getList(filter)
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'employeeId',
            headerName: 'Name',
            minWidth: 150,
            flex: 2,
            valueGetter: ({row}) =>
                `${row?.employeeId?.firstName || ''} ${row?.employeeId?.lastName || ''}`,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 2,
            minWidth: 200,
            valueGetter: ({row}) =>
                `${dayjs(row.createdAt).format(DATE_FORMAT)}`,
        },
        {
            field: 'checkIn',
            headerName: 'Check in',
            flex: 2,
            minWidth: 200,
            valueGetter: ({row}) =>
                `${dayjs(row.checkIn).format(TIME_12HR_FORMAT)}`,
        },
        {
            field: 'checkOut',
            headerName: 'Check out',
            flex: 2,
            minWidth: 200,
            valueGetter: ({row}) =>
            row.checkOut ? `${dayjs(row.checkOut).format(TIME_12HR_FORMAT)}` : '-',
        }
        

    ]


    return (
        <div className='employees-main-list f-wrp'>
            <div className='employees-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table  f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    )
}


AttendanceList.propTypes = {
}
export default AttendanceList;