import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import Marquee from "react-fast-marquee";
import AppLayout from '../../layouts/app/AppLayout';
import AppIcons from '../../assets/images/icons/index';
import SVGIcons from '../../assets/images/icons/svgIcons';
import './home.scss';
import './bannerAnimation.scss';
import { getAuthRoleName } from '../../helpers/auth.helper';
import { translateThis } from '../../helpers/language.helper';
// import bannerVideo from '../../assets/images/bannerVideo.mp4';
// import animatedBannerMainBG from '../../assets/images/animatedBanner/mainBG.png';
import animatedBannerBall from '../../assets/images/animatedBanner/ball.png';
import animatedBannerBallon1 from '../../assets/images/animatedBanner/ballon1.png';
import animatedBannerBallon2 from '../../assets/images/animatedBanner/ballon2.png';
import animatedBannerBallon3 from '../../assets/images/animatedBanner/ballon3.png';
import animatedBannerBlock1 from '../../assets/images/animatedBanner/block1.png';
import animatedBannerBlock2 from '../../assets/images/animatedBanner/block2.png';
import animatedBannerChild from '../../assets/images/animatedBanner/child.png';
import animatedBannerChildDino from '../../assets/images/animatedBanner/childDino.png';
import animatedBannerCloud1 from '../../assets/images/animatedBanner/cloud1.png';
import animatedBannerCloud2 from '../../assets/images/animatedBanner/cloud2.png';
import animatedBannerCloud3 from '../../assets/images/animatedBanner/cloud3.png';
import animatedBannerCloud4 from '../../assets/images/animatedBanner/cloud4.png';
import animatedBannerDuck from '../../assets/images/animatedBanner/duck.png';
import animatedBannerKite from '../../assets/images/animatedBanner/kite.png';
import animatedBannerLogoK from '../../assets/images/animatedBanner/LogoK.png';
import animatedBannerPony from '../../assets/images/animatedBanner/pony.png';
import animatedBannerRocket from '../../assets/images/animatedBanner/rocket.png';
import animatedBannerWorld from '../../assets/images/animatedBanner/World.png';
import animatedBannerFooter from '../../assets/images/animatedBanner/bannerFooter.png';
import CPRSubscribeForm from './cprForm/CPRSubscribeForm';

const aboutItems = [
    {
        className: 'box1',
        icon: AppIcons.Playground,
        title: translateThis("playground"),
        discription: translateThis("weArePlay-Based,FuelingImaginationAndAdventure,AsChildrenPlayAndLearn."),
    },
    {
        className: 'box2',
        icon: AppIcons.apple,
        title: translateThis("foodProgram"),
        discription: translateThis("weNourishYoungMindsWithNutritiousFoodsForAHealthyGrowth."),
    },
    {
        className: 'box3',
        icon: AppIcons.brain,
        title: translateThis("positiveLearning"),
        discription: translateThis("weHelpChildrenLearn,GiveThemLoveAndMakeSureTheyAreAlwaysHappy."),
    },
    {
        className: 'box4',
        icon: AppIcons.car,
        title: translateThis("transportation"),
        discription: translateThis("weCanAccommodateTransportationToEnsureYourPeaceOfMind."),
    },
    {
        className: 'box5',
        icon: AppIcons.child,
        title: translateThis("ages"),
        discription: <>{translateThis("weAcceptCareForKidsAndPrivateCareForChildren Aged")} <b>{translateThis("6WeeksTo5YearsOld")}</b></>,
    },
    {
        className: 'box6',
        icon: AppIcons.events,
        title: translateThis("events"),
        discription: translateThis("weOftenHostFunEvents:ScienceExperiments,MovieNights,Holidays,Birthdays."),
    },
    {
        className: 'box7',
        icon: AppIcons.language,
        title: translateThis("bilingual"),
        discription: <>{translateThis("weProvideReadingAndWritingTechniquesInEnglishAndSpanish.")}</>,
    },

];

const pressDetails = [
    {
        pressName: <><img src={AppIcons.V} alt='press' /> {translateThis("vox")}</>,
        link: 'https://www.vox.com/the-highlight/22977657/future-of-work-child-care-worker-shortage',
        newsTitle: translateThis("whenYourJobHelpsTheRestOfAmericaWork"),
        contend: translateThis("whySoManyAreGivingUpOnChildCareWorkAndWhatItWillMeanForEveryoneElse"),
        date: translateThis("apr11Th,2022"),
        pressImg: AppIcons.press1,
    },
    {
        pressName: <><img src={AppIcons.ct} alt='press' /> {translateThis("ctMirror")}</>,
        link: 'https://ctmirror.org/author/katherine-lantigua/',
        newsTitle: translateThis("opinion:CtIsInAChildCareCrisis.Here’SWhatWillFixIt"),
        contend: translateThis("ctHasTheOpportunityToExpandAStateProgramThatWouldUpliftParents,ChildrenAndChildCareProviders."),
        date: translateThis("feb19Th,2023"),
        pressImg: AppIcons.press2,
    },
];



const HomePage = () => {

    useEffect(() => {
        setTimeout(() => {
            document.body.classList.add('letAnimate');
        }, 500);
    });
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // custom home functions here
    const pageName = "Home";
    return (
        <AppLayout
            pageName={pageName}
            bodyClassName='home-main-wrp f-wrp'
        >

            <div className='animated-banner-wrp f-wrp' >
                <div className='bannerBG-con main-banner-wrp f-wrp'>
                    <div className='baner-content-sec f-wrp'>
                        <div className='container'>
                            <div className='banner-content f-wrp'>
                                <span>
                                    <div className='animntlogo'>
                                        <img className='animatedK' src={animatedBannerLogoK} alt='banner' />
                                    </div>
                                    <img src={AppIcons.bannerLogo} alt='logo' />
                                </span>
                                {(getAuthRoleName()) ? <Button onClick={() => {
                                    window.location.href = '/my-portal'
                                }} className='banner-btn'>{getAuthRoleName()}{translateThis("sPortal")}
                                </Button> : <Button onClick={() => {
                                    window.location.href = '/auth/login'
                                }} className='banner-btn'>{translateThis("login")}
                                </Button>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-img-wrp f-wrp'>
                    {/* <span className='bannerBG'><img src={animatedBannerMainBG} alt='banner' /></span> */}
                    {/* <span className='bannerBGmob'><img src={AppIcons.kcolorfulBanner} alt='banner' /></span> */}
                    <div className='banner-main f-wrp'>
                        <div className='bannerBG'>
                            <img src={animatedBannerChild} alt='banner' />
                            <div className='flying-grup'>
                                <span className='flying rocket'><img src={animatedBannerRocket} alt='banner' /></span>
                                <span className='flying kite'><img src={animatedBannerKite} alt='banner' /></span>
                            </div>
                        </div>
                        <span className='cloud cloud1'><img src={animatedBannerCloud1} alt='banner' /></span>
                        <span className='cloud cloud2'><img src={animatedBannerCloud2} alt='banner' /></span>
                        <span className='cloud cloud3'><img src={animatedBannerCloud3} alt='banner' /></span>
                        <span className='cloud cloud4'><img src={animatedBannerCloud4} alt='banner' /></span>
                        <div className='child-grup-sec'>
                            <span className='child-grup ChildDino'><img src={animatedBannerChildDino} alt='banner' /></span>
                            <span className='child-grup Pony'><img src={animatedBannerPony} alt='banner' /></span>
                            <span className='child-grup Ball'><img src={animatedBannerBall} alt='banner' /></span>
                            <span className='child-grup Block1'><img src={animatedBannerBlock1} alt='banner' /></span>
                            <span className='child-grup Block2'><img src={animatedBannerBlock2} alt='banner' /></span>
                            <span className='child-grup duck'><img src={animatedBannerDuck} alt='banner' /></span>
                            <div className='balloon-grup f-wrp'>
                                <span className='child-grup Ballon1'><img src={animatedBannerBallon1} alt='banner' /></span>
                                <span className='child-grup Ballon2'><img src={animatedBannerBallon2} alt='banner' /></span>
                                <span className='child-grup Ballon3'><img src={animatedBannerBallon3} alt='banner' /></span>
                            </div>
                        </div>

                        <div className='banner-basic f-wrp'>
                            <span className='world'><img src={animatedBannerWorld} alt='banner' /></span>
                            <span className='bannerFooter'><img src={animatedBannerFooter} alt='banner' /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mob-btn-wrp f-wrp'>

                {(getAuthRoleName()) ? <Button onClick={() => {
                    window.location.href = '/my-portal'
                }} className='banner-btn'>{getAuthRoleName()}{translateThis("sPortal")}
                </Button> : <Button onClick={() => {
                    window.location.href = '/auth/login'
                }} className='banner-btn'>{translateThis("login")}
                </Button>}

            </div>
            {/* <div className='main-banner-wrp f-wrp'>
                <span className='bannerBG'><img src={AppIcons.kcolorfulBanner} alt='banner' /></span>
                <video autoPlay muted id="myVideo" style={{width: '100%'}}>
                    <source src={bannerVideo} type="video/mp4" />
                        Your browser does not support HTML5 video.
                </video>
                <div className='bannerBG-con f-wrp'>
                    <div className='baner-content-sec f-wrp'>
                        <div className='container'>
                            <div className='banner-content f-wrp'>
                                <span>
                                    <img src={AppIcons.logo} alt='logo' />
                                    <img src={AppIcons.logo} alt='logo' />
                                </span>
                                {(getAuthRoleName()) && <Button onClick={() => {
                                    window.location.href = '/my-portal'
                                }} className='banner-btn'>{getAuthRoleName()}’s portal
                                </Button>}
                            </div>
                        </div>
                    </div>
                </div>
                <span className='childern'><img src={AppIcons.childern} alt='Childern' /></span>
                <span className='clouds'><img src={AppIcons.clouds} alt='Clouds' /></span>
                <span className='dinoGroup'><img src={AppIcons.dinoGroup} alt='Dino Group' /></span>
            </div> */}

            <div id='about-us' className='each-sec-wrapper abt-con-wrp f-wrp'>
                <div className='line-sec'>
                    <div className='abt-sec-line_1'><img src={AppIcons.line} alt='line' /></div>
                    <div className='abt-sec-line_2'><img src={AppIcons.line} alt='line' /></div>
                </div>
                <div className='container'>
                    <span className='abt-img-sec'>
                        <img src={AppIcons.abtChild} alt='child' />
                    </span>
                    <div className='abt-header f-wrp'>
                        <h2>{translateThis('about_us')}</h2>
                        <p>{translateThis("myNameIs")} <b>{translateThis("missK")}</b> {translateThis("andIHaveBeenProvidingLicensedChildCareInBridgeportFor6YearsWeAcceptCareForKidsAndPrivateCareForChildrenAged6WeeksTo5YearsOld")}</p>
                    </div>
                    <div className='content-sec-wrp f-wrp'>
                        <div className="row">
                            {aboutItems.map((element, key) => (
                                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12" key={key}>
                                    <div className={`${element.className} each-abt-sec f-wrp `}>
                                        <span className='abt-icon'><img src={element.icon} alt={element.title} /></span>
                                        <h3>{element.title}</h3>
                                        <p>{element.discription}</p>
                                    </div>
                                </div>
                            ))}
                        </div>


                        <Swiper
                            modules={[Navigation, Autoplay]}
                            spaceBetween={10}
                            slidesPerView={1.01}
                            className='content-sec-mob'
                            navigation
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                        >
                            {aboutItems.map((element, key) => (
                                <SwiperSlide key={key}>
                                    <div className={`${element.className} each-abt-sec f-wrp `}>
                                        <span className='abt-icon'><img src={element.icon} alt={element.title} /></span>
                                        <h3>{element.title}</h3>
                                        <p>{element.discription}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>

            <div className='each-sec-wrapper team-con-wrp f-wrp'>
                <div className='team-img-sec f-wrp'>
                    <span><img src={AppIcons.teamBG} alt='team' /></span>
                </div>
                <div className='team-con-sec f-wrp'>
                    <div className='container'>
                        <span><h2>{translateThis("weGetParentsInvolvedBecauseWeAreATeam")} {SVGIcons.HeartIcon()}</h2></span>
                    </div>
                </div>
                <div className='team-lft-objt f-wrp'>
                    {/* <div className='each-objt objtBG'>
                        <span><img src={AppIcons.union} alt='bg' /></span>
                    </div> */}
                    <div className='each-objt parentBG'>
                        <span><img src={AppIcons.Illu} alt='parents' /></span>
                    </div>
                </div>
            </div>

            <div id='program' className='each-sec-wrapper learn-more-wrp f-wrp'>
                <div className='containe r'>
                    <div className='learn-more-sec f-wrp'>
                        <div className='learn-con-wrp f-wrp'>
                            <span className='bg-tmp'><img src={AppIcons.lernmore_bgTP} alt='bg' /></span>
                            <div className='content-wrp f-wrp'>
                                <h1>{translateThis("learnAboutOurProgram")}</h1>
                                <p>{translateThis("weCreateAnEnrichingLearningExperienceThatEncompassesCreativity,Movement,ExplorationAndKnowledgeInAHolisticApproach.")}</p>
                                <ul>
                                    <li><span>{SVGIcons.BrushIcon()}</span><p>{translateThis("artsAndCrafts")}</p></li>
                                    <li><span>{SVGIcons.BookOpenIcon()}</span><p>{translateThis("readingTechniques")}</p></li>
                                    <li><span>{SVGIcons.SignatureIcon()}</span><p>{translateThis("writingTechniques")}</p></li>
                                    <li><span>{SVGIcons.MathSymbolsIcon()}</span><p>{translateThis("mathSkills")}</p></li>
                                    <li><span>{SVGIcons.DancingIcon()}</span><p>{translateThis("dancing")}</p></li>
                                    <li><span>{SVGIcons.MusicIcon()}</span><p>{translateThis("musicPlay")}</p></li>
                                    <li><span>{SVGIcons.TravelIcon()}</span><p>{translateThis("trips")}</p></li>
                                    <li><span>{SVGIcons.TreeIcon()}</span><p>{translateThis("outdoorActivities")}</p></li>
                                    <li><span>{SVGIcons.BrandIcon()}</span><p>{translateThis("circleTime")}</p></li>
                                    <li><span>{SVGIcons.SpellBookIcon()}</span><p>{translateThis("storyTime")}</p></li>
                                    <li><span>{SVGIcons.YogaIcon()}</span><p>{translateThis("yogaClasses")}</p></li>
                                </ul>
                                <div className='learn-con-slider'>
                                    <Swiper
                                        modules={[Autoplay]}
                                        spaceBetween={10}
                                        // slidesPerView={5}
                                        // slidesPerView={'auto'}
                                        slidesPerView={'auto'}
                                        slidesPerColumnFill="row"
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        className='each-learn-con-slider'
                                    // navigation
                                    >
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.BrushIcon()}</span><p>{translateThis("artsAndCrafts")}</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.BookOpenIcon()}</span><p>{translateThis("readingTechniques")}</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.SignatureIcon()}</span><p>{translateThis("writingTechniques")}</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.MathSymbolsIcon()}</span><p>{translateThis("mathSkills")}</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.DancingIcon()}</span><p>{translateThis("dancing")}</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.MusicIcon()}</span><p>{translateThis("musicPlay")}</p></li>
                                            </ul>
                                        </SwiperSlide>

                                    </Swiper>
                                    <Swiper
                                        modules={[Autoplay]}
                                        spaceBetween={10}
                                        slidesPerView="auto"
                                        className='each-learn-con-slider'
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                    // navigation
                                    >
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.TravelIcon()}</span><p>{translateThis("trips")}</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>
                                                <li><span>{SVGIcons.TreeIcon()}</span><p>{translateThis("outdoorActivities")}</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.BrandIcon()}</span><p>{translateThis("circleTime")}</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.SpellBookIcon()}</span><p>{translateThis("storyTime")}</p></li>

                                            </ul>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ul>

                                                <li><span>{SVGIcons.YogaIcon()}</span><p>{translateThis("yogaClasses")}</p></li>
                                            </ul>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                            <span className='bg-tmp'><img src={AppIcons.lernmore_bgBtm} alt='bg' /></span>
                        </div>
                        <div className='f-wrp'>
                            <div className='learn-img-wrp'>
                                <span className='main-img'><img src={AppIcons.Child_1} alt='child' />
                                    <span className='horse-img'><img src={AppIcons.horseGrup} alt='child' /></span>
                                </span>
                                <span className='sub-img'><img src={AppIcons.Child_2} alt='child' /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='each-sec-wrapper staff-sec-wrp f-wrp'>
                <div className='clouds-float-wrp f-wrp'>
                    <span className='clouds-img'><img src={AppIcons.cloud1} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloud2} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloud3} alt='cloud' /></span>
                </div>
                <div className='container'>
                    <div id='staff' className='staff-sec-container f-wrp'>
                        <div className='img-sec-wrp'>
                            <span className='staff-img'><img src={AppIcons.staff} alt='staff' /></span>
                        </div>
                        <div className='con-sec-wrp'>
                            <div className='staff-con-img f-wrp'>
                                <span className='angel-img'><img src={AppIcons.angel1} alt='angel' /></span>
                                <span className='baloon-img'><img src={AppIcons.balloon} alt='baloon' /></span>
                                <span className='angel2-img'><img src={AppIcons.angel2} alt='angel' /></span>
                            </div>
                            <div className='staff-con f-wrp'>
                                <h1>{translateThis("meetKatherine")}</h1>
                                <p>{translateThis("theExceptionalFounderOfKcolorfulDaycareWhoIsPassionateAboutChildrenSDevelopmentAndWellBeing")}  <b>{translateThis("katherineLantigua")}</b> {translateThis("isACertifiedChildcareProviderWithAWealthOfExperienceInNurturingAndEducatingChildren")} </p>
                                <p>{translateThis("staffTextTwo")}</p>
                                {/* <Link className='pln-btn' to={'/'}>Read more {SVGIcons.ArrowTrIcon()}</Link> */}
                            </div>

                        </div>
                    </div>
                </div>
                <span className='lady-img'><img src={AppIcons.Lady} alt='Lady' /></span>
            </div>

            <div className='each-sec-wrapper press-sec-wrp f-wrp'>
                <div className='container'>
                    <div className='press-header f-wrp'>
                        <span className='press-icon'><img src={AppIcons.pressIcon} alt='press' /></span>
                        <h1>{translateThis("katherineOnThePress")}</h1>
                        <p>{translateThis("pressText2")}</p>
                    </div>
                    <div className='press-content f-wrp'>
                        <div className='row'>
                            {pressDetails.map((element, key) => (
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" key={key}>
                                    <Link onClick={() => window.open(`${element.link}`, '_blank')} className='f-wrp'>
                                        <div className='basic-news-blk f-wrp'>
                                            <span className='press-basic'>{element.pressName}</span>
                                            <h3 className='press-title'>{element.newsTitle}</h3>
                                            <p>{element.contend}</p>
                                            <span className='date'>{element.date}</span>
                                        </div>
                                        <span className='press-img'><img src={element.pressImg} alt='press' /></span>
                                    </Link>
                                </div>
                            ))}
                        </div>
                        <div className='press-content-slider f-wrp'>
                            <Swiper
                                // modules={[Navigation]}
                                spaceBetween={10}
                                // slidesPerView={5}
                                slidesPerView={'auto'}
                                className='each-learn-con-slider'
                            // navigation
                            >

                                {pressDetails.map((element, key) => (
                                    <SwiperSlide key={key}>
                                        <Link onClick={() => window.open(`${element.link}`, '_blank')} className='f-wrp'>
                                            <div className='basic-news-blk f-wrp'>
                                                <span className='press-basic'>{element.pressName}</span>
                                                <h3 className='press-title'>{element.newsTitle}</h3>
                                                <p>{element.contend}</p>
                                                <span className='date'>{element.date}</span>
                                            </div>
                                            <span className='press-img'><img src={element.pressImg} alt='press' /></span>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                        </div>
                    </div>

                    <div className='crp-block-wrp f-wrp'>

                        <div className='crp-header f-wrp'>
                            <span className='press-icon'><img src={AppIcons.headLft} alt='icons' /></span>
                            <h1>{translateThis("connecticutChildcareCpr")} <span className='shareBtn'><img src={AppIcons.share} alt='share' />{translateThis('Share')}</span></h1>
                            <span className='press-icon'><img src={AppIcons.headRyt} alt='icons' /></span>
                        </div>
                        <div className='crp-con-blk f-wrp'>
                            <h1>{translateThis("obtainOrRenewYourFirstAidLicense")} <img src={AppIcons.txt} alt='icons' /> <span className='slide-txt'>{translateThis("bilingual")}</span></h1>
                            <p>{translateThis("renewYourFirstAidLicense")}</p>
                            <div className='rate-box f-wrp'>
                                <h2>{translateThis("requiredByTheStateOfConnecticut")}</h2>

                                <div className='rate'>
                                    <h1>{translateThis("$125")}</h1>
                                    <h5>{translateThis("perPerson")}<span>{translateThis("nonRefundable")}</span></h5>

                                </div>
                            </div>
                            <div className='into-box'>
                                <h1>{translateThis("certificateOfCompletion")}</h1>
                                <p>{translateThis("isGrantedAfterCoursework")}</p>
                            </div>
                        </div>
                        <div className='CPR-popup-btn f-wrp'>
                            <Button className='banner-btn' onClick={handleOpen}>{translateThis('Subscribe for CPR')}</Button>
                        </div>


                        <Modal
                            className='CPR-popup'
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="Subscribe for CPR"
                            aria-describedby="Subscribe for CPR"
                        >
                            <div className='CPR-content-wrp'>
                                <div className='cpr-popup-con-blk'>
                                    <div className='cpr-scroll-box f-wrp'>
                                        <div className='CPR-header f-wrp'>
                                            <h2>🚑{translateThis('Seeking First Aid License Renewal')} 🚑{translateThis('Look no further')} {SVGIcons.ShareIcon()}</h2>
                                            <Button onClick={handleClose}>{SVGIcons.CrossIcon()}</Button>
                                        </div>
                                        <div className='CPR-content-blk f-wrp'>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <div className='CPR-content-sec f-wrp'>
                                                        <p style={{ marginBottom: '0' }}><b>{translateThis('CRP_text_1')}</b></p>
                                                        <p>{translateThis('CRP_text_2')}</p>
                                                        <p>📣{translateThis('CRP_text_3')}</p>
                                                        <p style={{ marginBottom: '0' }}>🌟{translateThis('CRP_text_4')}</p>
                                                        <ul>
                                                            <li><p>{translateThis('CRP_text_5')}</p></li>
                                                            <li><p>{translateThis('CRP_text_6')}</p></li>
                                                            <li><p>{translateThis('CRP_text_7')}</p></li>
                                                            <li><p>{translateThis('CRP_text_8')}</p></li>
                                                        </ul>
                                                        <p>{translateThis('CRP_text_9')}💙👶</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <CPRSubscribeForm onClose={()=>handleClose()} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                    </div>
                </div>
            </div>

            <div className='scrolling-sec f-wrp'>

                <Marquee
                    autoFill
                    direction={"left"}
                    speed={70}
                >
                    <div style={{ padding: '0 15px' }}> For more than 5 providers Our Course Comes to YOUR City! </div>
                </Marquee>
            </div>


            <div id='contact-us' className='each-sec-wrapper contct-sec-wrp f-wrp'>
                <div className='container'>
                    <div className='contact-sec-container f-wrp'>
                        <div className='contct-img-sec'>
                            <span className='press-icon'><img src={AppIcons.logoK} alt='logo' /></span>
                        </div>
                        <div className='contct-con-sec'>
                            <div className='flex-box'>
                                <div className='conct-main-con'>
                                    <h1>{translateThis("findUsGetInTouch")}</h1>
                                    <p>{translateThis("discoverAWorldOfJoyAndGrowthForYourChildAtKcolorfulWhereLaughterAndLearningComeTogetherInPerfectHarmony")}</p>
                                    <ul>
                                        <li><span>{SVGIcons.PointerIcon()}</span><p>{translateThis("408BarnumAve,Bridgeport,Ct06608")}</p></li>
                                        <li><span>{SVGIcons.EmailIcon()}</span><p>{translateThis("kcolorfuldaycare@Gmail.Com")}</p></li>
                                        <li><div><span>{SVGIcons.TeleIcon()}</span><p>{translateThis("fax:203-549-9862")}</p></div>
                                            <div><span>{SVGIcons.phoneIcon()}</span><p>{translateThis("cell:347-257-2417")}</p></div></li>
                                    </ul>
                                </div>
                                <div className='wall-painting'>
                                    <span className='wall-paint'><img src={AppIcons.walpaper} alt='walpaper' /></span>
                                </div>
                            </div>
                            <ul className='social-list'>
                                <li className='whtsap'><Link onClick={() => window.open('https://wa.me/3472572417', '_blank')}><span><img src={AppIcons.whtsap} alt='whatsapp' /></span><p>{translateThis("whatsapp")}</p></Link></li>
                                <li className='fb'><Link><span><img src={AppIcons.fb} alt='fb' /></span><p>{translateThis("facebook")}</p></Link></li>
                                <li className='insta'><Link onClick={() => window.open('https://www.instagram.com/kcolorfuldaycare/?hl=en', '_blank')}><span><img src={AppIcons.insta} alt='insta' /></span><p>{translateThis("instagram")}</p></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='each-sec-wrapper video-sec-wrp f-wrp'>
                <div className='max-width-wrp'>
                    <span className='video-sec-bg'><img src={AppIcons.videoBG} alt='video' /></span>
                    <div className='video-btn-blk'>
                        <span className='video-icon'><img src={AppIcons.videoIcon} alt='video icon' /></span>
                        <Link className='blk-btn' to={'/'}>{translateThis("requestVideoTour")}</Link>
                    </div>
                </div>
            </div>

            <div id='enrollment' className='each-sec-wrapper enroll-sec-wrp f-wrp'>
                <div className='max-width-wrp'>
                    <span className='castle'><img src={AppIcons.castle} alt='castle' /></span>
                    <div className='container'>
                        <div className='enroll-sec-continer f-wrp'>
                            <h1>{translateThis("enrollment")}</h1>
                            <p>{translateThis("thankYouForYourInterestInOurChildcare")} <br />{translateThis("weLookForwardToMeetingYouAndYourChild")} </p>
                            <p>{translateThis("enrollmentText1")}</p>
                            <span>{translateThis("enrollmentText2")}</span>
                            {(getAuthRoleName() === 'parent') ? <Link className='blk-btn' to={'/my-portal'}>{translateThis("parentPortal")}</Link> : <Link className='blk-btn' to={'/auth/login'}>{translateThis("parentPortal")}</Link>}

                        </div>
                    </div>
                </div>
            </div>

            <div id='tuition' className='each-sec-wrapper tuition-sec-wrp f-wrp'>
                <div className='max-width-wrp'>
                    <div className='tuition-header f-wrp'>
                        <h1>{translateThis("tuition&Fees")}</h1>
                        <p>{translateThis("tuitionPara1")} <br />
                            {translateThis("tuitionPara2")}</p>
                        <div className='tution-head-img'>
                            <img className='tution-img1' src={AppIcons.readingKid} alt="tution header" />
                            <img className='tution-img2' src={AppIcons.rainbow} alt="tution header" />
                            <img className='tution-img3' src={AppIcons.kidbg} alt="tution header" />
                        </div>
                    </div>
                </div>

                <div className='clouds-float-wrp f-wrp'>
                    <span className='clouds-img'><img src={AppIcons.cloudEyeclose} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloudEyeclose} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloudEyeclose} alt='cloud' /></span>
                    <span className='clouds-img'><img src={AppIcons.cloudEyeclose} alt='cloud' /></span>
                </div>
                <div className='container'>
                    <div className='tuition-fee-structure f-wrp'>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className='fee-sec-wrp f-wrp'>
                                    <div className='fee-sec-img f-wrp'>
                                        <span className='fee-sec-icon'><img src={AppIcons.tuitionkid1} alt="Infants & Toddlers" /></span>
                                    </div>
                                    <div className="each-fee-sec f-wrp">
                                        <h3>{translateThis("infants&Toddlers")}</h3>
                                        <div className='trip-grid'>
                                            <div className='age'>
                                                <span><b>{translateThis("age")}</b>{translateThis("ageLimit")}</span>
                                            </div>
                                            <div className='days'>
                                                <span><b>{translateThis("days")}</b>{translateThis("daysLimit")}</span>
                                            </div>
                                            <div className='hours'>
                                                <span><b>{translateThis("hours")}</b>{translateThis("hoursLimit")}</span>
                                            </div>
                                        </div>
                                        <div className='pay-table'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>{translateThis("hourly:")} </td>
                                                        <td><span className='price'>{translateThis("$8")}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className='fee-sec-wrp f-wrp'>
                                    <div className='fee-sec-img f-wrp'>
                                        <span className='fee-sec-icon'><img src={AppIcons.tuitionkid2} alt="Transportation" /></span>
                                    </div>
                                    <div className="each-fee-sec f-wrp">
                                        <h3>{translateThis("transportation")}</h3>
                                        <div className='pay-table'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>{translateThis("weeklyRoundTrip")} </td>
                                                        <td><span className='price'>{translateThis("$70")}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{translateThis("weeklySingleTrip")} </td>
                                                        <td><span className='price'>{translateThis("$45")}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                <div className='fee-sec-wrp f-wrp'>
                                    <div className='fee-sec-img f-wrp'>
                                        <span className='fee-sec-icon'><img src={AppIcons.tuitionkid3} alt="Infants & Toddlers" /></span>
                                    </div>
                                    <div className="each-fee-sec f-wrp">

                                        <h3>{translateThis("infants&Toddlers")}</h3>
                                        <div className='trip-grid'>
                                            <div className='age'>
                                                <span><b>{translateThis("duration")}</b>{translateThis("holdASpotFor2Weeks")}</span>
                                            </div>
                                            <div className='hours'>
                                                <span><b style={{ color: '#FFF4EE' }}>{translateThis("non")}<br />{translateThis("refundable")}</b></span>
                                            </div>
                                        </div>
                                        <div className='pay-table'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>{translateThis("fee")} </td>
                                                        <td><span className='price'>{translateThis("$200")}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div className='tuition-info-txt'>
                                    <h2>{translateThis("weOnlyOfferFullTimeDaycareServices")}</h2>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div className='tuition-info-txt' style={{ float: 'right' }}>
                                    <h2>{translateThis("weOnlyWorkPart-TimeForEveningChildcare")}</h2>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div id='pay-online' className='each-sec-wrapper payment-sec-wrp f-wrp'>
                <div className='container'>
                    <span className='payment-img-sec'><img src={AppIcons.paymentBGImg} alt='payment' /></span>
                    <div className='payment-sec-container'>
                        <div className='payment-sec-con f-wrp'>
                            <h1>{translateThis("payOnline")}</h1>
                            <p>{translateThis("payOnlineText1")}</p>
                            <p>{translateThis("payOnlineText2")}
                                <span>{translateThis("payOnlineText3")}</span></p>
                            <p>{translateThis("payOnlineText4")}</p>
                            {(getAuthRoleName()) ? <Link className='blk-btn' to={'/my-portal'}>{translateThis("payOnline")}</Link> : <Link className='blk-btn' to={'/auth/login'}>{translateThis("payOnline")}</Link>}
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default HomePage;