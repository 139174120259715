import * as React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
// import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import ModalPopUp from '../../../components/modal/modal.popup';
// Components
import AppTable from '../../../components/table';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import { showSnackbar } from '../../../components/snackbar';
// import { translateThis } from '../../../helpers/language.helper';

// Services
import ApiCaller from '../../../services/api/general';
import { DATE_READABLE_FORMAT, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../../constants';
import { generatePDFBy } from '../../../services/api/pdf';
import { getDynamicFormPreview } from '../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';
import SVGIcons from '../../../assets/images/icons/svgIcons';


const AccidentReportHistoryTab = () => {

    const apiCaller = new ApiCaller('form-submissions')
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataArr, setDataArr] = React.useState([]);
    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedPreview, setSelectedPreview] = React.useState(<></>);


    const onViewClose = () => {
        setViewOpen(false);
    };
    /**
  the legendary use effect function
  This will run very first on render
   */
    React.useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList({ query: `sortBy=createdAt:desc&isParentSigned=true&isProviderSigned=true&formName=provider_accident_form` })
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * 
     * @param {string} formName 
     * @param {any} formData 
     */
    const onFormPreviewClick = (formName, formData) => {
        const previewSelected = getDynamicFormPreview(formName, { data: formData })
        setViewOpen(true);
        setSelectedPreview(previewSelected);
    }

    /**
   * Download PDF
   */
    const downloadPDF = (childId, userId, formName) => {
        if (childId && userId && formName) {
            showSnackbar().info("Selected form will be downloaded shortly")
            generatePDFBy(userId, childId, formName)
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected form has been downloaded successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                })
        } else {
            showSnackbar().warning('Something went wrong!');
        }

    }

    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 1,
            minWidth: 150,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'formTitle',
            headerName: 'Form',
            flex: 1,
            minWidth: 280,
        },
        {
            field: 'createdAt',
            headerName: 'Submission date',
            flex: 2,
            minWidth: 130,
            valueFormatter: ({ value }) => dayjs(value).format(DATE_READABLE_FORMAT)

        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: ({ row }) => {
                const { childId, userId, formName } = row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onFormPreviewClick(formName, row)
                    },
                    {
                        label: "Download",
                        onClick: () => {
                            downloadPDF(childId?.id, userId?.id, formName)
                        }
                    }
                ]} />
            }
        }

    ]

    return (
        <>
            <div className='panel-table-sec PC-Table f-wrp'>
                <AppTable
                    key={'parent-payment-table'}
                    columns={tableColumns}
                    isLoading={isLoading}
                    data={dataArr}
                />
                <div className='mob-table-wrp childView f-wrp'>
                    {dataArr.length > 0 ? <ul>
                        {dataArr.map((element) => (
                            <li key={element.id}>
                                <div className='each-sec-box'>
                                    <h4>{dayjs(element.additionalInfo?.submissionDate).format(DATE_ONLY_FORMAT)}</h4>
                                    <h4>{dayjs(element.additionalInfo?.submissionDate).format(MONTH_TEXT_FORMAT)}</h4>
                                </div>
                                <div className='each-sec-box'>
                                    <h4>{element.childId?.firstName}&nbsp;{element.childId?.lastName}</h4>
                                    <span>{element.formTitle}</span>
                                    <p className='flex-wrp'>{element.isParentSigned ?
                                        <span className='status complete'>{translateThis('Signed')}</span> :
                                        <span className='status pending'>{translateThis('Pending')}</span>}</p>
                                </div>
                                <div className='each-sec-box'>
                                    <ul className='btn-wrp'>
                                        {/* <li><button className='pln-btn ForwardIcon' type='button' onClick={() => { onFormPreviewClick(element.formName, element) }}><SVGIcons.ForwardIcon /></button></li> */}
                                        <li><button className='pln-btn' type='button' onClick={() => { downloadPDF(element.childId?.id, element.userId?.id, element.formName) }}><SVGIcons.DownloadIcon /></button></li>
                                    </ul>
                                </div>
                                <Link className='full-li-btn' onClick={() => { onFormPreviewClick(element.formName, element) }} />
                            </li >
                        ))}
                    </ul> : <div className='empty-placeholder f-wrp'>
                        <div className='placeholder-con'>
                            <img src={AppIcons.placeholder} alt='empty' />
                            <h4>{translateThis('File not found')}</h4>
                        </div>
                    </div>
                    }
                </div>
            </div>
            {viewOpen ? <ModalPopUp
                className='preview-popup'
                isOpen={viewOpen}
                onClose={onViewClose}
                aria-labelledby="table-view"
                aria-describedby="table-view"
            >
                {selectedPreview}
                <Button onClick={onViewClose} className='fill-btn'>Close</Button>
            </ModalPopUp> : null}
        </>
    )
}

export default AccidentReportHistoryTab;