import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import ModalPopUp from '../../../components/modal/modal.popup';

// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import { DATE_READABLE_FORMAT, DATE_ONLY_FORMAT, MONTH_TEXT_FORMAT } from '../../../constants/index';
import { downloadDailyLog } from '../../../services/api/pdf';
import { getDynamicFormPreview } from '../../../helpers/dynamicForm.helper';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppIcons from '../../../assets/images/icons';
import { translateThis } from '../../../helpers/language.helper';

const DailyLogHistoryTab = () => {
    const apiCaller = new ApiCaller('daily-logs');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [paginationData, setPaginationData] = useState({
        currentPage: 1,
        totalPages: 1,
        totalResults: 0,
        limit: 10,
    });
    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedPreview, setSelectedPreview] = React.useState(<></>);


    const onViewClose = () => {
        setViewOpen(false);
    };
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = (filter = {}) => {
        setIsLoading(true);
        filter = {
            ...filter, ...{
                query: 'isProviderSigned=true&isParentSigned=true'
            }
        }
        apiCaller.getList(filter)
            .then((data) => {
                setDataArr(data?.results || [])
                setPaginationData({
                    currentPage: data?.page || 1,
                    totalPages: data?.totalPages || 1,
                    totalResults: data?.totalResults || 0,
                    limit: data?.limit || 10,
                })
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
         * 
         * @param {string} formName 
         * @param {any} formData 
         */
    const onFormPreviewClick = (formName, formData) => {
        const previewSelected = getDynamicFormPreview(formName, { data: formData })
        setViewOpen(true);
        setSelectedPreview(previewSelected);
    }

    /**
   * Download Daily Log
   */
    const downloadDailyLogPDF = (id, logTitle) => {
        if (id && logTitle) {
            showSnackbar().info("Selected daily log will be downloaded shortly")
            downloadDailyLog(id, logTitle)
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected log has been downloaded successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                })
        } else {
            showSnackbar().warning('Something went wrong!.');
        }

    }


    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 1,
            minWidth: 120,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'logTitle',
            headerName: 'Communication title',
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'logDate',
            headerName: 'Communication Date',
            flex: 1,
            minWidth: 160,
            valueGetter: (params) => dayjs(params.row?.logDate).format(DATE_READABLE_FORMAT),
        },
        {
            field: 'submissionDate',
            headerName: 'Signature Date',
            flex: 1,
            minWidth: 140,
            valueGetter: (params) => dayjs(params.row?.submissionDate).format(DATE_READABLE_FORMAT),
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: ({ row }) => {
                const { id, logTitle, logKey, pdfURL } = row
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => {
                            if (pdfURL) {
                                window.open(pdfURL.tempURL, "_blank");
                            } else {
                                onFormPreviewClick(logKey, row)
                            }
                        }
                    },
                    {
                        label: "Download",
                        onClick: () => {
                            if (pdfURL) {
                                window.open(pdfURL.tempURL, "_blank");
                            } else {
                                downloadDailyLogPDF(id, logTitle)
                            }
                        }
                    }
                ]} />
            }
        },

    ]


    return (
        <>
            {/* <ProviderPortalHeader header='Children' /> */}
            <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                <div className='panel-table-sec provider-table PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                        useServerPagination
                        paginationData={paginationData}
                        paginationFn={getDataArr}
                    /> 
                    <div className='mob-table-wrp childView f-wrp'>
                        {dataArr.length > 0 ? <ul>
                            {dataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box'>
                                        <h4>{dayjs(element.logDate).format(DATE_ONLY_FORMAT)}</h4>
                                        <h4>{dayjs(element.logDate).format(MONTH_TEXT_FORMAT)}</h4>
                                    </div>
                                    <div className='each-sec-box'>
                                        <h4>{element.childId?.firstName}&nbsp;{element.childId?.lastName}</h4>
                                        <span>{element.logTitle}</span>
                                    </div>
                                    <div className='each-sec-box'>
                                        <ul className='btn-wrp'>
                                            <li><button className='pln-btn' type='button' onClick={() => {
                                                if (element.pdfURL) {
                                                    window.open(element.pdfURL.tempURL, "_blank");
                                                } else {
                                                    downloadDailyLogPDF(element.id, element.logTitle)
                                                }
                                            }}><SVGIcons.DownloadIcon /></button></li>
                                        </ul>
                                    </div>
                                    <Link className='full-li-btn' onClick={() => {
                                                if (element.pdfURL) {
                                                    window.open(element.pdfURL.tempURL, "_blank");
                                                } else {
                                                    onFormPreviewClick(element.logKey, element)
                                                }
                                            }} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholder} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                    {viewOpen ? <ModalPopUp
                        className='preview-popup'
                        isOpen={viewOpen}
                        onClose={onViewClose}
                        aria-labelledby="table-view"
                        aria-describedby="table-view"
                    >
                        {selectedPreview}
                        <Button onClick={onViewClose} className='fill-btn'>Close</Button>
                    </ModalPopUp> : null}
                </div>
            </div>
        </>
    )
}


export default DailyLogHistoryTab;