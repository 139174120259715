import React from 'react';
import { Grid, Button, Stack, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';

import './index.scss'

// Custom components
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { showSnackbar } from '../../../components/snackbar/index';
import { translateThis } from '../../../helpers/language.helper';
import TextInput from '../../../components/inputs/textInput/textInput';
import FileUploader from '../../../components/awsS3ImageUploader/file.uploader';
import { getURLExtension, getUniqueId } from '../../../helpers/helper.functions';
import AppIcons from '../../../assets/images/icons';
import ApiCaller from '../../../services/api/general';


const DynamicCustomFormsUploadTab = ({onSuccess}) => {
    const apiCaller = new ApiCaller('documents')
    const [formKey, setFormKey] = React.useState(getUniqueId());    
    const [customFormName, setCustomFormName] = React.useState('');    
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [file, setFile] = React.useState({});    


    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (file &&
            file?.fileKey &&
            customFormName) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            file: file?.fileKey,
            type:"form",
            name: customFormName
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success("Uploaded successfully!. You can view it in the downloads tab");
                    onFormComplete();
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };
    

    /**
     * On Form Complete
     */
    const onFormComplete = () => {
        setFile({});
        setFormKey(getUniqueId());
        setCustomFormName('');
        onSuccess();

    }
    

    return (
        <>

            <div key={formKey} className='panel-form-sec forms-main-wrapper dailyCommUpload f-wrp'>

                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}> 
                    <div className='info-head f-wrp'>
                                <span style={{color: '#000', display: 'block', padding: '25px', fontWeight: '600'}}>Upload an empty form for parents to download and print</span>
                            </div>                       

                        <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                                key={`provider_communication_form_customName-${formKey}`}
                                label={"Form name"}
                                name='provider_communication_form_customName'
                                id='provider_communication_form_customName'
                                value={customFormName}
                                translationKey='provider_communication_form_customName'
                                type='text'
                                isRequired={false}
                                placeholder='Form name'
                                className='custom-textfield'
                                getValue={(_, value) => {
                                    setCustomFormName(value)
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                        {file?.tempURL ? <span>
                                <IconButton children={<DeleteRounded fontSize='small' />} onClick={() => setFile({ fileKey: '', tempURL: '' })} />
                                {getURLExtension(file?.tempURL) === "pdf" ? <img style={{ width: '50px', height: '50px' }} src={AppIcons.pdfIcon} alt={"file"} /> : <img style={{ width: '200px', height: '200px' }} src={file.tempURL} alt={"file"} />}
                            </span> : null}
                            <br />
                            <Stack className='panel-form f-wrp calender-sec' spacing={3}>
                                <FileUploader
                                    key={`communication-uploader-${formKey}`}
                                    acceptOnly='application/pdf,image/*'
                                    uploadIcon={<SVGIcons.UploadIcon />}
                                    uploadCaption={translateThis("Upload a file or take a photo")}
                                    onUploadEnd={(file) => setFile(file)}
                                />
                            </Stack>
                        </Grid>                        

                        <Grid item xs={12} sm={12} md={12}>
                            <div className='button-sec f-wrp'>
                                <div className='lft-btn-sec' >
                                    <Button onClick={onFormComplete} className='cancel-btn'>{translateThis("cancel")}</Button>
                                </div>
                                <div className='ryt-btn-sec'>


                                    <LoadingButton
                                        loading={isSubmitting}
                                        loadingPosition='start'
                                        startIcon={<></>} // To remove Mui warning
                                        fullWidth
                                        disabled={!isValidForm()}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className='fill-btn'
                                        onClick={onSubmit}
                                    >
                                        {translateThis("submit")}
                                    </LoadingButton>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

DynamicCustomFormsUploadTab.propTypes = {
    onSuccess: PropTypes.func
}
export default DynamicCustomFormsUploadTab;