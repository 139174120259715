import { atom } from "recoil";
import persistAtom from "../recoilPersister";

export const providerConfigState = atom({
  key: 'providerConfig', // unique ID (with respect to other atoms/selectors)
  effects_UNSTABLE: [persistAtom],
  default: {
    selectedParentId: "",
    selectedPageView: "parent-list",
    selectedChildId: "",
    childrenSection: {
      selectedPageView: "children-list",
      selectedChildId: "",
    },
    bulletinSection: {
      selectedPageView: "bulletin-list",
      selectedBulletinId: "",
    },
    dailyCommunication: {
      providerCommunication: {},
      napTimeForm: {}
    }
  },
});