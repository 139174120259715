import React from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers';


const DateInput = (props) => {
    const {
        label = 'Select date',
        value = '',
        onChange = () => null,

    } = props;
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker
                        value={value}
                        onChange={onChange}
                        label={label}
                         />
                </DemoContainer>
            </LocalizationProvider>
        </div>

    )
}
DateInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func
}

export default DateInput;