import * as React from 'react';
import { useState } from 'react';
import propTypes from 'prop-types';
import dayjs from 'dayjs';

// @mui

import { Button, IconButton, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import '../../../../assets/css/form.scss';
// ----------------------------------------------------------------------
import { postChild, updateChild } from '../../../../services/api/child';
import { getAuthId } from '../../../../helpers/auth.helper';
import { childModel } from '../../../../models/child.model';
import { getUniqueId } from '../../../../helpers/helper.functions';
import ProfilePicUploader from '../../../../components/awsS3ImageUploader/profilePic.uploader';
import { DATE_FORMAT } from '../../../../constants/index';
import { translateThis } from '../../../../helpers/language.helper';


const AddEditChildForm = (props) => {
    const { id,
        childFormId = '',
        onClose = () => null,
        onSuccess = () => null,
        initialData = childModel
    } = props;
    const parentId = getAuthId();
    const [isLoading, setIsLoading] = useState(false);
    const [formId, setFormId] = useState('');
    const [formData, setFormData] = useState({});
    const [guardianArr, setGuardianArr] = useState([]);
    const childId = (id && id !== 'add-child') ? id : '';


    /**
     * Legendary use effect
     */
    React.useEffect(() => {
        setFormData(initialData);
        setGuardianArr(initialData.guardian);
        setFormId(getUniqueId('child-form', childFormId))
        return () => {
            setFormData({})
            setGuardianArr([])
            setFormId('')
        };
    }, [initialData, childFormId]);


    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = {...formData};
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData && formData.firstName &&
            formData.lastName &&
            formData.birthday &&
            formData.relationship &&
            (formData.singleGuardian ||
                guardianArr.length > 0)
        ) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsLoading(true);
            let action = postChild;
            
            const formPostData = {
                parentId,
                name: formData.name,
                firstName: formData.firstName,
                lastName: formData.lastName,
                birthday: formData.birthday,
                relationship: formData.relationship,
                profilePic: formData.profilePic?.fileKey,
                guardian: guardianArr
            }
            // lets take every Guardians
            if (formData.singleGuardian) {
                formPostData.guardian.push(formData.singleGuardian)
            }
            if (childId) {
                formPostData.childId = childId;
                action = updateChild;
            }
            action(formPostData).then((data) => {
                if (data.success) {
                    onSuccess();
                    setFormData(childModel)
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setGuardianArr([]) || setIsLoading(false));
        }
    };

    const onCancelClick = () => {
        setFormData({})
        setGuardianArr([...[]])
        if (typeof onClose === "function") {
            onClose();
        }

    }


    return (
        <div key={childId} className='add-child-layout f-wrp'>
            <Stack className='child-profile standard-form' spacing={3}>
                <ProfilePicUploader
                    key={`profile-pic-${formId}`}
                    onUploadStart={() => null}
                    onUploadEnd={(img) => setFormValue("profilePic", img)}
                    onRemoveFile={() => setFormValue("profilePic", '')}
                    defaultImage={formData?.profilePic}
                />
                <TextInput
                    key={`first-name-${formId}`}
                    label={translateThis('First Name')}
                    name='firstName'
                    value={formData?.firstName}
                    type='text'
                    required
                    placeholder={translateThis('First Name')}
                    getValue={setFormValue}
                    className='custom-textfield'
                />

                <TextInput
                    key={`last-name-${formId}`}
                    label={translateThis('Last name')}
                    name='lastName'
                    value={formData?.lastName}
                    type='text'
                    required
                    placeholder={translateThis('Last name')}
                    getValue={setFormValue}
                    className='custom-textfield'
                />

                <LocalizationProvider className="custom-textfield" dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                            maxDate={dayjs()}
                            key={`birthday-date-${formId}`}
                            defaultValue={formData?.birthday ? dayjs(formData?.birthday) : undefined}
                            onChange={(v) => setFormValue('birthday', dayjs(v).format(DATE_FORMAT))
                            }
                            label={translateThis('Birthday')} />
                    </DemoContainer>
                </LocalizationProvider>


                <FormControl fullWidth className='custom-textfield'>
                    <InputLabel id="select-relation">{translateThis('Relationship')} </InputLabel>
                    <Select
                        key={`relationship-name-${formId}`}
                        labelId="select-relation"
                        id="select-relation-select"
                        value={formData?.relationship || ''}
                        label={translateThis('Relationship')}
                        onChange={(e) => setFormValue('relationship', e.target.value)}
                    >
                        <MenuItem value={'mother'}>{translateThis('Mother')}</MenuItem>
                        <MenuItem value={'father'}>{translateThis('Father')}</MenuItem>
                        <MenuItem value={'guardian'}>{translateThis('Guardian')}</MenuItem>
                        <MenuItem value={'other'}>{translateThis('Other')}</MenuItem>
                    </Select>
                </FormControl>
                {(guardianArr.length > 0) ? <div className='custom-delete-element' key={`guardian-list-name-${formId}`}>
                    {guardianArr.map((guardian, k) => (
                        <div className='each-custom-element' key={`${formId}-${guardian}-${k}`}><span>{guardian}</span>
                            <IconButton
                                aria-label="remove"
                                onClick={() => {
                                    let _guardian = [...guardianArr];
                                    _guardian = _guardian.filter((_, i) => i !== k)
                                    setGuardianArr([..._guardian])
                                }}
                            >
                                {SVGIcons.MinusIcon()}
                            </IconButton>
                        </div>
                    ))}
                </div> : null}

                <div className='custom-add-element'>
                    <TextInput
                        id={'singleGuardian-id'}
                        key={`singleGuardian-${formId}`}
                        label={translateThis('Add guardian')}
                        name='singleGuardian'
                        type='text'
                        value={formData?.singleGuardian}
                        getValue={(_, v) => setFormValue('singleGuardian', v)}
                        onFocusout={(_, v,e) => {
                            e.target.value = ''
                        }}
                        placeholder={translateThis('Add guardian')}
                        className='custom-textfield'
                    />
                    <IconButton
                        aria-label="add"
                        onClick={() => {
                            const guardian = guardianArr;
                            if (formData?.singleGuardian) {
                                guardian.push(formData.singleGuardian);
                                setGuardianArr([...guardianArr]);    
                            }
                        }}
                    >
                        {SVGIcons.PlusIcon()}
                    </IconButton>
                </div>

                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!isValidForm()}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='standard-btn'
                    onClick={onSubmit}>
                    {childId ? <>{translateThis('Update child profile')}</> : <>{translateThis('Create child profile')}</>}
                </LoadingButton>

                <Button
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    className='standard-btn pln-btn'
                    onClick={() => onCancelClick()}>
                    {translateThis('cancel')}
                </Button>
            </Stack>
        </div>
    );
}

AddEditChildForm.propTypes = {
    id: propTypes.string,
    childFormId: propTypes.string,
    initialData: propTypes.any,
    onClose: propTypes.func,
    onSuccess: propTypes.func,
}

export default AddEditChildForm;