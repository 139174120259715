import { useEffect, useState } from 'react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import propTypes from 'prop-types';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


// @mui
import { Stack, Divider, Button, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';

// components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';

// services
import { getChild } from '../../../../services/api/child';

// helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_EXTENDED_FORMAT } from '../../../../constants/index';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import { getUniqueId } from '../../../../helpers/helper.functions';
import { getChildLogBy, submitDailyLog } from '../../../../services/api/dailyLogs';
import { providerConfigState } from '../../../../state/reducerAtoms/provider.atom';





const NapTimeForm = (props) => {
    const pageName = "Nap time";
    const logKey = "nap-time";

    const { childId, onChangeFormState = () => null } = props;
    const providerId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [logDate, setLogDate] = useState(dayjs());
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [submissionDate, setSubmissionDate] = useState(dayjs());

    // Save form temporarily 
    const [configState, setConfigState] = useRecoilState(providerConfigState);
    const defaultFormValues = configState.dailyCommunication?.napTimeForm[childId] ? configState.dailyCommunication?.napTimeForm[childId] : []
    const [initialFormData, setInitialFormData] = useState(defaultFormValues);
    const [formData, setFormData] = useState(defaultFormValues);

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({
        ...prv, ...{
            dailyCommunication: {
                ...prv.dailyCommunication,
                napTimeForm: { ...prv.dailyCommunication?.napTimeForm, ...modifiedObj }
            }
        }
    }));

    /**
        * Invokes when a cleanup triggered
        * @returns Fun
        */
    const cleanupFormFromState = () => {
        setConfigState((prv) => {
            const napTimeForm = { ...prv.dailyCommunication?.napTimeForm, [childId]: '' };
            delete napTimeForm[childId];
            return ({
                ...prv, ...{
                    dailyCommunication: {
                        ...prv.dailyCommunication,
                        napTimeForm
                    }
                }
            })
        })
    };

    // END of temporarily save form

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(logKey));
        if (childId) {
            getChildById();
        }
        setInterval(() => {
            setSubmissionDate(dayjs())
        }, 1000);
        return () => {
            setInitialFormData(null)
        };
        // eslint-disable-next-line
    }, [childId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                    getData();
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => { });
    }

    // get data from api
    const getData = () => {
        if (selectedChild) {
            setIsLoading(true);
            getChildLogBy(selectedChild.parentId, childId, logKey)
                .then((data) => {
                    if (data?.formData) {
                        // setInitialFormData(data?.formData)
                        // setFormData(data?.formData)
                    }
                })
                .catch(() => showSnackbar().failure('Something went wrong'))
                .finally(() => setIsLoading(false));
        }

    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
        modifyConfigState({ [childId]: filteredFormData })
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild.id) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const formDataAs = formData.filter((v, i, a) => a.findIndex(v2 => ['questionId', 'type'].every(k => v2[k] === v[k])) === i)
        const postData = {
            logKey: 'nap-time',
            logTitle: 'Nap time',
            additionalInfo: {
                isProviderSigned: true,
                providerSignature: signature,
                providerSignedDate: dayjs().format(DATE_TIME_FORMAT)
            },
            parentId: selectedChild.parentId,
            providerId,
            childId,
            formData: formDataAs,
            isProviderSigned: true,
            submissionDate: dayjs().format(DATE_TIME_FORMAT),
            logDate
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitDailyLog(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    cleanupFormFromState();
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>

                <>
                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head f-wrp'>
                            <h2>{translateThis("Daily communication")}</h2>
                        </div>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis(pageName)}</h3>
                                </div>
                            </Grid>
                            {(selectedChild) && <Grid item xs={12} sm={6} md={12} lg={6}>
                                <Stack className='panel-form' spacing={3}>
                                    <TextField
                                        type='text'
                                        disabled
                                        defaultValue={`${selectedChild?.firstName} ${selectedChild?.lastName}`}
                                        name="childName"
                                        label={translateThis('Child’s name')}
                                    />
                                </Stack>
                            </Grid>}

                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Stack className='panel-form calender-sec' spacing={3}>
                                    <LocalizationProvider className="custom-textfield" dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                format={DATE_FORMAT}
                                                label={translateThis('Log date')}
                                                defaultValue={logDate}
                                                maxDate={dayjs()}
                                                disableFuture
                                                onChange={(v) => setLogDate(dayjs(v).format(DATE_FORMAT))}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                            {submissionDate && <Grid item xs={12} sm={6} md={6} lg={3}>
                                <Stack className='panel-form calender-sec' spacing={3}>
                                    <TextField
                                        type='text'
                                        label={translateThis('Submission date and time')}
                                        value={dayjs(submissionDate).format(DATE_TIME_EXTENDED_FORMAT)}
                                        disabled
                                    />

                                </Stack>
                            </Grid>}

                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis('Fixed hours')}</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} style={{ paddingLeft: '0' }}>
                                <div className='form-slider-box nap-slider-wrp f-wrp'>

                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={10}
                                        slidesPerView={2}
                                        className='form_time_slider'
                                        navigation
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 0,
                                            },
                                            991: {
                                                slidesPerView: 2,
                                                spaceBetween: 40,
                                            },
                                            1200: {
                                                slidesPerView: 2,
                                                spaceBetween: 50,
                                            },
                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className='time-list-wrp panel-form f-wrp'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>{translateThis('Time')} </th>
                                                            <th style={{ textAlign: 'center' }}>{translateThis('Asleep')}</th>
                                                            <th>{translateThis('Staff member')}</th>
                                                            <th>{translateThis('oz of milk')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {['12:00', '12:05', '12:10', '12:15', '12:20', '12:25', '12:30', '12:35', '12:40', '12:45', '12:50', '12:55'].map((time) => (
                                                            <tr key={time}>
                                                                <td style={{ maxWidth: '120px' }}>
                                                                    <TextInput
                                                                        key={`nap_time_form_Key_Time_${time}`}
                                                                        name={`nap_time_form_Key_Time_${time}`}
                                                                        id={`nap_time_form_Key_Time_${time}`}
                                                                        value={time}
                                                                        readOnly
                                                                        type='text'
                                                                        placeholder=''
                                                                        className='custom-textfield'
                                                                        endIcon={(
                                                                            <InputAdornment position="end">
                                                                                {translateThis('PM')}
                                                                            </InputAdornment>
                                                                        )}
                                                                    />
                                                                </td>

                                                                <td style={{ width: '100px' }}>
                                                                    <FormGroup row>
                                                                        <FormControlLabel
                                                                            onChange={(e) => {
                                                                                setFormValue({
                                                                                    questionId: `nap_time_form_Key_Time_${time}_asleep`,
                                                                                    answer: e.target.checked,
                                                                                    translationKey: `nap_time_form_Key_Time_${time}_asleep`,
                                                                                    type: 'checkbox'
                                                                                })
                                                                            }}
                                                                            defaultChecked={answerGetter(`nap_time_form_Key_Time_${time}_asleep`, initialFormData)?.answer}
                                                                            control={<Checkbox
                                                                                defaultChecked={answerGetter(`nap_time_form_Key_Time_${time}_asleep`, initialFormData)?.answer}
                                                                                icon={<SVGIcons.CheckboxIcon />}
                                                                                checkedIcon={<SVGIcons.CheckboxFilledIcon />} />}
                                                                            label="" labelPlacement="end"
                                                                            style={{ margin: " 0 auto" }} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td style={{ paddingRight: '15px' }}>
                                                                    <TextInput
                                                                        key={`nap_time_form_Key_Time_${time}_staff_member_key`}
                                                                        name={`nap_time_form_Key_Time_${time}_staff_member`}
                                                                        id='nap_time_form_staffMember_1'
                                                                        value={answerGetter(`nap_time_form_Key_Time_${time}_staff_member`, initialFormData)?.answer}
                                                                        translationKey={`nap_time_form_Key_Time_${time}_staff_member`}
                                                                        type='text'
                                                                        placeholder=''
                                                                        className='custom-textfield'
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: `nap_time_form_Key_Time_${time}_staff_member`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />
                                                                </td>
                                                                <td style={{ width: '100px' }}>
                                                                    <TextInput
                                                                        key={`nap_time_form_Key_Time_${time}_oz_milk_key`}
                                                                        name={`nap_time_form_Key_Time_${time}_oz_milk`}
                                                                        id={`nap_time_form_Key_Time_${time}_oz_milk`}
                                                                        value={answerGetter(`nap_time_form_Key_Time_${time}_oz_milk`, initialFormData)?.answer}
                                                                        translationKey={`nap_time_form_Key_Time_${time}_oz_milk`}
                                                                        type='text'
                                                                        placeholder=''
                                                                        className='custom-textfield'
                                                                        endIcon={(
                                                                            <InputAdornment position="end">
                                                                                {translateThis('oz')}
                                                                            </InputAdornment>
                                                                        )}
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: `nap_time_form_Key_Time_${time}_oz_milk`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='time-list-wrp panel-form f-wrp'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>{translateThis('Time')} </th>
                                                            <th style={{ textAlign: 'center' }}>{translateThis('Asleep')}</th>
                                                            <th>{translateThis('Staff member')}</th>
                                                            <th>{translateThis('oz of milk')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {['1:00', '1:05', '1:10', '1:15', '1:20', '1:25', '1:30', '1:35', '1:40', '1:45', '1:50', '1:55'].map((time) => (
                                                            <tr key={time}>
                                                                <td style={{ maxWidth: '120px' }}>
                                                                    <TextInput
                                                                        key={`nap_time_form_Key_Time_${time}`}
                                                                        name={`nap_time_form_Key_Time_${time}`}
                                                                        id={`nap_time_form_Key_Time_${time}`}
                                                                        value={time}
                                                                        readOnly
                                                                        type='text'
                                                                        placeholder=''
                                                                        className='custom-textfield'
                                                                        endIcon={(
                                                                            <InputAdornment position="end">
                                                                                {translateThis('PM')}
                                                                            </InputAdornment>
                                                                        )}
                                                                    />
                                                                </td>

                                                                <td style={{ width: '100px' }}>
                                                                    <FormGroup row>
                                                                        <FormControlLabel
                                                                            onChange={(e) => {
                                                                                setFormValue({
                                                                                    questionId: `nap_time_form_Key_Time_${time}_asleep`,
                                                                                    answer: e.target.checked,
                                                                                    translationKey: `nap_time_form_Key_Time_${time}_asleep`,
                                                                                    type: 'checkbox'
                                                                                })
                                                                            }}
                                                                            defaultChecked={answerGetter(`nap_time_form_Key_Time_${time}_asleep`, initialFormData)?.answer}
                                                                            control={<Checkbox
                                                                                defaultChecked={answerGetter(`nap_time_form_Key_Time_${time}_asleep`, initialFormData)?.answer}
                                                                                icon={<SVGIcons.CheckboxIcon />}
                                                                                checkedIcon={<SVGIcons.CheckboxFilledIcon />} />}
                                                                            label="" labelPlacement="end"
                                                                            style={{ margin: " 0 auto" }} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td style={{ paddingRight: '15px' }}>
                                                                    <TextInput
                                                                        key={`nap_time_form_Key_Time_${time}_staff_member_key`}
                                                                        name={`nap_time_form_Key_Time_${time}_staff_member`}
                                                                        id='nap_time_form_staffMember_1'
                                                                        value={answerGetter(`nap_time_form_Key_Time_${time}_staff_member`, initialFormData)?.answer}
                                                                        translationKey={`nap_time_form_Key_Time_${time}_staff_member`}
                                                                        type='text'
                                                                        placeholder=''
                                                                        className='custom-textfield'
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: `nap_time_form_Key_Time_${time}_staff_member`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />
                                                                </td>
                                                                <td style={{ width: '100px' }}>
                                                                    <TextInput
                                                                        key={`nap_time_form_Key_Time_${time}_oz_milk_key`}
                                                                        name={`nap_time_form_Key_Time_${time}_oz_milk`}
                                                                        id={`nap_time_form_Key_Time_${time}_oz_milk`}
                                                                        value={answerGetter(`nap_time_form_Key_Time_${time}_oz_milk`, initialFormData)?.answer}
                                                                        translationKey={`nap_time_form_Key_Time_${time}_oz_milk`}
                                                                        type='text'
                                                                        placeholder=''
                                                                        className='custom-textfield'
                                                                        endIcon={(
                                                                            <InputAdornment position="end">
                                                                                {translateThis('oz')}
                                                                            </InputAdornment>
                                                                        )}
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: `nap_time_form_Key_Time_${time}_oz_milk`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='time-list-wrp panel-form f-wrp'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>{translateThis('Time')} </th>
                                                            <th style={{ textAlign: 'center' }}>{translateThis('Asleep')}</th>
                                                            <th>{translateThis('Staff member')}</th>
                                                            <th>{translateThis('oz of milk')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {['2:00', '2:05', '2:10', '2:15', '2:20', '2:25', '2:30', '2:35', '2:40', '2:45', '2:50', '2:55'].map((time) => (
                                                            <tr key={time}>
                                                                <td style={{ maxWidth: '120px' }}>
                                                                    <TextInput
                                                                        key={`nap_time_form_Key_Time_${time}`}
                                                                        name={`nap_time_form_Key_Time_${time}`}
                                                                        id={`nap_time_form_Key_Time_${time}`}
                                                                        value={time}
                                                                        readOnly
                                                                        type='text'
                                                                        placeholder=''
                                                                        className='custom-textfield'
                                                                        endIcon={(
                                                                            <InputAdornment position="end">
                                                                                {translateThis('PM')}
                                                                            </InputAdornment>
                                                                        )}
                                                                    />
                                                                </td>

                                                                <td style={{ width: '100px' }}>
                                                                    <FormGroup row>
                                                                        <FormControlLabel
                                                                            onChange={(e) => {
                                                                                setFormValue({
                                                                                    questionId: `nap_time_form_Key_Time_${time}_asleep`,
                                                                                    answer: e.target.checked,
                                                                                    translationKey: `nap_time_form_Key_Time_${time}_asleep`,
                                                                                    type: 'checkbox'
                                                                                })
                                                                            }}
                                                                            defaultChecked={answerGetter(`nap_time_form_Key_Time_${time}_asleep`, initialFormData)?.answer}
                                                                            control={<Checkbox
                                                                                defaultChecked={answerGetter(`nap_time_form_Key_Time_${time}_asleep`, initialFormData)?.answer}
                                                                                icon={<SVGIcons.CheckboxIcon />}
                                                                                checkedIcon={<SVGIcons.CheckboxFilledIcon />} />}
                                                                            label="" labelPlacement="end"
                                                                            style={{ margin: " 0 auto" }} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td style={{ paddingRight: '15px' }}>
                                                                    <TextInput
                                                                        key={`nap_time_form_Key_Time_${time}_staff_member_key`}
                                                                        name={`nap_time_form_Key_Time_${time}_staff_member`}
                                                                        id='nap_time_form_staffMember_1'
                                                                        value={answerGetter(`nap_time_form_Key_Time_${time}_staff_member`, initialFormData)?.answer}
                                                                        translationKey={`nap_time_form_Key_Time_${time}_staff_member`}
                                                                        type='text'
                                                                        placeholder=''
                                                                        className='custom-textfield'
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: `nap_time_form_Key_Time_${time}_staff_member`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />
                                                                </td>
                                                                <td style={{ width: '100px' }}>
                                                                    <TextInput
                                                                        key={`nap_time_form_Key_Time_${time}_oz_milk_key`}
                                                                        name={`nap_time_form_Key_Time_${time}_oz_milk`}
                                                                        id={`nap_time_form_Key_Time_${time}_oz_milk`}
                                                                        value={answerGetter(`nap_time_form_Key_Time_${time}_oz_milk`, initialFormData)?.answer}
                                                                        translationKey={`nap_time_form_Key_Time_${time}_oz_milk`}
                                                                        type='text'
                                                                        placeholder=''
                                                                        className='custom-textfield'
                                                                        endIcon={(
                                                                            <InputAdornment position="end">
                                                                                {translateThis('oz')}
                                                                            </InputAdornment>
                                                                        )}
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: `nap_time_form_Key_Time_${time}_oz_milk`,
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>Custom hours</h3>
                                </div>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} md={12}>
                                <div className='time-list-wrp panel-form add-list-time f-wrp'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Time </th>
                                                <th style={{ textAlign: 'center' }}>Asleep</th>
                                                <th>Staff member</th>
                                                <th>oz of milk</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ maxWidth: '100px' }}>
                                                    <TextInput
                                                        key={`nap_time_form_Key_Add_Time_1`}
                                                        // label={translateThis('nap_time_form_Add_Time_1')}
                                                        name='nap_time_form_Add_Time_1'
                                                        id='nap_time_form_Add_Time_1'
                                                        // value={answerGetter('nap_time_form_Add_Time_1', initialFormData)?.answer}
                                                        value='1:00'
                                                        translationKey='nap_time_form_Add_Time_1'
                                                        type='text'
                                                        placeholder=''
                                                        className='custom-textfield'
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    PM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'nap_time_form_Add_Time_1',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </td>

                                                <td style={{ width: '100px' }}>
                                                    <FormGroup row>
                                                        <FormControlLabel value="diapers" control={<Checkbox icon={<SVGIcons.CheckboxIcon />} checkedIcon={<SVGIcons.CheckboxFilledIcon />} />} label="" labelPlacement="end" style={{ margin: " 0 auto" }} />
                                                    </FormGroup>
                                                </td>
                                                <td style={{ paddingRight: '15px' }}>
                                                    <TextInput
                                                        key={`nap_time_form_Key_Add_staffMember_1`}
                                                        // label={translateThis('nap_time_form_Add_staffMember_1')}
                                                        name='nap_time_form_Add_staffMember_1'
                                                        id='nap_time_form_Add_staffMember_1'
                                                        value={answerGetter('nap_time_form_Add_staffMember_1', initialFormData)?.answer}
                                                        translationKey='nap_time_form_Add_staffMember_1'
                                                        type='text'
                                                        placeholder=''
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'nap_time_form_Add_staffMember_1',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </td>
                                                <td style={{ width: '100px' }}>
                                                    <TextInput
                                                        key={`nap_time_form_Key_Add_ozOfMilk_1`}
                                                        // label={translateThis('nap_time_form_Add_ozOfMilk_1')}
                                                        name='nap_time_form_Add_ozOfMilk_1'
                                                        id='nap_time_form_Add_ozOfMilk_1'
                                                        value={answerGetter('nap_time_form_Add_ozOfMilk_1', initialFormData)?.answer}
                                                        translationKey='nap_time_form_Add_ozOfMilk_1'
                                                        type='text'
                                                        placeholder=''
                                                        className='custom-textfield'
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    oz
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'nap_time_form_Add_ozOfMilk_1',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='btn-wrp'>
                                        <button type='button' className='fill-btn '> + </button>
                                    </div>
                                </div>
                            </Grid> */}



                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <h3>{translateThis('Comments')}</h3>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    key={`nap_time_form_Key_comments`}
                                    label={translateThis('nap_time_form_comments')}
                                    name='nap_time_form_comments'
                                    id='nap_time_form_comments'
                                    defaultValue={answerGetter('nap_time_form_comments', initialFormData)?.answer}
                                    type='text'
                                    multiline
                                    placeholder=''
                                    className='custom-textfield'
                                    onChange={(e) => {
                                        setFormValue({
                                            questionId: "nap_time_form_comments",
                                            answer: e.target.value,
                                            translationKey: 'nap_time_form_comments',
                                            type: 'text'
                                        })
                                    }}
                                    rows={4}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>

                                <div className='info-head f-wrp'>
                                    <span>&nbsp;</span>
                                </div>
                                {/* <div style={{ maxWidth: '200px', float: 'right' }}>
                                    <TextInput
                                        key={`nap_time_form_Key_timeOut`}
                                        label={translateThis('nap_time_form_timeOut')}
                                        name='nap_time_form_timeOut'
                                        id='nap_time_form_timeOut'
                                        value={answerGetter('nap_time_form_timeOut', initialFormData)?.answer}
                                        translationKey='nap_time_form_timeOut'
                                        type='number'
                                        required
                                        multiline
                                        placeholder='Submission time'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'nap_time_form_submission_time',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </div> */}

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className='info-head f-wrp'>
                                    <span>{translateThis('Staff signature')}</span>
                                </div>
                                <TextInput
                                    key={`nap_time_form_question_signatureName`}
                                    label={translateThis('nap_time_form_question_signatureName')}
                                    name='nap_time_form_question_signatureName'
                                    id='nap_time_form_question_signatureName'
                                    translationKey='nap_time_form_question_signatureName'
                                    type='text'
                                    required
                                    placeholder={translateThis('nap_time_form_question_signatureName')}
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'nap_time_form_question_signatureName',
                                            type: 'text'
                                        })
                                        setSignature(value)
                                    }}
                                    useDefaultValidation
                                />
                                <FormGroup className='check-label'>
                                    <FormControlLabel
                                        defaultChecked={isSigned}
                                        onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis('I')}, {(signature) && <b>{signature},</b>} {translateThis('affirm that the information')}</span>
                                        } />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Stack>
                </>

                <br />

                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='cancel-btn'>{translateThis('cancel')}</Button>
                    </div>
                    <div className='ryt-btn-sec pln-btn-style'>
                        {(childId === "sample123") ? <PDFDownloadButton
                            className='pln-btn'
                            // parentId={parentId}
                            childId={childId}
                        // formName={formName}
                        /> : null}

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm() || !isSigned || !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            {translateThis('submit')}
                        </LoadingButton>

                    </div>
                </div>
            </div>}
        </>
    );
}

NapTimeForm.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func
}

export default NapTimeForm;