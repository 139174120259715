import instance from './axios';

const axiosInstance = instance;
const apiEndPoint = '/daily-logs';


/**
* Gets the daily log form by ids
* @param {string} parentId;
* @param {string} childId;
* @param {string} logKey;
* @returns Object
*/
export const getChildLogBy = async (parentId, childId, logKey) => {
    try {
        const result = await axiosInstance.get(`${apiEndPoint}?parentId=${parentId}&childId=${childId}&logKey=${logKey}`);
        const { data = null } = result;
        return data.results && data.results[0] ? data.results[0] : {};
    } catch (e) {
        return null;
    }
}


/**
 * Submit a daily log form
 * @param {Object} data the form data
 */
export const submitDailyLog = async (data) => {
    try {
        const result = await axiosInstance.post(`${apiEndPoint}`, data);
        return ({ success: [200,201].includes(result.status) , message: "Log submitted successfully!"});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}