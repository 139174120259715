import React from 'react';
import propTypes from 'prop-types';
// MUI
import { Stack, Grid, Divider } from '@mui/material';

// Custom components
import BasicDropdown from '../../../components/dropdowns/basic.dropdown';
import ChildrenListDropdown from '../../../components/dropdowns/children.dropdown';

// Forms
import EmergencyNumber from './forms/emergencyNumber';
import ChildEnrollment from './forms/childEnrollment';
import SampleFormTwo from './forms/sampleFormTwo';
import ParentsInfoForm from './forms/parentsInfoForm';
import WrittenPermission from './forms/writtenPermission';
import EarlyChildhoodHealthAssessmentRecord from './forms/healthAssessmentRecord';
import ImmunizationRequirements from './forms/immunizationRequirements';
import C4KApplication from './forms/C4KApplication';
import C4KRedetermination from './forms/C4KRedetermination';
import SpecialNeedsVerificationForm from './forms/specialNeedsVerificationForm';
import { hasAccess } from '../../../helpers/auth.helper';
import AccessDeniedScreen from '../../../components/access/accessDenied';
import SelfEmployment from './forms/selfEmployment';
import DisabilityVerification from './forms/disabilityVerification';
import SpecialNeeds from './forms/specialNeeds';
import MedicalExemption from './forms/medicalExemption';
import { translateThis } from '../../../helpers/language.helper';


const ParentDynamicFormsToCompleteTab = (props) => {

    const { id = '' } = props;
    const [selectedFormName, setSelectedFormName] = React.useState('');
    const [selectedChildId, setSelectedChildId] = React.useState('');
    const [selectedForm, setSelectedForm] = React.useState(<></>);

    /**
     * 
     * @param {string} value 
     */
    const setSelectedChild = (value) => {
        setSelectedChildId(value);
        setSelectedFormName('');
        setSelectedForm(<></>);
    }
    /**
     * Set the selected form
     * @param {string} formName selected form
     */
    const handleFormSelection = (formName) => {
        setSelectedFormName(formName);
        let formToRender = <></>;
        switch (formName) {
            case "parent_info":
                formToRender = <ParentsInfoForm
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "emergency_number":
                formToRender = <EmergencyNumber
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "child_enrollment":
                formToRender = <ChildEnrollment
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "written_permission":
                formToRender = <WrittenPermission
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "early_childhood_health_assessment_record":
                formToRender = <EarlyChildhoodHealthAssessmentRecord
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "immunization_requirements":
                formToRender = <ImmunizationRequirements
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "c4k_application":
                formToRender = <C4KApplication
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "c4k_redetermination":
                formToRender = <C4KRedetermination
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "parent_provider_agreement":
                formToRender = <span style={{ display: 'block', textAlign: 'center', color: '#FF7940' }}>{translateThis("Parent-Provider Agreement will be completed by the")} <b style={{ color: '#FF7940' }}>{translateThis("Provider")}</b> {translateThis("and then become available for signature in")}</span>;
                break;
            case "summer_parent_provider_agreement":
                formToRender = <span style={{ display: 'block', textAlign: 'center', color: '#FF7940' }}>{translateThis("Summer Parent-Provider agreement form will be completed by the")} <b style={{ color: '#FF7940' }}>{translateThis("Provider")}</b> {translateThis("and then become available for signature in")}</span>;
                break;
            case "special-needs-verification-form":
                formToRender = <SpecialNeedsVerificationForm
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "self_employment":
                formToRender = <SelfEmployment
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "disability_verification":
                formToRender = <DisabilityVerification
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "special-needs":
                formToRender = <SpecialNeeds
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            case "medical-exemption":
                formToRender = <MedicalExemption
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            default:
                formToRender = <SampleFormTwo key={selectedChildId} childId={selectedChildId} />;
                break;
        }
        if (!hasAccess('forms')) {
            formToRender = <AccessDeniedScreen />
        }
        setSelectedForm(formToRender);
    };

    /**
     * Invokes when a form is submitted/cancelled or failed to submit
     */
    const onChangeFormState = () => {
        setSelectedFormName('');
        setSelectedForm(<></>);

    }

    return (
        <>
            <div className='panel-form-sec blk-bg forms-main-wrapper f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>
                <div className='info-txt'>
                    <p>{translateThis("Here you can complete forms")}</p>
                    {(selectedFormName === 'early_childhood_health_assessment_record') ? <span>{translateThis("Except Early childhood health assessment")}</span> : ''}
                </div>


                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className="form-chooser-wrp">
                                <div className="child-selection-div f-wrp">
                                    {!id && <ChildrenListDropdown
                                        id='formTabSelectChild'
                                        className='parent-child-selection-drop-down'
                                        onChange={setSelectedChild} />}
                                </div>
                                <div className="form-selection-div f-wrp">
                                    <BasicDropdown
                                        key={selectedFormName}
                                        label="Choose Form"
                                        id='parentFormSelect'
                                        isDisabled={!selectedChildId}
                                        options={[
                                            {
                                                'label': <b>{translateThis("Childcare forms")} </b>,
                                            },
                                            {
                                                'label': <p>{translateThis("1. Child enrollment")} <br /><i>{translateThis("1. Child enrollmentText")}</i></p>,
                                                "id": 'child_enrollment',
                                                "value": 'child_enrollment',
                                            },
                                            {
                                                'label': <p>{translateThis("2. Emergency Number")} <br /><i>{translateThis("2. Emergency NumberText")}</i></p>,
                                                "id": 'emergency_number',
                                                "value": 'emergency_number',
                                            },
                                            {
                                                'label': <p>{translateThis("3. Written permission")} <br /><i>{translateThis("3. Written permissionText")}</i></p>,
                                                "id": 'written_permission',
                                                "value": 'written_permission',
                                            },
                                            {
                                                'label': <p>{translateThis("4. Early childhood health assessment")} <br /><i>{translateThis("4. Early childhood health assessmentText")}</i></p>,
                                                "id": 'early_childhood_health_assessment_record',
                                                "value": 'early_childhood_health_assessment_record',
                                            },
                                            {
                                                'label': <p>{translateThis("5. Immunization requirements")} <br /><i>{translateThis("5. Immunization requirementsText")} </i></p>,
                                                "id": 'immunization_requirements',
                                                "value": 'immunization_requirements',
                                            },
                                            {
                                                'label': <Divider />,
                                            },
                                            {
                                                'label': <b>{translateThis("Care 4 Kids forms")} </b>,
                                            },
                                            {
                                                'label': <p>{translateThis("6. Application")}<br /><i>{translateThis("6. ApplicationText")} </i></p>,
                                                'id': 'c4k_application',
                                                'value': 'c4k_application',
                                            },
                                            {
                                                'label': <p>{translateThis("7. Redetermination")}<br /><i>{translateThis("7. RedeterminationText")}</i></p>,
                                                'id': 'c4k_redetermination',
                                                'value': 'c4k_redetermination',
                                            },
                                            {
                                                'label': <p>{translateThis("8. Parent-Provider agreement")}<br /><i>{translateThis("8. Parent-Provider agreementText")} </i></p>,
                                                'id': 'parent_provider_agreement',
                                                'value': 'parent_provider_agreement',
                                            },
                                            {
                                                'label': <p>{translateThis("9. Summer parent-provider agreement")}<br /><i>{translateThis("9. Summer parent-provider agreementText")} </i></p>,
                                                'id': 'summer_parent_provider_agreement',
                                                'value': 'summer_parent_provider_agreement',
                                            },
                                            {
                                                'label': <Divider />,
                                            },
                                            {
                                                'label': <b>{translateThis("Situational forms")}</b>,
                                            },
                                            {
                                                'label': <p>{translateThis("10. Self employment")}<br /><i>{translateThis("10. Self employmentText")}</i></p>,
                                                'id': 'self_employment',
                                                'value': 'self_employment',
                                            },
                                            {
                                                'label': <p>{translateThis("11. Disability verification")}<br /><i>{translateThis("11. Disability verificationText")}</i></p>,
                                                'id': 'disability_verification',
                                                'value': 'disability_verification',
                                            },
                                            {
                                                'label': <p>{translateThis("12. Special needs verification")}<br /><i>{translateThis("12. Special needs verificationText")}</i></p>,
                                                'id': 'special-needs-verification-form',
                                                'value': 'special-needs-verification-form',
                                                // 'id': 'special-needs',
                                                // 'value': 'special-needs',
                                            },
                                            {
                                                'label': <p>{translateThis("13. Medical exemption")}<br /><i>{translateThis("13. Medical exemptionText")}</i></p>,
                                                'id': 'medical-exemption',
                                                'value': 'medical-exemption',
                                            },

                                        ]}
                                        onChange={(value) => handleFormSelection(value)}
                                        value={selectedFormName}
                                    />
                                </div>
                            </div>
                            <p style={{ textAlign: 'center' }}>{translateThis("Once you select a child and a form")}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Stack className='panel-form' spacing={3}>
                                <div className="form-render-wrp">
                                    {selectedForm}
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

ParentDynamicFormsToCompleteTab.propTypes = {
    id: propTypes.string
}
export default ParentDynamicFormsToCompleteTab;