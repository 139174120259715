import * as React from 'react';
import dayjs from 'dayjs';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { Badge, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import ApiCaller from '../../services/api/general';

import { getAuthRoleName } from '../../helpers/auth.helper';
import { translateThis } from '../../helpers/language.helper';
import './notificationBell.scss';

import { DATE_TIME_EXTENDED_FORMAT } from '../../constants/index';

export default function NotificationBell() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const apiCaller = new ApiCaller('notification')
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataArr, setDataArr] = React.useState([]);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        // To get the notification count for the first time
        getNotifications()
        if (open) {
            getNotifications()
        }
        // eslint-disable-next-line
    }, [isLoading, open])

    const getNotifications = () => {
        apiCaller.getList({ limit: 50 })
            .then((data) => setDataArr(data?.results || []))
            .catch(() => null)
            .finally(() => setIsLoading(false));
    }

    /**
     * Handles when we click notification icon
     * @param {Event} event 
     */
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setIsLoading(true)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Badge
                max={10}
                badgeContent={dataArr.length}
                color="primary">
                <IconButton
                    children={<NotificationsRoundedIcon />}
                    onClick={handleOpen}
                />
            </Badge>


            <Popover
                id={id}
                className={` notificationBell ${(getAuthRoleName() === 'parent') ? 'parentNotificationBell' : 'providerNotificationBell'}`}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorPosition={{
                    top: 50,
                    left: 100,
                }}
            >
                <div className='notification-wrapper f-wrp'>

                    <div className='notification-header'>Notifications <button type='button' onClick={handleClose}>X</button></div>
                    <List className='notification-list' xs={{ maxWidth: '100%', minWidth: '100%', }} sm={{ width: '100%', maxWidth: 375, minWidth: 375, bgcolor: 'background.paper' }}>
                        {isLoading ? <ListItem alignItems="flex-start">

                            <ListItemText
                                primary={translateThis('Loading notifications')}
                            />
                        </ListItem> : (dataArr.length > 0) ? dataArr.map((notification) => (
                            <ListItem key={notification.id} alignItems="flex-start">
                                {/* <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar> */}
                                <ListItemText
                                    primary={notification.subject}
                                    secondary={
                                        <>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {translateThis(notification.message)}
                                            </Typography>
                                            <br />
                                            {dayjs(notification.createdAt).format(DATE_TIME_EXTENDED_FORMAT)}
                                        </>
                                    }
                                // secondaryAction={
                                //     <IconButton edge="end" aria-label="delete">
                                //       <DeleteIcon />
                                //     </IconButton>
                                //   }
                                />
                            </ListItem>

                        )) : <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={translateThis("No new notifications found")}
                            />
                        </ListItem>}


                    </List>
                </div>
            </Popover>
        </div>
    );
}
