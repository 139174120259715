import instance from './axios';

const axiosInstance = instance;

/**
 * Submit a dynamic form
 * @param {Object} data the form data
 */
export const submitForm = async (data) => {
    try {
        const result = await axiosInstance.post('/form-submissions', data);        
        return ({ success: [200,201].includes(result.status) , message: "Form submitted successfully!"});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}