import { atom } from "recoil";
import persistAtom from "../recoilPersister";

export const parentConfigState = atom({
  key: 'parentConfig', // unique ID (with respect to other atoms/selectors)
  effects_UNSTABLE: [persistAtom],
  default: {
    bulletinSection: {
      selectedPageView: "bulletin-list",
      selectedBulletinId: "",
    },
    me: {}
  },
});