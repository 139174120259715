import React from 'react';
import SVGIcons from '../../assets/images/icons/svgIcons';

import './header.scss';
import BurgerMenu from '../nav-section/burger';
import AppPopOverMenu from '../appPopOverMenu';
import { switchLanguage } from '../../helpers/language.helper';

export default function AppHeader() {
    return (
        <>
            <div className='main-head-wrp f-wrp'>
                <div className='container'>
                    <div className='burger-prof-sec'>
                        <div className='language-box'>
                            <AppPopOverMenu
                                icon={SVGIcons.GlobalIcon()}
                                options={[
                                    {
                                        label: 'English',
                                        onClick: ()=> switchLanguage('en')
                                    },
                                    {
                                        label: 'Spanish',
                                        onClick: ()=> switchLanguage('es')
                                    },
                                ]}
                            />
                        </div>
                        <div className='menu-box'>
                            <BurgerMenu />
                        </div>
                        {/* <div className='profile-sec-box'>
                            <Link to={'/'}>{SVGIcons.ProfileIcon()}</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )

}