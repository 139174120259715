import instance from './axios';

const axiosInstance = instance;
const endPoint = '/payment'

/**
 * Contact us
 * @param {Object} data the form data
 */
export const createPaymentSession = async (data) => {
    try {
        const result = await axiosInstance.post(`${endPoint}/initiate-payment-session`, data);
        return ({ success: [200, 201].includes(result.status), data: result.data, message: "Payment session initiated" });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}