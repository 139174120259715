import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import './index.scss';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import ProviderPortalHeader from '../components/header';
import { DATE_TIME_FORMAT, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../../constants/index';
import AppIcons from '../../../assets/images/icons';
import { translateThis } from '../../../helpers/language.helper';


const CPRSubscriptionList = () => {
    const apiCaller = new ApiCaller('cpr');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [paginationData, setPaginationData] = useState({
        currentPage: 0,
        totalPages: 0,
        totalResults: 0,
        limit: 10,
    });

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = (filter = {}) => {
        setIsLoading(true);
        apiCaller.getList(filter)
            .then((data) => {
                setDataArr(data?.results || [])
                setPaginationData({
                    currentPage: data?.page || 1,
                    totalPages: data?.totalPages || 1,
                    totalResults: data?.totalResults || 0,
                    limit: data?.limit || 10,
                })
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'fullName',
            headerName: 'Name',
            minWidth: 80,
            flex: 0.8,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || 'Full name'}`,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            minWidth: 90,
            flex: 0.9,
        },
        {
            field: 'address',
            headerName: 'Address',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'facilityName',
            headerName: 'Facility',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'createdAt',
            headerName: 'Date of subscription',
            flex: 1.2,
            minWidth: 120,
            valueGetter: (params) => dayjs(params.row.createdAt).format(DATE_TIME_FORMAT),
        },

    ]
    
    return (
        <>
            <div className='CPR-main-wrp f-wrp'>
                <ProviderPortalHeader header='CPR Subscription' />
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table PC-Table f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                            useServerPagination={false}
                            paginationData={paginationData}
                            paginationFn={getDataArr}
                        />
                        <div className='mob-table-wrp childView f-wrp'>
                            {dataArr.length > 0 ? <ul>
                                {dataArr.map((element) => (
                                    <li key={element.id}>
                                        <div className='each-sec-box'>
                                            <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                            <h4>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                        </div>
                                        <div className='each-sec-box'>
                                            <h4>{element.firstName}&nbsp; {element.lastName}</h4>
                                            <span>{element.facilityName}{element.facilityName? <>&nbsp;&#xFE31;&nbsp;</> : null }</span>
                                            <span>{element.phone}</span>
                                            <p>{element.address}</p>
                                        </div>
                                    </li >
                                ))}
                            </ul> : <div className='empty-placeholder f-wrp'>
                                <div className='placeholder-con'>
                                    <img src={AppIcons.placeholder} alt='empty' />
                                    <h4>{translateThis('There are no CPR forms')}</h4>
                                </div>
                            </div>
                            }
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}


CPRSubscriptionList.propTypes = {
    // onSelectChild: PropTypes.func
}
export default CPRSubscriptionList;