import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

// @mui
import { Stack, Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { DeleteRounded } from '@mui/icons-material';

// components

import { showSnackbar } from '../../../components/snackbar';
import RichTextEditor from '../../../components/rich-text-editor';

// Helpers
import { getAuthId } from '../../../helpers/auth.helper';
import { translateThis } from '../../../helpers/language.helper';

import SVGIcons from '../../../assets/images/icons/svgIcons';

import TextInput from '../../../components/inputs/textInput/textInput';
import FileUploader from '../../../components/awsS3ImageUploader/file.uploader';

import './index.scss';
// Services
import ApiCaller from '../../../services/api/general';
import { providerConfigState } from '../../../state/reducerAtoms/provider.atom';


// ........................................................................




const CreateBulletin = (props) => {
    const bulletinId = useRecoilValue(providerConfigState).bulletinSection?.selectedBulletinId;
    const apiCaller = new ApiCaller('bulletin');
    const { onClose = () => null } = props;
    const providerId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        image: {
            fileKey: '',
            tempURL: ''
        },
        title: '',
        subTitle: '',
        author: '',
        description: ''
    });

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }


    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (bulletinId) {
            getData()
        }
        return () => {
            setFormData({})
        };
        // eslint-disable-next-line
    }, [providerId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(bulletinId)
            .then((data) => {
                if (data) {
                    setFormData(data)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }


    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (formData.title && formData.subTitle && formData.description) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            title: formData.title,
            subTitle: formData.subTitle,
            author: formData.author,
            description: formData.description,
            image: formData.image.fileKey
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            const successMessage = "Bulletin created successfully"
            if (bulletinId) {
                updateBulletin(bulletinId, postData)
            } else {
                apiCaller.postData(postData).then((data) => {
                    if (data.success) {
                        showSnackbar().success(successMessage)
                        onClose();
                    } else {
                        showSnackbar().failure(data.message)
                    }
                }).finally(() => setIsSubmitting(false));
            }
        }
    };

    /**
     * Updates a bulletin using its id
     */
    const updateBulletin = (id, postData) => {
        apiCaller.updateData(id, postData).then((data) => {
            if (data.success) {
                showSnackbar().success("Bulletin updated successfully")
                onClose();
            } else {
                showSnackbar().failure(data.message)
            }
        }).finally(() => setIsSubmitting(false));

    }



    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading &&
                <div className='create-bulletin f-wrp'>
                    <Stack className='standard-form form-full-width panel-form' spacing={3}>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head f-wrp'>
                                    <span style={{ color: '#0F0A01', fontWeight: '700' }}>{translateThis("Create bulletin for Parent Portal")}</span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FileUploader
                                    acceptOnly='image/*'
                                    uploadCaption={translateThis("Select image")}
                                    onUploadEnd={(file) => setFormValue('image', file)}
                                />
                                <br/>
                                {formData?.image?.tempURL ? <span>
                                    <IconButton children={<DeleteRounded/>} onClick={()=>setFormValue('image', {fileKey: '', tempURL: ''})}/>
                                    <img style={{width: '200px', height: '200px'}} src={formData.image.tempURL} alt={"bulletin"} />
                                </span> : null}

                            </Grid>


                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput
                                    key={`bulletin_create_form_Key_title`}
                                    label={translateThis('bulletin_create_form_question_title')}
                                    name='title'
                                    id='bulletin_create_form_question_title'
                                    value={formData.title}
                                    translationKey='bulletin_create_form_question_title'
                                    type='text'
                                    required
                                    placeholder='Title'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue(name, value)
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>                           

                            <Grid item xs={12} sm={12} md={8}>
                                <TextInput
                                    key={`bulletin_create_form_Key_subtitle`}
                                    label={translateThis('bulletin_create_form_question_subtitle')}
                                    name='subTitle'
                                    id='bulletin_create_form_question_subtitle'
                                    value={formData.subTitle}
                                    translationKey='bulletin_create_form_question_subtitle'
                                    type='text'
                                    required
                                    placeholder='Subtitle'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue(name, value)
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    key={`bulletin_create_form_Key_author`}
                                    label={translateThis('bulletin_create_form_question_author')}
                                    name='author'
                                    id='bulletin_create_form_question_author'
                                    value={formData.author}
                                    translationKey='bulletin_create_form_question_author'
                                    type='text'
                                    required
                                    placeholder='Author'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue(name, value)
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <RichTextEditor defaultValue={formData.description} onChange={(value) => setFormValue('description', JSON.stringify(value))} />
                            </Grid>
                        </Grid>
                    </Stack>

                    <br />

                    <div className='button-sec f-wrp' style={{ paddingBottom: '24px' }}>
                        <div className='lft-btn-sec'>
                            <Button onClick={onClose} className='cancel-btn'>{translateThis("cancel")}</Button>
                        </div>
                        <div className='ryt-btn-sec pln-btn-style'>

                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                disabled={!isValidForm()}
                                size="large"
                                type="submit"
                                variant="contained"
                                className='fill-btn'
                                onClick={onSubmit}>
                                {translateThis("submit")}
                            </LoadingButton>

                        </div>
                    </div>
                </div>}
        </>
    );
}

CreateBulletin.propTypes = {
    onClose: propTypes.func,
}

export default CreateBulletin;











