import React from 'react';
import propTypes from 'prop-types';
import { Stack, Grid } from '@mui/material';
// Custom components
import BasicDropdown from '../../../components/dropdowns/basic.dropdown';
import ChildrenListDropdown from '../../../components/dropdowns/children.dropdown';
import ParentCommunicationForm from './forms/parentCommunication.form';
import { hasAccess } from '../../../helpers/auth.helper';
import AccessDeniedScreen from '../../../components/access/accessDenied';
import { translateThis } from '../../../helpers/language.helper';


const DailyLogToCompleteTab = (props) => {
    const { id = '' } = props;
    const [selectedFormName, setSelectedFormName] = React.useState('');
    const [selectedChildId, setSelectedChildId] = React.useState('');
    const [selectedForm, setSelectedForm] = React.useState(<></>);

    /**
     * 
     * @param {string} value 
     */
    const setSelectedChild = (value) => {
        setSelectedChildId(value);
        setSelectedFormName('');
        setSelectedForm(<></>);
    }
    /**
     * Set the selected form
     * @param {string} formName selected form
     */
    const handleFormSelection = (formName) => {
        setSelectedFormName(formName);
        let formToRender = <></>;
        switch (formName) {
            case "parent_communication_form":
                formToRender = <ParentCommunicationForm
                    key={selectedChildId}
                    childId={selectedChildId}
                    onChangeFormState={onChangeFormState}
                />;
                break;
            default:
                formToRender = <></>;
                break;
        }

        if(!hasAccess('forms')) {
            formToRender = <AccessDeniedScreen />
        }
        setSelectedForm(formToRender);
    };

    /**
     * Invokes when a form is submitted/cancelled or failed to submit
     */
    const onChangeFormState = () => {
        setSelectedFormName('');
        setSelectedForm(<></>);

    }

    return (
        <>
            <div className='panel-form-sec blk-bg forms-main-wrapper f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>

                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className="form-chooser-wrp">
                                <div className="child-selection-div f-wrp">
                                    {!id && <ChildrenListDropdown onChange={setSelectedChild} />}
                                </div>
                                <div className="form-selection-div f-wrp">
                                    <BasicDropdown
                                        key={selectedFormName}
                                        label="Choose Form"
                                        id='parentFormSelect'
                                        isDisabled={!selectedChildId}
                                        options={[
                                            {
                                                'label': "Parent Communication",
                                                "id": 'parent_communication_form',
                                                "value": 'parent_communication_form',
                                            }
                                        ]}
                                        onChange={(value) => handleFormSelection(value)}
                                        value={selectedFormName}
                                    />
                                </div>
                            </div>
                            <p style={{ textAlign: 'center' }}>{translateThis("Once you select a child the Daily Communication Log will become available for completion")}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Stack className='panel-form' spacing={3}>
                                <div className="form-render-wrp">
                                    {selectedForm}
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

DailyLogToCompleteTab.propTypes = {
    id: propTypes.string
}
export default DailyLogToCompleteTab;