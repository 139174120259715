import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteRounded } from '@mui/icons-material';
import './index.scss'

// Custom components
import AppIcons from '../../../assets/images/icons';
import ApiCaller from '../../../services/api/general';
import { getAuthId } from '../../../helpers/auth.helper';
import FileUploader from '../../../components/awsS3ImageUploader/file.uploader';
import { showSnackbar } from '../../../components/snackbar/index';
import { translateThis } from '../../../helpers/language.helper';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import TextInput from '../../../components/inputs/textInput/textInput';
import { getURLExtension } from '../../../helpers/helper.functions';


const ProviderPoliciesUploadTab = () => {
    const apiCaller = new ApiCaller('documents')
    const providerId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [file, setFile] = React.useState({});
    const [customFormName, setCustomFormName] = React.useState("");



    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (providerId) {
            getData()
        }
        // eslint-disable-next-line
    }, [providerId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getList({ query: '&type=policy' })
            .then((data) => {
                if (data?.results) {
                    setDataArr(data.results || [])
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (file &&
            file?.fileKey &&
            customFormName
            ) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            file: file?.fileKey,
            type: "policy",
            name: customFormName || 'policy'
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success("Policy uploaded successfully!");
                    setFile('');
                    setCustomFormName('');
                    getData();
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };

    /**
         * On submit the form
         */
    const deleteFileFromCloud = (id) => {
        setIsLoading(true);
        // Submitting data to backend for registration
        apiCaller.deleteData(id).then((data) => {
            if (data.success) {
                showSnackbar().success("File deleted successfully!");
                getData();
            } else {
                showSnackbar().failure(data.message)

            }
        }).finally(() => setIsLoading(true));

    };

    return (
        <>
            <div className='panel-form-sec forms-main-wrapper f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>
                <div className='info-head f-wrp'>
                    <span style={{ color: '#0F0A01', fontWeight: '400', display: 'block' }}>{translateThis("Upload policies as a file so parents can download it from their Parent Portal")}</span>
                    <br />
                </div>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                            <TextInput
                                key={`provider_policy_form_customName`}
                                label={"Policy name"}
                                name='provider_policy_form_customName'
                                id='provider_policy_form_customName'
                                value={customFormName}
                                translationKey='provider_policy_form_customName'
                                type='text'
                                placeholder='Policy name'
                                className='custom-textfield'
                                getValue={(_, value) => {
                                    setCustomFormName(value)
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                        {file?.tempURL ? <span>
                                <IconButton children={<DeleteRounded fontSize='small' />} onClick={() => setFile({ fileKey: '', tempURL: '' })} />
                                {getURLExtension(file?.tempURL) === "pdf" ? <img style={{ width: '50px', height: '50px' }} src={AppIcons.pdfIcon} alt={"file"} /> : <img style={{ width: '200px', height: '200px' }} src={file.tempURL} alt={"file"} />}
                            </span> : null}
                            <br />
                            <FileUploader
                                acceptOnly='application/pdf'
                                onUploadEnd={(file) => {
                                    setFile(file);
                                    
                                }}
                                uploadInfo={translateThis("You can also download the policies")}
                            />
                        </Grid>

                        <br />
                        <br />
                        <Grid item xs={12} sm={12} md={12}>

                            {isLoading ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> : <Grid item xs={12} sm={12} md={12}>
                                <div className='pdf-download f-wrp'>
                                    {dataArr.map((element) => (
                                        <>
                                            <div key={element.id}>
                                                <span><IconButton children={<DeleteRounded />} onClick={() => deleteFileFromCloud(element.id)} />
                                                    <Link className='each-pdf-download' target={'_blank'} to={element.file?.tempURL} >
                                                        <img src={AppIcons.pdfIcon} alt={element.name} />
                                                        <p style={{textAlign: 'center'}}>{element.name}</p>
                                                    </Link>
                                                </span>
                                            </div >
                                        </>
                                    ))}
                                </div>
                            </Grid>}
                        </Grid>


                        <div className='button-sec f-wrp' style={{ justifyContent: 'flex-end' }}>
                            <div className='ryt-btn-sec'>
                                <LoadingButton
                                    loading={isSubmitting}
                                    loadingPosition='start'
                                    startIcon={<></>} // To remove Mui warning
                                    fullWidth
                                    disabled={!isValidForm()}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className='fill-btn'
                                    onClick={onSubmit}
                                    style={{ backgroundColor: '#FF3D00' }}
                                >
                                    {translateThis("Save changes")}
                                </LoadingButton>
                            </div>
                        </div>

                    </Grid>
                </div>
            </div>
        </>
    );
}

export default ProviderPoliciesUploadTab;












