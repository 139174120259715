import React from 'react';
import { Pagination } from '@mui/material';
import PropTypes from 'prop-types';


function ServerSidePagination(props) {
  const { paginationFn, paginationData } = props;
  const currentPage = paginationData?.currentPage || 1;
  const totalPages = paginationData?.totalPages || 1;
  delete props.paginationFn;
  delete props.paginationData;


  return <Pagination
    color="primary"
    {...props}
    count={totalPages}
    showFirstButton
    showLastButton
    page={currentPage}
    defaultPage={1}
    onChange={(_, p) => {
      paginationFn({ page: p })
    }}
  />;
}

ServerSidePagination.propTypes = {
  paginationFn: PropTypes.func,
  paginationData: PropTypes.object

}

export default ServerSidePagination;