import * as React from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import './index.scss';
import RichTextViewer from '../../../components/rich-text-editor/TextViewer';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppIcons from '../../../assets/images/icons/index';




export default function Policy() {
    const apiCaller = new ApiCaller('policies');
    const documentApi = new ApiCaller('documents');
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDocumentsLoading, setIsDocumentsLoading] = React.useState(false);
    const [dataArr, setDataArr] = React.useState([]);
    const [policy, setPolicy] = React.useState('');



    /**
 the legendary use effect function
 This will run very first on render
  */
    React.useEffect(() => {
        getData()
        getDocuments()
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => {
                if (data?.results && data.results[0]) {
                    setPolicy(data.results[0])
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get policy documents from API
    const getDocuments = () => {
        setIsDocumentsLoading(true);
        documentApi.getList({ query: '&type=policy' })
            .then((data) => {
                if (data?.results) {
                    setDataArr(data.results || [])
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsDocumentsLoading(false));
    }



    return (
        <>
            {isLoading ? <span>Loading...</span> : <div className='privacy-sec-wrp f-wrp'>
                {/* <div className='button-sec f-wrp' style={{ justifyContent: 'center' }}>
                    {<Button onClick={()=>downloadFileByURL(policy?.policyFile?.tempURL) } className='fill-btn'>{translateThis("Download full policies")}</Button>}
                </div> */}
                {isDocumentsLoading ? <span className='loader'>{SVGIcons.LoaderIcon()}</span> : <Grid item xs={12} sm={12} md={12}>
                    <div className='pdf-download f-wrp'>
                        {dataArr.map((element) => (
                            <>
                                <div key={element.id}>
                                    <span>
                                        <Link className='each-pdf-download' target={'_blank'} to={element.file?.tempURL} >
                                            <img src={AppIcons.pdfIcon} alt={element.name} />
                                            <p style={{textAlign: 'center'}}>{element.name}</p>
                                        </Link>
                                    </span>
                                </div >
                            </>
                        ))}
                    </div>
                </Grid>}
                <div className='privacy-con-wrp f-wrp'>
                    <Grid className='privacy-sec-wrapper' container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='privcy-con-sec f-wrp'>
                                {(policy && policy.policy) ? <RichTextViewer richText={policy?.policy} /> : null}

                            </div>
                        </Grid>

                    </Grid>
                </div>
            </div>}
        </>
    );
}