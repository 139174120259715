import * as React from 'react';

import TabLayout from '../../../components/tabs';
import ParentDynamicFormsToCompleteTab from './toCompleteTab';
import ParentDynamicFormsDownloadsTab from './downloadsTab';
import ParentDynamicFormsUploadsTab from './uploadsTab';
import ParentDynamicFormsHistoryTab from './historyTab';
import ParentDynamicFormsToSignTab from './toSign.tab';


const ParentDynamicFormSection = () => {
    const tabNames = ['To Complete','To Sign', 'History','To upload',"Downloads"];
    const tabContents = [
        <ParentDynamicFormsToCompleteTab key={'to-complete-form'} />,
        <ParentDynamicFormsToSignTab key={'to-sign-form'} />,
        <ParentDynamicFormsHistoryTab key={'history-forms'} />,               
        <ParentDynamicFormsUploadsTab key={'to-upload'} />,
        <ParentDynamicFormsDownloadsTab key={'to-download'} />, 
    ];

    return (
        <div className='form-sec-tab'>
            <TabLayout
                tabNames={tabNames}
                tabContents={tabContents}
            />
        </div>
    )
}
ParentDynamicFormSection.propTypes = {}
export default ParentDynamicFormSection;