import * as React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Logout } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import AppIcons from '../../../assets/images/icons/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import './index.scss';
import { isLoggedIn, getAuthRoleName } from '../../../helpers/auth.helper';
import { translateThis } from '../../../helpers/language.helper';
import LogoutButton from '../../buttons/logout.button';


export default function BurgerMenu() {
  const isClientLoggedIn = isLoggedIn();

  const mobNavItems = [
    {
      title: translateThis("aboutUs"),
      icon: AppIcons.Kcolorful,
      isHidden: false,
      link: '/#about-us'
    },
    {
      title: translateThis("program"),
      icon: AppIcons.calenderIcon,
      isHidden: false,
      link: '/#program'
    },
    {
      title: translateThis("staff"),
      icon: AppIcons.twotoneFaceIcon,
      isHidden: false,
      link: '/#staff'
    },
    {
      title: translateThis("contactUs"),
      icon: AppIcons.phoneIcon,
      isHidden: false,
      link: '/#contact-us'
    },
    {
      title: translateThis("enrollment"),
      icon: AppIcons.fileCheckIcon,
      isHidden: false,
      link: '/#enrollment'
    },
    {
      title: translateThis("tuition"),
      icon: AppIcons.savingsIcon,
      isHidden: false,
      link: '/#tuition'
    },
    {
      title: translateThis("payOnline"),
      icon: AppIcons.creditCardIcon,
      isHidden: false,
      link: '/#pay-online'
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  /**
   * Handles the submenu click
   * @param {Event} event 
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the submenu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div className='f-wrp'>
        <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className='burger-icon'
        >

          {SVGIcons.BurgerMenuIcon()} &nbsp;&nbsp; {SVGIcons.ProfileIcon()}

        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          className='menu-main-wrp'
        >
          {!isClientLoggedIn &&
            <MenuItem className='each-menuItem'>
              <Link to={'auth/register'}>
                <Button onClick={handleClose}>
                  <span className='menu-icon'>{SVGIcons.ProfileIcon()}</span>
                  <span>{translateThis("parentSignUp")}</span>
                </Button>
              </Link>
            </MenuItem>}
          {!isClientLoggedIn &&
            <MenuItem className='each-menuItem'>
              <Link to={'auth/login'}>
                <Button onClick={handleClose}>
                  <span className='menu-icon'>{SVGIcons.ProfileIcon()}</span>
                  <span>{translateThis("parentLogIn")}</span>
                </Button>
              </Link>
            </MenuItem>}
          {isClientLoggedIn &&
            <MenuItem className='each-menuItem'>
              <Link to={'/my-portal'}>
                <Button onClick={handleClose}>
                  <span className='menu-icon'>{SVGIcons.ProfileIcon()}</span>
                  <span> {getAuthRoleName()} {translateThis("portal")}</span>
                </Button>
              </Link>
            </MenuItem>}
          <Divider />
          {mobNavItems.filter((item) => !item.isHidden).map((element) => (
            <MenuItem key={element.title} className='each-menuItem'>
              <NavHashLink smooth to={`${element.link}`} style={{ width: '100%' }} >
                <Button sx={{ textAlign: 'center' }} onClick={handleClose}>
                  <span className='menu-icon'><img src={element.icon} alt={element.title} /></span>
                  <span>{element.title}</span>
                </Button>
              </NavHashLink>
            </MenuItem>
          ))}
          <Divider />
          {isClientLoggedIn && <MenuItem className='each-menuItem' >
            <Link>
              <Button>
                <span className='menu-icon'><Logout /></span>
                <LogoutButton />
              </Button>
            </Link>
          </MenuItem>}
          {!isClientLoggedIn && <MenuItem className='each-menuItem'>
            <Link to={'auth/login'}>
              <Button onClick={handleClose}>
                <span className='menu-icon'>{SVGIcons.ProfileIcon()}</span>
                <span>{translateThis("providerLogin")}</span>
              </Button>
            </Link>
          </MenuItem>}
        </Menu>
      </div>
    </>
  )
}