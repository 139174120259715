import { Box } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss'

const AccessDeniedScreen = (props) => {
    const {
        title = 'You need permission',
        message = "You don't have permission to access this content. Please get in touch with the provider."
    } = props;
    return (
        <Box className='access-denied-screen'>
            <WarningRoundedIcon fontSize='large' />
            <h2 id="unstyled-modal-title">{title}</h2>
            <p id="unstyled-modal-description">{message}</p>
        </Box>
    )
}

AccessDeniedScreen.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
}
export default AccessDeniedScreen;