import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';

import SVGIcons from '../../assets/images/icons/svgIcons';
import { uploadImage } from '../../services/api/aws';

/**
 * 
 * @param {Object} props 
 * @returns JSX Element
 */

const ProfilePicUploader = (props) => {
    const {
        onUploadStart = () => null,
        onUploadEnd = () => null,
        onRemoveFile = () => null,
        defaultImage = {}
    } = props;
    const [image, setImage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(defaultImage.fileKey) {
            setImage(defaultImage)
        }
    }, [defaultImage]);

    /**
       * Handles the file upload
       * @param {any} e 
       */
    const handleUpload = async (e) => {
        setIsLoading(true);
        onUploadStart();
        await uploadImage(e.target.files[0], (result) => {
            if (result.success) {
                const uploadedImg = { fileKey: result.fileKey, tempURL: result.tempURL };
                setImage(uploadedImg);
                onUploadEnd(uploadedImg);
            }
            e.target.value = '';
            setIsLoading(false);
        })
    }

    /**
     * Removes a file by its key
     */
    const removeFile = () => {
        setImage('');
        onRemoveFile('');
    }

    return (
        <div>
            <div className='img-upload-wrp f-wrp'>
                {!image?.tempURL && <div className='upload-img single-upload-btn'>
                    {isLoading ? <span className='sm-loader'>{SVGIcons.LoaderIcon()}</span> : null}
                    <label htmlFor="prop-img"> {SVGIcons.UploadIcon()}
                        <input
                            accept="image/*"
                            className="property-image"
                            id="prop-img"
                            disabled={isLoading}
                            type="file"
                            onChange={handleUpload}
                        />
                    </label>
                </div>}
                {image && image?.tempURL ? <div className='each-img-blk' key={`image-${image.fileKey}`}>
                    <button type='button' onClick={removeFile}>{SVGIcons.DeleteIcon()}</button>
                <div className='profile-img-sec'>
                        <span><img src={image.tempURL} alt={image.fileKey} /></span>
                    </div>
                </div> : null}
            </div>
        </div>
    )
};

ProfilePicUploader.propTypes = {
    onUploadStart: propTypes.func,
    onUploadEnd: propTypes.func,
    onRemoveFile: propTypes.func,
    defaultImage: propTypes.any
};
export default ProfilePicUploader;

