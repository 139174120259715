import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import dayjs from 'dayjs';
// Mui
import { IconButton, CircularProgress, Button } from '@mui/material';

// Assets
import SVGIcons from '../../../assets/images/icons/svgIcons';

// Components
import AppTable from '../../../components/table/index';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import ModalPopUp from '../../../components/modal/modal.popup';
import { DATE_ONLY_FORMAT, MONTH_TEXT_FORMAT } from '../../../constants/index';
// Services
import ApiCaller from '../../../services/api/general';
import { updateChildById } from '../../../services/api/child';
import { generatePDFBy } from '../../../services/api/pdf';
import { getDynamicFormPreview } from '../../../helpers/dynamicForm.helper';
import ProviderPortalHeader from '../components/header';
import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';



const ChildView = (props) => {
    const {
        id = '',
        onClose = () => null,
    } = props;
    const apiCaller = new ApiCaller('children')
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [data, setData] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedPreview, setSelectedPreview] = React.useState(<></>);


    const onViewClose = () => {
        setViewOpen(false);
    };


    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        if (id) {
            getData()
        }
        return () => {
            setData(null)
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(id)
            .then((data) => setData(data))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * 
     * @param {string} formName 
     * @param {any} formData 
     */
    const onFormPreviewClick = (formName, formData) => {
        const previewSelected = getDynamicFormPreview(formName, { data: formData })
        setViewOpen(true);
        setSelectedPreview(previewSelected);
    }

    /**
  * Download PDF
  */
    const downloadPDF = (childId, userId, formName) => {
        if (childId && userId && formName) {
            setIsDownloading(true);
            showSnackbar().info("Selected form will be downloaded shortly")
            generatePDFBy(userId, childId, formName)
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected form has been downloaded successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setIsDownloading(false);
                })
        } else {
            showSnackbar().warning('Please sign the form to submit');
        }
    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            updateChildById(selectedId, { isDeleted: true })
                .then(() => showSnackbar().success('Child deleted successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getData();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'formTitle',
            headerName: 'Form',
            minWidth: 250,
            flex: 3,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                    (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
            }
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { childId, userId, formName } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onFormPreviewClick(formName, params.row)
                    },
                    {
                        label: isDownloading ? <CircularProgress size={20} /> : 'Download',
                        onClick: () => downloadPDF(childId, userId, formName)
                    }
                ]} />
            }
        },

    ]


    return (
        <>
            <div className='child-list-wrp f-wrp' style={{ marginTop: '10px' }}>
                <div className='pc-head'>
                    <IconButton
                        onClick={onClose}
                        aria-label="Go Back">
                        <SVGIcons.BackIcon />
                        {translateThis('Back')}
                    </IconButton>
                    {data?.firstName && <ProviderPortalHeader header={(data?.firstName) ? `${data?.firstName} ${data?.lastName}` : ''} />}
                    {data?.email && <span>{data?.email}</span>}
                </div>
                <div className='mob-head'>
                    <ProviderPortalHeader header='Parents' />
                    <div className='head-flex'>
                        <IconButton
                            onClick={onClose}
                            aria-label="Back">
                            <SVGIcons.LongArrowIcon />
                        </IconButton>
                        {data?.firstName && <h4>{data?.firstName} {data?.lastName}</h4>}
                    </div>
                </div>
            </div>
            <div className="table-sec-wrp f-wrp">
                <div className='panel-table-sec PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={data?.formsSubmitted || []}
                    />
                    <div className='mob-table-wrp childView f-wrp'>
                        {data?.formsSubmitted ? <ul>
                            {data?.formsSubmitted?.map(childViewData =>
                                <li key={childViewData.id}>
                                    <div className='each-sec-box'>
                                        <h4>{dayjs(childViewData.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                        <h4>{dayjs(childViewData.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                    </div>
                                    <div className='each-sec-box'>
                                        <h4>{childViewData.formTitle}</h4>
                                        <p className='flex-wrp'>{childViewData.isParentSigned ?
                                            <span className='status complete'>{translateThis('Signed')}</span> :
                                            <span className='status pending'>{translateThis('Pending')}</span>}</p>
                                    </div>
                                    <div className='each-sec-box'>
                                        <ul className='btn-wrp'>
                                            <li><button className='pln-btn' type='button' onClick={() => { downloadPDF(childViewData.childId, childViewData.userId, childViewData.formName) }}><SVGIcons.DownloadIcon /></button></li>
                                        </ul>
                                    </div>
                                    <Link className='full-li-btn' onClick={() => { onFormPreviewClick(childViewData.formName, childViewData) }} />
                                </li>
                            )}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.parentPlaceholder} alt='empty' />
                                <h4>{translateThis('No statistics')}</h4>
                                <p>{translateThis('There are no information loaded in the system')}</p>
                            </div>
                        </div>
                        }
                    </div>
                </div>

                {openDeleteDialog && <ConfirmDialog
                    isOpen={openDeleteDialog}
                    onClose={() => {
                        setOpenDeleteDialog(false);
                        setSelectedId('');
                    }}
                    title={`Delete child`}
                    className={'delete-popup'}
                    description={`Are you sure you want to delete this child?\nThis action is permanent and cannot be undone.`}
                    okayButtonText={'Delete child'}
                    onConfirm={deleteData}
                />}
                {viewOpen ? <ModalPopUp
                    className='preview-popup'
                    isOpen={viewOpen}
                    onClose={onViewClose}
                    aria-labelledby="table-view"
                    aria-describedby="table-view"
                >
                    {selectedPreview}
                    <Button onClick={onViewClose} className='fill-btn'>Close</Button>
                </ModalPopUp> : null}
            </div>
        </>
    )
}
ChildView.propTypes = {
    id: propTypes.string,
    onClose: propTypes.func,
}
export default ChildView;