import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';

const SelfEmploymentView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView selfEmploymentView f-wrp" style={{ padding: '0' }}>
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.logo} alt='profile' /></span>
                    </div>
                </div>
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>{translateThis("Self Employment")}</b></h3>
                    <div className='flex-box'>
                        <p>{translateThis("Name")}: <b>{answerGetter('self_employment_sectionOne_form_question_name', formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                        <p>{translateThis("Case Number")}: <b>{answerGetter('self_employment_sectionOne_form_question_caseNumber', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <h3><b>{translateThis("Home")}</b></h3>
                    <div className='auto-flex'>
                        <p>{translateThis("Street")}: <b>{answerGetter('self_employment_sectionOne_form_question_address', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("City")}: <b>{answerGetter('self_employment_sectionOne_form_question_city', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("State")}: <b>{answerGetter('self_employment_sectionOne_form_question_state', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("self_employment_sectionOne_form_question_zipcode")}: <b>{answerGetter('self_employment_sectionOne_form_question_zipcode', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <h3><b>{translateThis("Business")}</b></h3>
                    <p>{translateThis("Business Name")}: <b>{answerGetter('self_employment_sectionOne_form_question_business_Name', formData)?.answer || '-'}</b></p>
                    <div className='auto-flex'>
                        <p>{translateThis("Street")}: <b>{answerGetter('self_employment_sectionOne_form_question_address_1', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("City")}: <b>{answerGetter('self_employment_sectionOne_form_question_city_1', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("State")}: <b>{answerGetter('self_employment_sectionOne_form_question_state_1', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("self_employment_sectionOne_form_question_zipcode_1")}: <b>{answerGetter('self_employment_sectionOne_form_question_zipcode_1', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <p>{translateThis("Type of business (explain)")}: <b>{answerGetter('self_employment_sectionOne_form_question_typeOfBusiness', formData)?.answer || '-'}</b></p>
                    <div className='auto-flex'>
                        <p>{translateThis("Business Owner Name(s) 1")}: <b>{answerGetter('self_employment_sectionOne_form_question_owner_Name_1', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Business Owner Name(s) 2")}: <b>{answerGetter('self_employment_sectionOne_form_question_owner_Name_2', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Business Phone")}: <b>{answerGetter('self_employment_sectionOne_form_question_business_Phone', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Business start date (Month/Year)")}: <b>{answerGetter('self_employment_sectionOne_form_business_start_date', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='check-box inline'>
                        <p style={{ paddingTop: '0', minWidth: 'auto' }}>{translateThis("self_employment_sectionOne_form_business_tax_return")}</p>
                        <ul>
                            <li className={answerGetter("self_employment_sectionOne_form_business_tax_return", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("self_employment_sectionOne_form_business_tax_return", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    <i style={{ fontSize: '12px' }}> <b> {translateThis("At")} {translateThis("application")} ,</b> {translateThis("SelfEmployment_text")} <br />
                        {translateThis("At")} <b>{translateThis("redetermination")},</b> {translateThis("SelfEmployment_text_1")} </i>

                    <div className='gap' />
                    <p><b>{translateThis("Instructions")}</b></p>
                    <ul className='number-list'>
                        <li>{translateThis("Instructions_text_1")}
                            <ul>
                                <li>{translateThis("Instructions_text_2")}</li>
                                <li>{translateThis("Instructions_text_3")}</li></ul></li>
                        <li>{translateThis("Instructions_text_4")}
                            <b>{translateThis("Note:")}</b> {translateThis("Instructions_text_5")}</li>
                        <li>{translateThis("Instructions_text_6")}<b>{translateThis("IRS 1040")}</b> {translateThis("and")} <b>{translateThis("Schedule C.")}</b> {translateThis("Instructions_text_6_1")} </li>
                        <li>{translateThis("Instructions_text_7")}<b>{translateThis("IRS forms 1120")}</b> {translateThis("and")} <b>{translateThis("1065.")}</b> {translateThis("Instructions_text_7_1")}  </li>
                        <li>{translateThis("Instructions_text_8")}</li>
                        <li>{translateThis("Instructions_text_9")}</li>
                        <li>{translateThis("Instructions_text_10")}</li></ul>
                    <div className='gap' />
                    <p>{translateThis("Instructions_text_11")}</p>
                    <div className='gap' />
                </div>
                <div className='each-section-box'>
                    <h3><b>{translateThis("Business income calculation")}</b></h3>
                    <p>{translateThis("Please round all amounts to the nearest dollar")}.</p>
                    <div className='gap' />
                    <p><b>{translateThis("Income")}</b></p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("1a. Gross Income Earned")}</p>
                            <p>{translateThis("Enter your gross business income before deductions")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>{answerGetter('self_employment_sectionTwo_form_question_answer_1', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("1b. Period Covered")}</p>
                            <p>{translateThis("Tell us how long it took you to earn this money")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p>{translateThis("from")}: <b>{answerGetter('self_employment_sectionTwo_form_Key_income_answer_2_1', formData)?.answer || '-'}</b><br />{translateThis("to")}: <b>{answerGetter('self_employment_sectionTwo_form_Key_income_answer_2_2', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <p><b>{translateThis("Business Expenses")}</b></p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("2a. Car and Truck Expenses")}</p>
                            <p>{translateThis("2a. Car and Truck Expenses Text")}</p>
                        </div>
                        <div className="ryt-section">
                            <p>{translateThis("Miles")}: <b>{answerGetter('self_employment_sectionTwo_form_question_answer_3', formData)?.answer || '-'}</b> &nbsp; &nbsp; {translateThis("Total")} : <b>${answerGetter('self_employment_sectionTwo_form_question_answer_3_1', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("2b. Insurance")}</p>
                            <p>{translateThis("List the amount you pay for business insurance on your business")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: ${answerGetter('self_employment_sectionTwo_form_question_answer_4', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("2c. Equipment Rental")}</p>
                            <p>{translateThis("Enter the cost of renting vehicles, machinery or equipment for your business")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: ${answerGetter('self_employment_sectionTwo_form_question_answer_5', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("2d. Supplies")}</p>
                            <p>{translateThis("Enter the cost of supplies and materials used to operate your business")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: ${answerGetter('self_employment_sectionTwo_form_question_answer_6', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("2e. Licenses")}</p>
                            <p>{translateThis("Enter the cost of any licenses you purchased for your trade or business")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: ${answerGetter('self_employment_sectionTwo_form_question_answer_7', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("2f. Telephone")}</p>
                            <p>{translateThis("2f. Telephone Text")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: ${answerGetter('self_employment_sectionTwo_form_question_answer_8', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("2g. Employee Salaries")}</p>
                            <p>{translateThis("2g. Employee Salaries Text")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: ${answerGetter('self_employment_sectionTwo_form_question_answer_9', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <p><b>{translateThis("Total Business Expenses")}</b></p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("3. Total Business Expenses")}</p>
                            <p>{translateThis("Add the total expenses listed in line 2a through line 2g")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: ${answerGetter('self_employment_sectionTwo_form_question_answer_10', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <p><b>{translateThis("Net Business Income")}</b></p>
                    <div className="details-section">
                        <div className="lft-section">
                            <p>{translateThis("4. Net Business Income")}</p>
                            <p>{translateThis("Subtract the total expenses in line 3 from your gross earnings in line 1a")}.</p>
                        </div>
                        <div className="ryt-section">
                            <p><b>: ${answerGetter('self_employment_sectionTwo_form_question_answer_11', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <p><b>{translateThis("Certification")}</b></p>
                    <ul className='bullet-list'>
                        <p>{translateThis("Certification_1")}</p>
                        <li>{translateThis("Certification_2")}</li>
                        <li>{translateThis("Certification_3")}</li>
                        <li>{translateThis("Certification_4")}</li>
                    </ul>
                    <div className='gap' />
                    <p>{translateThis("Certification_5")}</p>
                </div>

                <div className="signature-blk" style={{ float: 'left', paddingBottom: '65px' }}>
                    <div style={{ float: 'right', textAlign: 'center' }}>
                        <h3>{data.additionalInfo && data.additionalInfo.parentSignature
                            ? data.additionalInfo.parentSignature
                            : ''}</h3>
                        <p>{translateThis("Business Owner")}</p>
                        <p>{translateThis("Date")}: <b>{data.additionalInfo?.parentSignedDate}</b></p>
                    </div>
                </div>
                <p className='revText ryt'><b>{translateThis("rev7/23")}</b></p>
            </div >
        </>
    )
}

SelfEmploymentView.propTypes = {
    data: PropTypes.any
}

export default SelfEmploymentView;