import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { forgotPassword } from '../../../services/api/auth';
import { showSnackbar } from '../../../components/snackbar';
import TextInput from '../../../components/inputs/textInput/textInput';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () => {
        setIsLoading(true);
        forgotPassword({ email }).then((data) => {
            if (data.success) {
                navigate('/auth/reset-password-email-sent', { replace: true });
                showSnackbar().success(data.message)
            } else {
                showSnackbar().failure(data.message);
            }
        }).finally(() => setIsLoading(false));
    };

    return (
        <>
            <Stack className='login-form standard-form' spacing={3}>
                <TextInput
                    key={`login-email`}
                    label='Email'
                    name='email'
                    type='email'
                    required
                    placeholder='Please enter your email address'
                    className='custom-textfield'
                    getValue={(_, value) => setEmail(value)}

                />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!email}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='standard-btn'
                    onClick={onSubmit}>
                    Send
                </LoadingButton>
            </Stack>

           


        </>
    );
}
