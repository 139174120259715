/* eslint-disable global-require */
const AppIcons = {
  sampleIcon: require('./sample.ico'),
  logo: require('../Logo.png'),
  mihapp: require('../mihapp.png'),
  bannerLogo: require('../animatedBanner/Logo.png'),
  loginBG: require('../login.jpg'),
  registerBG: require('../register.jpg'),

  calenderIcon: require('./calenderIcon.png'),
  carbonRuleIcon: require('./carbonRuleIcon.png'),
  creditCardIcon: require('./creditCardIcon.png'),
  fileCheckIcon: require('./fileCheckIcon.png'),
  Kcolorful: require('./Kcolorful.png'),
  logoutIcon: require('./logoutIcon.png'),
  phoneIcon: require('./phoneIcon.png'),
  savingsIcon: require('./savingsIcon.png'),
  twotoneFaceIcon: require('./twotoneFaceIcon.png'),

  kcolorfulBanner: require('../kcolorfulBanner.png'),
  childern: require('../banner/childern.png'),
  clouds: require('../banner/clouds.png'),
  dinoGroup: require('../banner/dinoGroup.png'),

  Playground: require('./Playground.png'),
  apple: require('./apple.png'),
  brain: require('./brain.png'),
  car: require('./car.png'),
  child: require('./child.png'),
  events: require('./events.png'),
  language: require('./language.png'),
  abtChild: require('../abtChild.png'),
  teamBG: require('../teamBG.jpg'),
  union: require('../Union.png'),
  Illu: require('../Illu.png'),
  line: require('../Line3.png'),

  Child_1: require('../child_1.png'),
  Child_2: require('../child_2.png'),
  horseGrup: require('../childcare/horseGrup.png'),

  staff: require('../staff_assets/staff.png'),
  angel1: require('../staff_assets/angel1.png'),
  angel2: require('../staff_assets/angel2.png'),
  balloon: require('../staff_assets/baloon.png'),
  cloud1: require('../staff_assets/cloud1.png'),
  cloud2: require('../staff_assets/cloud2.png'),
  cloud3: require('../staff_assets/cloud3.png'),
  Lady: require('../staff_assets/Lady.png'),

  pressIcon: require('../pressIcon.png'),
  V: require('../V.png'),
  ct: require('../ct.png'),
  press1: require('../press1.png'),
  press2: require('../press2.png'),

  headLft: require('../CPR/head-lft.png'),
  headRyt: require('../CPR/head-ryt.png'),
  txt: require('../CPR/txt.png'),
  logoK: require('../logoK.png'),
  fb: require('../contact/fb.png'),
  insta: require('../contact/insta.png'),
  whtsap: require('../contact/wa.png'),
  walpaper: require('../contact/wallpaper.png'),
  videoBG: require('../video-sec.png'),
  videoIcon: require('../videoIcon.png'),
  castle: require('../castle.png'),
  enrollmentBG: require('../enrollment-bg.png'),
  enrollmentBGImg: require('../enrollmentBGImg.png'),
  paymentBGImg: require('../paymentBGImg.png'),

  cloudEyeclose: require('../tuition/cloudEyeclose.png'),
  kidbg: require('../tuition/kidbg.png'),
  rainbow: require('../tuition/rainbow.png'),
  readingKid: require('../tuition/readingKid.png'),
  tuitionkid1: require('../tuition/tuitionkid1.png'),
  tuitionkid2: require('../tuition/tuitionkid2.png'),
  tuitionkid3: require('../tuition/tuitionkid3.png'),
  whastapp: require('./whastapp.png'),
  pdfIcon: require('./pdfIcon.png'),
  bulletin: require('../bulletin.png'),
  lernmore_bgTP: require('../lernmore_bgTP.png'),
  lernmore_bgBtm: require('../lernmore_bgBtm.png'),
  c4k: require('../Care4Kids.png'),
  placeholder: require('../placeholder.png'),
  inboxPlaceholder: require('../inboxPlaceholder.png'),
  parentPlaceholder: require('../parentPlaceholder.png'),
  paymentPlaceholder: require('../paymentPlaceholder.png'),
  graphBG: require('../graphBG.png'),
  share: require('../CPR/share.png'),

};
export default AppIcons;
/* eslint-enable global-require */
