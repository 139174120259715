import instance from './axios';

const axiosInstance = instance;

/**
 * Contact us
 * @param {Object} data the form data
 */
export const postEmployee = async (data) => {
    try {
        const result = await axiosInstance.post('/users/employees', data);
        return ({ success: result.status === 201, message: "Employee profile created successfully!" });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}


/**
 * Update a child
 * @param {Object} postData the form data
 */
export const updateEmployee = async (postData) => {
    const id = postData.childId;
    delete postData.childId;
    const formData = postData;
    try {
        const result = await axiosInstance.patch(`/children/${id}`, formData);
        return ({ success: result.status === 200, message: "Child profile updated successfully!" });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}


/**
 * Patch a child by id
 * @param {Object} data the form data
 */
export const updateEmployeeById = async (id, data) => {
    try {
        const result = await axiosInstance.patch(`/children/${id}`, data);        
        return ({ success: [200].includes(result.status) , message: "Action performed successfully!"});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}

/**
 * get a child by id
 * @param {Object} id the id of the child to get details
 */
export const getEmployee = async (id) => {
    try {
        const result = await axiosInstance.get(`/children/${id}`);        
        return {
            success: true,
            data: result.data
        };
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}