import * as React from 'react';
import { useRecoilValue } from 'recoil';

// @mui
import Box from '@mui/material/Box';
import { getProfile } from '../../services/api/auth';

// css
import './index.scss';

// Components
import AppLayout from '../../layouts/app/AppLayout';
import ProviderPortalDrawer from './components/drawer';
import { myPortalState } from '../../state/reducerAtoms/myPortal.atom';
import Profile from '../profile';
import ParentSection from './parentSection';
import ProviderDashboard from './dashboard';
import ProviderDailyLogSection from './dailyLogSection';
import ProviderDynamicFormSection from './dynamicFormSection';
import ProviderPoliciesSection from './policiesSection';
import ChildrenSection from './children';
import ProviderPaymentSection from './paymentSection';
import BulletinSection from './bulletinSection';
import AccidentPage from './accident/index';
import EmployeeSection from './employeeSection';
import ProviderAccountTab from './accountSection';
import AdministratorsSection from './administratorsSection';
import InboxSection from './inboxSection';
// import InboxView from './inboxSection/inboxView';
import ClockInTab from './clockInSection';
import AttendanceTab from './attendance';
import CPRSubscriptionList from './cprSection';




export default function ProviderPortal() {

    const pageName = "Provider portal";
    const [content, setContent] = React.useState(<></>);
    const { selectedNavItem = 'parents' } = useRecoilValue(myPortalState);
    const [design, setDesign] = React.useState({});

    React.useEffect(() => {
        getDesign()
        if (selectedNavItem) {
            onSelectNav(selectedNavItem)
        }

    }, [selectedNavItem]);

    const getDesign = () => {
        getProfile().then((data) => {
            if (data?.design) {
                setDesign(data?.design)
            }

        })
    }

    /**
     * Sets the content to display based on the current selection
     * @param {string} item 
     */
    const onSelectNav = (item) => {
        let contentSelected = <span>Parents</span>
        switch (item) {
            case 'dashboard':
                contentSelected = <ProviderDashboard />;
                break;
            case 'inbox':
                contentSelected = <InboxSection />;
                break;
            case 'cpr':
                contentSelected = <CPRSubscriptionList />;
                break;
            case 'parents':
                contentSelected = <ParentSection />;
                break;
            case 'children':
                contentSelected = <ChildrenSection />;
                break;
            case 'attendance':
                contentSelected = <AttendanceTab />;
                break;
            case 'logs':
                contentSelected = <ProviderDailyLogSection />;
                break;
            case 'accident':
                contentSelected = <AccidentPage />;
                break;
            case 'forms':
                contentSelected = <ProviderDynamicFormSection />;
                break;
            case 'payments':
                contentSelected = <span><ProviderPaymentSection />  </span>;
                break;
            case 'pricing':
                // contentSelected = <ProviderPricingSectionTab />;
                break;
            case 'bulletin':
                contentSelected = <BulletinSection />;
                break;
            case 'policies':
                contentSelected = <ProviderPoliciesSection />;
                break;
            case 'employees':
                contentSelected = <EmployeeSection />;
                break;
            case 'administrators':
                contentSelected = <AdministratorsSection />;
                break;
            case 'account':
                contentSelected = <Profile />;
                break;
            case 'clockIn':
                contentSelected = <ClockInTab />;
                break;
            case 'accountList':
                contentSelected = <ProviderAccountTab />;
                break;
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        setContent(contentSelected);
    }

    return (
        <>
            <AppLayout
                pageName={pageName}
                isLoadHeader={false}
                isLoadFooter={false}
                bodyClassName={`provider-portal ${design.theme || ''} ${design.buttonTheme || ''} f-wrp`}
            >
                <Box sx={{ display: 'flex' }} className="portal-main-structure">
                    <ProviderPortalDrawer />
                    <Box component="main" className='panel-main-body' sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
                        <Box className='panel-main-content'>
                            {content}
                        </Box>
                    </Box>
                </Box>
            </AppLayout>
        </>
    );
}