import instance from './axios';

const axiosInstance = instance;

/**
 * Patch a parent
 * @param {Object} data the form data
 */
export const updateParent = async (id, data) => {
    try {
        const result = await axiosInstance.patch(`/users/${id}`, data);        
        return ({ success: [200].includes(result.status) , message: "Action performed successfully!"});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}