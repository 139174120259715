import * as React from 'react';
import { Button } from '@mui/material';
import { translateThis } from '../../../helpers/language.helper';


const AttendanceEdit = () => {
    console.log('dssad');
    return (
        <>
            <div className='Attendance-section-edit provider-portal f-wrp'>
                <div className='prof-sec'>
                    <div className='prof-basic'>
                        <h4>Child name</h4>
                        <p><b>{translateThis('Date')}:</b> 20th August 2023</p>
                    </div>
                </div>
                <div className='Attendance-details'>
                    <h3>{translateThis('Attendance')}</h3>
                    <ul>
                        <li><p><b>{translateThis('Check in')}:</b> {translateThis('10:05 am')}</p></li>
                        <li><p><b>{translateThis('By')}:</b> {translateThis('Guardian')}</p></li>
                        <li><p><b>{translateThis('Check out')}:</b> {translateThis('11:05 am')}</p></li>
                        <li><p><b>{translateThis('By')}:</b> {translateThis('Mother')}</p></li>
                    </ul>
                    <p className='note'>{translateThis('AttendanceNote')}</p>
                    <div className='button-sec f-wrp' style={{justifyContent: 'flex-end'}}>
                        {/* <div className='lft-btn-sec'>
                            <Button className='cancel-btn'>{translateThis('cancel')}</Button>
                        </div> */}
                        <div className='ryt-btn-sec pln-btn-style'>
                            <Button className='pln-btn'>{translateThis('Mark absent')}</Button>
                            <Button className='fill-btn'>{translateThis('Accept')}</Button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AttendanceEdit;