import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { PropTypes } from 'prop-types';
import { generatePDFBy } from '../../services/api/pdf';


const PDFDownloadButton = (props) => {
    const { parentId = '', childId = '', formName = '' } = props;
    const [isLoading, setIsLoading] = useState(false);

    /**
     * On download pdf
     */
    const onDownloadPDF = () => {
        setIsLoading(true);
        generatePDFBy(parentId, childId, formName).finally(() => setIsLoading(false));
    };


    return (
        <LoadingButton
            loadingPosition='start'
            startIcon={<></>} // To remove Mui warning
            fullWidth
            loading={isLoading}
            disabled={isLoading}
            size="large"
            type="button"
            variant="contained"
            className='login-btn'
            onClick={onDownloadPDF}>
            Download PDF
        </LoadingButton>
    )
}

PDFDownloadButton.propTypes = {
    parentId: PropTypes.string,
    childId: PropTypes.string,
    formName: PropTypes.string
}

export default PDFDownloadButton;