import instance from './axios';

const axiosInstance = instance;


/**
* Gets the data by id
* @param {string} parentId;
* @param {string} childId;
* @param {string} formName;
* @returns Object
*/
export const generatePDFBy = async (parentId, childId, formName) => {
    try {
        const result = await axiosInstance.get(`/pdf-generate/form-submission?userId=${parentId}&childId=${childId}&formName=${formName}`, {responseType: 'blob'});        
        // create file link in browser's memory
        const href = URL.createObjectURL(result.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${formName}.pdf`); // or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return {success:true, message: ''};
    } catch (e) {
        return {success:false, message: 'Could not download the PDF at this time. Please try again later'};
    }
}

/**
* Download daily log 
* @param {string} id; logId
* @param {string} logName; name of the log
* @returns Object
*/
export const downloadDailyLog = async (id, logName='file') => {
    try {
        const result = await axiosInstance.get(`/pdf-generate/daily-logs?id=${id}`, {responseType: 'blob'});        
        // create file link in browser's memory
        const href = URL.createObjectURL(result.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${logName}.pdf`); // or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return {success:true, message: ''};
    } catch (e) {
        return {success:false, message: 'Could not download the PDF at this time. Please try again later'};
    }
}