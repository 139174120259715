import * as React from 'react';
import Box from '@mui/material/Box';
import { useRecoilState } from 'recoil';

import { getUniqueId } from '../../../helpers/helper.functions';

import './index.scss';

import { providerConfigState } from '../../../state/reducerAtoms/provider.atom';
import BulletinList from './list';
import ViewBulletin from './viewBulletin';
import CreateBulletin from './createBulletin';


export default function BulletinSection() {
    const [configState, setConfigState] = useRecoilState(providerConfigState);

    /**
     * Gets the content to display based on the current selection
     */
    const getSelectedContent = () => {
        const selectedItem = configState?.bulletinSection?.selectedPageView || 'bulletin-list';
        let contentSelected = <></>
        switch (selectedItem) {
            case 'bulletin-list':
                contentSelected = <BulletinList
                    key={`${getUniqueId()}-bulletin-key`}
                    onClose={() => {
                        modifyConfigState({ selectedBulletinId: "", selectedPageView: "bulletin-list" });
                    }}
                    onViewBulletin={(id) => {
                        modifyConfigState({ selectedPageView: "bulletin-view", selectedBulletinId: id });
                    }}
                    onAddNewBulletin={() => {
                        modifyConfigState({ selectedPageView: "create-bulletin", selectedBulletinId: "" });
                    }}
                    onEditBulletin={(id) => {
                        modifyConfigState({ selectedPageView: "create-bulletin", selectedBulletinId: id });
                    }}
                />;
                break;
            case 'bulletin-view':
                contentSelected = configState?.bulletinSection?.selectedBulletinId ? <ViewBulletin
                    key={`${getUniqueId(configState?.bulletinSection?.selectedBulletinId)}-bulletin-key-${configState?.bulletinSection?.selectedBulletinId}`}
                    id={configState?.bulletinSection?.selectedBulletinId}
                    onClose={() => {
                        modifyConfigState({ selectedBulletinId: "", selectedPageView: "bulletin-list" });
                    }}
                /> : <span>not found</span>;
                break;
            case 'create-bulletin':
                contentSelected = <CreateBulletin
                    key={`${getUniqueId()}-bulletin-key`}
                    onClose={() => {
                        modifyConfigState({ selectedBulletinId: "", selectedPageView: "bulletin-list" });
                    }}
                    onCreateClick={(childId) => {
                        modifyConfigState({ selectedPageView: "create-bulletin", selectedBulletinId: childId });
                    }}
                />;
                break;
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        return contentSelected;
    }

    /**
    * Invokes when a state modified
    * @returns Fun
    */
    const modifyConfigState = (modifiedObj = {}) => setConfigState((prv) => ({ ...prv, ...{ bulletinSection: { ...prv.bulletinSection, ...modifiedObj } } }));

    return (
        <>
            <Box component="div" className='bulletin-main-wrp f-wrp' sx={{ flexGrow: 1, p: 3, }}>
                {getSelectedContent()}
            </Box>
        </>
    );
}