import React from 'react';
import PropTypes from 'prop-types';
import { translateThis } from '../../../../helpers/language.helper';

import SVGIcons from '../../../../assets/images/icons/svgIcons';

const AddNewChildButton = (props) => {
    const { onClick = () => null } = props;
    return (
        <>
            <div className='add-child-sec'>
                <button onClick={onClick} type='button' className='add-child-btn'>
                    {SVGIcons.PlusRoundIcon()}
                    <span>{translateThis("Add New child")}</span>
                </button>
            </div>
        </>

    );
}

AddNewChildButton.propTypes = {
    onClick: PropTypes.func,
}

export default AddNewChildButton;