import { useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../assets/css/form.scss';
import SVGIcons from '../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../components/snackbar';
import TextInput from '../../../components/inputs/textInput/textInput';


// Helpers
import { translateThis } from '../../../helpers/language.helper';

// Services
import ApiCaller from '../../../services/api/general';
import { getAuthDetailsByKey } from '../../../helpers/auth.helper';

// ----------------------------------------------------------------------


const CPRSubscribeForm = (props) => {
    const apiCaller = new ApiCaller('cpr');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isLoading = false;
    const [formData, setFormData] = useState({
        firstName: getAuthDetailsByKey('firstName'),
        lastName: getAuthDetailsByKey('lastName'),
        phone: '',
        facilityName: '',
        address: '',
        email: getAuthDetailsByKey('email'),
      });
    const [isSendSubscriberReminderChecked, setIsSendSubscriberReminderChecked] = useState(false);
    

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        setFormData({...formData, [name]: value})
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.firstName
            && formData.lastName
            && formData.facilityName
            && formData.phone
            && formData.address
            && formData.email
            ) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = formData;
        if(isSendSubscriberReminderChecked) {
            postData.reminderDays = '2';
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            apiCaller.postData(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success("Form submitted successfully!.");
                    props.onClose()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }

    };


    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div>
                <Stack className='cpr-subscribe-form dark-bg standard-form' spacing={1}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextInput
                                    label={translateThis('firstName')}
                                    name='firstName'
                                    id='subscribe_form_Question_1'
                                    value={formData.firstName}
                                    translationKey='Name'
                                    type='text'
                                    isRequired
                                    placeholder={translateThis('firstName')}
                                    className='custom-textfield'
                                    getValue={(name, value) => setFormValue(name, value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextInput
                                    label={translateThis('Last name')}
                                    name='lastName'
                                    id='subscribe_form_Question_2'
                                    value={formData.lastName}
                                    getValue={(name, value) => setFormValue(name, value)}
                                    isRequired
                                    translationKey='Last name'
                                    type='text'
                                    placeholder={translateThis('Last name')}
                                    className='custom-textfield'                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={translateThis('subscribe_form_Question_6')}
                                    name='facilityName'
                                    id='subscribe_form_Question_6'
                                    value={formData.facilityName}
                                    getValue={(name, value) => setFormValue(name, value)}
                                    isRequired
                                    translationKey={translateThis('subscribe_form_Question_6')}
                                    type='text'
                                    placeholder={translateThis('subscribe_form_Question_6')}
                                    className='custom-textfield'
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={translateThis('Address')}
                                    name='address'
                                    id='subscribe_form_Question_3'
                                    value={formData.address}
                                    getValue={(name, value) => setFormValue(name, value)}
                                    isRequired
                                    translationKey='Address'
                                    type='text'
                                    placeholder={translateThis('Address')}
                                    className='custom-textfield'
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={translateThis('Phone')}
                                    name='phone'
                                    id='subscribe_form_Question_4'
                                    translationKey='Phone'
                                    type='number'
                                    value={formData.phone}
                                    getValue={(name, value) => setFormValue(name, value)}
                                    isRequired                                    
                                    placeholder={translateThis('Phone')}
                                    className='custom-textfield'
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={translateThis('E-mail')}
                                    name='email'
                                    id='subscribe_form_Question_5'
                                    value={formData.email}
                                    getValue={(name, value) => setFormValue(name, value)}
                                    isRequired
                                    translationKey='E-mail'
                                    type='email'
                                    placeholder={translateThis('E-mail')}
                                    className='custom-textfield'
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormGroup className='custom-checkbox'>
                                    <FormControlLabel control={<Checkbox checked={isSendSubscriberReminderChecked} onChange={(e) => setIsSendSubscriberReminderChecked(e.target.checked)} />} label={
                                        <>{translateThis('Send me a reminder every 2 years')}. </>
                                    } />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <br />
                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm()}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='login-btn fill-btn'
                            onClick={onSubmit}>
                            {translateThis('Subscribe')}
                        </LoadingButton>
                    </form>

                </Stack>

                <br />
            </div>}
        </>
    );
}

CPRSubscribeForm.propTypes = {
    onClose: propTypes.func
}

export default CPRSubscribeForm;