import instance from './axios';

const axiosInstance = instance;

/**
 * Contact us
 * @param {Object} data the form data
 */
export const contactUs = async (data) => {
    try {
        const result = await axiosInstance.post('/user-query', data);        
        return ({ success: [200,201].includes(result.status) , message: "Thank you for contacting us"});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}