import React from 'react';
import AppLayout from '../../layouts/app/AppLayout';
import ContactUsForm from './forms/contactUs.form';


const ContactUsPage = () => {
    // custom register functions here
    const pageName = 'contact us';
    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            pageName={pageName}
        >
            <h1>Contact us</h1>
            <ContactUsForm />
        </AppLayout>
    )
}

export default ContactUsPage;