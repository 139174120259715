import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

// routes
import Router from './routes/routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';

import "./assets/css/custom.css";


// ----------------------------------------------------------------------

export default function App() {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop /> 
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </RecoilRoot>
  );
}
