import React, { useEffect, useState } from 'react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';


import { Grid } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getAuthId } from '../../../helpers/auth.helper';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar';
import ChildProfileCard from './profileCard';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import { getUniqueId } from '../../../helpers/helper.functions';
import { childModel } from '../../../models/child.model';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { translateThis } from '../../../helpers/language.helper';

import AddNewChildButton from './components/addNewChildButton';
import AddEditChildForm from './forms/addEdit.form';

/**
 * 
 * @returns {JSX} the child section
 */
const ChildrenSection = () => {
    const parentId = getAuthId();
    const apiCaller = new ApiCaller('children')
    const [isLoading, setIsLoading] = useState(true);
    const [dataArr, setDataArr] = useState([]);
    const [selectedChildId, setSelectedChildId] = useState('');
    const [selectedChild, setSelectedChild] = useState('');
    const [selectedIdForDelete, setSelectedIdForDelete] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        if (parentId) {
            getDataArr()
        }
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList({ query: `&parentId=${parentId}` })
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * On add or edit or close child button click
     */
    const onAddEditCancelClick = (childId, initialData = childModel) => {
        setSelectedChildId(childId)
        setSelectedChild(initialData);
    }

    /**
     *On cancel/close child form button click
     */
    const onCancelClick = () => {
        setSelectedChildId('');
        setSelectedChild();
    }
    /**
   * User delete
   */
    const onDeleteChildConfirm = () => {
        if (selectedIdForDelete) {
            apiCaller.updateData(selectedIdForDelete, { parentId, isDeleted: true })
                .then((response) => response.success ? showSnackbar().success('Child deleted successfully') :
                    showSnackbar().failure(response.message))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedIdForDelete('');
                    getDataArr();
                })
        }

    }
    return (
        <>
            <Grid className='grid-sec-wrp' container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    {(selectedChildId === '' || selectedChildId === 'add-child') && <AddNewChildButton onClick={() => onAddEditCancelClick('add-child')} />}
                </Grid>
                {selectedChildId &&
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <AddEditChildForm
                            key={getUniqueId(selectedChildId, 'key')}
                            id={selectedChildId}
                            childFormId={getUniqueId(selectedChildId)}
                            initialData={selectedChild}
                            onClose={() => onCancelClick()}
                            onSuccess={() => onCancelClick() || getDataArr()}
                        />
                    </Grid>}
                <Grid item xs={12} sm={12} md={selectedChildId ? 6 : 12} lg={selectedChildId ? 4 : 8}>
                    {!isLoading && dataArr?.length === 0 &&
                        <span style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', color: '#B4AC9C' }}>{translateThis('No data found')}</span>}
                    {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
                    {!isLoading && !!dataArr?.length &&
                        <div className='children-list f-wrp'>
                            <Swiper
                                key={selectedChildId}
                                spaceBetween={10}
                                // slidesPerView = {selectedChildId?1.05 : 2.01}
                                className='childListForm'
                                breakpoints={{
                                    10: {
                                        slidesPerView: selectedChildId ? 1.05 : 1.05,
                                        spaceBetween: 10,
                                    },
                                    700: {
                                        slidesPerView: selectedChildId ? 2.05 : 2.01,
                                    },
                                    900: {
                                        slidesPerView: selectedChildId ? 1.05 : 2.01,
                                    },
                                    
                                    
                                }}
                            >
                                {dataArr.map((data) => <SwiperSlide key={`slider-${data.id}`}> <ChildProfileCard
                                    child={data}
                                    key={data.id}
                                    onEdit={() => {
                                        setSelectedChildId(data.id);
                                        setSelectedChild({ ...data });
                                    }}
                                    onDelete={() => {
                                        setSelectedIdForDelete(data.id);
                                        setOpenDeleteDialog(true);
                                    }}
                                /> </SwiperSlide>)}
                            </Swiper>
                        </div>
                    }
                    {openDeleteDialog && <ConfirmDialog
                        isOpen={openDeleteDialog}
                        onClose={() => {
                            setOpenDeleteDialog(false);
                            setSelectedIdForDelete('');
                        }}
                        title="Child profile deletion"
                        className={'delete-popup'}
                        description={<span>{translateThis('Are you sure you want to delete this profile')}<i>{translateThis('This action cannot be undone')}</i></span>}
                        okayButtonText={'Delete'}
                        onConfirm={onDeleteChildConfirm}
                    />}

                </Grid>
            </Grid>
        </>
    )
}

export default ChildrenSection;