export const childModel = {
    "firstName": "",
    "lastName": "",
    "birthday": "",
    "relationship": "",
    "profilePic": "",
    "guardian": [],
    "parentId": "",
    "status": "",
    "isDeleted": false
}