import React from 'react';
import propTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { translateThis } from '../../helpers/language.helper';


const BasicDropdown = (props) => {
    const {
        label = "select",
        id = "basic-dropdown",
        isDisabled = false,
        value = "",
        options = [],
        onChange
    } = props;
    const [selection, setSelection] = React.useState(value);

    /**
     * Handles the selection
     * @param {Event} event 
     */
    const handleChange = (event) => {
        setSelection(event.target.value);
        onChange(event.target.value)
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl sx={{ m: 1, minWidth: 200 }} disabled={isDisabled}>
                <InputLabel id={`label-${id}`}>{translateThis(label)}</InputLabel>
                <Select
                    autoWidth
                    labelId={`label-${id}`}
                    id={`select-${id}`}
                    value={selection}
                    label={translateThis(label)}
                    onChange={handleChange}
                >
                    {[...options].map((option, index) =>
                    (<MenuItem key={`${index}-${option?.id}-menu`} value={option?.id}
                        disabled={(typeof option === 'object') ? (!!option?.disabled) : false}>
                        {translateThis(option.label)}
                    </MenuItem>
                    ))
                    }
                </Select>
            </FormControl>
        </Box>
    );
}
BasicDropdown.propTypes = {
    label: propTypes.string,
    id: propTypes.string,
    value: propTypes.string,
    options: propTypes.array, // array of objects with 'name' and 'id,
    isDisabled: propTypes.bool,
    onChange: propTypes.func,

}
export default BasicDropdown