import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';

import { DATE_FORMAT } from '../../constants';

const AccidentFormView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView f-wrp" style={{padding: '0'}}>
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.logo} alt='profile' /></span>
                    </div>
                </div>
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="ryt-section">
                        <h2 style={{ fontSize: '28px', margin: '0', textAlign: 'center' }}><b>{translateThis("Accident")}</b></h2>
                    </div>
                </div>
                <div className='gap' />
                <div className='gap' />
                <div className='each-section-box'>
                    <h3><b>{translateThis("Details")}</b></h3>
                    <div className='gap' />
                    <div className='flex-box'>
                        <p>{translateThis("Child’s name")}: <b>{answerGetter('provider_accident_form_question_firstName', formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                        <p>{translateThis("Birthday")}: <b>{answerGetter('provider_accident_form_DateOFBirth', formData)?.answer || '-'}</b></p>
                        <div className='check-box inline'>
                            <p style={{ paddingTop: '0', minWidth: 'auto' }}>{translateThis("sex")}:</p>
                            <ul>
                                <li className={answerGetter("provider_accident_form_question_sex", formData)?.answer === 'Male' ? 'checked' : ''}>{translateThis("Male")}</li>
                                <li className={answerGetter("provider_accident_form_question_sex", formData)?.answer === 'Female' ? 'checked' : ''}>{translateThis("Female")}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex-box'>
                        <p>{translateThis("provider_accident_form_question_childcareAddress")}: <b>{answerGetter('provider_accident_form_question_childcareAddress', formData)?.answer || '-'}</b></p>
                    </div>

                    <div className='auto-flex'>
                        <p>{translateThis("Accident date")}: <b>{answerGetter('provider_accident_form_accidentDate', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("provider_accident_form_question_accidentTime")}: <b>{answerGetter('provider_accident_form_question_accidentTime', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Number of children present")}: <b>{answerGetter('provider_accident_form_question_numberOfChildrenPresent', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Number of staff members present")}: <b>{answerGetter('provider_accident_form_question_numberOfMembersPresent', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>{translateThis("accidentFormQn_1")}  </p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_1", formData)?.answer === 'Not notified' ? 'checked' : ''}>{translateThis("Not notified")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_1", formData)?.answer === 'Notified' ? 'checked' : ''}>{translateThis("Notified")}</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_1", formData)?.answer === 'Notified' ? <p>{translateThis("provider_accident_form_accidentFormQn_1_notified")}:  <b>{answerGetter('provider_accident_form_accidentFormQn_1_notified', formData)?.answer || '-'}</b></p> : null}
                    </div>
                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>{translateThis("accidentFormQn_2")}</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Bathroom' ? 'checked' : ''}>{translateThis("Bathroom")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Classroom' ? 'checked' : ''}>{translateThis("Classroom")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Doorway' ? 'checked' : ''}>{translateThis("Doorway")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Field trip' ? 'checked' : ''}>{translateThis("Field trip")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Hallway' ? 'checked' : ''}>{translateThis("Hallway")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Office' ? 'checked' : ''}>{translateThis("Office")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Playground' ? 'checked' : ''}>{translateThis("Playground")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Unknown' ? 'checked' : ''}>{translateThis("Unknown")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Vehicle' ? 'checked' : ''}>{translateThis("Vehicle")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_2", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_2_other', formData)?.answer || '-'}</b></p> : null}
                    </div>
                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>{translateThis("accidentFormQn_3")}</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Bike' ? 'checked' : ''}>{translateThis("Bike")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Climber' ? 'checked' : ''}>{translateThis("Climber")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Door' ? 'checked' : ''}>{translateThis("Door")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Furniture' ? 'checked' : ''}>{translateThis("Furniture")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Not applicable' ? 'checked' : ''}>{translateThis("Not applicable")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Playground' ? 'checked' : ''}>{translateThis("Playground")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Slide' ? 'checked' : ''}>{translateThis("Slide")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Swing' ? 'checked' : ''}>{translateThis("Swing")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Tricycle' ? 'checked' : ''}>{translateThis("Tricycle")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_3", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_3_other', formData)?.answer || '-'}</b></p> : null}
                    </div>

                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>{translateThis("accidentFormQn_4")}</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Fall to surface' ? 'checked' : ''}>{translateThis("Fall to surface")}{answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Fall to surface' ? <p>{translateThis("Fall to surface height of fall")}: <b>{translateThis("provider_accident_form_accidentFormQn_4_feet")}: {answerGetter('provider_accident_form_accidentFormQn_4_feet', formData)?.answer || '-'}</b>  <b>{translateThis("provider_accident_form_accidentFormQn_4_surfaceType")}: {answerGetter('provider_accident_form_accidentFormQn_4_feet', formData)?.answer || '-'}</b></p> : null}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Bitten by child' ? 'checked' : ''}>{translateThis("Bitten by child")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Hit by child' ? 'checked' : ''}>{translateThis("Hit by child")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Pushed by child' ? 'checked' : ''}>{translateThis("Pushed by child")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Object' ? 'checked' : ''}>{translateThis("Object")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Insect bite' ? 'checked' : ''}>{translateThis("Insect bite")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Insect sting' ? 'checked' : ''}>{translateThis("Insect sting")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Motor vehicle' ? 'checked' : ''}>{translateThis("Motor vehicle")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Running' ? 'checked' : ''}>{translateThis("Running")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Tripping' ? 'checked' : ''}>{translateThis("Tripping")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_4", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_4_other', formData)?.answer || '-'}</b></p> : null}
                    </div>
                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>{translateThis("accidentFormQn_5")}</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5", formData)?.answer === 'Left side' ? 'checked' : ''}>{translateThis("Left side")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5", formData)?.answer === 'Right side' ? 'checked' : ''}>{translateThis("Right side")}</li>
                            <div className='gap' />
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Ankle' ? 'checked' : ''}>{translateThis("Ankle")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Arm' ? 'checked' : ''}>{translateThis("Arm")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Back' ? 'checked' : ''}>{translateThis("Back")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Buttocks' ? 'checked' : ''}>{translateThis("Buttocks")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Chest' ? 'checked' : ''}>{translateThis("Chest")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Ear' ? 'checked' : ''}>{translateThis("Ear")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Eye' ? 'checked' : ''}>{translateThis("Eye")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Foot' ? 'checked' : ''}>{translateThis("Foot")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Genitals' ? 'checked' : ''}>{translateThis("Genitals")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Hand' ? 'checked' : ''}>{translateThis("Hand")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Head' ? 'checked' : ''}>{translateThis("Head")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Leg' ? 'checked' : ''}>{translateThis("Leg")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Mouth' ? 'checked' : ''}>{translateThis("Mouth")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Neck' ? 'checked' : ''}>{translateThis("Neck")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Nose' ? 'checked' : ''}>{translateThis("Nose")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Other face part' ? 'checked' : ''}>{translateThis("Other face part")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Tooth' ? 'checked' : ''}>{translateThis("Tooth")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Wrist' ? 'checked' : ''}>{translateThis("Wrist")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_5_part_of_the_body", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_5_other', formData)?.answer || '-'}</b></p> : null}
                    </div>

                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>{translateThis("accidentFormQn_6")}</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Broken bone' ? 'checked' : ''}>{translateThis("Broken bone")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Bruise' ? 'checked' : ''}>{translateThis("Bruise")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Burn' ? 'checked' : ''}>{translateThis("Burn")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Crushing injury' ? 'checked' : ''}>{translateThis("Crushing injury")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Cut' ? 'checked' : ''}>{translateThis("Cut")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Dislocation' ? 'checked' : ''}>{translateThis("Dislocation")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Loss of consciousness' ? 'checked' : ''}>{translateThis("Loss of consciousness")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Puncture' ? 'checked' : ''}>{translateThis("Puncture")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Scrape' ? 'checked' : ''}>{translateThis("Scrape")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Sprain' ? 'checked' : ''}>{translateThis("Sprain")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Swelling' ? 'checked' : ''}>{translateThis("Swelling")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Unknown' ? 'checked' : ''}>{translateThis("Unknown")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_5_type_of_injury", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_6_other', formData)?.answer || '-'}</b></p> : null}
                    </div>

                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>{translateThis("accidentFormQn_7")}</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Bandage' ? 'checked' : ''}>{translateThis("Bandage")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Cold pack' ? 'checked' : ''}>{translateThis("Cold pack")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Comfort' ? 'checked' : ''}>{translateThis("Comfort")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Elevation' ? 'checked' : ''}>{translateThis("Elevation")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Pressure' ? 'checked' : ''}>{translateThis("Pressure")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Washing' ? 'checked' : ''}>{translateThis("Washing")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Other' ? 'checked' : ''}>{translateThis("Other")}</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_5_first_aid", formData)?.answer === 'Other' ? <p><b>{answerGetter('provider_accident_form_accidentFormQn_5_first_aid_other', formData)?.answer || '-'}</b></p> : null}
                    </div>

                    <div className='check-box inline li-minWidth' style={{ flexDirection: 'column' }}>
                        <p>{translateThis("accidentFormQn_8")}</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === 'Clinic' ? 'checked' : ''}>{translateThis("Clinic")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === 'Home' ? 'checked' : ''}>{translateThis("Home")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === 'Hospital' ? 'checked' : ''}>{translateThis("Hospital")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === 'Not applicable' ? 'checked' : ''}>{translateThis("Not applicable")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === "Doctor's office by:" ? 'checked' : ''}>{translateThis("Doctor's office by")}:</li>
                        </ul>
                        {answerGetter("provider_accident_form_accidentFormQn_8", formData)?.answer === "Doctor's office by:" ?
                            <><li className={answerGetter("provider_accident_form_accidentFormQn_8_by", formData)?.answer === 'Parent Guardian' ? 'checked' : ''}>{translateThis("Parent Guardian")}</li>
                                <li className={answerGetter("provider_accident_form_accidentFormQn_8_by", formData)?.answer === 'Emergency pick up' ? 'checked' : ''}>{translateThis("Emergency pick up")}</li>
                                <li className={answerGetter("provider_accident_form_accidentFormQn_8_by", formData)?.answer === 'Ambulance Firefighter Police' ? 'checked' : ''}>{translateThis("Ambulance Firefighter Police")}</li></> : null}
                    </div>
                    <div className='gap' />
                    <div className='each-section-box'>
                        <p>{translateThis("comments")}: <b>{answerGetter('provider_accident_form_comments', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("provider_accident_form_notes")}: <b>{answerGetter('provider_accident_form_notes', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <h3><b>{translateThis("Health contacts")}</b></h3>

                    <p>{translateThis("The following check up took place at")} <b>{answerGetter('provider_accident_form_health_contact_question_childcareAddress', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("Check all that apply and document the contact and results of the contact")}</p>
                    <div className='check-box'>
                        <ul>
                            <li style={{ display: 'flex', flexWrap: 'wrap' }} className={answerGetter("provider_accident_form_accidentFormQn_5_seen_by_provider", formData)?.answer === 'Child was seen by Provider / Substitute' ? 'checked' : ''}>{translateThis("Child was seen by Provider")}  {answerGetter("provider_accident_form_accidentFormQn_5_seen_by_provider", formData)?.answer === 'Child was seen by Provider / Substitute' ? <p> &nbsp; &nbsp; &nbsp; {translateThis("Date")}: <b>{answerGetter('provider_accident_form_health_contact_question_seenByProviderOrSubstituteDate', formData)?.answer || '-'}</b> &nbsp; Time: <b>{answerGetter('provider_accident_form_health_contact_question_seenByProviderOrSubstituteTime', formData)?.answer || '-'}</b></p> : null} </li>
                            <li style={{ display: 'flex', flexWrap: 'wrap' }} className={answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_provider", formData)?.answer === 'Spoke with Provider / Substitute' ? 'checked' : ''}>{translateThis("Spoke with Provider")}  {answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_provider", formData)?.answer === 'Spoke with Provider / Substitute' ? <p> &nbsp; &nbsp; &nbsp; {translateThis("Date")}: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteDate', formData)?.answer || '-'}</b> &nbsp; Time: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteTime', formData)?.answer || '-'}</b></p> : null} </li>
                            <li style={{ display: 'flex', flexWrap: 'wrap' }} className={answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_physician", formData)?.answer === "Spoke with Child's Physician" ? 'checked' : ''}>{translateThis("Spoke with Child")} {answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_physician", formData)?.answer === "Spoke with Child's Physician" ? <p> &nbsp; &nbsp; &nbsp; {translateThis("Date")}: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithPhysicianDate', formData)?.answer || '-'}</b> &nbsp; Time: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithPhysicianTime', formData)?.answer || '-'}</b></p> : null} </li>
                            <li style={{ display: 'flex', flexWrap: 'wrap' }} className={answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_nurse", formData)?.answer === "Spoke with Nurse in the Doctor's office" ? 'checked' : ''}>{translateThis("Spoke with Nurse in the Doctor")} {answerGetter("provider_accident_form_accidentFormQn_5_spoke_with_nurse", formData)?.answer === "Spoke with Nurse in the Doctor's office" ? <p> &nbsp; &nbsp; &nbsp; {translateThis("Date")}: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeDate', formData)?.answer || '-'}</b> &nbsp; Time: <b>{answerGetter('provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeTime', formData)?.answer || '-'}</b></p> : null} </li>
                        </ul>
                    </div>

                    <p style={{ textAlign: 'center' }}><b>{translateThis("note")}</b> {translateThis("noteText")}</p>
                    <div className='gap' />
                    <p>{translateThis("Provider Substitute Assistant observation or assessment")} <b>{answerGetter('provider_accident_form_health_contact_observation', formData)?.answer || '-'}</b></p>
                    <div className='gap' />
                    <div className='gap' />
                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'left', textAlign: 'center' }}>                            
                            <h3>{answerGetter('provider_communication_form_question_observer_signatureName', formData)?.answer || '-'}</h3>
                            <p>{translateThis("Staff signature")}</p>
                            <p>{translateThis("Date")}: <b>{answerGetter('provider_accident_form_health_contact_question_observationDate', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className='gap' />
                    <p>{translateThis("Content of communication with nurse in the doctor")} <b>{answerGetter('provider_accident_form_health_contact_detailsFromNurseInDocOffice', formData)?.answer || '-'}</b></p>
                    <div className='gap' />
                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'left', textAlign: 'center' }}>
                            <h3>{answerGetter('provider_communication_form_question_observer_signatureName_2', formData)?.answer || '-'}</h3>
                            <p>{translateThis("Staff signature")}</p>
                            <p>{translateThis("Date")}: <b>{answerGetter('provider_accident_form_health_contact_question_observationDate_2', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>

                    <div className='check-box inline'>
                        <p>{translateThis("followNeeded")}</p>
                        <ul>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_follow_up_needed", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_5_follow_up_needed", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div>
                    {answerGetter("provider_accident_form_accidentFormQn_5_follow_up_needed", formData)?.answer === "Yes" ?
                        <>
                            <p>{translateThis("provider_accident_form_health_contact_followNeeded")} <b>{answerGetter('provider_accident_form_health_contact_followNeeded', formData)?.answer || '-'}</b></p>
                            <p>{translateThis("provider_accident_form_health_contact_followNeeded_1")} <b>{answerGetter('provider_accident_form_health_contact_followNeeded_1', formData)?.answer || '-'}</b></p>
                        </> : null}
                    <div className='gap' />
                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'left', textAlign: 'center' }}>
                            <h3>{answerGetter('provider_communication_form_question_observer_signatureName_3', formData)?.answer || '-'}</h3>
                            <p>{translateThis("Staff signature")}</p>
                            <p>{translateThis("Date")}: <b>{answerGetter('provider_accident_form_health_contact_question_observationDate_3', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>

                    <p><b>{translateThis("Parental notification of child's accident")}</b></p>
                    {/* <div className='check-box inline'>
                        <ul style={{paddingTop: '20px'}}>
                            <li className={answerGetter("provider_accident_form_accidentFormQn_copyGivenToParent", formData)?.answer === 'Copy given to parent' ? 'checked' : ''}>Copy given to parent</li>
                        </ul>
                    </div> */}
                    <div className='gap' />
                    <p>{translateThis("signatureI")}, <b>{answerGetter('provider_accident_form_health_contact_staffSignature_confirm', formData)?.answer || '-'}</b>, {translateThis("confirm that I was notified that my child")}, <b>{answerGetter('provider_accident_form_question_firstName', formData)?.answer || '-'}</b>, {translateThis("was involved in an accident during school hours, that resulted in an injury")}.</p>

                    <div className='gap' />
                    <div className="details-section">
                        <div className="lft-section">
                            <p><b>{translateThis("Accident")}</b> - {translateThis("Date")}: <b>{answerGetter('provider_accident_form_accidentDate', formData)?.answer || '-'}</b>  {translateThis("Time")}: <b>{answerGetter('provider_accident_form_question_accidentTime', formData)?.answer || '-'}</b></p>
                        </div>
                        <div className="ryt-section">
                            <p><b>{translateThis("Notification")}</b> - {translateThis("Date")}: <b>{(data?.additionalInfo?.submissionDate) ? dayjs(data?.additionalInfo?.submissionDate).format(DATE_FORMAT): ''}</b>  {translateThis("Time")}: <b>{answerGetter('provider_accident_form_health_contact_submission_confirm_time_2', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>
                    <div className='gap' />
                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'left', textAlign: 'center' }}>
                            <h2 style={{ fontSize: '42px' }}>{answerGetter('provider_accident_form_health_contact_staffSignature_confirm', formData)?.answer || '-'}</h2>
                            <p>{translateThis("Staff signature")}</p>
                            <p>{translateThis("provider_accident_form_submission_time")}: <b>{answerGetter('provider_accident_form_health_contact_submission_confirm_time_2', formData)?.answer || '-'}</b></p>
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    )
}

AccidentFormView.propTypes = {
    data: PropTypes.any
}

export default AccidentFormView;