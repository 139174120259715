import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { showSnackbar } from '../../../components/snackbar/index';
import TextInput from '../../../components/inputs/textInput/textInput';
import { resetPassword } from '../../../services/api/auth';


// ----------------------------------------------------------------------

const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () => {
        if (password === confirmPassword && token) {
            setIsLoading(true);
            resetPassword({ password, token }).then((data) => {
                if (data.success) {
                    navigate(`/auth/login`, { replace: true });
                    showSnackbar().success("Password reset successfully!")
                } else {
                    showSnackbar().failure("Password reset failed!");
                }
            }).finally(() => setIsLoading(false));
        } else {
            showSnackbar().failure("Passwords do not match!")
        }

    };

    return (
        <>
            <Stack className='login-form standard-form' spacing={3}>
                <TextInput
                    key={`login-password`}
                    label='Password'
                    name='password'
                    type='password'
                    required
                    placeholder='Please enter your password'
                    className='custom-textfield'
                    getValue={(_, value) => setPassword(value)}

                />
                <TextInput
                    key={`login-setConfirmPassword`}
                    label='Confirm password'
                    name='confirmPassword'
                    type='password'
                    required
                    placeholder='Please confirm your password'
                    className='custom-textfield'
                    getValue={(_, value) => setConfirmPassword(value)}

                />

                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!confirmPassword}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='standard-btn'
                    onClick={onSubmit}>
                    Change Password
                </LoadingButton>
            </Stack>




        </>
    );
}

export default ResetPasswordForm;