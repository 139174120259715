import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';
import { DATE_FORMAT } from '../../constants';



const ParentCommunicationFormView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp parentCommunicationView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.logo} alt='profile' /></span>
                    </div>
                </div>
                <div className='gap' />

                <h2 style={{ marginBottom: '0' }}>{translateThis("DAILY COMMUNICATION")}</h2>
                <h3>{translateThis("Parent communication")}</h3>
                <div className='gap' />
                <div className="flex-box padding_btm15">
                    <p>{translateThis("Child name")}: <b>{child?.firstName}&nbsp;{child?.lastName}</b></p>
                </div>
                <div className="flex-box padding_btm15">
                    <p>{translateThis("Communication date")}: <b>{dayjs(data.logDate).format(DATE_FORMAT)}</b></p>
                    <p>{translateThis("Submission date")}: <b>{dayjs(data.submissionDate).format(DATE_FORMAT)}</b></p>
                </div>
                <div className='gap' />
                <div className="auto-flex padding_btm15">
                    <span>{translateThis("Incidents before childcare")}:</span>
                    <p><b>{answerGetter('incidentsAtHome', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex">
                    <span>{translateThis("Food")}:</span>
                    <p><b>{answerGetter('food', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex">
                    <span>{translateThis("diaperChange")}:</span>
                    <p><b>{answerGetter('diaperChange', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex">
                    <span>{translateThis("mood")}:</span>
                    <p><b>{answerGetter('mood', formData)?.answer || '-'}</b></p>
                </div>
                <div className="auto-flex">
                    <span>{translateThis("natureOfSleep")}:</span>
                    <p><b><b>{answerGetter('natureOfSleep', formData)?.answer || '-'} </b></b></p>
                    
                </div>
                <div className="auto-flex">
                    <span>{translateThis("wakeUpTime")}: </span>
                    <p><b>{answerGetter('wakeUpTime', formData)?.answer || '-'} </b></p>
                    
                </div>
                <div className="basic-details padding_btm15">
                    <div className='gap' />
                    <div className="auto-flex padding_btm15">
                        <span>{translateThis("comments")}:</span>
                        <p><b>{answerGetter('comments', formData)?.answer || '-'}</b></p>
                    </div>
                </div>


                <div className="signature-blk">
                    <div className="details-section">
                        <div className="lft-section">
                            <div style={{ textAlign: 'center', float: 'left' }}>
                                <h3>{data.additionalInfo?.parentSignature || '-'}</h3>
                                <p>{translateThis("Parent Guardian signature")}</p>
                                <p>{translateThis("Submission date and time")}: <b>{data.additionalInfo?.parentSignedDate}</b></p>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div style={{ textAlign: 'center', float: 'right' }}>
                                <h3>{data.additionalInfo?.providerSignature || '-'}</h3>
                                <p>{translateThis("Provider signature")}</p>
                                <p>{translateThis("Submission date and time")}: <b>{data.additionalInfo?.providerSignedDate}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap' />
            </div>
        </>
    )
}

ParentCommunicationFormView.propTypes = {
    data: PropTypes.any
}
export default ParentCommunicationFormView;