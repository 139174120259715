/**
* @author Geethananth M
 * Use this file to define app related constants
 */
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALID_PASSWORD_RULE = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const DATE_ONLY_FORMAT = "DD";
export const MONTH_TEXT_FORMAT = "MMM";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_EXTEND_FORMAT = "DD MMMM YYYY";
export const TIME_FORMAT = 'HH:mm';
export const TIME_12HR_FORMAT = 'hh:mm a';
export const TIME_12HR_EXTENDED_FORMAT = 'hh:mm:ss a';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';
export const DATE_READABLE_FORMAT = 'DD MMMM YYYY';
export const DATE_TIME_READABLE_FORMAT = 'dddd, DD MMMM YYYY';
export const DATE_TIME_MESSAGE_RECEIVED_FORMAT = 'ddd, MMM DD, hh:mm a';
export const DATE_TIME_EXTENDED_FORMAT = 'MM/DD/YYYY hh:mm:ss a';
export const PARENT_ROLE = 'parent';
export const PROVIDER_ROLE = 'provider';
export const EMPLOYEE_ROLE = 'employee';