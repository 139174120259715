// eslint-disable
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { EMAIL_REGEX, VALID_PASSWORD_RULE } from '../../../constants';
import { getUniqueId } from '../../../helpers/helper.functions';
import { translateThis } from '../../../helpers/language.helper';

import './textInput.scss'

const uniQueKey = getUniqueId();
const TextInput = (props) => {
    const type = ['text', 'email', 'password', 'number'].includes(props.type) ? props.type : 'text';
    const { name,
        label,
        getValue,
        onFocusout = () => null,
        value = '',
        className = "",
        parentClassName = "",
        translationKey = "",
        placeholder = `Please enter ${name}`,
        isRequired = true,
        readOnly = false,
        isDisabled = false,
        useDefaultValidation = true,
        errorTxt = '',
        showError = false,
        startIcon = null,
        additionalProps = {},
        endIcon = null } = props;

    const [inputValue, setInputValue] = useState(value) // To hold the input value
    const [_errorTxt, _setErrorTxt] = useState(errorTxt) // To hold the input value
    const [showPassword, setShowPassword] = useState(false); // incase of password

    /**
     * Handles the onChange event
     * @param {HTMLInputElement} e 
     * @returns void
     */
    const onValueChange = (e) => {
        if (commonValidationsPass(e.target.value)) {
            setInputValue(e.target.value);
            if (getValue) {
                getValue(name, e.target.value, e)
            }
        }
    }

    const commonValidationsPass = (value) => {
        let isValid = true;
        let errorTxt = '';
        if (useDefaultValidation && isRequired) {
            isValid = false;
            errorTxt = 'This field is required';
            if (type === 'text') {
                isValid = value.length > 0;
            } else if (type === 'number') {
                isValid = value.length > 0;
            } else if (type === 'email') {
                errorTxt = 'Please enter a valid email';
                const rule = EMAIL_REGEX;
                isValid = rule.test(value.toLowerCase());
            } else if (type === 'password') {
                errorTxt = 'Please enter a valid password';
                const rule = VALID_PASSWORD_RULE;
                isValid = rule.test(value);
            }
        }
        _setErrorTxt(isValid === false ? errorTxt : '')
        return isValid;
    }


    /**
     * Sets the start icon for input Element if any
     * @returns JSX Element|| null
     */
    const setStartIcon = () => {
        if (startIcon) {
            return <>{startIcon}</>
        }
        return null;
    }

    /**
     * Sets the end icon for input Element if any
     * @returns JSX Element || null
     */
    const setEndIcon = () => {
        if (endIcon) {
            return <>{endIcon}</>
        }
        if (type === 'password') {
            return _passwordRevealIcon();
        }
        return null

    }

    /**
     * Shows the classic Eye icon for password
     * @returns JSX Element
     */
    const _passwordRevealIcon = () => (<InputAdornment position="end">
        <IconButton
            onClick={() => setShowPassword(!showPassword)}
            onMouseDown={(event) => event.preventDefault()}
            aria-label="toggle password visibility"
            edge="end">
            {showPassword ? <>{SVGIcons.EyeOpen()}</> : SVGIcons.EyeClosed()}
        </IconButton>
    </InputAdornment>);

    return (
        <div
        // Temp use of translationKey
            key={`${uniQueKey}-${translationKey}`}
            className={`app-text-input-container ${parentClassName}`}>
            <div
                className="form-group">
                <TextField
                    error={(showError && _errorTxt !== '')}
                    required={isRequired}
                    type={showPassword ? 'text' : type}
                    label={label}
                    name={name}
                    disabled={isDisabled}
                    className={className}
                    placeholder={translateThis(placeholder)}
                    defaultValue={inputValue}
                    onChange={onValueChange}
                    helperText={_errorTxt}
                    variant="outlined"
                    onBlur={(e) => onFocusout(name, e.target.value, e)}
                    InputProps={{
                        readOnly,
                        startAdornment: setStartIcon(),
                        endAdornment: setEndIcon()
                    }}
                   {...additionalProps} 
                />
            </div>
        </div>
    )
}

TextInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    getValue: PropTypes.func,
    onFocusout: PropTypes.func,
    parentClassName: PropTypes.string,
    translationKey: PropTypes.string,
    showError: PropTypes.bool,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    inputValue: PropTypes.any, // eslint-disable-line
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['text', 'email', 'password', 'number']),
    className: PropTypes.string,
    value: PropTypes.string,
    errorTxt: PropTypes.string,
    readOnly: PropTypes.bool,
    useDefaultValidation: PropTypes.bool,
    startIcon: PropTypes.any,
    endIcon: PropTypes.any,
    additionalProps: PropTypes.any,

}

export default TextInput;