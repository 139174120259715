import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import ProviderPortalHeader from '../components/header';
import { getAgeFromBirthday } from '../../../helpers/helper.functions';
import { DATE_FORMAT } from '../../../constants/index';



const ChildrenList = (props) => {
    const { onSelectChild = () => null } = props;
    const apiCaller = new ApiCaller('children');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [paginationData, setPaginationData] = useState({
        currentPage: 0,
        totalPages: 0,
        totalResults: 0,
        limit: 10,
    });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = (filter = {}) => {
        setIsLoading(true);
        apiCaller.getList(filter)
            .then((data) => {
                setDataArr(data?.results || [])
                setPaginationData({
                    currentPage: data?.page || 1,
                    totalPages: data?.totalPages || 1,
                    totalResults: data?.totalResults || 0,
                    limit: data?.limit || 10,
                })
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    const toggleActiveStatus = (id, status) => {
        status = status === "active" ? "inactive" : "active";
        const statusMsg = status === "active" ? "accepted" : "blocked";
        if (id) {
            apiCaller.updateData(id, { status })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected child has been ${statusMsg} successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getDataArr();
                })
        }

    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected child has been deleted successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'fullName',
            headerName: 'Child',
            minWidth: 160,
            flex: 2,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'age',
            headerName: 'Age',
            flex: 1,
            minWidth: 75,
            valueGetter: (params) => getAgeFromBirthday(params.row.birthday),
        },
        {
            field: 'birthday',
            headerName: 'Birthday',
            flex: 1,
            minWidth: 75,
            valueGetter: (params) => dayjs(params.row.birthday).format(DATE_FORMAT),
        },
        {
            field: 'guardian',
            headerName: 'Guardians',
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => (params.row?.guardian) ? params.row?.guardian.join(", ") : '',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                    (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
            }
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { id } = params.row;
                const { status } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'Forms',
                        onClick: () => onSelectChild(id)
                    },
                    {
                        label: status === "active" ? "Block" : "Accept",
                        onClick: () => {
                            toggleActiveStatus(id, status)
                        }
                    },
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]
    return (
        <>
            <ProviderPortalHeader header='Children' />
            <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                <div className='panel-table-sec provider-table PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                        useServerPagination={false}
                        paginationData={paginationData}
                        paginationFn={getDataArr}
                    />
                    <div className='mob-table-wrp f-wrp'>
                        <ul>
                            {dataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box'>
                                        <div className='status-with-btn'>
                                            <span>{(element.status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                                                (element.status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</span>

                                            <AppPopOverMenu
                                                icon={SVGIcons.StatusMobArrow()}
                                                options={[
                                                    {
                                                        label: element.status === "active" ? "Block" : "Accept",
                                                        onClick: () => {
                                                            toggleActiveStatus(element.id, element.status)
                                                        }
                                                    },
                                                ]} />
                                        </div>
                                    </div>
                                    <div className='each-sec-box'>
                                        <h4>{element.firstName}&nbsp;{element.lastName}</h4>
                                        <span>{element.email}</span>
                                        <p>{dayjs(element.birthday).format(DATE_FORMAT)}</p>
                                        <p>{element.guardian.join(", ")}</p>
                                    </div>
                                    <div className='each-sec-box'>
                                        <ul className='btn-wrp'>
                                            <li><button className='pln-btn' type='button' onClick={() => { setOpenDeleteDialog(true) }}><SVGIcons.DeleteIcon /></button></li>
                                            {/* <li><button className='pln-btn' type='button' onClick = {() => {onSelectChild(element.id) }}><SVGIcons.CatalogIcon/></button></li> */}
                                        </ul>
                                    </div>
                                    <Link className='full-li-btn' onClick={() => { onSelectChild(element.id) }} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {openDeleteDialog && <ConfirmDialog
                    isOpen={openDeleteDialog}
                    onClose={() => {
                        setOpenDeleteDialog(false);
                        setSelectedId('');
                    }}
                    title={`Delete child`}
                    className={'delete-popup'}
                    description={`Are you sure you want to delete this child?\nThis action is permanent and cannot be undone.`}
                    okayButtonText={'Delete child'}
                    onConfirm={deleteData}
                />}
            </div>
        </>
    )
}


ChildrenList.propTypes = {
    onSelectChild: PropTypes.func
}
export default ChildrenList;