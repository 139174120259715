import * as React from 'react';
import { useState } from 'react';
import propTypes from 'prop-types';

// @mui

import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { showSnackbar } from '../../../components/snackbar';
import TextInput from '../../../components/inputs/textInput/textInput';
import '../../../assets/css/form.scss';
// ----------------------------------------------------------------------
import { childModel } from '../../../models/child.model';
import { getUniqueId } from '../../../helpers/helper.functions';
import ProfilePicUploader from '../../../components/awsS3ImageUploader/profilePic.uploader';
import { postEmployee } from '../../../services/api/employee';


const AddEditEmployeeForm = (props) => {
    const { id,
        onClose = () => null,
        onSuccess = () => null,
        initialData = childModel
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [formId, setFormId] = useState('');
    const [formData, setFormData] = useState({});
    const resourceId = (id && id !== 'add-resource') ? id : '';


    /**
     * Legendary use effect
     */
    React.useEffect(() => {
        setFormData(initialData);
        setFormId(getUniqueId('resource-form'))
        return () => {
            setFormData({})
            setFormId('')
        };
    }, [initialData]);


    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = {...formData};
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData && formData.firstName &&
            formData.lastName &&
            formData.password &&
            formData.email            
        ) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsLoading(true);
            const action = postEmployee;
            
            const formPostData = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                password: formData.password,
                profilePic: formData.profilePic?.fileKey,
            }
            action(formPostData).then((data) => {
                if (data.success) {
                    onSuccess();
                    setFormData(childModel)
                    showSnackbar().success(data.message)
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsLoading(false));
        }
    };

    const onCancelClick = () => {
        setFormData({})
        if (typeof onClose === "function") {
            onClose();
        }

    }


    return (
        <form key={resourceId} className='add-employee-layout f-wrp'>
            <Stack className='employee-profile standard-form' spacing={3}>
                <ProfilePicUploader
                    key={`profile-pic-${formId}`}
                    onUploadStart={() => null}
                    onUploadEnd={(img) => setFormValue("profilePic", img)}
                    onRemoveFile={() => setFormValue("profilePic", '')}
                    defaultImage={formData?.profilePic}
                />
                <TextInput
                    key={`first-name-${formId}`}
                    label='First name'
                    name='firstName'
                    value={formData?.firstName}
                    type='text'
                    required
                    placeholder='First name'
                    getValue={setFormValue}
                    className='custom-textfield'
                />

                <TextInput
                    key={`last-name-${formId}`}
                    label='Last name'
                    name='lastName'
                    value={formData?.lastName}
                    type='text'
                    required
                    placeholder='Last name'
                    getValue={setFormValue}
                    className='custom-textfield'
                /> 
                <TextInput
                    key={`email-${formId}`}
                    label='Email'
                    name='email'
                    value={formData?.email}
                    type='email'
                    required
                    placeholder='Email'
                    getValue={setFormValue}
                    className='custom-textfield'
                />
                <TextInput
                    key={`password-name-${formId}`}
                    label='Password'
                    name='password'
                    value={formData?.password}
                    type='password'
                    required
                    placeholder='*******'
                    getValue={setFormValue}
                    className='custom-textfield'
                />

                


                
                

                

                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<></>} // To remove Mui warning
                    fullWidth
                    disabled={!isValidForm()}
                    size="large"
                    type="submit"
                    variant="contained"
                    className='standard-btn'
                    onClick={onSubmit}>
                    {resourceId ? 'Update employee' : 'Create employee'}
                </LoadingButton>

                <Button
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    className='standard-btn pln-btn'
                    onClick={() => onCancelClick()}>
                    Cancel
                </Button>
            </Stack>
        </form>
    );
}

AddEditEmployeeForm.propTypes = {
    id: propTypes.string,
    initialData: propTypes.any,
    onClose: propTypes.func,
    onSuccess: propTypes.func,
}

export default AddEditEmployeeForm;