import instance from './axios';

const axiosInstance = instance;

/**
 * Contact us
 * @param {Object} data the form data
 */
export const postChild = async (data) => {
    try {
        const result = await axiosInstance.post('/children', data);
        return ({ success: result.status === 201, message: "Child profile created successfully!" });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}


/**
 * Update a child
 * @param {Object} postData the form data
 */
export const updateChild = async (postData) => {
    const id = postData.childId;
    delete postData.childId;
    const formData = postData;
    try {
        const result = await axiosInstance.patch(`/children/${id}`, formData);
        return ({ success: result.status === 200, message: "Child profile updated successfully!" });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}

/**
* Gets the data by id
* @param {string} parentId;
* @param {string} childId;
* @param {string} formName;
* @returns Object
*/
export const getChildFormBy = async (parentId, childId, formName) => {
    try {
        const result = await axiosInstance.get(`/form-submissions?userId=${parentId}&childId=${childId}&formName=${formName}`);
        const { data = null } = result;
        return data.results && data.results[0] ? data.results[0] : {};
    } catch (e) {
        return null;
    }
}


/**
 * Patch a child by id
 * @param {Object} data the form data
 */
export const updateChildById = async (id, data) => {
    try {
        const result = await axiosInstance.patch(`/children/${id}`, data);        
        return ({ success: [200].includes(result.status) , message: "Action performed successfully!"});
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}

/**
 * get a child by id
 * @param {Object} id the id of the child to get details
 */
export const getChild = async (id) => {
    try {
        const result = await axiosInstance.get(`/children/${id}`);        
        return {
            success: true,
            data: result.data
        };
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}