import * as React from 'react';
import TabLayout from '../../../components/tabs';
import AccidentReportToSignTab from './toSign.tab';
import AccidentReportHistoryTab from './history.tab';

const AccidentReport = () => {
    const tabNames = ["To Sign", "History"];
    return (
        <div className='accidentReport-wrp-tab'>
            <TabLayout
                tabNames={tabNames}
                tabContents={[
                    <AccidentReportToSignTab key={'to_sign'} />,
                    <AccidentReportHistoryTab key={'history'} />,
                ]}
            />
        </div>
    )
}

export default AccidentReport;