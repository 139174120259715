import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
// @mui
import { Button } from '@mui/material';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import ProviderPortalHeader from '../components/header';
import { DATE_READABLE_FORMAT, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../../constants/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';



const BulletinList = (props) => {
    const {
        onViewBulletin = () => null,
        onEditBulletin = () => null,
        onAddNewBulletin = () => null,
    } = props;
    const apiCaller = new ApiCaller('bulletin');
    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    const toggleActiveStatus = (id, status) => {
        status = status === "active" ? "inactive" : "active";
        const statusMsg = status === "active" ? "published" : "unpublished";
        if (id) {
            apiCaller.updateData(id, { status })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected bulletin has been ${statusMsg} successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    getDataArr();
                })
        }

    }

    /**
   * User delete
   */
    const deleteData = () => {
        if (selectedId) {
            apiCaller.updateData(selectedId, { isDeleted: true })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected bulletin has been deleted successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr();
                })
        } else {
            setOpenDeleteDialog(false);
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 180,
            flex: 2,
        },
        {
            field: 'author',
            headerName: 'Author',
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'publishingDate',
            headerName: 'Publishing date',
            flex: 1,
            minWidth: 150,
            valueFormatter: ({ value }) => dayjs(value).format(DATE_READABLE_FORMAT),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { status } = params.row;
                return <>{(status === 'active' && <span className='tab-status'>{SVGIcons.StatusActiveIcon()}  </span>) ||
                    (status === 'inactive' && <span className='tab-status'>{SVGIcons.StatusInactiveIcon()} </span>)}</>
            }
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { id, status } = params.row;
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onViewBulletin(id)
                    },
                    {
                        label: 'Edit',
                        onClick: () => onEditBulletin(id)
                    },
                    {
                        label: status === "active" ? "Unpublish" : "Publish",
                        onClick: () => {
                            toggleActiveStatus(id, status)
                        }
                    },
                    {
                        label: "Delete",
                        onClick: () => {
                            setSelectedId(id);
                            setOpenDeleteDialog(true);
                        }
                    }
                ]} />
            }
        },

    ]

    return (
        <div className='bulletin-main-list f-wrp'>
            <ProviderPortalHeader header='Bulletin' />
            <Button onClick={onAddNewBulletin} className='red-btn' style={{ maxWidth: '275px', float: 'right', zIndex: '999' }}>{translateThis("Create new bulletin")}</Button>
            <div className='bulletin-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec provider-table PC-Table f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                        <div className='mob-table-wrp childView f-wrp'>
                            {dataArr.length > 0 ? <ul>
                                {dataArr.map((element) => (
                                    <li key={element.id}>
                                        <div className='each-sec-box'>
                                            <div className='status-with-btn'>
                                                <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                                <h4>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                            </div>
                                        </div>
                                        <div className='each-sec-box'>
                                            <h4>{element.title}</h4>
                                            <span>{element.author}</span>
                                        </div>
                                        <div className='each-sec-box'>
                                            <ul className='btn-wrp'>
                                                <li><button className='pln-btn' type='button' onClick={() => { onEditBulletin(element.id) }}><SVGIcons.EditPencilIcon /></button></li>
                                                <li><button className='pln-btn' type='button' onClick={() => { setSelectedId(element.id); setOpenDeleteDialog(true); }}><SVGIcons.DeleteIcon /></button></li>
                                            </ul>
                                        </div>
                                        <Link className='full-li-btn' onClick={() => { onViewBulletin(element.id) }} />
                                    </li>
                                ))}
                            </ul> : <div className='empty-placeholder f-wrp'>
                                <div className='placeholder-con'>
                                    <img src={AppIcons.Placeholder} alt='empty' />
                                    <h4>{translateThis('No bulletin found')}</h4>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {openDeleteDialog && <ConfirmDialog
                        isOpen={openDeleteDialog}
                        onClose={() => {
                            setOpenDeleteDialog(false);
                            setSelectedId('');
                        }}
                        title={`Delete bulletin`}
                        className={'delete-popup'}
                        description={`Are you sure you want to delete this bulletin?\nThis action is permanent and cannot be undone.`}
                        okayButtonText={'Delete bulletin'}
                        onConfirm={deleteData}
                    />}
                </div>
            </div>
        </div>
    )
}


BulletinList.propTypes = {
    onViewBulletin: PropTypes.func,
    onEditBulletin: PropTypes.func,
    onAddNewBulletin: PropTypes.func
}
export default BulletinList;