import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @mui
import { Stack } from '@mui/material';

// components
// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import AppIcons from '../../../../assets/images/icons';

// Components
import { showSnackbar } from '../../../../components/snackbar';


// Services
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { getUniqueId } from '../../../../helpers/helper.functions';
import { translateThis } from '../../../../helpers/language.helper';



const ImmunizationRequirements = (props) => {
    const formName = 'immunization-requirements';

    const { childId } = props;
    const parentId = getAuthId();
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [_, setFormData] = useState([]);
    // eslint-disable-next-line
    const [_child, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [initialFormData, setInitialFormData] = useState(null);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                if (data?.formData) {
                    setInitialFormData(data?.formData)
                    setFormData(data?.formData)
                } else {
                    setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }
    const immunizationForms = [
        {
            title: '5.Immunization requirements',
        },

    ];

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>

                <>

                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-txt'>
                            <p style={{color: '#98948A'}}>{translateThis("This file serves as reference for your child immunization schedule")}</p>
                        </div>
                        <div className='pdf-download f-wrp' style={{justifyContent: 'center', textAlign: 'center'}}>
                            {immunizationForms.map((element, key) => (
                                <Link className='each-pdf-download' target="_blank" to={'https://kcolors-dev.s3.amazonaws.com/5.+Immunization_schedule.pdf'} key={key}>
                                    <span><img src={AppIcons.pdfIcon} alt={element.title} /></span>
                                    <p>{translateThis(element.title)}</p>
                                </Link>
                            ))}
                        </div>


                        
                    </Stack>
                </>
            </div> : null}
        </>
    );
}

ImmunizationRequirements.propTypes = {
    childId: propTypes.string,
}

export default ImmunizationRequirements;
