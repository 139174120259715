import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import { answerGetter } from '../../helpers/dynamicForm.helper';
import { translateThis } from '../../helpers/language.helper';

const SpecialNeedsView = (props) => {
    const { data = {} } = props;
    const { formData = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView specialNeedView f-wrp">
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="ryt-section">
                        <h2 style={{ fontSize: '28px', margin: '0' }}><b>{translateThis("Special Needs")}</b></h2>
                        <div className='gap' />
                        <ul className='bullet-list'>
                            <li>{translateThis("Special Needs Text_1")}</li>
                            <li>{translateThis("Special Needs Text_2")}</li>
                        </ul>
                        <div className='gap' />
                        <p><b>{translateThis("Special Needs Text_3")}</b></p>
                        <p>{translateThis("Special Needs Text_4")}</p>
                    </div>
                </div>
                <div className='gap' />
                <div className='each-section-box'>
                    <div className='flex-box'>
                        <p>{translateThis("Special Needs Text_5")}: <b>{answerGetter('special_needs_form_question_patientName', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Special Needs Text_6")}: <b>{answerGetter('special_needs_form_question_adultName', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />


                    <div className="signature-blk" style={{ float: 'left', paddingBottom: '25px' }}>
                        <div style={{ float: 'right', textAlign: 'center' }}>
                            <h3>{data.additionalInfo && data.additionalInfo.parentSignature
                                ? data.additionalInfo.parentSignature
                                : ''}</h3>
                            <p>{translateThis("Parent Guardian signature")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

SpecialNeedsView.propTypes = {
    data: PropTypes.any
}

export default SpecialNeedsView;