import React, { useState } from 'react';
import { IconButton, MenuItem, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../iconify/Iconify';
import { getUniqueId } from '../../helpers/helper.functions';
import { translateThis } from '../../helpers/language.helper';

const AppPopOverMenu = (props) => {
    const { options = [], icon = <Iconify icon={'eva:more-vertical-fill'} />  } = props;
    const [open, setOpen] = useState(null);

    /**
     * Invokes when we open the popover
     * @param {any} event 
     */
    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };


    /**
     * Invokes when the popover is closed
     */
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const anchorId = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton aria-describedby={anchorId} size="large" color="inherit" onClick={handleOpenMenu}>
                {icon}
            </IconButton>
            <Popover
                key={'popover-menu'}
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {options.map((option) => <MenuItem key={`${getUniqueId()}-${option.label}`} onClick={() => {
                    if (typeof option.onClick === "function") {
                        option.onClick()
                    }
                    handleCloseMenu();
                }} sx={{ color: '#1D1D1B' }}>{translateThis(option.label)}</MenuItem>
                )}
            </Popover>
        </>
    )
}
AppPopOverMenu.propTypes = {
    icon: PropTypes.any,
    options: PropTypes.array,
}

export default AppPopOverMenu;