import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Box, Grid, Button, Divider } from '@mui/material';
// import { Line } from 'react-chartjs-2';
// import faker from 'faker';


import SVGIcons from '../../../assets/images/icons/svgIcons';
import './index.scss';
import ProviderPortalHeader from '../components/header';
import AppIcons from '../../../assets/images/icons';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const ProviderDashboard = () => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                // position: 'top' as const,
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,
        datasets: [
            {
                // label: 'Dataset 1',
                data: 100,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                // label: 'Dataset 2',
                data: 1000,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };


    const eachCards = [
        {
            headIcon: SVGIcons.CatalogIcon(),
            title: 'Daily logs',
            infoLine1: <><p>Pending signature <b>7</b></p></>,
            infoLine2: <><p>Daily submissions <b>8</b></p></>,
            link: ''
        },
        {
            headIcon: SVGIcons.FormsIcon(),
            title: 'Forms',
            infoLine1: <><p>Pending signature <b>7</b></p></>,
            infoLine2: <><p>Total submissions <b>8</b></p></>,
            link: ''
        },
        {
            headIcon: SVGIcons.AccidentIcon(),
            title: 'Accidents',
            infoLine1: <><p>Current month <b>7</b></p></>,
            infoLine2: <><p>Current year <b>8</b></p></>,
            link: ''
        },
    ];

    const eachAttendance = [
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'Juan García',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'Bruno Diaz',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'Georgina Apesteguia',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'María Soledad Rodriguez',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'María Soledad Rodriguez',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'María Soledad Rodriguez',
            time: '10:05 AM - 03:25 PM',
        },
        {
            icon: SVGIcons.ProfileIcon(),
            title: 'María Soledad Rodriguez',
            time: '10:05 AM - 03:25 PM',
        },

    ];

    return (

        <>
            <ProviderPortalHeader header='Dashboard' />
            <Box component="div" className='dashboard-main-wrp f-wrp' sx={{ flexGrow: 1, p: 3, }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Grid container spacing={2}>
                            {eachCards.map((element, key) => (
                                // eslint-disable-next-line react/jsx-key
                                <Grid item xs={12} sm={12} md={3} lg={3} key={key}>
                                    <div className={`${element.title} each-board-items f-wrp `}>
                                        <div className='card-header'>
                                            <span className={`${element.title}`}>{element.headIcon}</span>
                                            <h4>{element.title}</h4>
                                        </div>
                                        <div className='card-body'>
                                            {element.infoLine1}
                                            {element.infoLine2}
                                        </div>
                                        {/* <Divider />
                                        <div className='card-foot'>
                                            <Button className='pln-btn' onClick={() => console.log('clicked')} >Read more {SVGIcons.ArrowTrIcon()}</Button>
                                        </div> */}
                                    </div>
                                </Grid>
                            ))}
                            <Grid item xs={12} sm={12} md={2} lg={3}>
                                <div className='inbox-card f-wrp'>
                                    <span>{SVGIcons.InboxIcon()}</span>
                                    <h4>Inbox <b>0</b></h4>
                                    <p>Unread Messages</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                <div className='graph-wrp-sec'>
                                    <span className='graph-img'><img src={AppIcons.graphBG} alt='img' /></span>
                                    <div className='graph-box-card f-wrp'>
                                        <div className='card-header'>
                                            <span>{SVGIcons.PaymentsIcon()}</span>
                                            <h4>Payments collected</h4>
                                        </div>
                                        <div className='price-sec-container'>
                                            <div className='each-price-sec'>
                                                <h4>This month</h4>
                                                <p>September, 2023</p>
                                                <h2>$904</h2>
                                            </div>
                                            <div className='each-price-sec'>
                                                <h4>This year</h4>
                                                <p>2023</p>
                                                <h2>$8.104</h2>
                                            </div>
                                            <div className='each-price-sec'>
                                                <h4>Historical</h4>
                                                <p>Jan, 2022 to Set, 2023</p>
                                                <h2>$13.904</h2>
                                            </div>
                                        </div>
                                        <div className='graph-box f-wrp'>
                                            {/* <Line options={options} data={data} /> */}

                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <div className="each-board-items activeChildBox f-wrp">
                                    <div className='card-header'>
                                        <span>{SVGIcons.ChildIcon()}</span>
                                        <h4>Children</h4>
                                    </div>
                                    <div className='card-body'>
                                        <p>Active children <b>8</b></p>
                                    </div>
                                    <div className='card-header'>
                                        <span>{SVGIcons.ParentIcon()}</span>
                                        <h4>Parents</h4>
                                    </div>
                                    <div className='card-body'>
                                        <p>Active parent <b>8</b></p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <div className='date-indicator-sec each-board-items f-wrp'>
                            <h4>Wednesday, 26 July 2023</h4>
                            <span>{SVGIcons.ProfileIcon()}</span>
                        </div>
                        <div className='attendance-box-wrp each-board-items f-wrp'>
                            <div className='card-header'>
                                <span>{SVGIcons.ClockIcon()}</span>
                                <h4>Attendance</h4>
                            </div>
                            <div className='attendanceList-sec f-wrp'>
                                <ul>
                                    {eachAttendance.map((element, key) => (
                                        <li key={key}>
                                            <span>{element.icon}</span>
                                            <h4>{element.title}</h4>
                                            <p>{element.time}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

ProviderDashboard.propTypes = {};

export default ProviderDashboard;