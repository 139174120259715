import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';
// css
import '../../assets/css/formView.scss';
import AppIcons from '../../assets/images/icons';
import { translateThis } from '../../helpers/language.helper';
import { answerGetter } from '../../helpers/dynamicForm.helper';


const EarlyChildhoodHealthAssessmentRecordView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ECHARView f-wrp">
                <div className='flex-box'>
                    <div className="details-section c4k-img-sec" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ maxWidth: '250px', display: 'block' }}><img src={AppIcons.logo} alt='profile' /></span>
                    </div>
                </div>
                <div className='gap' />
                {/* <div className='prof-sec'>
                    <div className='prof-img'>
                        <span><img src={AppIcons.registerBG} alt='profile' /></span>
                    </div>
                    <div className='prof-basic'>
                        <h4>{child?.firstName}&nbsp;{child?.lastName}</h4>
                    </div>
                </div> */}
                <div className='gap' />
                <h4>{translateThis("State of Connecticut Department of Education")}</h4>
                <h3><b>{translateThis("Early Childhood Health Assessment Record")}</b></h3>
                <i style={{ fontSize: '14px' }}>{translateThis("For Children ages birth-5")}</i>
                <div className='gap' />
                <p>{translateThis("To Parent or Guardian")} <b> {translateThis("To Parent or Guardian Note")}</b></p>
                <div className='gap' />

                <div className='flex-box'>
                    <p>{translateThis("Child’s Name (last, first, middle)")}: <b>{answerGetter('early_childhood_health_assessment_form_question_name', formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                    <p>{translateThis("Birth Date")}: <b>{answerGetter('early_childhood_health_assessment_form_Key_birthday', formData)?.answer || dayjs(child?.birthday).format(DATE_FORMAT) || '-'}</b></p>
                    <div className='check-box inline' style={{ marginBottom: '0' }}>
                        <ul>
                            <li className={answerGetter("early_childhood_health_assessment_form_Key_question_sex", formData)?.answer === 'male' ? 'checked' : ''}>{translateThis("Male")}</li>
                            <li className={answerGetter("early_childhood_health_assessment_form_Key_question_sex", formData)?.answer === 'female' ? 'checked' : ''}>{translateThis("Female")}</li>
                        </ul>
                    </div>
                </div>
                <div className='flex-box'>
                    <p>{translateThis("Address")}: <b>{answerGetter('early_childhood_health_assessment_form_question_address', formData)?.answer}, {answerGetter('early_childhood_health_assessment_form_question_city', formData)?.answer}, {answerGetter('early_childhood_health_assessment_form_question_zipcode', formData)?.answer}</b></p>
                    <p>{translateThis("raceEthnicity")}: <b>{answerGetter('early_childhood_health_assessment_form_question_raceEthnicity', formData)?.answer || '-'}</b></p>
                    <div className='width175'>&nbsp;</div>
                </div>

                <div className='auto-flex' style={{ justifyContent: 'space-between' }}>
                    <p>{translateThis("Parent/guardian name (last, first, middle)")}: <b>{answerGetter('early_childhood_health_assessment_form_question_guardianName', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("early_childhood_health_assessment_form_question_guardianHomePhone")}: <b>{answerGetter('early_childhood_health_assessment_form_question_guardianHomePhone', formData)?.answer || '-'}</b></p>
                    <p style={{ textAlign: 'right' }}>{translateThis("early_childhood_health_assessment_form_question_guardianCellPhone")}: <b>{answerGetter('early_childhood_health_assessment_form_question_guardianCellPhone', formData)?.answer || '-'}</b></p>
                </div>

                <div className='gap' />

                <p>{translateThis("Early Childhood Program")}: <b>{answerGetter('early_childhood_health_assessment_form_question_ChildhoodName', formData)?.answer || '-'} </b></p>
                <p>{translateThis("early_childhood_health_assessment_form_Key_ChildhoodPhone")}: <b>{answerGetter('early_childhood_health_assessment_form_Key_ChildhoodPhone', formData)?.answer || '-'} </b></p>
                <div className='auto-flex' style={{ justifyContent: 'space-between' }}>
                    <p>{translateThis("early_childhood_health_assessment_form_question_PrimaryHealthCareProvider")}: <b>{answerGetter('early_childhood_health_assessment_form_question_PrimaryHealthCareProvider', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("early_childhood_health_assessment_form_Key_dentistName")}: <b>{answerGetter('early_childhood_health_assessment_form_Key_dentistName', formData)?.answer || '-'}</b></p>
                </div>
                <p>{translateThis("Health Insurance Company/Number or Medicaid/Number")}: <b>{answerGetter('early_childhood_health_assessment_form_Key_medicaidNumber', formData)?.answer || '-'} </b></p>

                <div className='gap' />

                <div className="details-section" style={{ alignItems: 'center' }}>
                    <div className="lft-section">
                        <p>{translateThis("Does the child have health insurance?")} <b> {answerGetter('early_childhood_health_assessment_form_question_insuranceQN_1', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Does the child have dental insurance?")} <b> {answerGetter('early_childhood_health_assessment_form_question_insuranceQN_2', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("Does the child have HUSKY insurance?")} <b> {answerGetter('early_childhood_health_assessment_form_question_insuranceQN_3', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className="ryt-section">
                        <p>{translateThis("If your child does not have health insurance")}: <br /><b>{translateThis("1-877-CT-HUSKY")}</b></p>
                    </div>
                </div>
                <div className='gap' />
                <h4 style={{ textAlign: 'center' }}>{translateThis("Part 1 — To be completed by parent/guardian")}.</h4>

                <div className='gap' />

                <div className='auto-grid custom-grid'>
                    <p>{translateThis("historyQuestion_1")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_1', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_9")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_9', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_16")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_16', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_2")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_2', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_10")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_10', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_17")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_17', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_3")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_3', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_11")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_11', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_18")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_18', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_4")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_4', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_12")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_12', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_19")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_19', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_5")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_5', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_20")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_20', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_6")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_6', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_13")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_13', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_21")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_21', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_7")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_7', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_14")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_14', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_22")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_22', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_8")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_8', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_15")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_15', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_23")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_23', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_24")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_24', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_25")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_25', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_26")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_26', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_27")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_27', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_28")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_28', formData)?.answer || '-'}</b></p>
                    <p>{translateThis("historyQuestion_29")}: <b> {answerGetter('early_childhood_health_assessment_form_question_historyQuestion_29', formData)?.answer || '-'}</b></p>

                </div>
                <div className='gap' />
                <p>{translateThis("Explain all yes answers or provide any additional information")}:</p>
                <p><b>{answerGetter('early_childhood_health_assessment_form_comments', formData)?.answer || '-'} </b></p>
                <div className='gap' />
                
                <p>{translateThis('historyQuestion_39')} <b><div className='check-box inline'>
                        <ul>
                            <li className={answerGetter("early_childhood_health_assessment_form_question_historyQuestion_39", formData)?.answer === 'Yes' ? 'checked' : ''}>{translateThis("Yes")}</li>
                            <li className={answerGetter("early_childhood_health_assessment_form_question_historyQuestion_39", formData)?.answer === 'No' ? 'checked' : ''}>{translateThis("No")}</li>
                        </ul>
                    </div></b></p>
                <p>{translateThis("Please list any medications your child will need to take during program hours")}:</p>
                <p><b>{answerGetter('early_childhood_health_assessment_form_comments_2', formData)?.answer || '-'}</b></p>

                <i style={{ fontSize: '13px' }}>{translateThis("All medications taken in child")}.</i>

                <div className="signature-blk" style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ paddingRight: '25px' }}>
                        <p style={{ color: '#4C483E' }}><b>{translateThis("I give my consent for my child")}</b></p>
                    </div>

                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <h3>{
                            data.additionalInfo && data.additionalInfo?.parentSignature
                                ? data.additionalInfo?.parentSignature
                                : ''
                        }</h3>
                        <p>{translateThis("Parent / guardian signature")}</p>
                        <p>{translateThis("Submission time")}: <b>{
                            data.additionalInfo && data.additionalInfo?.parentSignedDate
                                ? data.additionalInfo?.parentSignedDate
                                : ''
                        }</b></p>
                    </div>
                </div>
                <div className='gap' />
                <p style={{ color: '#4C483E' }}><b>{translateThis("sectionActNumbers")}</b></p>
            </div>
        </>
    )
}

EarlyChildhoodHealthAssessmentRecordView.propTypes = {
    data: PropTypes.any
}
export default EarlyChildhoodHealthAssessmentRecordView;