import * as React from 'react';
import PropTypes from 'prop-types';

// css
import '../../assets/css/formView.scss';
import { translateThis } from '../../helpers/language.helper';
import { answerGetter } from '../../helpers/dynamicForm.helper';

const MedicalExemptionView = (props) => {
    const { data = {} } = props;
    const { formData = {}, childId: child = {} } = data;
    
    return (
        <>
            <div className="view-sec-wrp form-view-wrp ppAgreementView medicalExemptionView f-wrp">
                <div className="details-section c4k-img-sec" style={{ alignItems: 'center' }}>
                    <div className="ryt-section">
                        <h2 style={{ fontSize: '28px', margin: '0' }}><b>{translateThis("Medical Exemption")}</b></h2>
                        <div className='gap' />
                        <p>{translateThis("Medical_Exemption_text_1")}</p>
                        <div className='gap' />
                        <p><b>{translateThis("To Whom It May Concern")}:</b></p>
                        <div className='gap' />
                        <p>{translateThis("Medical_Exemption_text_2")}: <b>{answerGetter('medical_exemption_form_question_studentName', formData)?.answer || `${child?.firstName} ${child?.lastName}` || '-'}</b></p>
                        <div className='gap' />
                        <p>{translateThis("Medical_Exemption_text_3")}</p>
                    </div>
                </div>
                <div className='gap' />

                <div className="signature-blk">
                    <div className="details-section">
                        <div className="lft-section">
                            <div style={{ textAlign: 'center', float: 'left' }}>
                                <h3>{answerGetter('medical_exemption_form_question_signatureName', formData)?.answer || '-'}</h3>
                                <p>{translateThis("Parent Guardian signature")}</p>
                            </div>
                        </div>
                        <div className="ryt-section">
                            <div style={{ textAlign: 'center', float: 'right' }}>
                                <h3>{answerGetter('medical_exemption_form_question_signatureName2', formData)?.answer || '-'}</h3>
                                <p>{translateThis("Parent Guardian signature")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gap' />
                <div className='each-section-box'>
                    <div className='flex-box'>
                        <p>{translateThis("Address")}: <b>{answerGetter('medical_exemption_form_question_Address', formData)?.answer || '-'}</b></p>
                        <p>{translateThis("medical_exemption_form_question_Telephone")}: <b>{answerGetter('medical_exemption_form_question_Telephone', formData)?.answer || '-'}</b></p>
                    </div>
                    <div className='gap' />
                    <p>{translateThis("Medical_Exemption_text_4")}</p>
                </div>
            </div>
        </>
    )
}

MedicalExemptionView.propTypes = {
    data: PropTypes.any
}

export default MedicalExemptionView;