import { useEffect, useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Button, Divider, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_TIME_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';


const MedicalExemption = (props) => {
    const formName = 'medical-exemption';
    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [initialFormData, setInitialFormData] = useState(null);
    const [firstParentSignature, setFirstParentSignature] = useState('');
    const [isFirstParentSigned, setIsFirstParentSigned] = useState(false);
    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData({ temp: 'temp' }) // Temp fix to show forms
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                } else {
                    // setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild && isFirstParentSigned) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        const postData = {
            formName: 'medical-exemption',
            formTitle: 'Medical Exemption',
            userId: parentId,
            childId,
            formData,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: signature,
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        }
    };



    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>
                <>
                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head f-wrp'>
                            <h2>{translateThis("Medical Exemption")}</h2>
                        </div>
                        <div className='parents-rights-sec'>
                            <p>{translateThis("Medical_Exemption_text_1")}</p>
                        </div>
                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <p style={{ margin: '0' }}><b>{translateThis("To Whom It May Concern")}</b></p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Stack className='panel-form' spacing={3}>
                                    <div className='info-head'>
                                        <h3>{translateThis("Medical_Exemption_text_2")}</h3>
                                    </div>
                                    {(selectedChild) &&  <TextField
                                        type='text'
                                        disabled
                                        defaultValue={`${selectedChild?.firstName} ${selectedChild?.lastName}`}
                                        label={translateThis('medical_exemption_form_question_studentName')}
                                        name='medical_exemption_form_question_studentName'
                                    /> }
                                    
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} style={{ padding: '0' }}>
                                <Divider style={{ display: 'none' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head'>
                                    <p>{translateThis("Medical_Exemption_text_3")}</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Stack className='panel-form' spacing={3}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Parent Guardian signature")}</h3>
                                    </div>
                                    <TextInput
                                        key={`medical_exemption_form_Key_signatureName`}
                                        label={translateThis('medical_exemption_form_question_signatureName')}
                                        name='medical_exemption_form_question_signatureName'
                                        id='medical_exemption_form_question_signatureName'
                                        value={answerGetter('medical_exemption_form_question_signatureName', initialFormData)?.answer}
                                        translationKey='medical_exemption_form_question_signatureName'
                                        type='text'
                                        required
                                        autocomplete="off"
                                        placeholder='Type your name'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'medical_exemption_form_question_signatureName',
                                                type: 'text'
                                            })
                                            setFirstParentSignature(value);
                                        }}
                                        useDefaultValidation
                                    />
                                    <FormGroup className='check-label'>
                                        <FormControlLabel onChange={(e) => setIsFirstParentSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis("signatureI")}, {firstParentSignature && <b>{firstParentSignature},</b>} {translateThis("affirm that the information provided is true and complete")}</span>
                                        } />
                                    </FormGroup>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Stack className='panel-form' spacing={3}>
                                    <div className='info-head f-wrp'>
                                        <h3>{translateThis("Parent Guardian signature")}</h3>
                                    </div>
                                    <TextInput
                                        key={`medical_exemption_form_key_question_signatureName2`}
                                        label={translateThis('medical_exemption_form_question_signatureName2')}
                                        name='medical_exemption_form_question_signatureName2'
                                        value={answerGetter('medical_exemption_form_question_signatureName2', initialFormData)?.answer}
                                        id='medical_exemption_form_question_signatureName2'
                                        translationKey='medical_exemption_form_question_signatureName2'
                                        type='text'
                                        required
                                        placeholder='Type your name'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'medical_exemption_form_question_signatureName2',
                                                type: 'text'
                                            })
                                            setSignature(value)
                                        }}
                                        useDefaultValidation
                                    />
                                    <FormGroup className='check-label'>
                                        <FormControlLabel onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                            <span>{translateThis("signatureI")}, {signature && <b>{signature},</b>} {translateThis("affirm that the information provided is true and complete")}</span>
                                        } />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`medical_exemption_form_Key_Address`}
                                    label={translateThis('medical_exemption_form_question_Address')}
                                    name='medical_exemption_form_question_Address'
                                    id='medical_exemption_form_question_Address'
                                    value={answerGetter('medical_exemption_form_question_Address', initialFormData)?.answer}
                                    translationKey='medical_exemption_form_question_Address'
                                    type='text'
                                    required
                                    autocomplete="off"
                                    placeholder='Address'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'medical_exemption_form_question_Address',
                                            type: 'text'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextInput
                                    key={`medical_exemption_form_Key_Telephone`}
                                    label={translateThis('medical_exemption_form_question_Telephone')}
                                    name='medical_exemption_form_question_Telephone'
                                    id='medical_exemption_form_question_Telephone'
                                    value={answerGetter('medical_exemption_form_question_Telephone', initialFormData)?.answer}
                                    translationKey='medical_exemption_form_question_Telephone'
                                    type='number'
                                    required
                                    autocomplete="off"
                                    placeholder='Telephone'
                                    className='custom-textfield'
                                    getValue={(name, value) => {
                                        setFormValue({
                                            questionId: name,
                                            answer: value,
                                            translationKey: 'medical_exemption_form_question_Telephone',
                                            type: 'number'
                                        })
                                    }}
                                    useDefaultValidation
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className='info-head'>
                                    <p>{translateThis("Medical_Exemption_text_4")}</p>
                                </div>
                            </Grid>
                        </Grid>
                    </Stack>
                </>
                <br />
                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='pln-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        {(parentId && childId && formName === "sample123") ? <PDFDownloadButton
                            className='pln-btn'
                            parentId={parentId}
                            childId={childId}
                            formName={formName}
                        /> : null}

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={!isValidForm() || !isSigned || !signature || !isFirstParentSigned}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onSubmit}>
                            {translateThis("submit")}
                        </LoadingButton>
                    </div>
                </div>
            </div> : null}
        </>
    );
}

MedicalExemption.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func
}

export default MedicalExemption;