import { Outlet } from 'react-router-dom';
import propTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// components
import AppHeader from '../../components/header';
import AppFooter from '../../components/footer';
import config from '../../config/config';

// ----------------------------------------------------------------------

const AppLayout = (props) => {
  const { appName } = config;

  const {
    pageName = "",
    isLoadHeader = true,
    isLoadFooter = true,
    headerClassName = "",
    bodyClassName = "",
    footerClassName = "",
    customHeader = null,
    customFooter = null,
    children = <></>
  } = props;
  return (
    <>
      <Helmet>
        <title> {pageName} | {appName} </title>
      </Helmet>
      {(isLoadHeader) && <div className={`app-header ${headerClassName}`}>
        {customHeader ? <>{customHeader}</> : <AppHeader />}
      </div>}

      <div className={`app-body ${bodyClassName}`}>
        {children}
        <Outlet />
      </div>

      {(isLoadFooter) && <div className={`app-footer ${footerClassName}`}>
        {customFooter ? <>{customFooter}</> : <AppFooter />}
      </div>}


    </>
  );
}
AppLayout.propTypes = {
  pageName: propTypes.string,
  isLoadHeader: propTypes.bool,
  isLoadFooter: propTypes.bool,
  customHeader: propTypes.element,
  customFooter: propTypes.element,
  children: propTypes.any,
  headerClassName: propTypes.string,
  bodyClassName: propTypes.string,
  footerClassName: propTypes.string,
}

export default AppLayout;