import { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Delete } from '@mui/icons-material';

// @mui
import { Stack, Divider, Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

// css
import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';

// Components
import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';


// Services
import { submitForm } from '../../../../services/api/dynamicForms';
import { getChild, getChildFormBy } from '../../../../services/api/child';

// Helpers
import { getAuthId } from '../../../../helpers/auth.helper';
import { answerGetter, onAddFormExtraSection, onDeleteFormExtraSection } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_EXTENDED_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';



const C4KApplication = (props) => {
    const formName = 'c4k-application';
    const stepRef = useRef(null)

    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [secondSignature, setSecondSignature] = useState('');
    const [isSecondSigned, setIsSecondSigned] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [accreditedArr, setAccreditedArr] = useState({});
    const [initialFormData, setInitialFormData] = useState(null);
    const [extraChildren, setExtraChildren] = useState([]);
    const [underAgeParents, setUnderAgeParents] = useState([]);
    const [trainingSections, setTrainingSections] = useState([]);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                setInitialFormData({ temp: 'temp' }) // Temp fix to show forms
                if (data?.formData) {
                    // setInitialFormData(data?.formData)
                    // setFormData(data?.formData)
                } else {
                    // setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const submitThisForm = () => {
        const postData = {
            formName: 'c4k-application',
            formTitle: 'C4K Application',
            userId: parentId,
            childId,
            formData,
            isParentSigned: true,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: true,
                parentSignature: signature,
                parentSignedDate: dayjs().format(DATE_TIME_FORMAT),
                submissionDate: dayjs().format(DATE_TIME_EXTENDED_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        } else {
            showSnackbar().warning("Please fill the required fields")
        }
    };

    /**
     * On Next button click
     */
    const onNextClick = () => {
        if (currentStep === 6) {
            submitThisForm()
        } else {
            const newStep = currentStep + 1;
            setCurrentStep(newStep);
            stepRef.current.scrollIntoView()
        }
    }
    /**
         * On Next button click
         */
    const onPrevStepClick = () => {
        if (currentStep > 1) {
            const newStep = currentStep - 1;
            setCurrentStep(newStep);
            stepRef.current.scrollIntoView()
        }
    }

    /**
     * Invokes when we click a checkbox item
     * @param {string} selectedItem 
     * @param {boolean} checked 
     */
    const onSelectAccreditedArr = (selectedItem, checked, name) => {
        let alreadySelectedItems = accreditedArr[name] || [];
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setAccreditedArr({ ...accreditedArr, [name]: alreadySelectedItems });
        setFormValue({
            questionId: name,
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: name,
            type: 'Checkbox'
        })
    }

    const steps = [
        'Applicant',
        'Other Parent',
        'Children',
        'Work',
        'Child Support',
        'Rights',
    ];

    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {(!isLoading && initialFormData) ? <div>

                <>

                    <Stack key={formKey} className='standard-form form-full-width C4KAppSection panel-form' spacing={3}>
                        <div ref={stepRef} className='info-head f-wrp'>
                            <h2>{translateThis("Care 4 Kids Application")}</h2>
                            <br />
                            <Box sx={{ width: '100%' }} className='stepper'>
                                <Stepper activeStep={currentStep} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{translateThis(label)}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            <br />
                        </div>

                        {currentStep && <div style={{ display: currentStep === 1 ? 'block' : 'none' }} className='C4KAppSectionOne f-wrp'>


                            <div className='info-head f-wrp'>
                                <h2>{translateThis("SECTION 1 Applicant information/head of household")}</h2>
                                <p>{translateThis("SECTION 1 Applicant information text")} <b>{translateThis("18")}</b> {translateThis("SECTION 1 Applicant information text2")} </p>
                            </div>
                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                                <Grid item xs={12} sm={6} md={4}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_firstName`}
                                        label={translateThis('c4k_application_sectionOne_form_question_firstName')}
                                        name='c4k_application_sectionOne_form_question_firstName'
                                        id='c4k_application_sectionOne_form_question_firstName'
                                        value={answerGetter('c4k_application_sectionOne_form_question_firstName', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_firstName'
                                        type='text'
                                        placeholder='First Name'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_firstName',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={1}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_MI`}
                                        label={translateThis('c4k_application_sectionOne_form_question_MI')}
                                        name='c4k_application_sectionOne_form_question_MI'
                                        id='c4k_application_sectionOne_form_question_MI'
                                        value={answerGetter('c4k_application_sectionOne_form_question_MI', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_MI'
                                        type='text'
                                        placeholder='M.I.'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_MI',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_lastName`}
                                        label={translateThis('c4k_application_sectionOne_form_question_lastName')}
                                        name='c4k_application_sectionOne_form_question_lastName'
                                        id='c4k_application_sectionOne_form_question_lastName'
                                        value={answerGetter('c4k_application_sectionOne_form_question_lastName', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_lastName'
                                        type='text'
                                        placeholder='Last Name'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_lastName',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                value={answerGetter('c4k_application_sectionOne_form_DateOFBirth', initialFormData)?.answer ?
                                                    dayjs(answerGetter('c4k_application_sectionOne_form_DateOFBirth', initialFormData)?.answer) :
                                                    ''
                                                }
                                                onChange={(v) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_DateOFBirth',
                                                        answer: dayjs(v).format(DATE_FORMAT),
                                                        translationKey: 'c4k_application_sectionOne_form_DateOFBirth',
                                                        type: 'text'
                                                    })
                                                }
                                                }
                                                label={translateThis("Date of Birth")} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_address`}
                                        label={translateThis('c4k_application_sectionOne_form_question_address')}
                                        name='c4k_application_sectionOne_form_question_address'
                                        id='c4k_application_sectionOne_form_question_address'
                                        value={answerGetter('c4k_application_sectionOne_form_question_address', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_address'
                                        type='text'
                                        required
                                        placeholder='Street Address'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_address',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_floorNumber`}
                                        label={translateThis('c4k_application_sectionOne_form_question_floorNumber')}
                                        name='c4k_application_sectionOne_form_question_floorNumber'
                                        id='c4k_application_sectionOne_form_question_floorNumber'
                                        value={answerGetter('c4k_application_sectionOne_form_question_floorNumber', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_floorNumber'
                                        type='text'
                                        required
                                        placeholder='Floor/Apartment number'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_floorNumber',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_city`}
                                        label={translateThis('c4k_application_sectionOne_form_question_city')}
                                        name='c4k_application_sectionOne_form_question_city'
                                        id='c4k_application_sectionOne_form_question_city'
                                        value={answerGetter('c4k_application_sectionOne_form_question_city', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_city'
                                        type='text'
                                        required
                                        placeholder='City'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_city',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_state`}
                                        label={translateThis('c4k_application_sectionOne_form_question_state')}
                                        name='c4k_application_sectionOne_form_question_state'
                                        id='c4k_application_sectionOne_form_question_state'
                                        value={answerGetter('c4k_application_sectionOne_form_question_state', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_state'
                                        type='text'
                                        required
                                        placeholder='State'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_state',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_zipcode`}
                                        label={translateThis('c4k_application_sectionOne_form_question_zipcode')}
                                        name='c4k_application_sectionOne_form_question_zipcode'
                                        id='c4k_application_sectionOne_form_question_zipcode'
                                        value={answerGetter('c4k_application_sectionOne_form_question_zipcode', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_zipcode'
                                        type='text'
                                        required
                                        placeholder='Zipcode'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_zipcode',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_primaryPhone`}
                                        label={translateThis('c4k_application_sectionOne_form_question_primaryPhone')}
                                        name='c4k_application_sectionOne_form_question_primaryPhone'
                                        id='c4k_application_sectionOne_form_question_primaryPhone'
                                        value={answerGetter('c4k_application_sectionOne_form_question_primaryPhone', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_primaryPhone'
                                        type='number'
                                        required
                                        placeholder='Primary Phone'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_primaryPhone',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_workPhone`}
                                        label={translateThis('c4k_application_sectionOne_form_question_workPhone')}
                                        name='c4k_application_sectionOne_form_question_workPhone'
                                        id='c4k_application_sectionOne_form_question_workPhone'
                                        value={answerGetter('c4k_application_sectionOne_form_question_workPhone', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_workPhone'
                                        type='number'
                                        required
                                        placeholder='Work Phone'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_workPhone',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_socialSecurityNumber`}
                                        label={translateThis('c4k_application_sectionOne_form_question_socialSecurityNumber')}
                                        name='c4k_application_sectionOne_form_question_socialSecurityNumber'
                                        id='c4k_application_sectionOne_form_question_socialSecurityNumber'
                                        value={answerGetter('c4k_application_sectionOne_form_question_socialSecurityNumber', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_socialSecurityNumber'
                                        type='number'
                                        placeholder='Social Security Number (optional)'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_socialSecurityNumber',
                                                type: 'number'
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_application_sectionOne_form_Key_email`}
                                        label={translateThis('c4k_application_sectionOne_form_question_email')}
                                        name='c4k_application_sectionOne_form_question_email'
                                        id='c4k_application_sectionOne_form_question_email'
                                        value={answerGetter('c4k_application_sectionOne_form_question_email', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionOne_form_question_email'
                                        type='email'
                                        required
                                        placeholder='E-mail Address'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionOne_form_question_email',
                                                type: 'email'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack className='panel-form box-class-form' spacing={3}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_Gender">{translateThis('c4k_application_sectionOne_form_Key_Gender')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Gender"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_Gender', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_Gender"
                                                name="c4k_application_sectionOne_form_Key_Gender"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_Gender',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_Gender',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="F (Female)" control={<Radio />} label={translateThis("F Female")} />
                                                <FormControlLabel value="M (Male)" control={<Radio />} label={translateThis("M Male")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_maritalStatus">{translateThis('c4k_application_sectionOne_form_Key_maritalStatus')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Marital Status"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_maritalStatus', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_maritalStatus"
                                                name="c4k_application_sectionOne_form_Key_maritalStatus"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_maritalStatus',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_maritalStatus',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Married" control={<Radio />} label={translateThis("Married")} />
                                                <FormControlLabel value="Single" control={<Radio />} label={translateThis("Single")} />
                                                <FormControlLabel value="Separated" control={<Radio />} label={translateThis("Separated")} />
                                                <FormControlLabel value="Divorced" control={<Radio />} label={translateThis("Divorced")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel className='main-head' id="c4k_application_sectionOne_form_Key_race">{translateThis('c4k_application_sectionOne_form_Key_race')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Marital Status"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_race', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_race"
                                                name="c4k_application_sectionOne_form_race"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_race',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_race',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="A (Asian)" control={<Radio />} label={translateThis("A Asian")} />
                                                <FormControlLabel value="B (Black/African)" control={<Radio />} label={translateThis("B Black/African")} />
                                                <FormControlLabel value="C (White)" control={<Radio />} label={translateThis("C White")} />
                                                <FormControlLabel value="N (American Indian/Alaska Native)" control={<Radio />} label={translateThis("N American IndianAlaska Native")} />
                                                <FormControlLabel value="P (Native Hawaiian/Other Pacific Islander)" control={<Radio />} label={translateThis("P Native HawaiianOther Pacific Islander")} />
                                                &nbsp;&nbsp;<FormControlLabel value="I prefer not to answer" control={<Radio />} label={translateThis("I prefer not to answer")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_hispanicLatino:">{translateThis('c4k_application_sectionOne_form_Key_hispanicLatino')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Hispanic Latino"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_hispanicLatino', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_hispanicLatino"
                                                name="c4k_application_sectionOne_form_Key_hispanicLatino"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_hispanicLatino',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_hispanicLatino',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                <FormControlLabel value="I prefer not to answer" control={<Radio />} label={translateThis("I prefer not to answer")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_generalQN_1:">{translateThis('generalQN_1')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Does your household have assets that exceed $1 million in value?"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_generalQN_1', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_generalQN_1"
                                                name="c4k_application_sectionOne_form_Key_generalQN_1"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_generalQN_1',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_generalQN_1',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_generalQN_2:">{translateThis('generalQN_2')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Is this Application for child care assistance for a foster child?"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_generalQN_2', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_generalQN_2"
                                                name="c4k_application_sectionOne_form_Key_generalQN_2"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_generalQN_2',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_generalQN_2',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_generalQN_3:">{translateThis('generalQN_3')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Are you living in a temporary housing situation?"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_generalQN_3', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_generalQN_3"
                                                name="c4k_application_sectionOne_form_Key_generalQN_3"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_generalQN_3',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_generalQN_3',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_generalQN_4:">{translateThis('generalQN_4')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Have you moved 3 or more times in the past year?"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_generalQN_4', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_generalQN_4"
                                                name="c4k_application_sectionOne_form_Key_generalQN_4"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_generalQN_4',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_generalQN_4',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_generalQN_5:">{translateThis('generalQN_5')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Are you an active member of the United States Military?"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_generalQN_5', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_generalQN_5"
                                                name="c4k_application_sectionOne_form_Key_generalQN_5"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_generalQN_5',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_generalQN_5',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                        {answerGetter('c4k_application_sectionOne_form_Key_generalQN_5', formData)?.answer === "Yes" && <FormControl className='inline-options' style={{ justifyContent: 'center' }}>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_generalQN_5_1:">{translateThis('generalQN_5_1')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="(If YES, check box to the right)"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_generalQN_5_1', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_generalQN_5_1"
                                                name="c4k_application_sectionOne_form_Key_generalQN_5_1"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_generalQN_5_1',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_generalQN_5_1',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Active Duty U.S. Military" control={<Radio />} label={translateThis("Active Duty U.S. Military")} />
                                                <FormControlLabel value="National Guard Military Reserve" control={<Radio />} label={translateThis("National Guard Military Reserve")} />
                                            </RadioGroup>
                                        </FormControl>}
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionOne_form_Key_generalQN_6:">{translateThis('generalQN_6')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Do you have an impairment that requires an accommodation or extra help completing this application?"
                                                defaultValue={answerGetter('c4k_application_sectionOne_form_Key_generalQN_6', initialFormData)?.answer}
                                                id="c4k_application_sectionOne_form_Key_generalQN_6"
                                                name="c4k_application_sectionOne_form_Key_generalQN_6"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionOne_form_Key_generalQN_6',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionOne_form_Key_generalQN_6',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <TextInput
                                            key={`c4k_application_sectionOne_form_Key_primaryLanguage`}
                                            label={translateThis('c4k_application_sectionOne_form_question_primaryLanguage')}
                                            name='c4k_application_sectionOne_form_question_primaryLanguage'
                                            id='c4k_application_sectionOne_form_question_primaryLanguage'
                                            value={answerGetter('c4k_application_sectionOne_form_question_primaryLanguage', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionOne_form_question_primaryLanguage'
                                            type='text'
                                            placeholder='Primary language spoken in your home'
                                            className='custom-textfield maxWidth_550 minWidth_max'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionOne_form_question_primaryLanguage',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />

                                        <FormGroup className='check-label'>
                                            <FormControlLabel style={{ maxWidth: '100%', justifyContent: 'center' }} control={<Checkbox />} label={
                                                <p>Marque aquí si desea recibir cartas y formularios en español. <span>{translateThis("Check here to receive letters and forms in Spanish")}</span></p>
                                            } />
                                        </FormGroup>

                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>}
                        {currentStep && <div style={{ display: currentStep === 2 ? 'block' : 'none' }} className='C4KAppSectionTwo f-wrp'>
                            <div className='info-head f-wrp'>
                                <h2>{translateThis("SECTION 2 Information on the other parent living in your home")}</h2>
                                <p>{translateThis("You MUST list your spouse, civil union partner or other legal parent of your children")}</p>
                            </div>
                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                                <Grid item xs={12} sm={6} md={6}>
                                    <TextInput
                                        key={`c4k_application_sectionTwo_form_Key_Name`}
                                        label={translateThis('c4k_application_sectionTwo_form_question_Name')}
                                        name='c4k_application_sectionTwo_form_question_Name'
                                        id='c4k_application_sectionTwo_form_question_Name'
                                        value={answerGetter('c4k_application_sectionTwo_form_question_Name', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionTwo_form_question_Name'
                                        type='text'
                                        placeholder={translateThis('c4k_application_sectionTwo_form_question_Name')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionTwo_form_question_Name',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                value={answerGetter('c4k_application_sectionTwo_form_DateOFBirth', initialFormData)?.answer ?
                                                    dayjs(answerGetter('c4k_application_sectionTwo_form_DateOFBirth', initialFormData)?.answer) :
                                                    ''
                                                }
                                                onChange={(v) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionTwo_form_DateOFBirth',
                                                        answer: dayjs(v).format(DATE_FORMAT),
                                                        translationKey: 'c4k_application_sectionTwo_form_DateOFBirth',
                                                        type: 'text'
                                                    })
                                                }
                                                }
                                                label={translateThis('Date of Birth')} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`c4k_application_sectionTwo_form_Key_relationship`}
                                        label={translateThis('c4k_application_sectionTwo_form_question_relationship')}
                                        name='c4k_application_sectionTwo_form_question_relationship'
                                        id='c4k_application_sectionTwo_form_question_relationship'
                                        value={answerGetter('c4k_application_sectionTwo_form_question_relationship', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionTwo_form_question_relationship'
                                        type='text'
                                        required
                                        placeholder='Relationship to Applicant'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionTwo_form_question_relationship',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_application_sectionTwo_form_Key_socialSecurityNumber`}
                                        label={translateThis('c4k_application_sectionTwo_form_question_socialSecurityNumber')}
                                        name='c4k_application_sectionTwo_form_question_socialSecurityNumber'
                                        id='c4k_application_sectionTwo_form_question_socialSecurityNumber'
                                        value={answerGetter('c4k_application_sectionTwo_form_question_socialSecurityNumber', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionTwo_form_question_socialSecurityNumber'
                                        type='number'
                                        placeholder='Social Security Number (optional)'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionTwo_form_question_socialSecurityNumber',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack className='panel-form box-class-form' spacing={3} style={{ border: 'none', padding: '0' }}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionTwo_form_Key_Gender">{translateThis('c4k_application_sectionTwo_form_Key_Gender')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Gender"
                                                defaultValue={answerGetter('c4k_application_sectionTwo_form_Key_Gender', initialFormData)?.answer}
                                                id="c4k_application_sectionTwo_form_Key_Gender"
                                                name="c4k_application_sectionTwo_form_Key_Gender"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionTwo_form_Key_Gender',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionTwo_form_Key_Gender',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="F (Female)" control={<Radio />} label={translateThis("F Female")} />
                                                <FormControlLabel value="M (Male)" control={<Radio />} label={translateThis("M Male")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />


                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionTwo_form_Key_generalQN_1">{translateThis('c4k_application_sectionTwo_form_Key_generalQN_1')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Is this person a parent of a child living in the home?"
                                                defaultValue={answerGetter('c4k_application_sectionTwo_form_Key_generalQN_1', initialFormData)?.answer}
                                                id="c4k_application_sectionTwo_form_Key_generalQN_1"
                                                name="c4k_application_sectionTwo_form_Key_generalQN_1"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionTwo_form_Key_generalQN_1',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionTwo_form_Key_generalQN_1',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                {(answerGetter('c4k_application_sectionTwo_form_Key_generalQN_1', formData)?.answer === "Yes") && <TextInput
                                                    key={`c4k_application_sectionTwo_form_Key_generalQN_1_1`}
                                                    label={translateThis('c4k_application_sectionTwo_form_question_generalQN_1_1')}
                                                    name='c4k_application_sectionTwo_form_question_generalQN_1_1'
                                                    id='c4k_application_sectionTwo_form_question_generalQN_1_1'
                                                    value={answerGetter('c4k_application_sectionTwo_form_question_generalQN_1_1', initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionTwo_form_question_generalQN_1_1'
                                                    type='text'
                                                    placeholder='Name of Child'
                                                    className='custom-textfield maxWidth_550 minWidth_max'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionTwo_form_question_generalQN_1_1',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />}
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionTwo_form_Key_generalQN_5:">{translateThis('generalQN_5')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Is the adult listed above an active member of the United States Military?"
                                                defaultValue={answerGetter('c4k_application_sectionTwo_form_Key_generalQN_5', initialFormData)?.answer}
                                                id="c4k_application_sectionTwo_form_Key_generalQN_5"
                                                name="c4k_application_sectionTwo_form_Key_generalQN_5"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionTwo_form_Key_generalQN_5',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionTwo_form_Key_generalQN_5',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                        {(answerGetter('c4k_application_sectionTwo_form_Key_generalQN_5', formData)?.answer === "Yes") && <FormControl className='inline-options' style={{ justifyContent: 'center' }}>
                                            <FormLabel id="c4k_application_sectionTwo_form_Key_generalQN_5_1:">{translateThis('generalQN_5_1')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="(If YES, check box to the right)"
                                                defaultValue={answerGetter('c4k_application_sectionTwo_form_Key_generalQN_5_1', initialFormData)?.answer}
                                                id="c4k_application_sectionTwo_form_Key_generalQN_5_1"
                                                name="c4k_application_sectionTwo_form_Key_generalQN_5_1"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionTwo_form_Key_generalQN_5_1',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionTwo_form_Key_generalQN_5_1',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Active Duty U.S. Military" control={<Radio />} label={translateThis("Active Duty U.S. Military")} />
                                                <FormControlLabel value="National Guard Military Reserve" control={<Radio />} label={translateThis("National Guard Military Reserve")} />
                                            </RadioGroup>
                                        </FormControl>}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>}

                        {currentStep && <div style={{ display: currentStep === 3 ? 'block' : 'none' }} className='C4KAppSectionThree f-wrp'>

                            <div className='info-head f-wrp'>
                                <h2>{translateThis("SECTION 3 Children information")}</h2>
                                <p>{translateThis("Please list all children under the age of 18 that live in the home")}</p>
                                <div className='info-txt'>
                                    <span><b>{translateThis("KEY A (Asian) B (Black/African Descent) C (White) N (American Indian/Alaskan Native) P (Native Hawaiian/Other Pacific Islander) NA (I prefer not to answer)")}</b></span>
                                </div>
                            </div>
                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                                <Grid item xs={12} sm={6} md={6}>
                                    <TextInput
                                        key={`c4k_application_sectionThree_form_Key_Name`}
                                        label={translateThis('c4k_application_sectionThree_form_question_Name')}
                                        name='c4k_application_sectionThree_form_question_Name'
                                        id='c4k_application_sectionThree_form_question_Name'
                                        value={answerGetter('c4k_application_sectionThree_form_question_Name', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionThree_form_question_Name'
                                        type='text'
                                        placeholder={translateThis('c4k_application_sectionThree_form_question_Name')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionThree_form_question_Name',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                value={answerGetter('c4k_application_sectionThree_form_DateOFBirth', initialFormData)?.answer ?
                                                    dayjs(answerGetter('c4k_application_sectionThree_form_DateOFBirth', initialFormData)?.answer) :
                                                    ''
                                                }
                                                onChange={(v) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionThree_form_DateOFBirth',
                                                        answer: dayjs(v).format(DATE_FORMAT),
                                                        translationKey: 'c4k_application_sectionThree_form_DateOFBirth',
                                                        type: 'text'
                                                    })
                                                }
                                                }
                                                label={translateThis("Date of Birth")} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <TextInput
                                        key={`c4k_application_sectionThree_form_Key_relationship`}
                                        label={translateThis('c4k_application_sectionThree_form_question_relationship')}
                                        name='c4k_application_sectionThree_form_question_relationship'
                                        id='c4k_application_sectionThree_form_question_relationship'
                                        value={answerGetter('c4k_application_sectionThree_form_question_relationship', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionThree_form_question_relationship'
                                        type='text'
                                        required
                                        placeholder={translateThis('c4k_application_sectionThree_form_question_relationship')}
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionThree_form_question_relationship',
                                                type: 'text'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextInput
                                        key={`c4k_application_sectionThree_form_Key_socialSecurityNumber`}
                                        label={translateThis('c4k_application_sectionThree_form_question_socialSecurityNumber')}
                                        name='c4k_application_sectionThree_form_question_socialSecurityNumber'
                                        id='c4k_application_sectionThree_form_question_socialSecurityNumber'
                                        value={answerGetter('c4k_application_sectionThree_form_question_socialSecurityNumber', initialFormData)?.answer}
                                        translationKey='c4k_application_sectionThree_form_question_socialSecurityNumber'
                                        type='number'
                                        placeholder='Social Security Number (optional)'
                                        className='custom-textfield'
                                        getValue={(name, value) => {
                                            setFormValue({
                                                questionId: name,
                                                answer: value,
                                                translationKey: 'c4k_application_sectionThree_form_question_socialSecurityNumber',
                                                type: 'number'
                                            })
                                        }}
                                        useDefaultValidation
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack className='panel-form box-class-form' spacing={3}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionThree_form_Key_careNeeded">{translateThis('c4k_application_sectionThree_form_Key_careNeeded')}:</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Child Care Needed?"
                                                defaultValue={answerGetter('c4k_application_sectionThree_form_Key_careNeeded', initialFormData)?.answer}
                                                id="c4k_application_sectionThree_form_Key_careNeeded"
                                                name="c4k_application_sectionThree_form_Key_careNeeded"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionThree_form_Key_careNeeded',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionThree_form_Key_careNeeded',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                        {(answerGetter('c4k_application_sectionThree_form_Key_careNeeded', formData)?.answer === "Yes") && <>
                                            <FormControl className='inline-options'>
                                                <FormLabel id="c4k_application_sectionThree_form_Key_Gender">{translateThis('c4k_application_sectionThree_form_Key_Gender')}:</FormLabel>
                                                <RadioGroup row
                                                    aria-labelledby="Gender"
                                                    defaultValue={answerGetter('c4k_application_sectionThree_form_Key_Gender', initialFormData)?.answer}
                                                    id="c4k_application_sectionThree_form_Key_Gender"
                                                    name="c4k_application_sectionThree_form_Key_Gender"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionThree_form_Key_Gender',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionThree_form_Key_Gender',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="F (Female)" control={<Radio />} label={translateThis("F Female")} />
                                                    <FormControlLabel value="M (Male)" control={<Radio />} label={translateThis("M Male")} />
                                                </RadioGroup>
                                            </FormControl>
                                            <br />
                                            <FormControl className='inline-options'>
                                                <FormLabel className='main-head' id="c4k_application_sectionThree_form_Key_race">{translateThis('c4k_application_sectionThree_form_Key_race')}</FormLabel>
                                                <RadioGroup row
                                                    aria-labelledby="Marital Status"
                                                    defaultValue={answerGetter('c4k_application_sectionThree_form_race', initialFormData)?.answer}
                                                    id="c4k_application_sectionThree_form_race"
                                                    name="c4k_application_sectionThree_form_race"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionThree_form_race',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionThree_form_race',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="A (Asian)" control={<Radio />} label={translateThis("A Asian")} />
                                                    <FormControlLabel value="B (Black/African)" control={<Radio />} label={translateThis("B BlackAfrican")} />
                                                    <FormControlLabel value="C (White)" control={<Radio />} label={translateThis("C White")} />
                                                    <FormControlLabel value="N (American Indian/Alaska Native)" control={<Radio />} label={translateThis("N American IndianAlaska Native")} />
                                                    <FormControlLabel value="P (Native Hawaiian/Other Pacific Islander)" control={<Radio />} label={translateThis("P Native HawaiianOther Pacific Islander")} />
                                                    &nbsp;&nbsp;<FormControlLabel value="I prefer not to answer" control={<Radio />} label={translateThis("I prefer not to answer")} />
                                                </RadioGroup>
                                            </FormControl>
                                            <br />
                                            <FormControl className='inline-options'>
                                                <FormLabel id="c4k_application_sectionThree_form_Key_hispanicLatino:">{translateThis('hispanicLatino')}</FormLabel>
                                                <RadioGroup row
                                                    aria-labelledby="Hispanic Latino"
                                                    defaultValue={answerGetter('c4k_application_sectionThree_form_Key_hispanicLatino', initialFormData)?.answer}
                                                    id="c4k_application_sectionThree_form_Key_hispanicLatino"
                                                    name="c4k_application_sectionThree_form_Key_hispanicLatino"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionThree_form_Key_hispanicLatino',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionThree_form_Key_hispanicLatino',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                    <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                    <FormControlLabel value="NA" control={<Radio />} label={translateThis("NA")} />
                                                </RadioGroup>
                                            </FormControl>
                                            <br />
                                            <FormControl className='inline-options'>
                                                <FormLabel id="c4k_application_sectionThree_form_Key_Citizenship:">{translateThis('c4k_application_sectionThree_form_Key_Citizenship')}</FormLabel>
                                                <RadioGroup row
                                                    aria-labelledby="Citizenship Status?"
                                                    defaultValue={answerGetter('c4k_application_sectionThree_form_Key_Citizenship', initialFormData)?.answer}
                                                    id="c4k_application_sectionThree_form_Key_Citizenship"
                                                    name="c4k_application_sectionThree_form_Key_Citizenship"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionThree_form_Key_Citizenship',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionThree_form_Key_Citizenship',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Citizen" control={<Radio />} label={translateThis("Citizen")} />
                                                    <FormControlLabel value="Permanent Resident" control={<Radio />} label={translateThis("Permanent Resident")} />
                                                    <FormControlLabel value="Other" control={<Radio />} label={translateThis("Other")} />
                                                </RadioGroup>
                                            </FormControl>
                                            <br />
                                            <FormControl className='inline-options'>
                                                <FormLabel id="c4k_application_sectionThree_form_Key_Shots">{translateThis('c4k_application_sectionThree_form_Key_Shots')}</FormLabel>
                                                <RadioGroup row
                                                    aria-labelledby="Is child up to date with shots? "
                                                    defaultValue={answerGetter('c4k_application_sectionThree_form_Key_Shots', initialFormData)?.answer}
                                                    id="c4k_application_sectionThree_form_Key_Shots"
                                                    name="c4k_application_sectionThree_form_Key_Shots"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionThree_form_Key_Shots',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionThree_form_Key_Shots',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                    <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </>}
                                    </Stack>
                                </Grid>
                                {(answerGetter('c4k_application_sectionThree_form_Key_careNeeded', formData)?.answer === "Yes") && <>
                                    {extraChildren.map((no, i) => (
                                        <Grid key={`child-no-${i}`} item xs={12} sm={12} md={12}>
                                            <Stack className='panel-form box-class-form' spacing={3}>
                                                <Grid className='flex-row' item xs={12} sm={12} md={12}>
                                                    <div className='info-head f-wrp'>
                                                        <h3>Child #{i + 2}</h3>
                                                    </div>
                                                    <IconButton
                                                        onClick={() => onDeleteFormExtraSection(
                                                            'c4k_application_sectionThree_form_extraChildren',
                                                            extraChildren,
                                                            setExtraChildren,
                                                            setFormValue,
                                                            no
                                                        )}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Grid>

                                                <FormControl className='inline-options'>
                                                    <FormLabel id="c4k_application_sectionThree_form_Key_Gender">{translateThis('c4k_application_sectionThree_form_Key_Gender')}:</FormLabel>
                                                    <RadioGroup row
                                                        aria-labelledby="Gender"
                                                        defaultValue={answerGetter(`c4k_application_sectionThree_form_${no}_Gender`, initialFormData)?.answer}
                                                        id="c4k_application_sectionThree_form_Key_Gender"
                                                        name={`c4k_application_sectionThree_form_${no}_Gender`}
                                                        onChange={(e) => {
                                                            setFormValue({
                                                                questionId: `c4k_application_sectionThree_form_${no}_Gender`,
                                                                answer: e.target.value,
                                                                translationKey: 'c4k_application_sectionThree_form_Key_Gender',
                                                                type: 'radio'
                                                            })
                                                        }}
                                                    >
                                                        <FormControlLabel value="F (Female)" control={<Radio />} label={translateThis("F Female")} />
                                                        <FormControlLabel value="M (Male)" control={<Radio />} label={translateThis("M Male")} />
                                                    </RadioGroup>
                                                </FormControl>
                                                <br />
                                                <FormControl className='inline-options'>
                                                    <FormLabel className='main-head' id="c4k_application_sectionThree_form_Key_race">{translateThis('c4k_application_sectionThree_form_Key_race')}</FormLabel>
                                                    <RadioGroup row
                                                        aria-labelledby="Race Status"
                                                        defaultValue={answerGetter(`c4k_application_sectionThree_form_${no}_race`, initialFormData)?.answer}
                                                        id="c4k_application_sectionThree_form_race"
                                                        name={`c4k_application_sectionThree_form_${no}_race`}
                                                        onChange={(e) => {
                                                            setFormValue({
                                                                questionId: `c4k_application_sectionThree_form_${no}_race`,
                                                                answer: e.target.value,
                                                                translationKey: 'c4k_application_sectionThree_form_race',
                                                                type: 'radio'
                                                            })
                                                        }}
                                                    >
                                                        <FormControlLabel value="A (Asian)" control={<Radio />} label={translateThis("A Asian")} />
                                                        <FormControlLabel value="B (Black/African)" control={<Radio />} label={translateThis("B BlackAfrican")} />
                                                        <FormControlLabel value="C (White)" control={<Radio />} label={translateThis("C White")} />
                                                        <FormControlLabel value="N (American Indian/Alaska Native)" control={<Radio />} label={translateThis("N American IndianAlaska Native")} />
                                                        <FormControlLabel value="P (Native Hawaiian/Other Pacific Islander)" control={<Radio />} label={translateThis("P Native HawaiianOther Pacific Islander")} />
                                                        &nbsp;&nbsp;<FormControlLabel value="I prefer not to answer" control={<Radio />} label={translateThis("I prefer not to answer")} />
                                                    </RadioGroup>
                                                </FormControl>
                                                <br />
                                                <FormControl className='inline-options'>
                                                    <FormLabel id="c4k_application_sectionThree_form_Key_hispanicLatino:">{translateThis('hispanicLatino')}</FormLabel>
                                                    <RadioGroup row
                                                        aria-labelledby="Hispanic Latino"
                                                        defaultValue={answerGetter(`c4k_application_sectionThree_form_${no}_hispanicLatino`, initialFormData)?.answer}
                                                        id="c4k_application_sectionThree_form_Key_hispanicLatino"
                                                        name={`c4k_application_sectionThree_form_${no}_hispanicLatino`}
                                                        onChange={(e) => {
                                                            setFormValue({
                                                                questionId: `c4k_application_sectionThree_form_${no}_hispanicLatino`,
                                                                answer: e.target.value,
                                                                translationKey: 'c4k_application_sectionThree_form_Key_hispanicLatino',
                                                                type: 'radio'
                                                            })
                                                        }}
                                                    >
                                                        <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                        <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                        <FormControlLabel value="NA" control={<Radio />} label={translateThis("NA")} />
                                                    </RadioGroup>
                                                </FormControl>
                                                <br />
                                                <FormControl className='inline-options'>
                                                    <FormLabel id="c4k_application_sectionThree_form_Key_Citizenship:">{translateThis('c4k_application_sectionThree_form_Key_Citizenship')}</FormLabel>
                                                    <RadioGroup row
                                                        aria-labelledby="Citizenship Status?"
                                                        defaultValue={answerGetter(`c4k_application_sectionThree_form_${no}_Citizenship`, initialFormData)?.answer}
                                                        id="c4k_application_sectionThree_form_Key_Citizenship"
                                                        name={`c4k_application_sectionThree_form_${no}_Citizenship`}
                                                        onChange={(e) => {
                                                            setFormValue({
                                                                questionId: `c4k_application_sectionThree_form_${no}_Citizenship`,
                                                                answer: e.target.value,
                                                                translationKey: 'c4k_application_sectionThree_form_Key_Citizenship',
                                                                type: 'radio'
                                                            })
                                                        }}
                                                    >
                                                        <FormControlLabel value="Citizen" control={<Radio />} label={translateThis("Citizen")} />
                                                        <FormControlLabel value="Permanent Resident" control={<Radio />} label={translateThis("Permanent Resident")} />
                                                        <FormControlLabel value="Other" control={<Radio />} label={translateThis("Other")} />
                                                    </RadioGroup>
                                                </FormControl>
                                                <br />
                                                <FormControl className='inline-options'>
                                                    <FormLabel id="c4k_application_sectionThree_form_Key_Shots">{translateThis('c4k_application_sectionThree_form_Key_Shots')}</FormLabel>
                                                    <RadioGroup row
                                                        aria-labelledby="Is child up to date with shots? "
                                                        defaultValue={answerGetter(`c4k_application_sectionThree_form_${no}_Shots`, initialFormData)?.answer}
                                                        id="c4k_application_sectionThree_form_Key_Shots"
                                                        name={`c4k_application_sectionThree_form_${no}_Shots`}
                                                        onChange={(e) => {
                                                            setFormValue({
                                                                questionId: `c4k_application_sectionThree_form_${no}_Shots`,
                                                                answer: e.target.value,
                                                                translationKey: 'c4k_application_sectionThree_form_Key_Shots',
                                                                type: 'radio'
                                                            })
                                                        }}
                                                    >
                                                        <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                        <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                    </RadioGroup>
                                                </FormControl>

                                            </Stack>
                                        </Grid>))}




                                </>}



                                {(answerGetter('c4k_application_sectionThree_form_Key_careNeeded', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={12}>
                                    <Button onClick={() => onAddFormExtraSection(
                                        'c4k_application_sectionThree_form_extraChildren',
                                        extraChildren,
                                        setExtraChildren,
                                        setFormValue
                                    )} className='red-btn'>{translateThis("Add new Child")}</Button>
                                </Grid>}
                                <Grid item xs={12} sm={12} md={12}>
                                    <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px', paddingTop: '0' }}>
                                        <Grid item xs={12} sm={12} md={(answerGetter('c4k_application_sectionThree_form_QN_1', formData)?.answer === "Yes") ? 6 : 12}>
                                            <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                <FormControl className='inline-options yesNo-option'>
                                                    <FormLabel id="c4k_application_sectionThree_form_QN_1">{translateThis('c4k_application_sectionThree_form_QN_1')}</FormLabel>
                                                    <RadioGroup row
                                                        aria-labelledby="Do any of the children listed above have special needs? "
                                                        defaultValue={answerGetter('c4k_application_sectionThree_form_QN_1', initialFormData)?.answer}
                                                        id="c4k_application_sectionThree_form_QN_1"
                                                        name="c4k_application_sectionThree_form_QN_1"
                                                        onChange={(e) => {
                                                            setFormValue({
                                                                questionId: 'c4k_application_sectionThree_form_QN_1',
                                                                answer: e.target.value,
                                                                translationKey: 'c4k_application_sectionThree_form_QN_1',
                                                                type: 'radio'
                                                            })
                                                        }}
                                                    >
                                                        <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                        <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Stack>
                                        </Grid>
                                        {(answerGetter('c4k_application_sectionThree_form_QN_1', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={6}>
                                            <Stack className='panel-form box-class-form inline-box' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                <TextInput
                                                    key={`c4k_application_sectionThree_form_Key_QN_2`}
                                                    label={translateThis('c4k_application_sectionThree_form_QN_2')}
                                                    name='c4k_application_sectionThree_form_QN_2'
                                                    id='c4k_application_sectionThree_form_QN_2'
                                                    value={answerGetter('c4k_application_sectionThree_form_QN_2', initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionThree_form_QN_2'
                                                    type='text'
                                                    placeholder={translateThis('c4k_application_sectionThree_form_QN_2')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionThree_form_QN_2',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Stack>
                                        </Grid>}
                                        <Grid item xs={12} sm={12} md={(answerGetter('c4k_application_sectionThree_form_QN_3', formData)?.answer === "Yes")? 7 : 12}>
                                            <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                <FormControl className='inline-options yesNo-option'>
                                                    <FormLabel id="c4k_application_sectionThree_form_QN_3">{translateThis('c4k_application_sectionThree_form_QN_3')}</FormLabel>
                                                    <RadioGroup row
                                                        aria-labelledby="Do you share joint custody with any of the children listed above?"
                                                        defaultValue={answerGetter('c4k_application_sectionThree_form_QN_3', initialFormData)?.answer}
                                                        id="c4k_application_sectionThree_form_QN_3"
                                                        name="c4k_application_sectionThree_form_QN_3"
                                                        onChange={(e) => {
                                                            setFormValue({
                                                                questionId: 'c4k_application_sectionThree_form_QN_3',
                                                                answer: e.target.value,
                                                                translationKey: 'c4k_application_sectionThree_form_QN_3',
                                                                type: 'radio'
                                                            })
                                                        }}
                                                    >
                                                        <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                        <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Stack>
                                        </Grid>
                                        {(answerGetter('c4k_application_sectionThree_form_QN_3', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={5}>
                                            <Stack className='panel-form box-class-form inline-box' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                <TextInput
                                                    key={`c4k_application_sectionThree_form_Key_QN_4`}
                                                    label={translateThis('c4k_application_sectionThree_form_QN_4')}
                                                    name='c4k_application_sectionThree_form_QN_4'
                                                    id='c4k_application_sectionThree_form_QN_4'
                                                    value={answerGetter('c4k_application_sectionThree_form_QN_4', initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionThree_form_QN_4'
                                                    type='text'
                                                    placeholder={translateThis('c4k_application_sectionThree_form_QN_4')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionThree_form_QN_4',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Stack>
                                        </Grid>}
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                        <FormControl className='inline-options'>
                                            <FormLabel id="c4k_application_sectionThree_form_QN_5">{translateThis('c4k_application_sectionThree_form_QN_5')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Do any of the children listed above have their own children living in your home?"
                                                defaultValue={answerGetter('c4k_application_sectionThree_form_QN_5', initialFormData)?.answer}
                                                id="c4k_application_sectionThree_form_QN_5"
                                                name="c4k_application_sectionThree_form_QN_5"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionThree_form_QN_5',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionThree_form_QN_5',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                </Grid>

                                {(answerGetter('c4k_application_sectionThree_form_QN_5', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={12}>
                                    <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                        <FormLabel style={{ paddingBottom: '0', color: '#B4AC9C' }} id="c4k_application_sectionThree_form_QN_6Label">{translateThis('c4k_application_sectionThree_form_QN_6Label')}</FormLabel>
                                        <FormControl className='inline-options'>
                                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                                <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                                                    <TextInput
                                                        key={`c4k_application_sectionThree_form_Key_QN_6`}
                                                        label={translateThis('c4k_application_sectionThree_form_QN_6')}
                                                        name='c4k_application_sectionThree_form_QN_6'
                                                        id='c4k_application_sectionThree_form_QN_6'
                                                        value={answerGetter('c4k_application_sectionThree_form_QN_6', initialFormData)?.answer}
                                                        translationKey='c4k_application_sectionThree_form_QN_6'
                                                        type='text'
                                                        required
                                                        placeholder='Parent(s) Under Age 18'
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_application_sectionThree_form_QN_6',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                                                    <TextInput
                                                        key={`c4k_application_sectionThree_form_Key_QN_7`}
                                                        label={translateThis('c4k_application_sectionThree_form_QN_7')}
                                                        name='c4k_application_sectionThree_form_QN_7'
                                                        id='c4k_application_sectionThree_form_QN_7'
                                                        value={answerGetter('c4k_application_sectionThree_form_QN_7', initialFormData)?.answer}
                                                        translationKey='c4k_application_sectionThree_form_QN_7'
                                                        type='text'
                                                        placeholder={translateThis('c4k_application_sectionThree_form_QN_7')}
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_application_sectionThree_form_QN_7',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </Grid>
                                            </Grid>


                                        </FormControl>
                                    </Stack>
                                    {underAgeParents.map((no, i) => (
                                        <Grid key={`underAgeParents-key-${i}`} container spacing={3} style={{ marginLeft: '0px', marginTop: '0px' }}>
                                            <Grid className='flex-row' item xs={12} sm={12} md={12}>
                                                <div className='info-head f-wrp' />
                                                <IconButton
                                                    onClick={() => onDeleteFormExtraSection(
                                                        'c4k_application_sectionThree_form_underAgeParents',
                                                        underAgeParents,
                                                        setUnderAgeParents,
                                                        setFormValue,
                                                        no
                                                    )}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Grid>
                                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                                <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                                                    <TextInput
                                                        key={`c4k_application_sectionThree_form_Key_QN_6`}
                                                        label={translateThis('c4k_application_sectionThree_form_QN_6')}
                                                        name={`c4k_application_sectionThree_form_${no}_parentName`}
                                                        id='c4k_application_sectionThree_form_QN_6'
                                                        value={answerGetter(`c4k_application_sectionThree_form_${no}_parentName`, initialFormData)?.answer}
                                                        translationKey='c4k_application_sectionThree_form_QN_6'
                                                        type='text'
                                                        required
                                                        placeholder={translateThis('c4k_application_sectionThree_form_QN_6')}
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_application_sectionThree_form_QN_6',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} style={{ paddingTop: '0' }}>
                                                    <TextInput
                                                        key={`c4k_application_sectionThree_form_Key_QN_7`}
                                                        label={translateThis('c4k_application_sectionThree_form_QN_7')}
                                                        name={`c4k_application_sectionThree_form_${no}_childName`}
                                                        id='c4k_application_sectionThree_form_QN_7'
                                                        value={answerGetter(`c4k_application_sectionThree_form_${no}_childName`, initialFormData)?.answer}
                                                        translationKey='c4k_application_sectionThree_form_QN_7'
                                                        type='text'
                                                        placeholder={translateThis('c4k_application_sectionThree_form_QN_7')}
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_application_sectionThree_form_QN_7',
                                                                type: 'text'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </Grid>
                                            </Grid>



                                        </Grid>
                                    ))}
                                </Grid>}
                                {(answerGetter('c4k_application_sectionThree_form_QN_5', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={12}>
                                    <Button onClick={() => onAddFormExtraSection(
                                        'c4k_application_sectionThree_form_underAgeParents',
                                        underAgeParents,
                                        setUnderAgeParents,
                                        setFormValue
                                    )} className='red-btn'>{translateThis("Add new Parent under age 18 +")}</Button>
                                </Grid>}
                            </Grid>
                        </div>}
                        {currentStep && <div style={{ display: currentStep === 4 ? 'block' : 'none' }} className='C4KAppSectionFour f-wrp'>

                            <div className='info-head f-wrp'>
                                <h2>{translateThis("SECTION 4: Work/training activity and income information")}</h2>
                                <p>{translateThis("Fill out the information below for all parents in the home.")} <b>{translateThis("If there are more than 2 activities, make a copy of this page")}</b></p>
                            </div>

                            <Stack className='panel-form box-class-form' spacing={1}>
                                <div className='info-txt'>
                                    <p>{translateThis("Complete the following information about your work/training activity.")}</p>
                                </div>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '0' }}>

                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_1`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_1')}
                                            name='c4k_application_sectionFour_form_QN_1'
                                            id='c4k_application_sectionFour_form_QN_1'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_1', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_1'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFour_form_QN_1')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_1',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl className='inline-options mob-title-fix' style={{ background: 'transparent' }}>
                                            <FormLabel className='main-head' id="c4k_application_sectionFour_form_QN_2" style={{ transform: 'none', whiteSpace: 'nowrap' }}>{translateThis('c4k_application_sectionFour_form_QN_2')}</FormLabel>

                                            <FormGroup row
                                                aria-labelledby="Type of Activity"
                                                id="c4k_application_sectionFour_form_QN_2"
                                                name="c4k_application_sectionFour_form_QN_2"
                                                onChange={(e) => {
                                                    onSelectAccreditedArr(e.target.value, e.target.checked, 'c4k_application_sectionFour_form_QN_2');
                                                }}
                                            >
                                                <FormControlLabel value="Work" control={<Checkbox defaultChecked={answerGetter('c4k_application_sectionFour_form_QN_2', initialFormData)?.rawAnswer.includes('Work')} />} label={translateThis("Work")} />
                                                <FormControlLabel value="High School" control={<Checkbox defaultChecked={answerGetter('c4k_application_sectionFour_form_QN_2', initialFormData)?.rawAnswer.includes('High School')} />} label={translateThis("Work")} />
                                                <FormControlLabel value="Self-Employed" control={<Checkbox defaultChecked={answerGetter('c4k_application_sectionFour_form_QN_2', initialFormData)?.rawAnswer.includes('Self-Employed')} />} label={translateThis("Self-Employed")} />
                                                <FormControlLabel value="Training or Education approved by JFES" control={<Checkbox defaultChecked={answerGetter('c4k_application_sectionFour_form_QN_2', initialFormData)?.rawAnswer.includes('Training or Education approved by JFES')} />} label={translateThis("Training or Education approved by JFES")} />
                                                <FormControlLabel value="Higher Education" control={<Checkbox defaultChecked={answerGetter('c4k_application_sectionFour_form_QN_2', initialFormData)?.rawAnswer.includes('Higher Education')} />} label={translateThis("Higher Education ")} />
                                                <FormControlLabel value="GED/Adult Education" control={<Checkbox defaultChecked={answerGetter('c4k_application_sectionFour_form_QN_2', initialFormData)?.rawAnswer.includes('GED/Adult Education')} />} label={translateThis("GED/Adult Education ")} />
                                                <FormControlLabel value="Workforce Development/Training program" control={<Checkbox defaultChecked={answerGetter('c4k_application_sectionFour_form_QN_2', initialFormData)?.rawAnswer.includes('Workforce Development/Training program')} />} label={translateThis("Workforce Development/Training program ")} />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} >

                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_3`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_3')}
                                            name='c4k_application_sectionFour_form_QN_3'
                                            id='c4k_application_sectionFour_form_QN_3'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_3', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_3'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFour_form_QN_3')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_3',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} >

                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_4`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_4')}
                                            name='c4k_application_sectionFour_form_QN_4'
                                            id='c4k_application_sectionFour_form_QN_4'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_4', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_4'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFour_form_QN_4')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_4',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                        <i style={{ fontSize: '12px', fontStyle: 'normal', color: '#98948A' }}>{translateThis("I.e. retail, construction, real estate, contractor, etc.")}</i>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} >
                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_5`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_5')}
                                            name='c4k_application_sectionFour_form_QN_5'
                                            id='c4k_application_sectionFour_form_QN_5'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_5', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_5'
                                            type='text'
                                            placeholder='Address'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_5',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={2} >
                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_6`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_6')}
                                            name='c4k_application_sectionFour_form_QN_6'
                                            id='c4k_application_sectionFour_form_QN_6'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_6', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_6'
                                            type='text'
                                            placeholder='City'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_6',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2} >
                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_7`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_7')}
                                            name='c4k_application_sectionFour_form_QN_7'
                                            id='c4k_application_sectionFour_form_QN_7'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_7', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_7'
                                            type='text'
                                            placeholder='State'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_7',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2} >
                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_8`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_8')}
                                            name='c4k_application_sectionFour_form_QN_8'
                                            id='c4k_application_sectionFour_form_QN_8'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_8', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_8'
                                            type='text'
                                            placeholder='Zipcode'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_8',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    value={answerGetter('c4k_application_sectionFour_form_QN_9', initialFormData)?.answer ?
                                                        dayjs(answerGetter('c4k_application_sectionFour_form_QN_9', initialFormData)?.answer) :
                                                        ''
                                                    }
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionFour_form_QN_9',
                                                            answer: dayjs(v).format(DATE_FORMAT),
                                                            translationKey: 'c4k_application_sectionFour_form_QN_9',
                                                            type: 'text'
                                                        })
                                                    }
                                                    }
                                                    label={translateThis("Start Date")} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_10`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_10')}
                                            name='c4k_application_sectionFour_form_QN_10'
                                            id='c4k_application_sectionFour_form_QN_10'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_10', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_10'
                                            type='number'
                                            placeholder='Phone'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_10',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_11`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_11')}
                                            name='c4k_application_sectionFour_form_QN_11'
                                            id='c4k_application_sectionFour_form_QN_11'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_11', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_11'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFour_form_QN_11')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_11',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} >
                                        <TextInput
                                            key={`c4k_application_sectionFour_form_Key_QN_12`}
                                            label={translateThis('c4k_application_sectionFour_form_QN_12')}
                                            name='c4k_application_sectionFour_form_QN_12'
                                            id='c4k_application_sectionFour_form_QN_12'
                                            value={answerGetter('c4k_application_sectionFour_form_QN_12', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFour_form_QN_12'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFour_form_QN_12')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFour_form_QN_12',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px', paddingTop: '0' }}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <FormControl className='inline-options mob-title-fix'>
                                                        <FormLabel id="c4k_application_sectionFour_form_QN_13">{translateThis('c4k_application_sectionFour_form_QN_13')}</FormLabel>
                                                        <RadioGroup row
                                                            aria-labelledby="How frequently do you get paid?"
                                                            defaultValue={answerGetter('c4k_application_sectionFour_form_QN_13', initialFormData)?.answer}
                                                            id="c4k_application_sectionFour_form_QN_13"
                                                            name="c4k_application_sectionFour_form_QN_13"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'c4k_application_sectionFour_form_QN_13',
                                                                    answer: e.target.value,
                                                                    translationKey: 'c4k_application_sectionFour_form_QN_13',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >
                                                            <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                            <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                            <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                            <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={7}>
                                                <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <FormLabel id="c4k_application_sectionFour_form_QN_14" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFour_form_QN_14')}</FormLabel>
                                                    <FormControl className='inline-options'>

                                                        <RadioGroup row
                                                            aria-labelledby="How much do you get paid before taxes are deducted (gross income)?"
                                                            defaultValue={answerGetter('c4k_application_sectionFour_form_QN_14', initialFormData)?.answer}
                                                            id="c4k_application_sectionFour_form_QN_14"
                                                            name="c4k_application_sectionFour_form_QN_14"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'c4k_application_sectionFour_form_QN_14',
                                                                    answer: e.target.value,
                                                                    translationKey: 'c4k_application_sectionFour_form_QN_14',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >
                                                            <FormControlLabel value="Hourly" control={<Radio />} label={translateThis("Hourly")} />
                                                            <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                            <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                            <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                            <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                            <FormControlLabel value="Annually" control={<Radio />} label={translateThis("Annually")} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                                <Stack className='panel-form box-class-form inline-box' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <TextInput
                                                        key={`c4k_application_sectionFour_form_Key_QN_15`}
                                                        label={translateThis('c4k_application_sectionFour_form_QN_15')}
                                                        name='c4k_application_sectionFour_form_QN_15'
                                                        id='c4k_application_sectionFour_form_QN_15'
                                                        value={answerGetter('c4k_application_sectionFour_form_QN_15', initialFormData)?.answer}
                                                        translationKey='c4k_application_sectionFour_form_QN_15'
                                                        type='number'
                                                        placeholder='Amount'
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_application_sectionFour_form_QN_15',
                                                                type: 'number'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </Stack>

                                            </Grid>


                                            <Grid item xs={12} sm={12} md={9}>
                                                <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <FormLabel id="c4k_application_sectionFour_form_QN_16" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFour_form_QN_16')}</FormLabel>
                                                    <FormControl className='inline-options'>

                                                        <RadioGroup row
                                                            aria-labelledby="If you are self-employed, how much do you get paid before taxes and expenses are deducted (gross income)?"
                                                            defaultValue={answerGetter('c4k_application_sectionFour_form_QN_16', initialFormData)?.answer}
                                                            id="c4k_application_sectionFour_form_QN_16"
                                                            name="c4k_application_sectionFour_form_QN_16"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'c4k_application_sectionFour_form_QN_16',
                                                                    answer: e.target.value,
                                                                    translationKey: 'c4k_application_sectionFour_form_QN_16',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >
                                                            <FormControlLabel value="Hourly" control={<Radio />} label={translateThis("Hourly")} />
                                                            <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                            <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                            <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                            <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                            <FormControlLabel value="Annually" control={<Radio />} label={translateThis("Annually")} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3}>
                                                <Stack className='panel-form box-class-form inline-box' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <TextInput
                                                        key={`c4k_application_sectionFour_form_Key_QN_17`}
                                                        label={translateThis('c4k_application_sectionFour_form_QN_17')}
                                                        name='c4k_application_sectionFour_form_QN_17'
                                                        id='c4k_application_sectionFour_form_QN_17'
                                                        value={answerGetter('c4k_application_sectionFour_form_QN_17', initialFormData)?.answer}
                                                        translationKey='c4k_application_sectionFour_form_QN_17'
                                                        type='number'
                                                        placeholder='Amount'
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_application_sectionFour_form_QN_17',
                                                                type: 'number'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </Stack>

                                            </Grid>
                                            <Grid item xs={12} sm={12} md={7}>
                                                <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <FormLabel id="c4k_application_sectionFour_form_QN_18" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFour_form_QN_18')}</FormLabel>
                                                    <FormControl className='inline-options'>

                                                        <RadioGroup row
                                                            aria-labelledby="If you are self-employed, what are your expenses (dollar amount)?"
                                                            defaultValue={answerGetter('c4k_application_sectionFour_form_QN_18', initialFormData)?.answer}
                                                            id="c4k_application_sectionFour_form_QN_18"
                                                            name="c4k_application_sectionFour_form_QN_18"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'c4k_application_sectionFour_form_QN_18',
                                                                    answer: e.target.value,
                                                                    translationKey: 'c4k_application_sectionFour_form_QN_18',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >
                                                            <FormControlLabel value="Hourly" control={<Radio />} label={translateThis("Hourly")} />
                                                            <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                            <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                            <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                            <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                            <FormControlLabel value="Annually" control={<Radio />} label={translateThis("Annually")} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5}>
                                                <Stack className='panel-form box-class-form inline-box' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <TextInput
                                                        key={`c4k_application_sectionFour_form_Key_QN_19`}
                                                        label={translateThis('c4k_application_sectionFour_form_QN_19')}
                                                        name='c4k_application_sectionFour_form_QN_19'
                                                        id='c4k_application_sectionFour_form_QN_19'
                                                        value={answerGetter('c4k_application_sectionFour_form_QN_19', initialFormData)?.answer}
                                                        translationKey='c4k_application_sectionFour_form_QN_19'
                                                        type='number'
                                                        placeholder='Amount'
                                                        className='custom-textfield'
                                                        getValue={(name, value) => {
                                                            setFormValue({
                                                                questionId: name,
                                                                answer: value,
                                                                translationKey: 'c4k_application_sectionFour_form_QN_19',
                                                                type: 'number'
                                                            })
                                                        }}
                                                        useDefaultValidation
                                                    />
                                                </Stack>

                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <FormControl className='inline-options mob-title-fix'>
                                                        <FormLabel id="c4k_application_sectionFour_form_QN_20">{translateThis('c4k_application_sectionFour_form_QN_20')}</FormLabel>
                                                        <RadioGroup row
                                                            aria-labelledby="Daily roundtrip commute from child care setting to work/activity?"
                                                            defaultValue={answerGetter('c4k_application_sectionFour_form_QN_20', initialFormData)?.answer}
                                                            id="c4k_application_sectionFour_form_QN_20"
                                                            name="c4k_application_sectionFour_form_QN_20"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'c4k_application_sectionFour_form_QN_20',
                                                                    answer: e.target.value,
                                                                    translationKey: 'c4k_application_sectionFour_form_QN_20',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >
                                                            <FormControlLabel value="None" control={<Radio />} label={translateThis("None")} />
                                                            <FormControlLabel value="1-30 minutes" control={<Radio />} label={translateThis("1-30 minutes")} />
                                                            <FormControlLabel value="31-60 minutes" control={<Radio />} label={translateThis("31-60 minutes")} />
                                                            <FormControlLabel value="More than 60 minutes" control={<Radio />} label={translateThis("More than 60 minutes")} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <FormControl className='inline-options mob-title-fix'>
                                                        <FormLabel id="c4k_application_sectionFour_form_QN_21">{translateThis('c4k_application_sectionFour_form_QN_21')}</FormLabel>
                                                        <RadioGroup row
                                                            aria-labelledby="Daily roundtrip commute from child care setting to work/activity?"
                                                            defaultValue={answerGetter('c4k_application_sectionFour_form_QN_21', initialFormData)?.answer}
                                                            id="c4k_application_sectionFour_form_QN_21"
                                                            name="c4k_application_sectionFour_form_QN_21"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'c4k_application_sectionFour_form_QN_21',
                                                                    answer: e.target.value,
                                                                    translationKey: 'c4k_application_sectionFour_form_QN_21',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >
                                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                    <FormControl className='inline-options whiteSpace'>
                                                        <FormGroup row>

                                                            <FormControlLabel value="c4k_application_sectionFour_form_QN_22" control={<Checkbox />} label={
                                                                <span id="c4k_application_sectionFour_form_QN_22" style={{ whiteSpace: 'normal' }}>{translateThis('c4k_application_sectionFour_form_QN_22')}</span>
                                                            } />

                                                        </FormGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {trainingSections.map((no, i) => (
                                    <Grid key={`trainingSections-key-${i}`} container spacing={3} style={{ marginLeft: '0px', marginTop: '0px' }}>
                                        <Grid className='flex-row' item xs={12} sm={12} md={12}>
                                            <div className='info-head f-wrp' />
                                            <IconButton
                                                onClick={() => onDeleteFormExtraSection(
                                                    'c4k_application_sectionThree_form_trainingSections',
                                                    trainingSections,
                                                    setTrainingSections,
                                                    setFormValue,
                                                    no
                                                )}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                            <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '0' }}>

                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_1`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_1')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_1`}
                                                    id='c4k_application_sectionFour_form_QN_1'
                                                    value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_1`, initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_1'
                                                    type='text'
                                                    placeholder={translateThis('c4k_application_sectionFour_form_QN_1')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_1',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <FormControl className='inline-options mob-title-fix' style={{ background: 'transparent' }}>
                                                    <FormLabel className='main-head' id="c4k_application_sectionFour_form_QN_2" style={{ transform: 'none', whiteSpace: 'nowrap' }}>{translateThis('c4k_application_sectionFour_form_QN_2')}</FormLabel>

                                                    <FormGroup row
                                                        aria-labelledby="Type of Activity"
                                                        id="c4k_application_sectionFour_form_QN_2"
                                                        name="c4k_application_sectionFour_form_QN_2"
                                                        onChange={(e) => {
                                                            onSelectAccreditedArr(e.target.value, e.target.checked, `section_${no}_c4k_application_sectionFour_form_QN_2`);
                                                        }}
                                                    >
                                                        <FormControlLabel value="Work" control={<Checkbox defaultChecked={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_2`, initialFormData)?.rawAnswer.includes('Work')} />} label={translateThis("Work")} />
                                                        <FormControlLabel value="High School" control={<Checkbox defaultChecked={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_2`, initialFormData)?.rawAnswer.includes('High School')} />} label={translateThis("Work")} />
                                                        <FormControlLabel value="Self-Employed" control={<Checkbox defaultChecked={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_2`, initialFormData)?.rawAnswer.includes('Self-Employed')} />} label={translateThis("Self-Employed")} />
                                                        <FormControlLabel value="Training or Education approved by JFES" control={<Checkbox defaultChecked={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_2`, initialFormData)?.rawAnswer.includes('Training or Education approved by JFES')} />} label={translateThis("Training or Education approved by JFES")} />
                                                        <FormControlLabel value="Higher Education" control={<Checkbox defaultChecked={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_2`, initialFormData)?.rawAnswer.includes('Higher Education')} />} label={translateThis("Higher Education ")} />
                                                        <FormControlLabel value="GED/Adult Education" control={<Checkbox defaultChecked={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_2`, initialFormData)?.rawAnswer.includes('GED/Adult Education')} />} label={translateThis("GED/Adult Education ")} />
                                                        <FormControlLabel value="Workforce Development/Training program" control={<Checkbox defaultChecked={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_2`, initialFormData)?.rawAnswer.includes('Workforce Development/Training program')} />} label={translateThis("Workforce Development/Training program ")} />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} >

                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_3`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_3')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_3`}
                                                    id='c4k_application_sectionFour_form_QN_3'
                                                    value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_3`, initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_3'
                                                    type='text'
                                                    placeholder={translateThis('c4k_application_sectionFour_form_QN_3')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_3',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >

                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_4`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_4')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_4`}
                                                    id='c4k_application_sectionFour_form_QN_4'
                                                    value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_4`, initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_4'
                                                    type='text'
                                                    placeholder={translateThis('c4k_application_sectionFour_form_QN_4')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_4',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                                <i style={{ fontSize: '12px', fontStyle: 'normal', color: '#98948A' }}>{translateThis("I.e. retail, construction, real estate, contractor, etc.")}</i>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_5`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_5')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_5`}
                                                    id='c4k_application_sectionFour_form_QN_5'
                                                    value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_5`, initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_5'
                                                    type='text'
                                                    placeholder='Address'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_5',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={2} >
                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_6`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_6')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_6`}
                                                    id='c4k_application_sectionFour_form_QN_6'
                                                    value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_6`, initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_6'
                                                    type='text'
                                                    placeholder='City'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_6',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2} >
                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_7`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_7')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_7`}
                                                    id='c4k_application_sectionFour_form_QN_7'
                                                    value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_7`, initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_7'
                                                    type='text'
                                                    placeholder='State'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_7',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2} >
                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_8`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_8')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_8`}
                                                    id='c4k_application_sectionFour_form_QN_8'
                                                    value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_8`, initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_8'
                                                    type='text'
                                                    placeholder='Zipcode'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_8',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_9`, initialFormData)?.answer ?
                                                                dayjs(answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_9`, initialFormData)?.answer) :
                                                                ''
                                                            }
                                                            onChange={(v) => {
                                                                setFormValue({
                                                                    questionId: `section_${no}_c4k_application_sectionFour_form_QN_9`,
                                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                                    translationKey: 'c4k_application_sectionFour_form_QN_9',
                                                                    type: 'text'
                                                                })
                                                            }
                                                            }
                                                            label={translateThis("Start Date")} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} >
                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_10`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_10')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_10`}
                                                    id={`section_${no}_c4k_application_sectionFour_form_QN_10`}
                                                    value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_10`, initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_10'
                                                    type='number'
                                                    placeholder='Phone'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_10',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >
                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_11`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_11')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_11`}
                                                    id={`section_${no}_c4k_application_sectionFour_form_QN_11`}
                                                    value={answerGetter('c4k_application_sectionFour_form_QN_11', initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_11'
                                                    type='text'
                                                    placeholder={translateThis('c4k_application_sectionFour_form_QN_11')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_11',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} >
                                                <TextInput
                                                    key={`c4k_application_sectionFour_form_Key_QN_12`}
                                                    label={translateThis('c4k_application_sectionFour_form_QN_12')}
                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_12`}
                                                    id={`section_${no}_c4k_application_sectionFour_form_QN_12`}
                                                    value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_12`, initialFormData)?.answer}
                                                    translationKey='c4k_application_sectionFour_form_QN_12'
                                                    type='text'
                                                    placeholder={translateThis('c4k_application_sectionFour_form_QN_12')}
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'c4k_application_sectionFour_form_QN_12',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12}>
                                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px', paddingTop: '0' }}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <FormControl className='inline-options mob-title-fix'>
                                                                <FormLabel id="c4k_application_sectionFour_form_QN_13">{translateThis('c4k_application_sectionFour_form_QN_13')}</FormLabel>
                                                                <RadioGroup row
                                                                    aria-labelledby="How frequently do you get paid?"
                                                                    defaultValue={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_13`, initialFormData)?.answer}
                                                                    id={`section_${no}_c4k_application_sectionFour_form_QN_13`}
                                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_13`}
                                                                    onChange={(e) => {
                                                                        setFormValue({
                                                                            questionId: `section_${no}_c4k_application_sectionFour_form_QN_13`,
                                                                            answer: e.target.value,
                                                                            translationKey: 'c4k_application_sectionFour_form_QN_13',
                                                                            type: 'radio'
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={7}>
                                                        <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <FormLabel id="c4k_application_sectionFour_form_QN_14" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFour_form_QN_14')}</FormLabel>
                                                            <FormControl className='inline-options'>

                                                                <RadioGroup row
                                                                    aria-labelledby="How much do you get paid before taxes are deducted (gross income)?"
                                                                    defaultValue={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_14`, initialFormData)?.answer}
                                                                    id={`section_${no}_c4k_application_sectionFour_form_QN_14`}
                                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_14`}
                                                                    onChange={(e) => {
                                                                        setFormValue({
                                                                            questionId: `section_${no}_c4k_application_sectionFour_form_QN_14`,
                                                                            answer: e.target.value,
                                                                            translationKey: 'c4k_application_sectionFour_form_QN_14',
                                                                            type: 'radio'
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="Hourly" control={<Radio />} label={translateThis("Hourly")} />
                                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                                    <FormControlLabel value="Annually" control={<Radio />} label={translateThis("Annually")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5}>
                                                        <Stack className='panel-form box-class-form inline-box' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <TextInput
                                                                key={`c4k_application_sectionFour_form_Key_QN_15`}
                                                                label={translateThis('c4k_application_sectionFour_form_QN_15')}
                                                                name={`section_${no}_c4k_application_sectionFour_form_QN_15`}
                                                                id={`section_${no}_c4k_application_sectionFour_form_QN_15`}
                                                                value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_15`, initialFormData)?.answer}
                                                                translationKey='c4k_application_sectionFour_form_QN_15'
                                                                type='number'
                                                                placeholder='Amount'
                                                                className='custom-textfield'
                                                                getValue={(name, value) => {
                                                                    setFormValue({
                                                                        questionId: name,
                                                                        answer: value,
                                                                        translationKey: 'c4k_application_sectionFour_form_QN_15',
                                                                        type: 'number'
                                                                    })
                                                                }}
                                                                useDefaultValidation
                                                            />
                                                        </Stack>

                                                    </Grid>


                                                    <Grid item xs={12} sm={12} md={9}>
                                                        <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <FormLabel id="c4k_application_sectionFour_form_QN_16" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFour_form_QN_16')}</FormLabel>
                                                            <FormControl className='inline-options'>

                                                                <RadioGroup row
                                                                    aria-labelledby="If you are self-employed, how much do you get paid before taxes and expenses are deducted (gross income)?"
                                                                    defaultValue={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_16`, initialFormData)?.answer}
                                                                    id="c4k_application_sectionFour_form_QN_16"
                                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_16`}
                                                                    onChange={(e) => {
                                                                        setFormValue({
                                                                            questionId: `section_${no}_c4k_application_sectionFour_form_QN_16`,
                                                                            answer: e.target.value,
                                                                            translationKey: 'c4k_application_sectionFour_form_QN_16',
                                                                            type: 'radio'
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="Hourly" control={<Radio />} label={translateThis("Hourly")} />
                                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                                    <FormControlLabel value="Annually" control={<Radio />} label={translateThis("Annually")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3}>
                                                        <Stack className='panel-form box-class-form inline-box' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <TextInput
                                                                key={`c4k_application_sectionFour_form_Key_QN_17`}
                                                                label={translateThis('c4k_application_sectionFour_form_QN_17')}
                                                                name={`section_${no}_c4k_application_sectionFour_form_QN_17`}
                                                                id={`section_${no}_c4k_application_sectionFour_form_QN_17`}
                                                                value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_17`, initialFormData)?.answer}
                                                                translationKey='c4k_application_sectionFour_form_QN_17'
                                                                type='number'
                                                                placeholder='Amount'
                                                                className='custom-textfield'
                                                                getValue={(name, value) => {
                                                                    setFormValue({
                                                                        questionId: name,
                                                                        answer: value,
                                                                        translationKey: 'c4k_application_sectionFour_form_QN_17',
                                                                        type: 'number'
                                                                    })
                                                                }}
                                                                useDefaultValidation
                                                            />
                                                        </Stack>

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={7}>
                                                        <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <FormLabel id="c4k_application_sectionFour_form_QN_18" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFour_form_QN_18')}</FormLabel>
                                                            <FormControl className='inline-options'>

                                                                <RadioGroup row
                                                                    aria-labelledby="If you are self-employed, what are your expenses (dollar amount)?"
                                                                    defaultValue={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_18`, initialFormData)?.answer}
                                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_18`}
                                                                    id={`section_${no}_c4k_application_sectionFour_form_QN_18`}
                                                                    onChange={(e) => {
                                                                        setFormValue({
                                                                            questionId: `section_${no}_c4k_application_sectionFour_form_QN_18`,
                                                                            answer: e.target.value,
                                                                            translationKey: 'c4k_application_sectionFour_form_QN_18',
                                                                            type: 'radio'
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="Hourly" control={<Radio />} label={translateThis("Hourly")} />
                                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                                    <FormControlLabel value="Annually" control={<Radio />} label={translateThis("Annually")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5}>
                                                        <Stack className='panel-form box-class-form inline-box' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <TextInput
                                                                key={`c4k_application_sectionFour_form_Key_QN_19`}
                                                                label={translateThis('c4k_application_sectionFour_form_QN_19')}
                                                                name={`section_${no}_c4k_application_sectionFour_form_QN_19`}
                                                                id={`section_${no}_c4k_application_sectionFour_form_QN_19`}
                                                                value={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_19`, initialFormData)?.answer}
                                                                translationKey='c4k_application_sectionFour_form_QN_19'
                                                                type='number'
                                                                placeholder='Amount'
                                                                className='custom-textfield'
                                                                getValue={(name, value) => {
                                                                    setFormValue({
                                                                        questionId: name,
                                                                        answer: value,
                                                                        translationKey: 'c4k_application_sectionFour_form_QN_19',
                                                                        type: 'number'
                                                                    })
                                                                }}
                                                                useDefaultValidation
                                                            />
                                                        </Stack>

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <FormControl className='inline-options mob-title-fix'>
                                                                <FormLabel id="c4k_application_sectionFour_form_QN_20">{translateThis('c4k_application_sectionFour_form_QN_20')}</FormLabel>
                                                                <RadioGroup row
                                                                    aria-labelledby="Daily roundtrip commute from child care setting to work/activity?"
                                                                    defaultValue={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_20`, initialFormData)?.answer}
                                                                    id={`section_${no}_c4k_application_sectionFour_form_QN_20`}
                                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_20`}
                                                                    onChange={(e) => {
                                                                        setFormValue({
                                                                            questionId: `section_${no}_c4k_application_sectionFour_form_QN_20`,
                                                                            answer: e.target.value,
                                                                            translationKey: 'c4k_application_sectionFour_form_QN_20',
                                                                            type: 'radio'
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="None" control={<Radio />} label={translateThis("None")} />
                                                                    <FormControlLabel value="1-30 minutes" control={<Radio />} label={translateThis("1-30 minutes")} />
                                                                    <FormControlLabel value="31-60 minutes" control={<Radio />} label={translateThis("31-60 minutes")} />
                                                                    <FormControlLabel value="More than 60 minutes" control={<Radio />} label={translateThis("More than 60 minutes")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <FormControl className='inline-options mob-title-fix'>
                                                                <FormLabel id="c4k_application_sectionFour_form_QN_21">{translateThis('c4k_application_sectionFour_form_QN_21')}</FormLabel>
                                                                <RadioGroup row
                                                                    aria-labelledby="Daily roundtrip commute from child care setting to work/activity?"
                                                                    defaultValue={answerGetter(`section_${no}_c4k_application_sectionFour_form_QN_21`, initialFormData)?.answer}
                                                                    id={`section_${no}_c4k_application_sectionFour_form_QN_21`}
                                                                    name={`section_${no}_c4k_application_sectionFour_form_QN_21`}
                                                                    onChange={(e) => {
                                                                        setFormValue({
                                                                            questionId: `section_${no}_c4k_application_sectionFour_form_QN_21`,
                                                                            answer: e.target.value,
                                                                            translationKey: 'c4k_application_sectionFour_form_QN_21',
                                                                            type: 'radio'
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                                    <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Stack className='panel-form box-class-form' spacing={1} style={{ padding: '0', border: 'none' }}>
                                                            <FormControl className='inline-options whiteSpace'>
                                                                <FormGroup row>

                                                                    <FormControlLabel value="c4k_application_sectionFour_form_QN_22" control={<Checkbox />} label={
                                                                        <span id="c4k_application_sectionFour_form_QN_22" style={{ whiteSpace: 'normal' }}>{translateThis('c4k_application_sectionFour_form_QN_22')}</span>
                                                                    } />

                                                                </FormGroup>
                                                            </FormControl>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}

                            </Stack>

                            <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Button onClick={() => onAddFormExtraSection(
                                        'c4k_application_sectionThree_form_trainingSections',
                                        trainingSections,
                                        setTrainingSections,
                                        setFormValue
                                    )} className='red-btn'>{translateThis("Add new section +")}</Button>
                                </Grid>
                            </Grid>




                        </div>}

                        {currentStep && <div style={{ display: currentStep === 5 ? 'block' : 'none' }} className='C4KAppSectionFive f-wrp'>

                            <div className='info-head f-wrp'>
                                <h2>{translateThis("SECTION 5 Child support paid and additional income information")}</h2>
                                <br />
                            </div>

                            <Stack className='panel-form box-class-form' spacing={1}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                        <FormControl className='inline-options mob-title-fix' style={{ background: 'transparent', marginTop: '0', paddingTop: '0' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_1" style={{ transform: 'none', whiteSpace: 'nowrap' }}>{translateThis('c4k_application_sectionFive_form_QN_1')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Does anyone living in your home pay child support?"
                                                defaultValue={answerGetter('c4k_application_sectionFive_form_QN_1', initialFormData)?.answer}
                                                id="c4k_application_sectionFive_form_QN_1"
                                                name="c4k_application_sectionFive_form_QN_1"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionFive_form_QN_1',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionFive_form_QN_1',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_1_1" style={{ transform: 'none', whiteSpace: 'nowrap' }}>{translateThis('c4k_application_sectionFive_form_QN_1_1')}</FormLabel>
                                        </FormControl>
                                    </Grid>
                                    {(answerGetter('c4k_application_sectionFive_form_QN_1', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={6} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_2`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_2')}
                                            name='c4k_application_sectionFive_form_QN_2'
                                            id='c4k_application_sectionFive_form_QN_2'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_2', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_2'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_2')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_2',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_1', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={6}>
                                        <Stack className='panel-form box-class-form' spacing={1} style={{ border: 'none' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_3" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFive_form_QN_3')}</FormLabel>
                                            <FormControl className='inline-options mob-title-fix' style={{ marginTop: '0' }}>

                                                <RadioGroup row
                                                    aria-labelledby="How often?"
                                                    defaultValue={answerGetter('c4k_application_sectionFive_form_QN_3', initialFormData)?.answer}
                                                    id="c4k_application_sectionFive_form_QN_3"
                                                    name="c4k_application_sectionFive_form_QN_3"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionFive_form_QN_3',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionFive_form_QN_3',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                    </Grid>}
                                </Grid>
                            </Stack>
                            <br />
                            <Stack className='panel-form box-class-form' spacing={1}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                        <FormControl className='inline-options mob-title-fix' style={{ background: 'transparent', marginTop: '0', paddingTop: '0' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_4" style={{ transform: 'none', whiteSpace: 'nowrap' }}>{translateThis('c4k_application_sectionFive_form_QN_4')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Does anyone living in your home receive a DCF stipend?"
                                                defaultValue={answerGetter('c4k_application_sectionFive_form_QN_4', initialFormData)?.answer}
                                                id="c4k_application_sectionFive_form_QN_4"
                                                name="c4k_application_sectionFive_form_QN_4"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionFive_form_QN_4',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionFive_form_QN_4',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {(answerGetter('c4k_application_sectionFive_form_QN_4', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_5`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_5')}
                                            name='c4k_application_sectionFive_form_QN_5'
                                            id='c4k_application_sectionFive_form_QN_5'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_5', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_5'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_5')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_5',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_4', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_6`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_6')}
                                            name='c4k_application_sectionFive_form_QN_6'
                                            id='c4k_application_sectionFive_form_QN_6'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_6', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_6'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_6')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_6',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_4', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={6}>
                                        <Stack className='panel-form box-class-form' spacing={1} style={{ border: 'none' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_7" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFive_form_QN_7')}</FormLabel>
                                            <FormControl className='inline-options mob-title-fix' style={{ marginTop: '0' }}>

                                                <RadioGroup row
                                                    aria-labelledby="How often?"
                                                    defaultValue={answerGetter('c4k_application_sectionFive_form_QN_7', initialFormData)?.answer}
                                                    id="c4k_application_sectionFive_form_QN_7"
                                                    name="c4k_application_sectionFive_form_QN_7"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionFive_form_QN_7',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionFive_form_QN_7',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                    </Grid>}
                                </Grid>
                            </Stack>
                            <br />
                            <Stack className='panel-form box-class-form' spacing={1}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                        <FormControl className='inline-options mob-title-fix' style={{ background: 'transparent', marginTop: '0', paddingTop: '0' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_8" style={{ transform: 'none', whiteSpace: 'nowrap' }}>{translateThis('c4k_application_sectionFive_form_QN_8')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Does anyone living in your home receive unemployment compensation?"
                                                defaultValue={answerGetter('c4k_application_sectionFive_form_QN_8', initialFormData)?.answer}
                                                id="c4k_application_sectionFive_form_QN_8"
                                                name="c4k_application_sectionFive_form_QN_8"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionFive_form_QN_8',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionFive_form_QN_8',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {(answerGetter('c4k_application_sectionFive_form_QN_8', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_9`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_9')}
                                            name='c4k_application_sectionFive_form_QN_9'
                                            id='c4k_application_sectionFive_form_QN_9'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_9', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_9'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_9')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_9',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_8', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_10`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_10')}
                                            name='c4k_application_sectionFive_form_QN_10'
                                            id='c4k_application_sectionFive_form_QN_10'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_10', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_10'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_10')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_10',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_8', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={6}>
                                        <Stack className='panel-form box-class-form' spacing={1} style={{ border: 'none' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_11" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFive_form_QN_11')}</FormLabel>
                                            <FormControl className='inline-options mob-title-fix' style={{ marginTop: '0' }}>

                                                <RadioGroup row
                                                    aria-labelledby="How often?"
                                                    defaultValue={answerGetter('c4k_application_sectionFive_form_QN_11', initialFormData)?.answer}
                                                    id="c4k_application_sectionFive_form_QN_11"
                                                    name="c4k_application_sectionFive_form_QN_11"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionFive_form_QN_11',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionFive_form_QN_11',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                    </Grid>}
                                </Grid>
                            </Stack>
                            <br />
                            <Stack className='panel-form box-class-form' spacing={1}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                        <FormControl className='inline-options mob-title-fix' style={{ background: 'transparent', marginTop: '0', paddingTop: '0' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_12" style={{ transform: 'none', whiteSpace: 'nowrap' }}>{translateThis('c4k_application_sectionFive_form_QN_12')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Does anyone living in your home receive Social Security Income?"
                                                defaultValue={answerGetter('c4k_application_sectionFive_form_QN_12', initialFormData)?.answer}
                                                id="c4k_application_sectionFive_form_QN_12"
                                                name="c4k_application_sectionFive_form_QN_12"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionFive_form_QN_12',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionFive_form_QN_12',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {(answerGetter('c4k_application_sectionFive_form_QN_12', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_13`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_13')}
                                            name='c4k_application_sectionFive_form_QN_13'
                                            id='c4k_application_sectionFive_form_QN_13'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_13', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_13'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_13')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_13',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_12', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_14`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_14')}
                                            name='c4k_application_sectionFive_form_QN_14'
                                            id='c4k_application_sectionFive_form_QN_14'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_14', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_14'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_14')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_14',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_12', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={6}>
                                        <Stack className='panel-form box-class-form' spacing={1} style={{ border: 'none' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_15" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFive_form_QN_15')}</FormLabel>
                                            <FormControl className='inline-options mob-title-fix' style={{ marginTop: '0' }}>

                                                <RadioGroup row
                                                    aria-labelledby="How often?"
                                                    defaultValue={answerGetter('c4k_application_sectionFive_form_QN_15', initialFormData)?.answer}
                                                    id="c4k_application_sectionFive_form_QN_15"
                                                    name="c4k_application_sectionFive_form_QN_15"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionFive_form_QN_15',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionFive_form_QN_15',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                    </Grid>}
                                </Grid>
                            </Stack>
                            <br />
                            <Stack className='panel-form box-class-form' spacing={1}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                        <FormControl className='inline-options mob-title-fix' style={{ background: 'transparent', marginTop: '0', paddingTop: '0' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_16" style={{ transform: 'none', whiteSpace: 'nowrap' }}>{translateThis('c4k_application_sectionFive_form_QN_16')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Do you get child care assistance from another source?"
                                                defaultValue={answerGetter('c4k_application_sectionFive_form_QN_16', initialFormData)?.answer}
                                                id="c4k_application_sectionFive_form_QN_16"
                                                name="c4k_application_sectionFive_form_QN_16"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionFive_form_QN_16',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionFive_form_QN_16',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {(answerGetter('c4k_application_sectionFive_form_QN_16', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_17`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_17')}
                                            name='c4k_application_sectionFive_form_QN_17'
                                            id='c4k_application_sectionFive_form_QN_17'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_17', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_17'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_17')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_17',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_16', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_18`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_18')}
                                            name='c4k_application_sectionFive_form_QN_18'
                                            id='c4k_application_sectionFive_form_QN_18'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_18', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_18'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_18')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_18',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_16', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={6}>
                                        <Stack className='panel-form box-class-form' spacing={1} style={{ border: 'none' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_19" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFive_form_QN_19')}</FormLabel>
                                            <FormControl className='inline-options mob-title-fix' style={{ marginTop: '0' }}>

                                                <RadioGroup row
                                                    aria-labelledby="How often?"
                                                    defaultValue={answerGetter('c4k_application_sectionFive_form_QN_19', initialFormData)?.answer}
                                                    id="c4k_application_sectionFive_form_QN_19"
                                                    name="c4k_application_sectionFive_form_QN_19"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionFive_form_QN_19',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionFive_form_QN_19',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                    </Grid>}
                                </Grid>
                            </Stack>
                            <br />
                            <Stack className='panel-form box-class-form' spacing={1}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                        <FormControl className='inline-options mob-title-fix' style={{ background: 'transparent', marginTop: '0', paddingTop: '0' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_20" style={{ transform: 'none', whiteSpace: 'nowrap' }}>{translateThis('c4k_application_sectionFive_form_QN_20')} <br />
                                                <p style={{ margin: '0' }}> (i.e. alimony, pensions, workers’ compensation, veteran benefits, rental income)</p></FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Does anyone living in your home receive any other income ?"
                                                defaultValue={answerGetter('c4k_application_sectionFive_form_QN_20', initialFormData)?.answer}
                                                id="c4k_application_sectionFive_form_QN_20"
                                                name="c4k_application_sectionFive_form_QN_20"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'c4k_application_sectionFive_form_QN_20',
                                                        answer: e.target.value,
                                                        translationKey: 'c4k_application_sectionFive_form_QN_20',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                                <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />
                                            </RadioGroup>

                                            {(answerGetter('c4k_application_sectionFive_form_QN_20', formData)?.answer === "Yes") && <TextInput
                                                key={`c4k_application_sectionFive_form_Key_QN_21`}
                                                label={translateThis('c4k_application_sectionFive_form_QN_21')}
                                                name='c4k_application_sectionFive_form_QN_21'
                                                id='c4k_application_sectionFive_form_QN_21'
                                                value={answerGetter('c4k_application_sectionFive_form_QN_21', initialFormData)?.answer}
                                                translationKey='c4k_application_sectionFive_form_QN_21'
                                                type='text'
                                                placeholder={translateThis('c4k_application_sectionFive_form_QN_21')}
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_application_sectionFive_form_QN_21',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />}

                                        </FormControl>
                                    </Grid>
                                    {(answerGetter('c4k_application_sectionFive_form_QN_20', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_22`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_22')}
                                            name='c4k_application_sectionFive_form_QN_22'
                                            id='c4k_application_sectionFive_form_QN_22'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_22', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_22'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_22')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_22',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_20', formData)?.answer === "Yes") && <Grid item xs={12} sm={6} md={3} className='app-txt-padding0'>

                                        <TextInput
                                            key={`c4k_application_sectionFive_form_Key_QN_23`}
                                            label={translateThis('c4k_application_sectionFive_form_QN_23')}
                                            name='c4k_application_sectionFive_form_QN_23'
                                            id='c4k_application_sectionFive_form_QN_23'
                                            value={answerGetter('c4k_application_sectionFive_form_QN_23', initialFormData)?.answer}
                                            translationKey='c4k_application_sectionFive_form_QN_23'
                                            type='text'
                                            placeholder={translateThis('c4k_application_sectionFive_form_QN_23')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'c4k_application_sectionFive_form_QN_23',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>}
                                    {(answerGetter('c4k_application_sectionFive_form_QN_20', formData)?.answer === "Yes") && <Grid item xs={12} sm={12} md={6}>
                                        <Stack className='panel-form box-class-form' spacing={1} style={{ border: 'none' }}>
                                            <FormLabel id="c4k_application_sectionFive_form_QN_24" style={{ color: '#B4AC9C', paddingBottom: '0' }}>{translateThis('c4k_application_sectionFive_form_QN_24')}</FormLabel>
                                            <FormControl className='inline-options' style={{ marginTop: '0' }}>

                                                <RadioGroup row
                                                    aria-labelledby="How often?"
                                                    defaultValue={answerGetter('c4k_application_sectionFive_form_QN_24', initialFormData)?.answer}
                                                    id="c4k_application_sectionFive_form_QN_24"
                                                    name="c4k_application_sectionFive_form_QN_24"
                                                    onChange={(e) => {
                                                        setFormValue({
                                                            questionId: 'c4k_application_sectionFive_form_QN_24',
                                                            answer: e.target.value,
                                                            translationKey: 'c4k_application_sectionFive_form_QN_24',
                                                            type: 'radio'
                                                        })
                                                    }}
                                                >
                                                    <FormControlLabel value="Weekly" control={<Radio />} label={translateThis("Weekly")} />
                                                    <FormControlLabel value="Bi-Weekly" control={<Radio />} label={translateThis("Bi-Weekly")} />
                                                    <FormControlLabel value="Semi-Monthly" control={<Radio />} label={translateThis("Semi-Monthly")} />
                                                    <FormControlLabel value="Monthly" control={<Radio />} label={translateThis("Monthly")} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                    </Grid>}
                                </Grid>
                            </Stack>
                        </div>}

                        {currentStep && <div style={{ display: currentStep === 6 ? 'block' : 'none' }} className='C4KAppSectionSix f-wrp'>

                            <div className='info-head f-wrp'>
                                <h2>{translateThis("SECTION 6 Parents rights and responsibilities")}</h2>
                                <br />
                            </div>

                            <div className='parents-rights-sec'>
                                <p>{translateThis("Parents_rights_and_responsibilities_1")}<b>{translateThis("Parents_rights_and_responsibilities_1_1")}</b> {translateThis("Parents_rights_and_responsibilities_1_2")} <b>{translateThis("Parents_rights_and_responsibilities_1_3")}</b>.</p>
                                <ul>
                                    <li>{translateThis("Parents_rights_and_responsibilities_2")} <b>{translateThis("Parents_rights_and_responsibilities_2_1")} </b>.</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_3")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_4")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_5")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_6")}</li>
                                </ul>
                                <br />
                                <p><b>{translateThis("Parents_rights_and_responsibilities_7")}</b> </p>
                                <ul>
                                    <li>{translateThis("Parents_rights_and_responsibilities_8")} <Link style={{ color: '#FF3D00' }} onClick={() => window.open('https://www.ctcare4kids.com', '_blank')}> &nbsp;www.ctcare4kids.com</Link>.</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_9")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_10")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_11")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_12")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_13")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_14")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_15")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_16")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_17")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_18")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_19")}</li>
                                    <li>{translateThis("Parents_rights_and_responsibilities_20")}</li>
                                </ul>
                                <br />
                                <p><b>{translateThis("Parents_rights_and_responsibilities_21")}<br />
                                {translateThis("Parents_rights_and_responsibilities_22")}</b></p>
                            </div>

                            <Stack className='panel-form' spacing={1}>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                    <Grid item xs={12} sm={6} md={6}>


                                        <Stack className='panel-form' spacing={3}>
                                            <div className='info-head f-wrp'>
                                                <span style={{ color: '#98948A' }}>{translateThis("Applicant signature")}</span>
                                            </div>
                                            <TextInput
                                                key={`c4k_application_sectionSix_form_Key_QN_applicantSign`}
                                                label={translateThis('c4k_application_sectionSix_form_QN_applicantSign')}
                                                name='c4k_application_sectionSix_form_QN_applicantSign'
                                                id='c4k_application_sectionSix_form_QN_applicantSign'
                                                translationKey='c4k_application_sectionSix_form_QN_applicantSign'
                                                type='text'
                                                required
                                                placeholder='Type your name'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setSignature(value);
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_application_sectionSix_form_QN_applicantSign',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                            <FormGroup className='check-label'>
                                                <FormControlLabel onChange={(e) => setIsSigned(e.target.checked)} required control={<Checkbox />} label={
                                                    <span>{translateThis("signatureI")}, {signature && <b>{signature},</b>} {translateThis("affirm that the information provided is true and complete")}</span>
                                                } />
                                            </FormGroup>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>


                                        <Stack className='panel-form' spacing={3}>
                                            <div className='info-head f-wrp'>
                                                <span style={{ color: '#98948A' }}>{translateThis("Signature of other legally responsible adult living with you")} <br /><i style={{ fontStyle: 'normal', fontSize: '12px', position: 'absolute', color: '#98948A' }}>{translateThis("sign_text_ie")}</i></span>
                                            </div>
                                            <TextInput
                                                key={`c4k_application_sectionSix_form_Key_QN_responsibleSign`}
                                                label={translateThis('c4k_application_sectionSix_form_QN_responsibleSign')}
                                                name='c4k_application_sectionSix_form_QN_responsibleSign'
                                                id='c4k_application_sectionSix_form_QN_responsibleSign'
                                                translationKey='c4k_application_sectionSix_form_QN_responsibleSign'
                                                type='text'
                                                required
                                                placeholder='Type your name'
                                                className='custom-textfield'
                                                getValue={(name, value) => {
                                                    setSecondSignature(value)
                                                    setFormValue({
                                                        questionId: name,
                                                        answer: value,
                                                        translationKey: 'c4k_application_sectionSix_form_QN_responsibleSign',
                                                        type: 'text'
                                                    })
                                                }}
                                                useDefaultValidation
                                            />
                                            <FormGroup className='check-label'>
                                                <FormControlLabel defaultChecked={isSecondSigned} onChange={(e) => setIsSecondSigned(e.target.checked)} required control={<Checkbox />} label={
                                                    <span>{translateThis("signatureI")}, {secondSignature && <b>{secondSignature},</b>} {translateThis("affirm that the information provided is true and complete")}</span>
                                                } />
                                            </FormGroup>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <br />
                                        <Divider />
                                        <br />
                                        <div className='info-head f-wrp' style={{ textAlign: 'center', maxWidth: '767px', margin: '0 auto', float: 'none' }}>
                                            <p>{translateThis("RETURN THIS APPLICATION TO CARE 4 KIDS")} <br />
                                            {translateThis("ONLINE")} <Link style={{ color: '#FF3D00' }} onClick={() => window.open('https://www.ctcare4kids.com/upload/', '_blank')}> &nbsp;www.ctcare4kids.com/upload/</Link><br />
                                            {translateThis("MAIL")}<br />
                                            {translateThis("FAX")}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </div>}

                        <br />
                        <Divider />
                        <br />
                    </Stack>
                </>

                <br />

                <div className='button-sec mob-btn-grid f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={onChangeFormState} className='cancel-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec'>
                        <Button
                            onClick={onPrevStepClick}
                            disabled={currentStep === 1}
                            className='pln-btn'
                        >{translateThis("Previous")}</Button>

                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition='start'
                            startIcon={<></>} // To remove Mui warning
                            fullWidth
                            disabled={currentStep === 6 && !signature}
                            size="large"
                            type="submit"
                            variant="contained"
                            className='fill-btn'
                            onClick={onNextClick}>
                            {currentStep === 6 ? <>{translateThis("submit")}</> : <>{translateThis("Next")}</>}
                        </LoadingButton>

                    </div>
                </div>
            </div> : null}
        </>
    );
}

C4KApplication.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func,
}

export default C4KApplication;