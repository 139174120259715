import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Stack } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Components
import SVGIcons from '../../../assets/images/icons/svgIcons';
import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import { DATE_FORMAT, DATE_TIME_FORMAT, DATE_ONLY_FORMAT, MONTH_TEXT_FORMAT } from '../../../constants/index';
import AppButton from '../../../components/buttons/app.button';
import TextInput from '../../../components/inputs/textInput/textInput';
import { translateThis } from '../../../helpers/language.helper';
import { downloadDailyLog } from '../../../services/api/pdf';
import { getDynamicFormPreview } from '../../../helpers/dynamicForm.helper';
import ModalPopUp from '../../../components/modal/modal.popup';
import AppIcons from '../../../assets/images/icons';



const ProviderDailyLogToSignTab = () => {
    const apiCaller = new ApiCaller('daily-logs');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openSubmissionDialog, setOpenSubmissionDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [providerSignature, setProviderSignature] = useState('');
    const [viewOpen, setViewOpen] = React.useState(false);
    const [selectedPreview, setSelectedPreview] = React.useState(<></>);

    const onViewClose = () => {
        setViewOpen(false);
    };

    /**
    the legendary use effect function
    This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => {
                if (data) {
                    let dataArray = data?.results || [];
                    dataArray = dataArray.filter((data) => (!data.isProviderSigned || !data.isParentSigned))
                    setDataArr(dataArray)
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false));
    }



    /**
   * submit Daily Log
   */
    const submitDailyLog = () => {
        if (selectedId && providerSignature) {
            setIsSubmitting(true);
            apiCaller.updateData(selectedId, {
                isProviderSigned: true,
                status: 'completed',
                additionalInfo: {
                    isProviderSigned: true,
                    providerSignature,
                    providerSignedDate: dayjs().format(DATE_TIME_FORMAT)
                }
            })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected log has been submitted successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenSubmissionDialog(false);
                    setIsSubmitting(false);
                    setSelectedId('');
                    setProviderSignature('');
                    getDataArr();
                })
        } else {
            showSnackbar().warning('Please sign the form to submit');
        }

    }

    /**
   * Download Daily Log
   */
    const downloadDailyLogPDF = (id, logTitle) => {
        if (id && logTitle) {
            setIsDownloading(true);
            showSnackbar().info("Selected daily log will be downloaded shortly")
            downloadDailyLog(id, logTitle)
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected log has been downloaded successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setIsDownloading(false);
                })
        } else {
            showSnackbar().warning('Something went wrong!.');
        }

    }

    /**
         * 
         * @param {string} formName 
         * @param {any} formData 
         */
    const onFormPreviewClick = (formName, formData) => {
        const previewSelected = getDynamicFormPreview(formName, { data: formData })
        setViewOpen(true);
        setSelectedPreview(previewSelected);
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 2,
            minWidth: 150,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'logTitle',
            headerName: 'Communication title',
            flex: 2,
            minWidth: 200,
        },
        {
            field: 'logDate',
            headerName: 'Communication date',
            flex: 2,
            minWidth: 200,
            valueGetter: (params) => dayjs(params.row?.logDate).format(DATE_FORMAT),
        },
        {
            field: 'isParentSigned',
            headerName: 'Parent',
            minWidth: 100,
            flex: 0.8,
            renderCell: (params) => params.row?.isParentSigned ?
                <span className='complete'><SVGIcons.TickIcon /> Signed</span> :
                <span className='pending'><SVGIcons.ClockCountdownIcon /> Pending</span>,
        },
        {
            field: 'isProviderSigned',
            headerName: 'Provider',
            minWidth: 100,
            flex: 0.8,
            renderCell: (params) => params.row?.isProviderSigned ?
                <span className='complete'><SVGIcons.TickIcon /> Signed</span> :
                <AppButton
                    onClick={() => {
                        setOpenSubmissionDialog(true);
                        setSelectedId(params.row?.id);
                    }}
                    btnText='Sign'
                />,
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 1,
            minWidth: 90,
            sortable: false,
            renderCell: ({ row }) => {
                const { id, logTitle, logKey } = row
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onFormPreviewClick(logKey, row)
                    },
                    {
                        label: isDownloading ? <CircularProgress size={20} /> : 'Download',
                        onClick: () => downloadDailyLogPDF(id, logTitle)
                    }
                ]} />
            }
        },

    ]




    return (
        <div className='dailyComms-tosign-wrp f-wrp'>
            <div className='red-txt f-wrp'>
                <p>{translateThis("dailyCommsText")}</p>
            </div>
            <Stack className='panel-form f-wrp' spacing={3}>
                <LocalizationProvider className="custom-textfield" dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker label={translateThis("Date")} />
                    </DemoContainer>
                </LocalizationProvider>
                <p style={{ fontSize: '12px', margin: '0', paddingLeft: '15px' }}>{translateThis("maximumRange")}</p>
            </Stack>

            <div style={{ width: '100%', paddingTop: '25px' }} className="table-sec-wrp f-wrp">
                <div className='panel-table-sec provider-table PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />

                    <div className='mob-table-wrp childView f-wrp'>
                    {dataArr.length > 0 ? <ul>
                            {dataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box'>
                                        <h4>{dayjs(element.submissionDate).format(DATE_ONLY_FORMAT)}</h4>
                                        <h4>{dayjs(element.submissionDate).format(MONTH_TEXT_FORMAT)}</h4>
                                    </div>
                                    <div className='each-sec-box'>
                                        <h4>{element.childId?.firstName}&nbsp;{element.childId?.lastName}</h4>
                                        <span>{element.logTitle}</span>
                                        <p className='flex-wrp'> {translateThis('Parent')}: {element.isParentSigned ?
                                            <span className='status complete'>{translateThis('Signed')}</span> :
                                            <span className='status pending'>{translateThis('Pending')}</span>}</p>
                                    </div>
                                    <div className='each-sec-box'>
                                        <ul className='btn-wrp'>
                                            {/* <li><button className='pln-btn ForwardIcon' type='button' onClick={() => { onFormPreviewClick(element.logKey, element) }}><SVGIcons.ForwardIcon /></button></li> */}
                                            <li><button className='pln-btn' type='button' onClick={() => { downloadDailyLogPDF(element.id, element.logTitle) }}><SVGIcons.DownloadIcon /></button></li>
                                        </ul>
                                        <span>{element.isProviderSigned ?
                                            <span className='status complete'>{translateThis('Signed')}</span> :
                                            <AppButton
                                                onClick={() => {
                                                    setOpenSubmissionDialog(true);
                                                    setSelectedId(element.id);
                                                }}
                                                btnText='Sign'
                                            />}</span>
                                    </div>
                                    <Link className='full-li-btn' onClick={() => { onFormPreviewClick(element.logKey, element) }} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholder} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                {openSubmissionDialog && <ConfirmDialog
                    isOpen={openSubmissionDialog}
                    onClose={() => {
                        setOpenSubmissionDialog(false);
                        setSelectedId('');
                        setProviderSignature('');
                    }}
                    title={`Log submission signature`}
                    className={'delete-popup'}
                    description={<div>
                        <TextInput
                            key={`provider_signature`}
                            label={translateThis('typeYourName')}
                            name='providerSignature'
                            id='providerSignature'
                            value={providerSignature}
                            type='text'
                            required
                            placeholder={translateThis('typeYourName')}
                            className='custom-textfield'
                            getValue={(_, value) => {
                                setProviderSignature(value)
                            }}
                            useDefaultValidation
                        />
                        <br />
                        <span>
                            {translateThis("maximumRangeText")}<span style={{ color: '#FF3D00' }}> {translateThis("This action cannot be undone")}</span>
                        </span>
                    </div>}
                    okayButtonText={'Sign form'}
                    confirmButton={
                        <AppButton
                            onClick={submitDailyLog}
                            isDisabled={providerSignature.length < 2}
                            btnText={translateThis('signForm')}
                            isLoading={isSubmitting}
                        />
                    }
                />}

                {viewOpen ? <ModalPopUp
                    className='preview-popup'
                    isOpen={viewOpen}
                    onClose={onViewClose}
                    aria-labelledby="table-view"
                    aria-describedby="table-view"
                >
                    {selectedPreview}
                    <Button onClick={onViewClose} className='fill-btn'>{translateThis("close")}</Button>
                </ModalPopUp> : null}
            </div>
        </div>
    )
}


export default ProviderDailyLogToSignTab;