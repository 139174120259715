import React from 'react';
import propTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import './index.scss'
import { translateThis } from '../../../helpers/language.helper';

// Custom components
import BasicDropdown from '../../../components/dropdowns/basic.dropdown';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import ChildrenListDropdown from '../../../components/dropdowns/children.dropdown';


const ProviderAccidentUploadTab = (props) => {

    const { id = '' } = props;
    const [selectedFormName, setSelectedFormName] = React.useState('');
    const [selectedChildId, setSelectedChildId] = React.useState('');
    // const [selectedForm, setSelectedForm] = React.useState(<></>);
    /**
     * 
     * @param {string} value 
     */
    const setSelectedChild = (value) => {
        setSelectedChildId(value);
        setSelectedFormName('');
    }

    /**
         * Set the selected form
         * @param {string} formName selected form
         */
    const handleFormSelection = (formName) => {
        setSelectedFormName(formName);
    };

    return (
        <>
            <div className='panel-form-sec forms-main-wrapper dailyCommUpload f-wrp'>
                <div className='info-txt'>
                    <p>{translateThis("hereYouCanUploadThe")} <b>{translateThis("accidents")}</b> {translateThis("youCompletedOffline")}</p>
                </div>
                <div className='info-head f-wrp'>
                    <span>{translateThis("assignYourUploadToAChild")}</span>
                </div>
                <div className='form-detail-sec f-wrp'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className="form-chooser-wrp" style={{ paddingTop: '10px' }}>
                                <div className="child-selection-div f-wrp">
                                    {!id && <ChildrenListDropdown onChange={setSelectedChild} />}
                                </div>
                                <div className="form-selection-div f-wrp">
                                    <BasicDropdown
                                        key={selectedFormName}
                                        label="Select a communication"
                                        id='providerFormSelect'
                                        isDisabled={!selectedChildId}
                                        options={[
                                            {
                                                'label': "Accident",
                                                "id": 'provider_form_accident',
                                                "value": 'provider_form_accident',
                                            },

                                        ]}
                                        onChange={(value) => handleFormSelection(value)}
                                        value={selectedFormName}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className='form-upload-sec f-wrp'>
                                <label htmlFor='form-upload' className='red-txt'>
                                    <input id='form-upload' required type='file' style={{ opacity: '0', display: 'none' }} />
                                    <SVGIcons.UploadIcon />
                                    <p>{translateThis("uploadAFileOrTakeAPhoto")}</p>
                                </label>
                                <div className='info-txt'>
                                    <span>{translateThis("uploadInfoText1")}</span>
                                </div>
                            </div>

                            <div className='info-txt'>
                                <p>{translateThis("hereYouCanUploadThe")} <b>{translateThis("incidentLogs")}</b> {translateThis("youCompletedOffline")}</p>
                            </div>

                            <div className='form-upload-sec f-wrp'>
                                <label htmlFor='form_upload_2' className='red-txt'>
                                    <input id='form_upload_2' required type='file' style={{ opacity: '0', display: 'none' }} />
                                    <SVGIcons.UploadIcon />
                                    <p>{translateThis("uploadAFileOrTakeAPhoto")}</p>
                                </label>
                                <div className='info-txt'>
                                    <span>{translateThis("uploadInfoText1")}</span>
                                </div>
                            </div>


                            <div className='button-sec f-wrp'>
                                <div className='lft-btn-sec'>
                                    <Button onClick={() => navigator('/')} className='cancel-btn'>{translateThis("cancel")}</Button>
                                </div>
                                <div className='ryt-btn-sec'>


                                    <LoadingButton
                                        // loading={isSubmitting}
                                        loadingPosition='start'
                                        startIcon={<></>} // To remove Mui warning
                                        fullWidth
                                        // disabled={!isValidForm()}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className='fill-btn'
                                    // onClick={onSubmit}
                                    >
                                        {translateThis("submit")}
                                    </LoadingButton>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

ProviderAccidentUploadTab.propTypes = {
    id: propTypes.string
}
export default ProviderAccidentUploadTab;