import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { CircularProgress, Button } from '@mui/material';
import ModalPopUp from '../../../components/modal/modal.popup';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ConfirmDialog from '../../../components/dialogs/confirm.dialog';
import { DATE_TIME_FORMAT, DATE_ONLY_FORMAT, MONTH_TEXT_FORMAT } from '../../../constants/index';
import AppButton from '../../../components/buttons/app.button';
import TextInput from '../../../components/inputs/textInput/textInput';
import { translateThis } from '../../../helpers/language.helper';
import { generatePDFBy } from '../../../services/api/pdf';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { getDynamicFormPreview } from '../../../helpers/dynamicForm.helper';
import AppIcons from '../../../assets/images/icons';





const DynamicFormToSignTab = () => {
    const apiCaller = new ApiCaller('form-submissions');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [openSubmissionDialog, setOpenSubmissionDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [providerSignature, setProviderSignature] = useState('');
    const [viewOpen, setViewOpen] = useState(false);
    const [selectedPreview, setSelectedPreview] = useState(<></>);


    const onViewClose = () => {
        setViewOpen(false);
    };



    /**
     * 
     * @param {string} formName 
     * @param {any} formData 
     */
    const onFormPreviewClick = (formName, formData) => {
        const previewSelected = getDynamicFormPreview(formName, { data: formData })
        setViewOpen(true);
        setSelectedPreview(previewSelected);
    }


    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList()
            .then((data) => {
                if (data) {
                    let dataArray = data?.results || [];
                    dataArray = dataArray.filter((data) => (!data.isProviderSigned || !data.isParentSigned) && ['parent-provider-agreement', 'summer-pp-agreement'].includes(data.formName))
                    setDataArr(dataArray)
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false));
    }



    /**
   * submit Daily Log
   */
    const submitDailyLog = () => {
        if (selectedId && providerSignature) {
            setIsSubmitting(true);
            apiCaller.updateData(selectedId, {
                isProviderSigned: true,
                status: 'completed',
                additionalInfo: {
                    isProviderSigned: true,
                    providerSignature,
                    providerSignedDate: dayjs().format(DATE_TIME_FORMAT),
                }
            })
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected log has been submitted successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenSubmissionDialog(false);
                    setIsSubmitting(false);
                    setSelectedId('');
                    setProviderSignature('');
                    getDataArr();
                })
        } else {
            showSnackbar().warning('Please sign the form to submit');
        }

    }

    /**
   * Download PDF
   */
    const downloadPDF = (childId, userId, formName) => {
        if (childId && userId && formName) {
            setIsDownloading(true);
            showSnackbar().info("Selected form will be downloaded shortly")
            generatePDFBy(userId, childId, formName)
                .then(({ success, message }) => success ?
                    showSnackbar().success(`The selected form has been downloaded successfully.`)
                    : showSnackbar().warning(message)
                )
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setIsDownloading(false);
                })
        } else {
            showSnackbar().warning('Please sign the form to submit');
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'childId',
            headerName: 'Child',
            flex: 1,
            minWidth: 170,
            valueFormatter: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
        },
        {
            field: 'formTitle',
            headerName: 'Form',
            flex: 1.5,
            minWidth: 250,
        },
        {
            field: 'createdAt',
            headerName: 'Submission Date',
            flex: 2,
            minWidth: 150,
            valueGetter: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
        {
            field: 'isParentSigned',
            headerName: 'Parent',
            minWidth: 120,
            flex: 0.8,
            renderCell: (params) => params.row?.isParentSigned ?
                <span className='complete'><SVGIcons.TickIcon /> Signed</span> :
                <span className='pending'><SVGIcons.ClockCountdownIcon /> Pending</span>,
        },
        {
            field: 'isProviderSigned',
            headerName: 'Provider',
            minWidth: 120,
            flex: 0.8,
            renderCell: (params) => params.row?.isProviderSigned ?
                <span className='complete'><SVGIcons.TickIcon /> Signed</span> :
                <AppButton
                    onClick={() => {
                        setOpenSubmissionDialog(true);
                        setSelectedId(params.row?.id);
                    }}
                    btnText='Sign'
                />,
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { childId, userId, formName } = params.row
                return <AppPopOverMenu options={[
                    {
                        label: 'View',
                        onClick: () => onFormPreviewClick(formName, params.row)
                    },
                    {
                        label: isDownloading ? <CircularProgress size={20} /> : 'Download',
                        onClick: () => downloadPDF(childId?.id, userId?.id, formName)
                    }
                ]} />
            }
        },

    ]

    return (
        <>

            <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                <div className='panel-table-sec provider-table PC-Table f-wrp'>
                    <AppTable
                        columns={tableColumns}
                        isLoading={isLoading}
                        data={dataArr}
                    />

                    <div className='mob-table-wrp childView f-wrp'>
                        {dataArr.length > 0 ? <ul>
                            {dataArr.map((element) => (
                                <li key={element.id}>
                                    <div className='each-sec-box'>
                                        <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                        <h4>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                    </div>
                                    <div className='each-sec-box'>
                                        <h4>{element.childId?.firstName}&nbsp;{element.childId?.lastName}</h4>
                                        <span>{element.formTitle}</span>
                                        <p className='flex-wrp'>{element.isParentSigned ?
                                            <span className='status complete'>{translateThis('Signed')}</span> :
                                            <span className='status pending'>{translateThis('Pending')}</span>}</p>
                                    </div>
                                    <div className='each-sec-box'>
                                        <ul className='btn-wrp'>
                                            <li><button className='pln-btn' type='button' onClick={() => { downloadPDF(element.childId?.id, element.userId?.id, element.formName) }}><SVGIcons.DownloadIcon /></button></li>
                                        </ul>
                                        <span>{element.isProviderSigned ?
                                            <span className='status complete'>{translateThis('Signed')}</span> :
                                            <AppButton
                                                onClick={() => {
                                                    setOpenSubmissionDialog(true);
                                                    setSelectedId(element.id);
                                                }}
                                                btnText='Sign'
                                            />}</span>
                                    </div>
                                    <Link className='full-li-btn' onClick={() => { onFormPreviewClick(element.formName, element) }} />
                                </li >
                            ))}
                        </ul> : <div className='empty-placeholder f-wrp'>
                            <div className='placeholder-con'>
                                <img src={AppIcons.placeholder} alt='empty' />
                                <h4>{translateThis('File not found')}</h4>
                            </div>
                        </div>
                        }
                    </div>

                </div>
                {openSubmissionDialog && <ConfirmDialog
                    isOpen={openSubmissionDialog}
                    onClose={() => {
                        setOpenSubmissionDialog(false);
                        setSelectedId('');
                        setProviderSignature('');
                    }}
                    title={`Log submission signature`}
                    className={'delete-popup'}
                    description={<div>
                        <TextInput
                            key={`provider_signature`}
                            label={translateThis('typeYourName')}
                            name='providerSignature'
                            id='providerSignature'
                            value={providerSignature}
                            type='text'
                            required
                            placeholder={translateThis('typeYourName')}
                            className='custom-textfield'
                            getValue={(_, value) => {
                                setProviderSignature(value)
                            }}
                            useDefaultValidation
                        />
                        <br />
                        <span>
                            By clicking this checkbox you confirm you have read and accepted the submission made by the parent. <span style={{ color: '#FF3D00' }}> This action cannot be undone.</span>
                        </span>
                    </div>}
                    okayButtonText={'Sign form'}
                    confirmButton={
                        <AppButton
                            onClick={submitDailyLog}
                            isDisabled={providerSignature.length < 2}
                            btnText={translateThis('signForm')}
                            isLoading={isSubmitting}
                        />
                    }
                />}
                {viewOpen ? <ModalPopUp
                    className='preview-popup'
                    isOpen={viewOpen}
                    onClose={onViewClose}
                    aria-labelledby="table-view"
                    aria-describedby="table-view"
                >
                    {selectedPreview}
                    <Button onClick={onViewClose} className='fill-btn'>Close</Button>
                </ModalPopUp> : null}
            </div>
        </>
    )
}


export default DynamicFormToSignTab;