import React, {useState, useEffect} from 'react';
import { Grid, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';


import './index.scss'

// Custom components
import SVGIcons from '../../../assets/images/icons/svgIcons';

import { DATE_EXTEND_FORMAT } from '../../../constants';
import ApiCaller from '../../../services/api/general';
import { parentConfigState } from '../../../state/reducerAtoms/parent.atom';
import { showSnackbar } from '../../../components/snackbar/index';
import RichTextViewer from '../../../components/rich-text-editor/TextViewer';
import { translateThis } from '../../../helpers/language.helper';


const ViewParentBulletin = (props) => {
    const {
        onClose = () => null,
    } = props;
    const apiCaller = new ApiCaller('bulletin');
    const bulletinId = useRecoilValue(parentConfigState).bulletinSection?.selectedBulletinId;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        image: {
            fileKey: '',
            tempURL: ''
        },
        title: '',
        subTitle: '',
        author: '',
        description: ''
    });


    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (bulletinId) {
            getData()
        }
        return () => {
            setData({})
        };
        // eslint-disable-next-line
    }, [bulletinId]);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(bulletinId)
            .then((data) => {
                if (data) {
                    setData(data)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <div className='back-btn-wrp bulletin-back-btn f-wrp'>
                <IconButton
                    onClick={onClose}
                    aria-label="Go Back">
                    <SVGIcons.BackIcon />
                    {translateThis('Back')}
                </IconButton>
            </div>
            <div className='parent-bulletin-view f-wrp' style={{ padding: '24px', borderRadius: '24px' }}>

            {(!isLoading && data.title !== '') ? <Grid container spacing={3}>
            {(data?.image?.tempURL) ? <Grid item xs={12} sm={12} md={5}>
                        <div className='preview-img-sec f-wrp'>
                            <img src={data?.image?.tempURL} alt='Bulletin' />
                        </div> 
                    </Grid> : <span className='no-img' />}
                    <Grid item xs={12} sm={12} md={7}>
                        <div className='bulletin-con-sec f-wrp'>
                            <div className='basic-details f-wrp'>
                                <span>{data?.author} &nbsp;</span>
                                {(data?.createdAt) ? <span>{dayjs(data?.createdAt).format(DATE_EXTEND_FORMAT)}</span> : null}
                            </div>
                            <div className='f-wrp'>
                                <h1>{data?.title}</h1>
                                <h2>{data?.subTitle}</h2>
                                {(data.description) ? <RichTextViewer richText={data.description} /> : null}
                                </div>
                        </div>
                    </Grid>
                </Grid> : <span>Loading...</span>}
            </div>
        </>
    );
}
ViewParentBulletin.propTypes = {
    onClose: PropTypes.func
}
export default ViewParentBulletin;
