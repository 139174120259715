import { Navigate, useRoutes } from 'react-router-dom';
import PrivateRoute from './private.route';

// pages
import HomePage from '../pages/home';
import LoginPage from '../pages/auth/login';
import RegisterPage from '../pages/auth/register';
import ForgotPasswordPage from '../pages/auth/forgotPassword';
import PasswordResetMailSuccess from '../pages/auth/passwordResetMailSuccess';
import Page404 from '../pages/pageNotFound';
import ContactUsPage from '../pages/contactUs';
import MyPortal from '../pages/myPortal/index';
import PaymentSuccess from '../pages/payment/successPage';
import PdfPreviewPage from '../pages/pdfRender';
import ResetPasswordPage from '../pages/auth/resetPassword';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = [
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: 'contact-us',
      element: <ContactUsPage />, 
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '/pdf-preview/:formId',
      element: <PdfPreviewPage />,
    },
    {
      path: 'my-portal',
      element: <PrivateRoute><MyPortal /></PrivateRoute>
    },
    {
      path: 'payment-success',
      element: <PaymentSuccess />
    },
    {
      path: 'auth',
      children: [
        { element: <Navigate to="login" />, index: true },
        { path:'register', element: <RegisterPage /> },
        { path: 'login', element: <LoginPage /> },
        {path: 'forgot-password', element: <ForgotPasswordPage />},
        {path: 'reset-password-email-sent', element: <PasswordResetMailSuccess />},
        {path: 'reset-password', element: <ResetPasswordPage />}
      ]
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const privateRoutes =  [];

  return useRoutes(routes.concat(privateRoutes));
}
