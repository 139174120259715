import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';

import { Autocomplete, Box, CircularProgress, FormControl, TextField } from '@mui/material';

import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../snackbar';
import { getAuthId, getAuthRoleName } from '../../helpers/auth.helper';
import { PARENT_ROLE } from '../../constants';
import { translateThis } from '../../helpers/language.helper';



const ChildrenListDropdown = ({
    onChange = () => null,
    className = ''
}) => {
    const parentId = getAuthId();
    const currentRole = getAuthRoleName();
    const apiCaller = new ApiCaller('children')
    const [isLoading, setIsLoading] = useState(true);
    const [dataArr, setDataArr] = useState([]);

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        if (parentId) {
            getDataArr()
        }
        return () => {
            setDataArr([]);
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        const filter = currentRole === PARENT_ROLE ? `&parentId=${parentId}&sortBy=firstName` : '&sortBy=firstName';
        apiCaller.getList({ query: filter })
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Handles the selection Event
     * @param {Event} event 
     */
    const handleChange = (value) => {
        if (typeof onChange === 'function') {
            onChange(value?.id || '');
        }

    };
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl sx={{ m: 1, minWidth: 200 }} disabled={isLoading}>
                <Autocomplete
                    ListboxProps={{ className: `${currentRole}-dropdown-options-list` }}
                    className={className}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {`${option.firstName} ${option.lastName}`}
                        </li>
                    )}
                    id="select-child-dropdown"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    options={dataArr}
                    sx={{ marginTop: '-15px' }}
                    onChange={(_, option) => {
                        handleChange(option);
                    }}
                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={translateThis("Select Child")}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </FormControl>

        </Box>
    )
}

ChildrenListDropdown.propTypes = {
    onChange: propTypes.func,
    className: propTypes.string
}

export default ChildrenListDropdown;