import axios from "axios";
import config from "../../config/config";
import { getAuthToken, logoutUser } from "../../helpers/auth.helper";
import { showSnackbar } from '../../components/snackbar';

// REACT_APP_API_URL
const { apiURL } = config

const axiosInstance = axios.create({
  baseURL: apiURL,
  // timeout: 1000,
  // headers: { 'Authorization': `Bearer ${getAuthToken()}` }
});

axiosInstance
  .interceptors
  .request
  .use((config) => {
    if (getAuthToken()) {
      config.headers.Authorization = `Bearer ${getAuthToken()}`;
    }
    return config;
  }, (error) => Promise.reject(error));

axiosInstance.interceptors.response.use((response) => response,
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    try {
      if (error.response?.status === 401 && getAuthToken()) {
        showSnackbar({}, () => logoutUser()).warning('Session expired. Please login');
      }
      if ([500, 404].includes(error.response?.status)) {
        showSnackbar().info('Something went wrong. please try again later!');
      }
      if (error.code === "ERR_NETWORK") {
        showSnackbar().failure('Server is unreachable. please try again later!');
      }
    } catch (e) {
      showSnackbar().failure('Something went wrong. please try again later!');
    }
    return Promise.reject(error);
  });

export default axiosInstance;