import React from 'react';
import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import AppIcons from '../../assets/images/icons/index';
import AppLayout from '../../layouts/app/AppLayout';
import RegisterForm from './forms/register.form';

import './auth.scss';

const RegisterPage = () => {
    // custom register functions here
    const pageName = 'Register';
    return (
        <AppLayout
            isLoadHeader={false}
            isLoadFooter={false}
            pageName={pageName}
            bodyClassName='Signup-wrp auth-layout'
        >
            <div className='auth-page-layout f-wrp'>
                <div className='container-fluid'>
                    <div className='flex-box'>
                        <div className='form-sec-wrp'>
                            <Link to={'/'} className='auth-logo'><img src={AppIcons.logo} alt='register' /></Link>
                            <div className='auth-form'>
                                <Stack className='form-tile'>
                                    <Typography variant="h3" fontStyle={'normal'} fontWeight={'400'}>Get started now</Typography>
                                    <Typography variant="h4" fontStyle={'normal'} fontWeight={'400'}>Enter your credentials</Typography>
                                </Stack>
                                <RegisterForm />
                            </div>
                        </div>
                        <div className='form-img-wrp'>
                            <span><img src={AppIcons.registerBG} alt='register' /></span>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default RegisterPage;