import * as React from 'react';
import TabLayout from '../../../components/tabs'; 
import ProviderPortalHeader from '../components/header';
import ProviderAccidentToCompleteTab from './toComplete';
import ProviderAccidentHistoryTab from './historyTab';
// import ProviderAccidentDownloadTab from './downloadTab';
import ProviderAccidentUploadTab from './uploadTab';
import ProviderAccidentToSignTab from './toSign.tab';

const AccidentPage = () => {

    const tabNames = ["To complete", "To Sign", "History", "To uploads"];
    const tabContents = [
        <ProviderAccidentToCompleteTab key={'to_complete'} />,
        <ProviderAccidentToSignTab key={'to_sign'} />,
        <ProviderAccidentHistoryTab key={'to_history'} />,
        // <ProviderAccidentDownloadTab key={'to_downloads'}/>,
        <ProviderAccidentUploadTab key={'to_uploads'} />
    ];

    return (
        <>
        <ProviderPortalHeader header='Accident' />
            <div className='tab-sec-wrp pay-table dailyComms-sec-tab f-wrp'>
                <TabLayout
                    tabContents={tabContents}
                    tabNames={tabNames}
                />
            </div>
        </>
    )
}

export default AccidentPage;