import { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';

// @mui
import { Stack, Divider, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import { showSnackbar } from '../../../../components/snackbar';
import TextInput from '../../../../components/inputs/textInput/textInput';

import '../../../../assets/css/form.scss';
import SVGIcons from '../../../../assets/images/icons/svgIcons';
import { submitForm } from '../../../../services/api/dynamicForms';
// ----------------------------------------------------------------------
import { getAuthId } from '../../../../helpers/auth.helper';
import { getChild, getChildFormBy } from '../../../../services/api/child';
import { answerGetter } from '../../../../helpers/dynamicForm.helper';
import { translateThis } from '../../../../helpers/language.helper';
import PDFDownloadButton from '../../../../components/buttons/pdfDownload.button';
import { DATE_FORMAT, DATE_TIME_EXTENDED_FORMAT, TIME_12HR_FORMAT } from '../../../../constants/index';
import { getUniqueId } from '../../../../helpers/helper.functions';
import TimeInput from '../../../../components/inputs/timeInput';

const AccidentForm = (props) => {
    const formName = 'provider_accident_form';
    const { childId, onChangeFormState = () => null } = props;
    const parentId = getAuthId();
    const stepRef = useRef(null)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState([]);
    const [selectedChild, setSelectedChild] = useState(null);
    const [formKey, setFormKey] = useState('');
    const [formToShow, setFormToShow] = useState('accident-details');
    const [signature, setSignature] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [accidentPartArr, setAccidentPartArr] = useState([]);
    const [firstAidArr, setFirstAidArr] = useState([]);
    const [typeOfInjuryArr, setTypeOfInjuryArr] = useState([]);
    const [seenByProviderArr, setSeenByProviderArr] = useState([]);
    const [spokeByProviderArr, setSpokeByProviderArr] = useState([]);
    // const [copyGivenToParentArr, setCopyGivenToParentArr] = useState([]);
    const [spokeByPhysicianArr, setSpokeByPhysicianArr] = useState([]);
    const [spokeWithNurseArr, setSpokeWithNurseArr] = useState([]);
    const [initialFormData, setInitialFormData] = useState(null);

    /**
    * The legendary use effect function
    * This will run very first on render
    */
    useEffect(() => {
        setFormKey(getUniqueId(formName))
        if (childId && parentId) {
            getData()
        }
        if (childId) {
            getChildById();
        }
        return () => {
            setInitialFormData({});
            setFormKey(getUniqueId(`${formName}-destroyed`))
        };
        // eslint-disable-next-line
    }, [childId, parentId]);

    // get child data from api
    const getChildById = () => {
        getChild(childId)
            .then(({ success, data }) => {
                if (success) {
                    setSelectedChild({ ...data });
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    // get data from api
    const getData = () => {
        setIsLoading(true);
        getChildFormBy(parentId, childId, formName)
            .then((data) => {
                if (data?.formData) {
                    setInitialFormData(data?.formData)
                    setFormData(data?.formData)
                } else {
                    setInitialFormData({})
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (answerObj) => {
        const filteredFormData = formData.filter((answer) => answer.questionId !== answerObj.questionId);
        filteredFormData.push(answerObj)
        setFormData(filteredFormData);
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = true;
        if (signature && childId && isSigned && selectedChild) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const submitThisForm = () => {
        const postData = {
            formName,
            formTitle: 'Accident',
            userId: selectedChild?.parentId,
            childId,
            formData,
            isParentSigned: false,
            isProviderSigned: true,
            additionalInfo: {
                isParentSigned: false,
                isProviderSigned: true,
                parentSignature: '',
                providerSignature: signature,
                submissionDate: dayjs().format(DATE_TIME_EXTENDED_FORMAT)
            },
        }
        if (isValidForm()) {
            // Submitting data to backend for registration
            setIsSubmitting(true);
            submitForm(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(data.message);
                    onChangeFormState()
                } else {
                    showSnackbar().failure(data.message)

                }
            }).finally(() => setIsSubmitting(false));

        } else {
            showSnackbar().warning("Please fill the required fields")
        }
    };

    /**
     * Invokes when we click a checkbox item
     * @param {string} selectedItem 
     * @param {boolean} checked 
     */
    const onSelectAccidentPartArr = (selectedItem, checked) => {
        let alreadySelectedItems = accidentPartArr;
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setAccidentPartArr(alreadySelectedItems);
        setFormValue({
            questionId: 'provider_accident_form_accidentFormQn_5_part_of_the_body',
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: 'provider_accident_form_accidentFormQn_5_part_of_the_body',
            type: 'Checkbox'
        })
    }
    /**
         * Invokes when we click a checkbox item
         * @param {string} selectedItem 
         * @param {boolean} checked 
         */
    const onSelectFirstAidArr = (selectedItem, checked) => {
        let alreadySelectedItems = firstAidArr;
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setFirstAidArr(alreadySelectedItems);
        setFormValue({
            questionId: 'provider_accident_form_accidentFormQn_5_first_aid',
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: 'provider_accident_form_accidentFormQn_5_first_aid',
            type: 'Checkbox'
        })
    }

    /**
             * Invokes when we click a checkbox item
             * @param {string} selectedItem 
             * @param {boolean} checked 
             */
    const onSelectTypeOfInjuryArr = (selectedItem, checked) => {
        let alreadySelectedItems = typeOfInjuryArr;
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setTypeOfInjuryArr(alreadySelectedItems);
        setFormValue({
            questionId: 'provider_accident_form_accidentFormQn_5_type_of_injury',
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: 'provider_accident_form_accidentFormQn_5_type_of_injury',
            type: 'Checkbox'
        })
    }

    /**
     * Invokes when we click a checkbox item
     * @param {string} selectedItem 
     * @param {boolean} checked 
     */
    const onSelectSeenByProviderArr = (selectedItem, checked, name) => {
        let alreadySelectedItems = seenByProviderArr;
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setSeenByProviderArr(alreadySelectedItems);
        setFormValue({
            questionId: name,
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: name,
            type: 'Checkbox'
        })
    }

    /**
     * Invokes when we click a checkbox item
     * @param {string} selectedItem 
     * @param {boolean} checked 
     */
    const onSelectSpokeByProviderArr = (selectedItem, checked, name) => {
        let alreadySelectedItems = spokeByProviderArr;
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setSpokeByProviderArr(alreadySelectedItems);
        setFormValue({
            questionId: name,
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: name,
            type: 'Checkbox'
        })
    }

    // const onSelectCopyGivenToParentArr = (selectedItem, checked, name) => {
    //     let alreadySelectedItems = copyGivenToParentArr;
    //     if (!checked) {
    //         alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
    //     } else {
    //         alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
    //         alreadySelectedItems.push(selectedItem)
    //     }
    //     setCopyGivenToParentArr(alreadySelectedItems);
    //     setFormValue({
    //         questionId: name,
    //         answer: alreadySelectedItems.join(', '),
    //         rawAnswer: alreadySelectedItems,
    //         translationKey: name,
    //         type: 'Checkbox'
    //     })
    // }

    /**
     * Invokes when we click a checkbox item
     * @param {string} selectedItem 
     * @param {boolean} checked 
     */
    const onSelectSpokeByPhysicianArr = (selectedItem, checked, name) => {
        let alreadySelectedItems = spokeByPhysicianArr;
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setSpokeByPhysicianArr(alreadySelectedItems);
        setFormValue({
            questionId: name,
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: name,
            type: 'Checkbox'
        })
    }

    /**
     * Invokes when we click a checkbox item
     * @param {string} selectedItem 
     * @param {boolean} checked 
     */
    const onSelectSpokeWithNurseArr = (selectedItem, checked, name) => {
        let alreadySelectedItems = spokeWithNurseArr;
        if (!checked) {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem);
        } else {
            alreadySelectedItems = alreadySelectedItems.filter((i) => i !== selectedItem)
            alreadySelectedItems.push(selectedItem)
        }
        setSpokeWithNurseArr(alreadySelectedItems);
        setFormValue({
            questionId: name,
            answer: alreadySelectedItems.join(', '),
            rawAnswer: alreadySelectedItems,
            translationKey: name,
            type: 'Checkbox'
        })
    }




    return (
        <>
            {isLoading && <span className='loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading && <div ref={stepRef} >
                <>
                    <Stack key={formKey} className='standard-form form-full-width panel-form' spacing={3}>
                        <div className='info-head f-wrp'>
                            <h2>{translateThis("Accident")}</h2>
                        </div>
                        <div className='form-slider-box f-wrp'>
                            {(formToShow) && <div style={{ display: formToShow === "accident-details" ? 'block' : 'none' }} className='left-form-container'>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp'>
                                            <h3>{translateThis("Details")}</h3>
                                        </div>
                                    </Grid>
                                    {(selectedChild) && <Grid item xs={12} sm={6} md={12} lg={6}>
                                        <Stack className='panel-form' spacing={3}>
                                            <TextField
                                                type='text'
                                                disabled
                                                defaultValue={`${selectedChild?.firstName} ${selectedChild?.lastName}`}
                                                name="childName"
                                                label={translateThis("Child’s name")}
                                            />
                                        </Stack>
                                    </Grid>}
                                    {selectedChild && <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <Stack className='panel-form calender-sec' spacing={3}>
                                            <TextField
                                                type='text'
                                                label={translateThis("Birthday")}
                                                value={dayjs(selectedChild?.birthday).format(DATE_FORMAT)}
                                                disabled
                                            />

                                        </Stack>
                                    </Grid>}
                                    <Grid item xs={12} sm={12} md={6} lg={3}>
                                        <FormControl className='border-option-input'>
                                            <FormLabel id="sexLabel">{translateThis('sex')}</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="Radio Role"
                                                defaultValue={answerGetter('provider_accident_form_question_sex', initialFormData?.formData)?.answer}
                                                name="provider_accident_form_question_sex"
                                                onChange={(e) => {
                                                    setFormValue({
                                                        questionId: 'provider_accident_form_Key_question_sex',
                                                        answer: e.target.value,
                                                        translationKey: 'provider_accident_form_question_sex',
                                                        type: 'radio'
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="Male" control={<Radio />} label={translateThis("Male")} />
                                                <FormControlLabel value="Female" control={<Radio />} label={translateThis("Female")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput
                                            key={`provider_accident_form_Key_childcareAddress`}
                                            label={translateThis('provider_accident_form_question_childcareAddress')}
                                            name='provider_accident_form_question_childcareAddress'
                                            id='provider_accident_form_question_childcareAddress'
                                            value={answerGetter('provider_accident_form_question_childcareAddress', initialFormData?.formData)?.answer}
                                            translationKey='provider_accident_form_question_childcareAddress'
                                            type='text'
                                            required
                                            placeholder={translateThis('provider_accident_form_question_childcareAddress')}
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'provider_accident_form_question_childcareAddress',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={3}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker
                                                    value={answerGetter('provider_accident_form_accidentDate', initialFormData?.formData)?.answer ?
                                                        dayjs(answerGetter('provider_accident_form_accidentDate', initialFormData?.formData)?.answer) :
                                                        undefined
                                                    }
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'provider_accident_form_Key_accidentDate',
                                                            answer: dayjs(v).format(DATE_FORMAT),
                                                            translationKey: 'provider_accident_form_accidentDate',
                                                            type: 'text'
                                                        })
                                                    }
                                                    }
                                                    label={translateThis("Accident date")} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <TimeInput
                                            key={`provider_accident_form_Key_accidentTime`}
                                            defaultValue={dayjs(answerGetter(`provider_accident_form_question_accidentTime`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                            label={translateThis('provider_accident_form_question_accidentTime')}
                                            onChange={(v) => {
                                                setFormValue({
                                                    questionId: `provider_accident_form_question_accidentTime`,
                                                    answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                    rawAnswer: dayjs(v),
                                                    translationKey: `provider_accident_form_question_accidentTime`,
                                                    type: 'text'
                                                })
                                            }}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className='flex-field'>
                                            <p>{translateThis("Number of children present")}</p>
                                            <div style={{ maxWidth: '100px' }}>
                                                <TextInput
                                                    key={`provider_accident_form_Key_numberOfChildrenPresent`}
                                                    label={translateThis('provider_accident_form_question_numberOfChildrenPresent')}
                                                    name='provider_accident_form_question_numberOfChildrenPresent'
                                                    id='provider_accident_form_question_numberOfChildrenPresent'
                                                    value={answerGetter('provider_accident_form_question_numberOfChildrenPresent', initialFormData?.formData)?.answer}
                                                    translationKey='provider_accident_form_question_numberOfChildrenPresent'
                                                    type='number'
                                                    required
                                                    placeholder='00'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_accident_form_question_numberOfChildrenPresent',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <div className='flex-field' >
                                            <p>{translateThis("Number of staff members present")}</p>
                                            <div style={{ maxWidth: '100px' }}>
                                                <TextInput
                                                    key={`provider_accident_form_Key_numberOfMembersPresent`}
                                                    label={translateThis('provider_accident_form_question_numberOfMembersPresent')}
                                                    name='provider_accident_form_question_numberOfMembersPresent'
                                                    id='provider_accident_form_question_numberOfMembersPresent'
                                                    value={answerGetter('provider_accident_form_question_numberOfMembersPresent', initialFormData?.formData)?.answer}
                                                    translationKey='provider_accident_form_question_numberOfMembersPresent'
                                                    type='text'
                                                    required
                                                    placeholder='00'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_accident_form_question_numberOfMembersPresent',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid container spacing={3} style={{ marginLeft: '0', marginTop: '0px' }}>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={3}>
                                                    <FormControl className='with-txtArea-sec'>
                                                        <FormLabel className='main-head' id="accidentFormQn_1">{translateThis('accidentFormQn_1')}</FormLabel>
                                                        <RadioGroup row
                                                            aria-labelledby="Radio Role"
                                                            defaultValue={answerGetter('provider_accident_form_accidentFormQn_1', initialFormData?.formData)?.answer}
                                                            name="provider_accident_form_accidentFormQn_1"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_accidentFormQn_1',
                                                                    answer: e.target.value,
                                                                    translationKey: 'provider_accident_form_accidentFormQn_1',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >

                                                            <FormControlLabel value="Not notified" control={<Radio />} label={translateThis("Not notified")} />
                                                            <FormControlLabel className='with-txt-box' value="Notified" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                <span>{translateThis("Notified")}</span>
                                                                {(answerGetter('provider_accident_form_accidentFormQn_1', formData)?.answer === "Notified") && <TimeInput
                                                                    key={`provider_accident_form_accidentFormQn_1_notified`}
                                                                    defaultValue={dayjs(answerGetter(`provider_accident_form_accidentFormQn_1_notified`, initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                                    label={translateThis('provider_accident_form_accidentFormQn_1_notified')}
                                                                    onChange={(v) => {
                                                                        setFormValue({
                                                                            questionId: `provider_accident_form_accidentFormQn_1_notified`,
                                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                                            rawAnswer: dayjs(v),
                                                                            translationKey: `provider_accident_form_accidentFormQn_1_notified`,
                                                                            type: 'text'
                                                                        })
                                                                    }}
                                                                />}
                                                            </div>
                                                            } />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <br />
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={3}>
                                                    <FormControl className='with-txtArea-sec'>
                                                        <FormLabel className='main-head' id="accidentFormQn_3">{translateThis('accidentFormQn_3')}</FormLabel>
                                                        <RadioGroup row
                                                            aria-labelledby="Radio Role"
                                                            defaultValue={answerGetter('provider_accident_form_accidentFormQn_3', initialFormData?.formData)?.answer}
                                                            name="provider_accident_form_accidentFormQn_3"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_accidentFormQn_3',
                                                                    answer: e.target.value,
                                                                    translationKey: 'provider_accident_form_accidentFormQn_3',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >

                                                            <FormControlLabel value="Bike" control={<Radio />} label={translateThis("Bike")} />
                                                            <FormControlLabel value="Climber" control={<Radio />} label={translateThis("Climber")} />
                                                            <FormControlLabel value="Door" control={<Radio />} label={translateThis("Door")} />
                                                            <FormControlLabel value="Furniture" control={<Radio />} label={translateThis("Furniture")} />
                                                            <FormControlLabel value="Not applicable" control={<Radio />} label={translateThis("Not applicable")} />
                                                            <FormControlLabel value="Playground" control={<Radio />} label={translateThis("Playground")} />
                                                            <FormControlLabel value="Slide" control={<Radio />} label={translateThis("Slide")} />
                                                            <FormControlLabel value="Swing" control={<Radio />} label={translateThis("Swing")} />
                                                            <FormControlLabel value="Tricycle" control={<Radio />} label={translateThis("Tricycle")} />
                                                            <FormControlLabel className='with-txt-box' value="Other" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                <span>{translateThis("Other")}</span>
                                                                {(answerGetter('provider_accident_form_accidentFormQn_3', formData)?.answer === "Other") && <TextInput
                                                                    key={`provider_accident_form_Key_accidentFormQn_3_other`}
                                                                    label={translateThis('provider_accident_form_accidentFormQn_3_other')}
                                                                    name='provider_accident_form_accidentFormQn_3_other'
                                                                    id='provider_accident_form_accidentFormQn_3_other'
                                                                    value={answerGetter('provider_accident_form_accidentFormQn_3_other', initialFormData?.formData)?.answer}
                                                                    translationKey='provider_accident_form_accidentFormQn_3_other'
                                                                    type='text'
                                                                    required
                                                                    placeholder='Type here'
                                                                    className='custom-textfield'
                                                                    getValue={(name, value) => {
                                                                        setFormValue({
                                                                            questionId: name,
                                                                            answer: value,
                                                                            translationKey: 'provider_accident_form_accidentFormQn_3_other',
                                                                            type: 'text'
                                                                        })
                                                                    }}
                                                                    useDefaultValidation
                                                                />}
                                                            </div>
                                                            } />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <br />
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={3}>
                                                    <FormControl className='with-txtArea-sec'>
                                                        <FormLabel className='main-head' id="accidentFormQn_5">{translateThis('accidentFormQn_5')}</FormLabel>
                                                        <RadioGroup row
                                                            aria-labelledby="Radio Role"
                                                            defaultValue={answerGetter('provider_accident_form_accidentFormQn_5', initialFormData?.formData)?.answer}
                                                            name="provider_accident_form_accidentFormQn_5"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_accidentFormQn_5',
                                                                    answer: e.target.value,
                                                                    translationKey: 'provider_accident_form_accidentFormQn_5',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >

                                                            <FormControlLabel value="Left side" control={<Radio />} label={translateThis("Left side")} />
                                                            <FormControlLabel value="Right side" control={<Radio />} label={translateThis("Right side")} />
                                                        </RadioGroup>

                                                        <FormGroup
                                                            row style={{ paddingTop: '20px' }}
                                                            aria-labelledby="5. Part of body involved"
                                                            defaultValue={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.answer}
                                                            id="provider_accident_form_accidentFormQn_5_part_of_the_body"
                                                            name="provider_accident_form_accidentFormQn_5_part_of_the_body"
                                                            onChange={(e) => {
                                                                onSelectAccidentPartArr(e.target.value, e.target.checked);
                                                            }}
                                                        >
                                                            <FormControlLabel value="Ankle" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Ankle')} />} label={translateThis("Ankle")} />
                                                            <FormControlLabel value="Arm" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Arm')} />} label={translateThis("Arm")} />
                                                            <FormControlLabel value="Back" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Back')} />} label={translateThis("Back")} />
                                                            <FormControlLabel value="Buttocks" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Buttocks')} />} label={translateThis("Buttocks")} />
                                                            <FormControlLabel value="Chest" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Chest')} />} label={translateThis("Chest")} />
                                                            <FormControlLabel value="Ear" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Ear')} />} label={translateThis("Ear")} />
                                                            <FormControlLabel value="Eye" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Eye')} />} label={translateThis("Eye")} />
                                                            <FormControlLabel value="Foot" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Foot')} />} label={translateThis("Foot")} />
                                                            <FormControlLabel value="Genitals" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Genitals')} />} label={translateThis("Genitals")} />
                                                            <FormControlLabel value="Hand" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Hand')} />} label={translateThis("Hand")} />
                                                            <FormControlLabel value="Head" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Head')} />} label={translateThis("Head")} />
                                                            <FormControlLabel value="Leg" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Leg')} />} label={translateThis("Leg")} />
                                                            <FormControlLabel value="Mouth" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Mouth')} />} label={translateThis("Mouth")} />
                                                            <FormControlLabel value="Neck" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Neck')} />} label={translateThis("Neck")} />
                                                            <FormControlLabel value="Nose" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Nose')} />} label={translateThis("Nose")} />
                                                            <FormControlLabel value="Other face part" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Other face part')} />} label={translateThis("Other face part")} />
                                                            <FormControlLabel value="Tooth" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Tooth')} />} label={translateThis("Tooth")} />
                                                            <FormControlLabel value="Wrist" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Wrist')} />} label={translateThis("Wrist")} />
                                                            <FormControlLabel value="Other" className='with-txt-box' control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', initialFormData)?.rawAnswer.includes('Other')} />} label={
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                    <span>{translateThis("Other")}</span>
                                                                    {answerGetter('provider_accident_form_accidentFormQn_5_part_of_the_body', formData)?.rawAnswer.includes('Other') && <TextInput
                                                                        key={`provider_accident_form_Key_accidentFormQn_5_other`}
                                                                        label={translateThis('provider_accident_form_accidentFormQn_5_other')}
                                                                        name='provider_accident_form_accidentFormQn_5_other'
                                                                        id='provider_accident_form_accidentFormQn_5_other'
                                                                        value={answerGetter('provider_accident_form_accidentFormQn_5_other', initialFormData?.formData)?.answer}
                                                                        translationKey='provider_accident_form_accidentFormQn_5_other'
                                                                        type='text'
                                                                        placeholder='Type here'
                                                                        className='custom-textfield'
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: 'provider_accident_form_accidentFormQn_5_other',
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />}
                                                                </div>
                                                            } />

                                                        </FormGroup>

                                                    </FormControl>
                                                </Stack>
                                            </Grid>

                                            <br />
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={3}>
                                                    <FormControl className='with-txtArea-sec'>
                                                        <FormLabel className='main-head' id="accidentFormQn_7">{translateThis('accidentFormQn_7')}</FormLabel>

                                                        <FormGroup row style={{ paddingTop: '20px' }}
                                                            aria-labelledby="5. Part of body involved"
                                                            defaultValue={answerGetter('provider_accident_form_accidentFormQn_5_first_aid', initialFormData)?.answer}
                                                            id="provider_accident_form_accidentFormQn_5_first_aid"
                                                            name="provider_accident_form_accidentFormQn_5_first_aid"
                                                            onChange={(e) => {
                                                                onSelectFirstAidArr(e.target.value, e.target.checked);
                                                            }}>
                                                            <FormControlLabel value="Bandage" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_first_aid', initialFormData)?.rawAnswer.includes('Bandage')} />} label={translateThis("Bandage")} />
                                                            <FormControlLabel value="Cold pack" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_first_aid', initialFormData)?.rawAnswer.includes('Cold pack')} />} label={translateThis("Cold pack")} />
                                                            <FormControlLabel value="Comfort" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_first_aid', initialFormData)?.rawAnswer.includes('Comfort')} />} label={translateThis("Comfort")} />
                                                            <FormControlLabel value="Elevation" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_first_aid', initialFormData)?.rawAnswer.includes('Elevation')} />} label={translateThis("Elevation")} />
                                                            <FormControlLabel value="Pressure" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_first_aid', initialFormData)?.rawAnswer.includes('Pressure')} />} label={translateThis("Pressure")} />
                                                            <FormControlLabel value="Washing" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_first_aid', initialFormData)?.rawAnswer.includes('Washing')} />} label={translateThis("Washing")} />


                                                            <FormControlLabel value="Other" className='with-txt-box' control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_first_aid', initialFormData)?.rawAnswer.includes('Other')} />} label={
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                    <span>{translateThis("Other")}</span>
                                                                    {answerGetter('provider_accident_form_accidentFormQn_5_first_aid', formData)?.rawAnswer.includes('Other') && <TextInput
                                                                        key={`provider_accident_form_accidentFormQn_5_first_aid_other`}
                                                                        label={translateThis('provider_accident_form_accidentFormQn_5_other')}
                                                                        name='provider_accident_form_accidentFormQn_5_first_aid_other'
                                                                        id='provider_accident_form_accidentFormQn_5_first_aid_other'
                                                                        value={answerGetter('provider_accident_form_accidentFormQn_5_first_aid_other', initialFormData?.formData)?.answer}
                                                                        translationKey='provider_accident_form_accidentFormQn_5_first_aid_other'
                                                                        type='text'
                                                                        placeholder='Type here'
                                                                        className='custom-textfield'
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: 'provider_accident_form_accidentFormQn_5_first_aid_other',
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />}
                                                                </div>
                                                            } />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={3}>
                                                    <FormControl className='with-txtArea-sec'>
                                                        <FormLabel className='main-head' id="accidentFormQn_2">{translateThis('accidentFormQn_2')}</FormLabel>
                                                        <RadioGroup row
                                                            aria-labelledby="Radio Role"
                                                            defaultValue={answerGetter('provider_accident_form_accidentFormQn_2', initialFormData?.formData)?.answer}
                                                            name="provider_accident_form_accidentFormQn_2"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_accidentFormQn_2',
                                                                    answer: e.target.value,
                                                                    translationKey: 'provider_accident_form_accidentFormQn_2',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >

                                                            <FormControlLabel value="Bathroom" control={<Radio />} label={translateThis("Bathroom")} />
                                                            <FormControlLabel value="Classroom" control={<Radio />} label={translateThis("Classroom")} />
                                                            <FormControlLabel value="Doorway" control={<Radio />} label={translateThis("Doorway")} />
                                                            <FormControlLabel value="Field trip" control={<Radio />} label={translateThis("Field trip")} />
                                                            <FormControlLabel value="Hallway" control={<Radio />} label={translateThis("Hallway")} />
                                                            <FormControlLabel value="Office" control={<Radio />} label={translateThis("Office")} />
                                                            <FormControlLabel value="Playground" control={<Radio />} label={translateThis("Playground")} />
                                                            <FormControlLabel value="Unknown" control={<Radio />} label={translateThis("Unknown")} />
                                                            <FormControlLabel value="Vehicle " control={<Radio />} label={translateThis("Vehicle")} />

                                                            <FormControlLabel className='with-txt-box' value="Other" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                <span>{translateThis("Other")}</span>
                                                                {(answerGetter('provider_accident_form_accidentFormQn_2', formData)?.answer === "Other") && <TextInput
                                                                    key={`provider_accident_form_Key_accidentFormQn_2_other`}
                                                                    label={translateThis('provider_accident_form_accidentFormQn_2_other')}
                                                                    name='provider_accident_form_accidentFormQn_2_other'
                                                                    id='provider_accident_form_accidentFormQn_2_other'
                                                                    value={answerGetter('provider_accident_form_accidentFormQn_2_other', initialFormData?.formData)?.answer}
                                                                    translationKey='provider_accident_form_accidentFormQn_2_other'
                                                                    type='text'
                                                                    required
                                                                    placeholder='Type here'
                                                                    className='custom-textfield'
                                                                    getValue={(name, value) => {
                                                                        setFormValue({
                                                                            questionId: name,
                                                                            answer: value,
                                                                            translationKey: 'provider_accident_form_accidentFormQn_2_other',
                                                                            type: 'text'
                                                                        })
                                                                    }}
                                                                    useDefaultValidation
                                                                />}
                                                            </div>
                                                            } />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <br />
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={3}>
                                                    <FormControl className='with-txtArea-sec'>
                                                        <FormLabel className='main-head' id="accidentFormQn_4">{translateThis('accidentFormQn_4')}</FormLabel>
                                                        <RadioGroup row
                                                            aria-labelledby="Radio Role"
                                                            defaultValue={answerGetter('provider_accident_form_accidentFormQn_4', initialFormData?.formData)?.answer}
                                                            name="provider_accident_form_accidentFormQn_4"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_accidentFormQn_4',
                                                                    answer: e.target.value,
                                                                    translationKey: 'provider_accident_form_accidentFormQn_4',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >
                                                            <FormControlLabel style={{ width: '100%', minWidth: '100%', maxWidth: '100%' }} className='with-txt-box' value="Fall to surface" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                <span style={{ minWidth: 'max-content' }}>{translateThis("Fall to surface height of fall")}</span>
                                                                {(answerGetter('provider_accident_form_accidentFormQn_4', formData)?.answer === "Fall to surface") && <div style={{ minWidth: '70px' }}>
                                                                    <TextInput
                                                                        key={`provider_accident_form_Key_accidentFormQn_4_feet`}
                                                                        label={translateThis('provider_accident_form_accidentFormQn_4_feet')}
                                                                        name='provider_accident_form_accidentFormQn_4_feet'
                                                                        id='provider_accident_form_accidentFormQn_4_feet'
                                                                        value={answerGetter('provider_accident_form_accidentFormQn_4_feet', initialFormData?.formData)?.answer}
                                                                        translationKey='provider_accident_form_accidentFormQn_4_feet'
                                                                        type='text'
                                                                        required
                                                                        placeholder={translateThis('provider_accident_form_accidentFormQn_4_feet')}
                                                                        className='custom-textfield'
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: 'provider_accident_form_accidentFormQn_4_feet',
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />
                                                                </div>}

                                                                {(answerGetter('provider_accident_form_accidentFormQn_4', formData)?.answer === "Fall to surface") && <div style={{ minWidth: '125px' }}>
                                                                    <TextInput
                                                                        key={`provider_accident_form_Key_accidentFormQn_4_surfaceType`}
                                                                        label={translateThis('provider_accident_form_accidentFormQn_4_surfaceType')}
                                                                        name='provider_accident_form_accidentFormQn_4_surfaceType'
                                                                        id='provider_accident_form_accidentFormQn_4_surfaceType'
                                                                        value={answerGetter('provider_accident_form_accidentFormQn_4_surfaceType', initialFormData?.formData)?.answer}
                                                                        translationKey='provider_accident_form_accidentFormQn_4_surfaceType'
                                                                        type='text'
                                                                        required
                                                                        placeholder={translateThis('provider_accident_form_accidentFormQn_4_surfaceType')}
                                                                        className='custom-textfield'
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: 'provider_accident_form_accidentFormQn_4_surfaceType',
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />
                                                                </div>}
                                                            </div>
                                                            } />

                                                            <FormControlLabel value="Bitten by child" control={<Radio />} label={translateThis("Bitten by child")} />
                                                            <FormControlLabel value="Hit by child" control={<Radio />} label={translateThis("Hit by child")} />
                                                            <FormControlLabel value="Pushed by child" control={<Radio />} label={translateThis("Pushed by child")} />
                                                            <FormControlLabel value="Object" control={<Radio />} label={translateThis("Object")} />
                                                            <FormControlLabel value="Insect bite" control={<Radio />} label={translateThis("Insect bite")} />
                                                            <FormControlLabel value="Insect sting" control={<Radio />} label={translateThis("Insect sting")} />
                                                            <FormControlLabel value="Motor vehicle" control={<Radio />} label={translateThis("Motor vehicle")} />
                                                            <FormControlLabel value="Running" control={<Radio />} label={translateThis("Running")} />
                                                            <FormControlLabel value="Tripping" control={<Radio />} label={translateThis("Tripping")} />
                                                            <FormControlLabel className='with-txt-box' value="Other" control={<Radio />} label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                <span>{translateThis("Other")}</span>
                                                                {(answerGetter('provider_accident_form_accidentFormQn_4', formData)?.answer === "Other") && <TextInput
                                                                    key={`provider_accident_form_Key_accidentFormQn_4_other`}
                                                                    label={translateThis('provider_accident_form_accidentFormQn_4_other')}
                                                                    name='provider_accident_form_accidentFormQn_4_other'
                                                                    id='provider_accident_form_accidentFormQn_4_other'
                                                                    value={answerGetter('provider_accident_form_accidentFormQn_4_other', initialFormData?.formData)?.answer}
                                                                    translationKey='provider_accident_form_accidentFormQn_4_other'
                                                                    type='text'
                                                                    required
                                                                    placeholder='Type here'
                                                                    className='custom-textfield'
                                                                    getValue={(name, value) => {
                                                                        setFormValue({
                                                                            questionId: name,
                                                                            answer: value,
                                                                            translationKey: 'provider_accident_form_accidentFormQn_4_other',
                                                                            type: 'text'
                                                                        })
                                                                    }}
                                                                    useDefaultValidation
                                                                />}
                                                            </div>
                                                            } />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <br />
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={3}>
                                                    <FormControl className='with-txtArea-sec'>
                                                        <FormLabel className='main-head' id="accidentFormQn_6">{translateThis('accidentFormQn_6')}</FormLabel>

                                                        <FormGroup row style={{ paddingTop: '20px' }}
                                                            aria-labelledby="6. Type of injury"
                                                            defaultValue={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.answer}
                                                            id="provider_accident_form_accidentFormQn_5_type_of_injury"
                                                            name="provider_accident_form_accidentFormQn_5_type_of_injury"
                                                            onChange={(e) => {
                                                                onSelectTypeOfInjuryArr(e.target.value, e.target.checked);
                                                            }}>
                                                            <FormControlLabel value="Broken bone" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Broken bone')} />} label={translateThis("Broken bone")} />
                                                            <FormControlLabel value="Bruise" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Bruise')} />} label={translateThis("Bruise")} />
                                                            <FormControlLabel value="Burn" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Burn')} />} label={translateThis("Burn")} />
                                                            <FormControlLabel value="Crushing injury" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Crushing injury')} />} label={translateThis("Crushing injury")} />
                                                            <FormControlLabel value="Cut" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Cut')} />} label={translateThis("Cut")} />
                                                            <FormControlLabel value="Dislocation" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Dislocation')} />} label={translateThis("Dislocation")} />
                                                            <FormControlLabel value="Loss of consciousness" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Loss of consciousness')} />} label={translateThis("Loss of consciousness")} />
                                                            <FormControlLabel value="Puncture" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Puncture')} />} label={translateThis("Puncture")} />
                                                            <FormControlLabel value="Scrape" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Scrape')} />} label={translateThis("Scrape")} />
                                                            <FormControlLabel value="Sprain" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Sprain')} />} label={translateThis("Sprain")} />
                                                            <FormControlLabel value="Swelling" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Swelling')} />} label={translateThis("Swelling")} />
                                                            <FormControlLabel value="Unknown" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Unknown')} />} label={translateThis("Unknown")} />
                                                            <FormControlLabel value="Other" className='with-txt-box' control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', initialFormData)?.rawAnswer.includes('Other')} />} label={
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                    <span>{translateThis("Other")}</span>
                                                                    {(answerGetter('provider_accident_form_accidentFormQn_5_type_of_injury', formData)?.rawAnswer.includes('Other')) && <TextInput
                                                                        key={`provider_accident_form_Key_accidentFormQn_6_other`}
                                                                        label={translateThis('provider_accident_form_accidentFormQn_6_other')}
                                                                        name='provider_accident_form_accidentFormQn_6_other'
                                                                        id='provider_accident_form_accidentFormQn_6_other'
                                                                        value={answerGetter('provider_accident_form_accidentFormQn_6_other', initialFormData?.formData)?.answer}
                                                                        translationKey='provider_accident_form_accidentFormQn_6_other'
                                                                        type='text'
                                                                        placeholder='Type here'
                                                                        className='custom-textfield'
                                                                        getValue={(name, value) => {
                                                                            setFormValue({
                                                                                questionId: name,
                                                                                answer: value,
                                                                                translationKey: 'provider_accident_form_accidentFormQn_6_other',
                                                                                type: 'text'
                                                                            })
                                                                        }}
                                                                        useDefaultValidation
                                                                    />}
                                                                </div>
                                                            } />



                                                        </FormGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                            <br />

                                            <Grid item xs={12} sm={12} md={12}>
                                                <Stack className='panel-form box-class-form' spacing={3}>
                                                    <FormControl className='with-txtArea-sec'>
                                                        <FormLabel className='main-head' id="accidentFormQn_8">{translateThis('accidentFormQn_8')}</FormLabel>
                                                        <RadioGroup row
                                                            aria-labelledby="Radio Role"
                                                            defaultValue={answerGetter('provider_accident_form_accidentFormQn_8', initialFormData?.formData)?.answer}
                                                            name="provider_accident_form_accidentFormQn_8"
                                                            onChange={(e) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_accidentFormQn_8',
                                                                    answer: e.target.value,
                                                                    translationKey: 'provider_accident_form_accidentFormQn_8',
                                                                    type: 'radio'
                                                                })
                                                            }}
                                                        >

                                                            <FormControlLabel value="Clinic" control={<Radio />} label={translateThis("Clinic")} />
                                                            <FormControlLabel value="Home" control={<Radio />} label={translateThis("Home")} />
                                                            <FormControlLabel value="Hospital" control={<Radio />} label={translateThis("Hospital")} />
                                                            <FormControlLabel value="Not applicable" control={<Radio />} label={translateThis("Not applicable")} />

                                                            <Stack className='panel-form box-class-form' spacing={3} style={{ maxWidth: '100%' }}>
                                                                <FormControlLabel className='with-txt-box' value="Doctor's office by:" control={<Radio />} label="Doctor’s office by:" />
                                                                {(answerGetter('provider_accident_form_accidentFormQn_8', formData)?.answer === "Doctor's office by:") && <RadioGroup row
                                                                    defaultValue={answerGetter('provider_accident_form_accidentFormQn_8_by', initialFormData?.formData)?.answer}
                                                                    name="provider_accident_form_accidentFormQn_8_by"
                                                                    onChange={(e) => {
                                                                        setFormValue({
                                                                            questionId: 'provider_accident_form_accidentFormQn_8_by',
                                                                            answer: e.target.value,
                                                                            translationKey: 'provider_accident_form_accidentFormQn_8_by',
                                                                            type: 'radio'
                                                                        })
                                                                    }}
                                                                    style={{ marginTop: '0', maxWidth: '100%' }}>
                                                                    <FormControlLabel value="Parent Guardian" control={<Radio />} label={translateThis("Parent Guardian")} />
                                                                    <FormControlLabel value="Emergency pick up" control={<Radio />} label={translateThis("Emergency pick up")} />
                                                                    <FormControlLabel value="Ambulance Firefighter Police" control={<Radio />} label={translateThis("Ambulance Firefighter Police")} />
                                                                </RadioGroup>}
                                                            </Stack>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp'>
                                            <h3>{translateThis("Comments")}</h3>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            key={`provider_accident_form_Key_comments`}
                                            label={translateThis('provider_accident_form_comments')}
                                            name='provider_accident_form_comments'
                                            id='provider_accident_form_comments'
                                            defaultValue={answerGetter('provider_accident_form_comments', initialFormData?.formData)?.answer}
                                            type='text'
                                            multiline
                                            placeholder='Corrective action needed to prevent reoccurrence'
                                            className='custom-textfield'
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: "provider_accident_form_comments",
                                                    answer: e.target.value,
                                                    translationKey: 'provider_accident_form_comments',
                                                    type: 'text'
                                                })
                                            }}
                                            rows={4}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            key={`provider_accident_form_Key_notes`}
                                            label={translateThis('provider_accident_form_notes')}
                                            name='provider_accident_form_notes'
                                            id='provider_accident_form_notes'
                                            defaultValue={answerGetter('provider_accident_form_notes', initialFormData?.formData)?.answer}
                                            translationKey='provider_accident_form_notes'
                                            type='text'
                                            multiline
                                            placeholder='Notes (optional)'
                                            className='custom-textfield'
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: "provider_accident_form_notes",
                                                    answer: e.target.value,
                                                    translationKey: 'provider_accident_form_notes',
                                                    type: 'text'
                                                })
                                            }}
                                            rows={4}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Divider />
                                    </Grid>

                                </Grid>
                            </div>}
                            {(formToShow) && <div style={{ display: formToShow === "health-details" ? 'block' : 'none' }} className='left-form-container'>
                                <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px' }}>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp'>
                                            <h3>{translateThis("Health contacts")}</h3>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='flex-field' style={{ gap: '24px' }}>
                                            <p style={{ width: '50%' }}>{translateThis("The following check up took place at")} </p>
                                            <div style={{ width: '50%' }}>
                                                <TextInput
                                                    key={`provider_accident_form_health_contact_Key_childcareAddress`}
                                                    label={translateThis('provider_accident_form_health_contact_question_childcareAddress')}
                                                    name='provider_accident_form_health_contact_question_childcareAddress'
                                                    id='provider_accident_form_health_contact_question_childcareAddress'
                                                    value={answerGetter('provider_accident_form_health_contact_question_childcareAddress', initialFormData?.formData)?.answer}
                                                    translationKey='provider_accident_form_health_contact_question_childcareAddress'
                                                    type='text'
                                                    placeholder='Childcare address'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_accident_form_health_contact_question_childcareAddress',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp'>
                                            <span>{translateThis("Check all that apply and document the contact and results of the contact")}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px', alignItems: 'center' }}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <FormGroup row
                                                    onChange={(e) => {
                                                        onSelectSeenByProviderArr(e.target.value, e.target.checked, 'provider_accident_form_accidentFormQn_5_seen_by_provider');
                                                    }}>
                                                    <FormControlLabel value="Child was seen by Provider / Substitute" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_seen_by_provider', initialFormData)?.rawAnswer.includes('Child was seen by Provider / Substitute')} />} label={translateThis("Child was seen by Provider")} />
                                                </FormGroup>
                                            </Grid>
                                            {(answerGetter('provider_accident_form_accidentFormQn_5_seen_by_provider', formData)?.rawAnswer.includes('Child was seen by Provider / Substitute')) && <Grid item xs={12} sm={12} md={3}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            value={answerGetter('provider_accident_form_health_contact_question_seenByProviderOrSubstituteDate', initialFormData?.formData)?.answer ?
                                                                dayjs(answerGetter('provider_accident_form_health_contact_question_seenByProviderOrSubstituteDate', initialFormData?.formData)?.answer) :
                                                                undefined
                                                            }
                                                            onChange={(v) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_health_contact_question_seenByProviderOrSubstituteDate',
                                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                                    translationKey: 'provider_accident_form_health_contact_question_seenByProviderOrSubstituteDate',
                                                                    type: 'text'
                                                                })
                                                            }
                                                            }
                                                            label={translateThis("Date")} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>}
                                            {(answerGetter('provider_accident_form_accidentFormQn_5_seen_by_provider', formData)?.rawAnswer.includes('Child was seen by Provider / Substitute')) && <Grid item xs={12} sm={12} md={3}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter('provider_accident_form_health_contact_question_seenByProviderOrSubstituteTime', initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_accident_form_health_contact_question_seenByProviderOrSubstituteTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'provider_accident_form_health_contact_question_seenByProviderOrSubstituteTime',
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: 'provider_accident_form_health_contact_question_seenByProviderOrSubstituteTime',
                                                            type: 'text'
                                                        })
                                                    }}
                                                />

                                            </Grid>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px', alignItems: 'center' }}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <FormGroup row
                                                    onChange={(e) => {
                                                        onSelectSpokeByProviderArr(e.target.value, e.target.checked, 'provider_accident_form_accidentFormQn_5_spoke_with_provider');
                                                    }}>
                                                    <FormControlLabel value="Spoke with Provider / Substitute" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_spoke_with_provider', initialFormData)?.rawAnswer.includes('Spoke with Provider / Substitute')} />} label={translateThis("Spoke with Provider")} />
                                                </FormGroup>
                                            </Grid>
                                            {(answerGetter('provider_accident_form_accidentFormQn_5_spoke_with_provider', formData)?.rawAnswer.includes('Spoke with Provider / Substitute')) && <Grid item xs={12} sm={12} md={3}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            value={answerGetter('provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteDate', initialFormData?.formData)?.answer ?
                                                                dayjs(answerGetter('provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteDate', initialFormData?.formData)?.answer) :
                                                                undefined
                                                            }
                                                            onChange={(v) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteDate',
                                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                                    translationKey: 'provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteDate',
                                                                    type: 'text'
                                                                })
                                                            }
                                                            }
                                                            label={translateThis("Date")} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>}
                                            {(answerGetter('provider_accident_form_accidentFormQn_5_spoke_with_provider', formData)?.rawAnswer.includes('Spoke with Provider / Substitute')) && <Grid item xs={12} sm={12} md={3}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter('provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteTime', initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteTime',
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: 'provider_accident_form_health_contact_question_spokeWithProviderOrSubstituteTime',
                                                            type: 'text'
                                                        })
                                                    }}
                                                />

                                            </Grid>}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px', alignItems: 'center' }}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <FormGroup row
                                                    onChange={(e) => {
                                                        onSelectSpokeByPhysicianArr(e.target.value, e.target.checked, 'provider_accident_form_accidentFormQn_5_spoke_with_physician');
                                                    }}>
                                                    <FormControlLabel value="Spoke with Child's Physician" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_spoke_with_physician', initialFormData)?.rawAnswer.includes("Spoke with Child's Physician")} />} label={translateThis("Spoke with Child")} />
                                                </FormGroup>
                                            </Grid>
                                            {(answerGetter('provider_accident_form_accidentFormQn_5_spoke_with_physician', formData)?.rawAnswer.includes("Spoke with Child's Physician")) && <Grid item xs={12} sm={12} md={3}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            value={answerGetter('provider_accident_form_health_contact_question_spokeWithPhysicianDate', initialFormData?.formData)?.answer ?
                                                                dayjs(answerGetter('provider_accident_form_health_contact_question_spokeWithPhysicianDate', initialFormData?.formData)?.answer) :
                                                                undefined
                                                            }
                                                            onChange={(v) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_health_contact_question_spokeWithPhysicianDate',
                                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                                    translationKey: 'provider_accident_form_health_contact_question_spokeWithPhysicianDate',
                                                                    type: 'text'
                                                                })
                                                            }
                                                            }
                                                            label={translateThis("Date")} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>}
                                            {(answerGetter('provider_accident_form_accidentFormQn_5_spoke_with_physician', formData)?.rawAnswer.includes("Spoke with Child's Physician")) && <Grid item xs={12} sm={12} md={3}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter('provider_accident_form_health_contact_question_spokeWithPhysicianTime', initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_accident_form_health_contact_question_spokeWithPhysicianTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'provider_accident_form_health_contact_question_spokeWithPhysicianTime',
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: 'provider_accident_form_health_contact_question_spokeWithPhysicianTime',
                                                            type: 'text'
                                                        })
                                                    }}
                                                />


                                            </Grid>}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                                        <Grid container spacing={3} style={{ marginLeft: '-24px', marginTop: '0px', alignItems: 'center' }}>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <FormGroup row
                                                    onChange={(e) => {
                                                        onSelectSpokeWithNurseArr(e.target.value, e.target.checked, 'provider_accident_form_accidentFormQn_5_spoke_with_nurse');
                                                    }}>
                                                    <FormControlLabel value="Spoke with Nurse in the Doctor's office" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_5_spoke_with_nurse', initialFormData)?.rawAnswer.includes("Spoke with Nurse in the Doctor's office")} />} label={translateThis("Spoke with Nurse in the Doctor")} />
                                                </FormGroup>
                                            </Grid>
                                            {(answerGetter('provider_accident_form_accidentFormQn_5_spoke_with_nurse', formData)?.rawAnswer.includes("Spoke with Nurse in the Doctor's office")) && <Grid item xs={12} sm={12} md={3}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            value={answerGetter('provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeDate', initialFormData?.formData)?.answer ?
                                                                dayjs(answerGetter('provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeDate', initialFormData?.formData)?.answer) :
                                                                undefined
                                                            }
                                                            onChange={(v) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeDate',
                                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                                    translationKey: 'provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeDate',
                                                                    type: 'text'
                                                                })
                                                            }
                                                            }
                                                            label={translateThis("Date")} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>}
                                            {(answerGetter('provider_accident_form_accidentFormQn_5_spoke_with_nurse', formData)?.rawAnswer.includes("Spoke with Nurse in the Doctor's office")) && <Grid item xs={12} sm={12} md={3}>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter('provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeTime', initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeTime')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeTime',
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: 'provider_accident_form_health_contact_question_spokeWithNurseInDocOfficeTime',
                                                            type: 'text'
                                                        })
                                                    }}
                                                />
                                            </Grid>}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-txt red-box f-wrp' style={{ marginTop: '40px' }}>
                                            <p><b>{translateThis("note")} </b>{translateThis("noteText")}</p>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp'>
                                            <span>{translateThis("Provider Substitute Assistant observation or assessment")}</span>
                                        </div>
                                        <TextField
                                            key={`provider_accident_form_health_contact_Key_observation`}
                                            label={translateThis('provider_accident_form_health_contact_observation')}
                                            name='provider_accident_form_health_contact_observation'
                                            id='provider_accident_form_health_contact_observation'
                                            defaultValue={answerGetter('provider_accident_form_health_contact_observation', initialFormData?.formData)?.answer}
                                            type='text'
                                            required
                                            multiline
                                            placeholder='Type here'
                                            className='custom-textfield'
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: "provider_accident_form_health_contact_observation",
                                                    answer: e.target.value,
                                                    translationKey: 'provider_accident_form_health_contact_observation',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                            rows={4}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>

                                        <div className='info-head f-wrp'>
                                            <span>&nbsp;</span>
                                        </div>
                                        <div style={{ maxWidth: '200px', float: 'right', marginTop: '-8px' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        value={answerGetter('provider_accident_form_health_contact_question_observationDate', initialFormData?.formData)?.answer ?
                                                            dayjs(answerGetter('provider_accident_form_health_contact_question_observationDate', initialFormData?.formData)?.answer) :
                                                            undefined
                                                        }
                                                        onChange={(v) => {
                                                            setFormValue({
                                                                questionId: 'provider_accident_form_health_contact_question_observationDate',
                                                                answer: dayjs(v).format(DATE_FORMAT),
                                                                translationKey: 'provider_accident_form_health_contact_question_observationDate',
                                                                type: 'text'
                                                            })
                                                        }
                                                        }
                                                        label={translateThis("Date")} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <div className='info-head f-wrp'>
                                            <span>{translateThis("Staff signature")}</span>
                                        </div>
                                        <TextInput
                                            key={`provider_communication_form_Key_observer_signatureName`}
                                            label={translateThis('provider_communication_form_question_observer_signatureName')}
                                            name='provider_communication_form_question_observer_signatureName'
                                            id='provider_communication_form_question_observer_signatureName'
                                            value={answerGetter('provider_communication_form_question_observer_signatureName', initialFormData?.formData)?.answer}
                                            translationKey='provider_communication_form_question_observer_signatureName'
                                            type='text'
                                            required
                                            placeholder='Type your name'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'provider_communication_form_question_observer_signatureName',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-head f-wrp'>
                                            <span>{translateThis("Content of communication with nurse in the doctor")}</span>
                                        </div>
                                        <TextField
                                            key={`provider_accident_form_health_contact_Key_detailsFromNurseInDocOffice`}
                                            label={translateThis('provider_accident_form_health_contact_detailsFromNurseInDocOffice')}
                                            name='provider_accident_form_health_contact_detailsFromNurseInDocOffice'
                                            id='provider_accident_form_health_contact_detailsFromNurseInDocOffice'
                                            defaultValue={answerGetter('provider_accident_form_health_contact_detailsFromNurseInDocOffice', initialFormData?.formData)?.answer}
                                            type='text'
                                            required
                                            multiline
                                            placeholder='Type here'
                                            className='custom-textfield'
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: "provider_accident_form_health_contact_detailsFromNurseInDocOffice",
                                                    answer: e.target.value,
                                                    translationKey: 'provider_accident_form_health_contact_detailsFromNurseInDocOffice',
                                                    type: 'text'
                                                })
                                            }}
                                            rows={4}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>

                                        <div className='info-head f-wrp'>
                                            <span>&nbsp;</span>
                                        </div>
                                        <div style={{ maxWidth: '200px', float: 'right', marginTop: '-8px' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        value={answerGetter('provider_accident_form_health_contact_question_observationDate_2', initialFormData?.formData)?.answer ?
                                                            dayjs(answerGetter('provider_accident_form_health_contact_question_observationDate_2', initialFormData?.formData)?.answer) :
                                                            undefined
                                                        }
                                                        onChange={(v) => {
                                                            setFormValue({
                                                                questionId: 'provider_accident_form_health_contact_question_observationDate_2',
                                                                answer: dayjs(v).format(DATE_FORMAT),
                                                                translationKey: 'provider_accident_form_health_contact_question_observationDate_2',
                                                                type: 'text'
                                                            })
                                                        }
                                                        }
                                                        label={translateThis("Date")} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <div className='info-head f-wrp'>
                                            <span>{translateThis("Staff signature")}</span>
                                        </div>
                                        <TextInput
                                            key={`provider_communication_form_Key_observer_signatureName_2`}
                                            label={translateThis('provider_communication_form_question_observer_signatureName_2')}
                                            name='provider_communication_form_question_observer_signatureName_2'
                                            id='provider_communication_form_question_observer_signatureName_2'
                                            value={answerGetter('provider_communication_form_question_observer_signatureName_2', initialFormData?.formData)?.answer}
                                            translationKey='provider_communication_form_question_observer_signatureName_2'
                                            type='text'
                                            required
                                            placeholder='Type your name'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'provider_communication_form_question_observer_signatureName_2',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <RadioGroup row style={{ paddingTop: '20px', alignItems: 'center' }}
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'provider_accident_form_accidentFormQn_5_follow_up_needed',
                                                    answer: e.target.value,
                                                    translationKey: 'provider_accident_form_accidentFormQn_5_follow_up_needed',
                                                    type: 'radio'
                                                })
                                            }}>
                                            <FormLabel id="followNeeded" style={{ paddingRight: '24px' }}>{translateThis('followNeeded')}</FormLabel>
                                            <FormControlLabel value="Yes" control={<Radio />} label={translateThis("Yes")} />
                                            <FormControlLabel value="No" control={<Radio />} label={translateThis("No")} />

                                        </RadioGroup>
                                        {(answerGetter('provider_accident_form_accidentFormQn_5_follow_up_needed', formData)?.answer === "Yes") && <TextField
                                            key={`provider_accident_form_health_contact_Key_followNeeded`}
                                            label={translateThis('provider_accident_form_health_contact_followNeeded')}
                                            name='provider_accident_form_health_contact_followNeeded'
                                            id='provider_accident_form_health_contact_followNeeded'
                                            defaultValue={answerGetter('provider_accident_form_health_contact_followNeeded', initialFormData?.formData)?.answer}
                                            type='text'
                                            required
                                            multiline
                                            placeholder='If yes, what needs to be done?'
                                            className='custom-textfield'
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'provider_accident_form_health_contact_followNeeded',
                                                    answer: e.target.value,
                                                    translationKey: 'provider_accident_form_health_contact_followNeeded',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                            rows={4}
                                        />}
                                        <br />
                                        {(answerGetter('provider_accident_form_accidentFormQn_5_follow_up_needed', formData)?.answer === "Yes") && <TextField
                                            key={`provider_accident_form_health_contact_Key_followNeeded_1`}
                                            label={translateThis('provider_accident_form_health_contact_followNeeded_1')}
                                            name='provider_accident_form_health_contact_followNeeded_1'
                                            id='provider_accident_form_health_contact_followNeeded_1'
                                            defaultValue={answerGetter('provider_accident_form_health_contact_followNeeded_1', initialFormData?.formData)?.answer}
                                            type='text'
                                            required
                                            multiline
                                            placeholder='Follow-up was provided as follows:'
                                            className='custom-textfield'
                                            onChange={(e) => {
                                                setFormValue({
                                                    questionId: 'provider_accident_form_health_contact_followNeeded_1',
                                                    answer: e.target.value,
                                                    translationKey: 'provider_accident_form_health_contact_followNeeded_1',
                                                    type: 'text'
                                                })
                                            }}
                                            rows={4}
                                        />}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>

                                        <div className='info-head f-wrp'>
                                            <span>&nbsp;</span>
                                        </div>
                                        <div style={{ maxWidth: '200px', float: 'right', marginTop: '-8px' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        value={answerGetter('provider_accident_form_health_contact_question_observationDate_3', initialFormData?.formData)?.answer ?
                                                            dayjs(answerGetter('provider_accident_form_health_contact_question_observationDate_3', initialFormData?.formData)?.answer) :
                                                            undefined
                                                        }
                                                        onChange={(v) => {
                                                            setFormValue({
                                                                questionId: 'provider_accident_form_health_contact_question_observationDate_3',
                                                                answer: dayjs(v).format(DATE_FORMAT),
                                                                translationKey: 'provider_accident_form_health_contact_question_observationDate_3',
                                                                type: 'text'
                                                            })
                                                        }
                                                        }
                                                        label={translateThis("Date")} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <div className='info-head f-wrp'>
                                            <span>{translateThis("Staff signature")}</span>
                                        </div>
                                        <TextInput
                                            key={`provider_communication_form_Key_observer_signatureName_3`}
                                            label={translateThis('provider_communication_form_question_observer_signatureName_3')}
                                            name='provider_communication_form_question_observer_signatureName_3'
                                            id='provider_communication_form_question_observer_signatureName_3'
                                            value={answerGetter('provider_communication_form_question_observer_signatureName_3', initialFormData?.formData)?.answer}
                                            translationKey='provider_communication_form_question_observer_signatureName_3'
                                            type='text'
                                            required
                                            placeholder='Type your name'
                                            className='custom-textfield'
                                            getValue={(name, value) => {
                                                setFormValue({
                                                    questionId: name,
                                                    answer: value,
                                                    translationKey: 'provider_communication_form_question_observer_signatureName_3',
                                                    type: 'text'
                                                })
                                            }}
                                            useDefaultValidation
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={12} md={12}>
                                        <div className='info-head'>
                                            <span>Parental notification of child's accident</span>
                                        </div>
                                        <FormGroup row
                                            onChange={(e) => {
                                                onSelectCopyGivenToParentArr(e.target.value, e.target.checked, 'provider_accident_form_accidentFormQn_copyGivenToParent');
                                            }}>
                                            <FormControlLabel value="Copy given to parent" control={<Checkbox defaultChecked={answerGetter('provider_accident_form_accidentFormQn_copyGivenToParent', initialFormData)?.rawAnswer.includes('Copy given to parent')} />} label="Copy given to parent" />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>

                                        <div className='form-declaration f-wrp' style={{ paddingBottom: '50px' }}>
                                            <p> <i style={{ position: 'relative', top: '30px' }}>I, &nbsp;</i>
                                                <i style={{ maxWidth: '210px', display: 'inline-block' }}>
                                                    <TextField value={signature} />
                                                </i>
                                                <i style={{ position: 'relative', top: '30px' }}>&nbsp;, confirm that I was notified that my child, &nbsp;</i>
                                                <i style={{ maxWidth: '210px', display: 'inline-block' }}><TextInput
                                                    key={`provider_accident_form_health_contact_Key_parentNotification_1`}
                                                    label={translateThis('provider_accident_form_health_contact_question_parentNotification_1')}
                                                    name='provider_accident_form_health_contact_question_parentNotification_1'
                                                    id='provider_accident_form_health_contact_question_parentNotification_1'
                                                    value={answerGetter('provider_accident_form_health_contact_question_parentNotification_1', initialFormData?.formData)?.answer}
                                                    translationKey='provider_accident_form_health_contact_question_parentNotification_1'
                                                    type='text'
                                                    required
                                                    placeholder='Child name'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_accident_form_health_contact_question_parentNotification_1',
                                                            type: 'text'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                /></i>
                                                <i style={{ position: 'relative', top: '30px' }}>&nbsp;,was involved in an accident during school hours, that resulted in an injury.</i> </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container spacing={2} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <div className='info-head'>
                                                    <span>Accident</span>
                                                </div>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            value={answerGetter('provider_accident_form_health_contact_question_dateOfAccident', initialFormData?.formData)?.answer ?
                                                                dayjs(answerGetter('provider_accident_form_health_contact_question_dateOfAccident', initialFormData?.formData)?.answer) :
                                                                undefined
                                                            }
                                                            onChange={(v) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_health_contact_question_dateOfAccident',
                                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                                    translationKey: 'provider_accident_form_health_contact_question_dateOfAccident',
                                                                    type: 'text'
                                                                })
                                                            }
                                                            }
                                                            label="Date of accident" />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <div className='info-head'>
                                                    <span>&nbsp;</span>
                                                </div>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter('provider_accident_form_health_contact_Accident_time', initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_accident_form_health_contact_Accident_time')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'provider_accident_form_health_contact_Accident_time',
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: 'provider_accident_form_health_contact_Accident_time',
                                                            type: 'text'
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <div className='info-head'>
                                                    <span>Notification</span>
                                                </div>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            value={answerGetter('provider_accident_form_health_contact_question_dateOfNotification', initialFormData?.formData)?.answer ?
                                                                dayjs(answerGetter('provider_accident_form_health_contact_question_dateOfNotification', initialFormData?.formData)?.answer) :
                                                                undefined
                                                            }
                                                            onChange={(v) => {
                                                                setFormValue({
                                                                    questionId: 'provider_accident_form_health_contact_question_dateOfNotification',
                                                                    answer: dayjs(v).format(DATE_FORMAT),
                                                                    translationKey: 'provider_accident_form_health_contact_question_dateOfNotification',
                                                                    type: 'text'
                                                                })
                                                            }
                                                            }
                                                            label="Date of notification" />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                                <div className='info-head'>
                                                    <span>&nbsp;</span>
                                                </div>
                                                <TimeInput
                                                    defaultValue={dayjs(answerGetter('provider_accident_form_health_contact_Notification_time', initialFormData)?.answer, TIME_12HR_FORMAT)}
                                                    label={translateThis('provider_accident_form_health_contact_Notification_time')}
                                                    onChange={(v) => {
                                                        setFormValue({
                                                            questionId: 'provider_accident_form_health_contact_Notification_time',
                                                            answer: dayjs(v).format(TIME_12HR_FORMAT),
                                                            rawAnswer: dayjs(v),
                                                            translationKey: 'provider_accident_form_health_contact_Notification_time',
                                                            type: 'text'
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container spacing={2} style={{ marginLeft: '-24px', marginTop: '0px' }}>
                                            <Grid item xs={12} sm={6} md={6} />
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <div className='info-head'>
                                                    <span>{translateThis("Staff signature")}</span>
                                                </div>
                                                <TextInput
                                                    key={`provider_accident_form_health_contact_Key_staffSignature_confirm`}
                                                    label={translateThis('provider_accident_form_health_contact_staffSignature_confirm')}
                                                    name='provider_accident_form_health_contact_staffSignature_confirm'
                                                    id='provider_accident_form_health_contact_staffSignature_confirm'
                                                    value={answerGetter('provider_accident_form_health_contact_staffSignature_confirm', initialFormData?.formData)?.answer}
                                                    translationKey='provider_accident_form_health_contact_staffSignature_confirm'
                                                    type='text'
                                                    required
                                                    placeholder='Type your name'
                                                    className='custom-textfield'
                                                    getValue={(name, value) => {
                                                        setSignature(value);
                                                        setFormValue({
                                                            questionId: name,
                                                            answer: value,
                                                            translationKey: 'provider_accident_form_health_contact_staffSignature_confirm',
                                                            type: 'number'
                                                        })
                                                    }}
                                                    useDefaultValidation
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} />
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <FormGroup fullWidth className='check-label f-wrp'>
                                                    <FormControlLabel required control={<Checkbox onChange={(e) => setIsSigned(e.target.checked)} />} label={
                                                        <span>{translateThis("signatureI")}, {signature && <b>{signature}, </b>} {translateThis("affirm that the information")}</span>
                                                    } />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>}

                            {/* <div className="formScrollBtn">
                                <button disabled={formToShow === 'accident-details'} type='button' onClick={() => {
                                    setFormToShow('accident-details');
                                    stepRef.current.scrollIntoView();
                                }}>❮</button>
                                <button disabled={formToShow === 'health-details'} type='button' onClick={() => {
                                    stepRef.current.scrollIntoView();
                                    setFormToShow('health-details')
                                }}>❯</button>
                            </div> */}
                        </div>
                    </Stack>
                </>

                <br />

                <div className='button-sec f-wrp'>
                    <div className='lft-btn-sec'>
                        <Button onClick={() => navigator('/')} className='cancel-btn'>{translateThis("cancel")}</Button>
                    </div>
                    <div className='ryt-btn-sec pln-btn-style'>
                        {(parentId && childId && formName === "sample123") ? <PDFDownloadButton
                            className='pln-btn'
                            parentId={parentId}
                            childId={childId}
                            formName={formName}
                        /> : null}
                        {formToShow === 'health-details' ? <Button disabled={formToShow === 'accident-details'} onClick={() => {
                            setFormToShow('accident-details');
                            stepRef.current.scrollIntoView();
                        }} className='pln-btn'>{translateThis("Previous")}</Button> : null}

                        {formToShow === 'accident-details' ? <Button disabled={formToShow === 'health-details'} onClick={() => {
                            stepRef.current.scrollIntoView();
                            setFormToShow('health-details')
                        }} className='fill-btn'>{translateThis("Next")}</Button> : <>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition='start'
                                startIcon={<></>} // To remove Mui warning
                                fullWidth
                                disabled={!isValidForm() || formToShow !== 'health-details' || !signature || !isSigned}
                                size="large"
                                type="submit"
                                variant="contained"
                                className='fill-btn'
                                onClick={submitThisForm}>
                                {translateThis("submit")}
                            </LoadingButton>
                        </>}

                    </div>
                </div>
            </div>}
        </>
    );
}

AccidentForm.propTypes = {
    childId: propTypes.string,
    onChangeFormState: propTypes.func,
}

export default AccidentForm;