export const ParentCommunicationFormModel = {
    incidentsAtHome: '',
    food: 'bottle',
    diaperChange: 'dry',
    mood:'normal',
    wakeUpTime:'',
    natureOfSleep: 'Slept soundly',
    comments: '',
    signature:''
}

/**
 * Parent communication form default values
 */
export const ParentCommunicationFormDefaultValues = [
    {
        "questionId": "incidentsAtHome",
        "answer": "",
        "translationKey": "incidentsAtHome",
        "type": "text"
    },
    {
        "questionId": "food",
        "answer": "Bottle | Nursed",
        "translationKey": "food",
        "type": "radio"
    },
    {
        "questionId": "diaperChange",
        "answer": "Dry",
        "translationKey": "diaperChange",
        "type": "radio"
    },
    {
        "questionId": "mood",
        "answer": "Normal",
        "translationKey": "mood",
        "type": "radio"
    },
    {
        "questionId": "wakeUpTime",
        "answer": "",
        "translationKey": "wakeUpTime",
        "type": "text"
    },
    {
        "questionId": "natureOfSleep",
        "answer": "Slept soundly",
        "translationKey": "natureOfSleep",
        "type": "text"
    },
    {
        "questionId": "comments",
        "answer": "",
        "translationKey": "comments",
        "type": "text"
    },
    {
        "questionId": "signature",
        "answer": "",
        "translationKey": "signature",
        "type": "text"
    }
]