import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// Components

import AppTable from '../../../components/table/index';
import ApiCaller from '../../../services/api/general';
import { showSnackbar } from '../../../components/snackbar/index';
import AppPopOverMenu from '../../../components/appPopOverMenu';
import ModalPopUp from '../../../components/modal/modal.popup';

import './index.scss';
import InboxView from './inboxView';
import AddEditMessageForm from './addEditMessage';
import { DATE_FORMAT, MONTH_TEXT_FORMAT, DATE_ONLY_FORMAT } from '../../../constants/index';
import { getAuthDetailsByKey } from '../../../helpers/auth.helper';
import { translateThis } from '../../../helpers/language.helper';
import AppIcons from '../../../assets/images/icons';



const InboxSection = () => {

    const [openViewMessageDialog, setOpenViewMessageDialog] = useState(false);
    const [showNewMessagePopup, setShowNewMessagePopup] = useState(false);

    const apiCaller = new ApiCaller('inbox');
    const [isLoading, setIsLoading] = useState(false);

    const [dataArr, setDataArr] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState({});

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr()
        return () => {
            setDataArr([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getDataArr = () => {
        setIsLoading(true);
        apiCaller.getList({query: `email=${getAuthDetailsByKey('email')}`})
            .then((data) => setDataArr(data?.results || []))
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone',
            flex: 1,
            minWidth: 160,
            valueFormatter: ({ val }) => val || '-'
        },
        {
            field: 'subject',
            headerName: 'Subject',
            flex: 1,
            minWidth: 160,
        },
        // {
        //     field: 'message',
        //     headerName: 'Message ',
        //     flex: 1.3,
        //     minWidth: 160,
        //     renderCell: ({ row }) => <RichTextViewer richText={row.message} />,
        // },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 0.7,
            minWidth: 160,
            valueFormatter: ({ val }) => dayjs(val).format(DATE_FORMAT)
        },
        {
            field: 'id',
            headerName: 'Actions',
            flex: 0.5,
            width: 90,
            sortable: false,
            renderCell: ({ row }) => <AppPopOverMenu options={[
                {
                    label: 'View',
                    onClick: () => {
                        setOpenViewMessageDialog(true);
                        setSelectedMessage(row);

                    }
                },

            ]} />
        },
    ]


    return (
        <div className='parent-inbox-wrp f-wrp'>
            <Button onClick={() => setShowNewMessagePopup(true)} className='red-btn' style={{ maxWidth: '275px', float: 'right' }}>{translateThis("+ New Message")}</Button>

            <div className='inbox-con-wrp f-wrp'>
                <div style={{ width: '100%' }} className="table-sec-wrp f-wrp">
                    <div className='panel-table-sec parent-table PC-Table f-wrp'>
                        <AppTable
                            columns={tableColumns}
                            isLoading={isLoading}
                            data={dataArr}
                        />
                        <div className='mob-table-wrp childView f-wrp'>
                            {dataArr.length > 0 ? <ul>
                                {dataArr.map((element) => (
                                    <li key={element.id}>
                                        <div className='each-sec-box'>
                                            <h4>{dayjs(element.createdAt).format(DATE_ONLY_FORMAT)}</h4>
                                            <h4>{dayjs(element.createdAt).format(MONTH_TEXT_FORMAT)}</h4>
                                        </div>
                                        <div className='each-sec-box'>
                                            <h4>{element.name}</h4>
                                            <span>{element.subject}</span>
                                        </div>
                                        <Link className='full-li-btn' onClick={() => { setOpenViewMessageDialog(true); setSelectedMessage(element) }} />
                                    </li >
                                ))}
                            </ul> : <div className='empty-placeholder f-wrp'>
                                <div className='placeholder-con'>
                                    <img src={AppIcons.inboxPlaceholder} alt='empty' />
                                    <h4>{translateThis('Inbox empty')}</h4>
                                    <p>{translateThis('There are 0 messages')} </p>
                                </div>
                            </div>
                            }
                        </div>
                    </div>

                    {openViewMessageDialog && <ModalPopUp
                        isOpen={openViewMessageDialog}
                        onClose={() => {
                            setOpenViewMessageDialog(false);
                        }}
                        className={'modal-popup inbox-popup'}
                        children={<div>

                            <InboxView data={selectedMessage} />
                        </div>}

                    />}
                    {showNewMessagePopup ? <ModalPopUp
                        isShowBackButton={false}
                        onClose={() => setShowNewMessagePopup(false)}
                        isOpen={showNewMessagePopup}
                    >
                        <AddEditMessageForm
                            onClose={() => setShowNewMessagePopup(false)}
                            onSuccess={() => {
                                getDataArr();
                                setShowNewMessagePopup(false);
                            }}

                        />

                    </ModalPopUp> : null}
                </div>
            </div>
        </div>
    )
}
InboxSection.propTypes = {}

export default InboxSection;
